import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './i18n';

import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

import "./App.css";
import Home from "../src/Components/pages/Home/Home";
import HomeCleaning from "./Components/pages/Homecleaning/Home_cleaning";
import WindowClean from "./Components/pages/WindowCleaning/Window_Clean";
import DHome from "./Components/pages/DeepCleaning/D_Home";
import Moveoutcleaning from "./Components/pages/MoveoutCleaning/Moveout_cleaning";
import About from "./Components/pages/Generalhandyman/Home_cleaning";
import Generalhandyman from "./Components/pages/About/Home_cleaning";
import Generalhandyman1 from "./Components/pages/About/sections/Generalhandyman1";
import HomeRenovation from "./Components/pages/HomeRenovation/Home_Renovation";
import Location from "./Components/pages/Location/Home_cleaning";
import Recycle from "./Components/pages/Recycle/Recycle";

import OfficeCleaning from "./Components/pages/OfficeCleaning/OfficeCleaning";
import ForHousingCooperative from "./Components/pages/ForHousingCooperative/ForHousingCooperative";
import OfficeRelocation from "./Components/pages/OfficeRelocation/OfficeRelocation";
import CleaningAsSaleryBenifit from "./Components/pages/CleaningAsSaleryBenifit/CleaningAsSaleryBenifit";

import Moving_In from "./Components/pages/MoveoutService/Moveout_cleaning";

import { Route, BrowserRouter, Routes } from "react-router-dom";
import Navbar from "./Components/Gernal_Components/Navbar";
import Footer from "./Components/Gernal_Components/Footer";
import { ChakraProvider } from "@chakra-ui/react";
import Booking from "./Components/pages/Booking/Booking";
import Calender from "./Components/pages/Booking/Calender";
import WindowCleaning from "./Components/pages/WindowCleaning/Window_Clean_Form";
import BookByVisit from "./Components/pages/Homecleaning/Book_By_Visit";
import BookByCall from "./Components/pages/Homecleaning/Book_By_Call";
import Contact from "./Components/pages/Contact/Contact";
import DeepCleaning from "./Components/pages/DeepCleaning/Deep_Cleaning";
import MoveForm from "./Components/pages/MoveoutCleaning/Moving_Service";
import MovingOutService from "./Components/pages/MoveoutCleaning/MovingOut_Service";
import MovingOutService1 from './Components/pages/MoveoutService/MovingOut_Service1';
import MovingService from './Components/pages/MoveoutService/Moving_Service';
import MovingOutService2 from './Components/pages/MoveoutService/sections/MovingOut_Service2'
import MovingOutCleaning from './Components/pages/MoveoutService/sections/MovingOutCleaning'
import HomeRenovation1 from "./Components/pages/HomeRenovation/HomeRenovation1"
import GoToTop from "./Components/GoToTop";
import Note from "./Components/pages/MoveoutCleaning/Note";

import Recycling from './Components/pages/Recycle/Recycling';
import OfficeCleaningContact from './Components/pages/OfficeCleaning/OfficeCleaningContact';
import OfficeRelocationContact from './Components/pages/OfficeRelocation/OfficeRelocationContact';
import ForHousingCooperativeContact from "./Components/pages/ForHousingCooperative/ForHousingCooperativeContact";
import CleaningAsSaleryBenifitContact from "./Components/pages/CleaningAsSaleryBenifit/CleaningAsSaleryBenifitContact";

import Loader from "./Components/Gernal_Components/Loader";

function App() {
  const [loading, setLoading] = useState(false);
  const [language , setLanguage] = useState("en")

  const translatePage = async (targetLanguage) => {
    setLoading(true); // Show loader
  
    // Get all the text nodes on the page
    const textNodes = getTextNodesIn(document.body);
  
    // Loop through each text node and translate its content
    for (const node of textNodes) {
      // Skip if the node is empty or only contains whitespace
      if (!node.nodeValue.trim()) continue;
  
      await axios.post('http://localhost:5000/translate', {
        q: node.nodeValue,
        source: language,
        target: targetLanguage,
      })
      .then(response => {
        // Replace the original text with the translated text
        node.nodeValue = response.data.translatedText;
      })
      .catch(error => {
        console.error("Error translating text: ", error);
      });
    }
  
    // Set the new language
    localStorage.setItem('language', targetLanguage);
  
    setLoading(false); // Hide loader after translation is done
  };
  
  
  // Helper function to get all text nodes in an element
  function getTextNodesIn(node) {
    let textNodes = [];
    if (node.nodeType === Node.TEXT_NODE) {
      textNodes.push(node);
    } else {
      let children = node.childNodes;
      for (let i = 0; i < children.length; i++) {
        textNodes.push(...getTextNodesIn(children[i]));
      }
    }
    return textNodes;
  }

  // function handleLanguageChange(lang) {
  //   // Check if a language is already set in localStorage
  //   const languageInLocalStorage = localStorage.getItem('language');
  
  //   if (languageInLocalStorage) {
  //     // If a language is already set, remove it
  //     localStorage.removeItem('language');
  
  //     // Reload the page
  //   window.location.reload();

  //   // Add an event listener for the 'load' event
  //   window.addEventListener('load', () => {
  //     // Call translatePage after the page reloads
  //     translatePage(lang);
  //   });
  //   } else {
  //     // If no language is set, start the translation normally
  //     translatePage(lang);
  //   }
  // }

  return (
    <ChakraProvider>
      <BrowserRouter>
        <TawkMessengerReact
          propertyId="661d2d721ec1082f04e27903"
          widgetId="1hrgvj2sd"
        />
        <div className="App text-center text-black ">
        <style jsx>{`
                .heading {
                    animation: pulse 1s infinite;
                }
                .preview-button{
                    transition: bottom 0.5s ease;
                }
                .image-div:hover .preview-button{
                    bottom: 20px;
                }
            `}</style>
          {loading && <Loader />}
          <Navbar translatePage={translatePage} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Home_clean" index element={<HomeCleaning />} />
            <Route path="/Window_clean" element={<WindowClean />} />
            <Route path="/Deep_Clean" element={<DHome />} />
            <Route path="/MovingService" element={<Moveoutcleaning />} />
            <Route path="/about" element={<About />} />
            <Route path="/Dödsbo" element={<Recycle />} />
            <Route path="/Dödsbo_Form" element={<Recycling />} />

            <Route path="office_cleaning" element={<OfficeCleaning />} />
            <Route path="office_cleaning_contact" element={<OfficeCleaningContact />}/>
            <Route path="for_housing_cooperative" element={<ForHousingCooperative />} />
            <Route path="for_housing_cooperative_contact" element={<ForHousingCooperativeContact />} />
            <Route path="office_relocation" element={<OfficeRelocation />} />
            <Route path="office_relocation_contact" element={<OfficeRelocationContact />}/>
            <Route path="cleaning_as_salery_benifit" element={<CleaningAsSaleryBenifit />} />
            <Route path="cleaning_as_salery_benifit_contact" element={<CleaningAsSaleryBenifitContact />} />

            <Route path="/home_Renovation" element={<HomeRenovation />} />
            <Route path="/home_Renovation1" element={<HomeRenovation1 />} />

            <Route path="/location" element={<Location />} />
            <Route path="/Generalhandyman" element={<Generalhandyman />} />
            <Route path="/Move_Out_Cleaning" element={<Moving_In />} />
            <Route path="/home_cleaning" element={<BookByVisit />} />
            <Route path="/book_by_call" element={<BookByCall />} />
            <Route path="/deep_cleaning" element={<DeepCleaning />} />
            <Route path="/window_cleaning" element={<WindowCleaning />} />
            <Route path="/Booking" element={<Booking />} />
            <Route path="/Generalhandyman1" element={<Generalhandyman1 />} />
            <Route path="/moving_service" element={<MoveForm />} />
            <Route path="/moving_out_service" element={<MovingOutService />} />
            <Route path="/moving_out_service1" element={<MovingOutService1 />} />
            <Route path="/moving_out_service2" element={<MovingOutService2 />} />

            <Route path="/moving_out_cleaning" element={<MovingOutCleaning />} />
            <Route path="/moving_service1" element={<MovingService />} />
            <Route path="/calender" element={<Calender />} />
            <Route path="/note" element={<Note />} />


            <Route path="/contact" element={<Contact />} />

            <Route path="*" element={<div>It and error</div>} />
          </Routes>
          <GoToTop />
          <Footer />
        </div>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
