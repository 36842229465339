import { useState } from 'react';
import { BiSolidChevronDown } from 'react-icons/bi';

import { useTranslation } from 'react-i18next';

const WhatIsIncluded = () => {

  const { t } = useTranslation();

    const [hoveredIndex, setHoveredIndex] = useState(null);

    const handleMouseEnter = (index) => {
      setHoveredIndex(index);
    };
  
    const handleMouseLeave = () => {
      setHoveredIndex(null);
    };
  
    const cleanmethods = [
      {
        heading: `${t('forHousingCooperative.whatIsIncluded.service.heading1')}`,
        description: t('forHousingCooperative.whatIsIncluded.service.description1', { returnObjects: true }),
        iconUrl: 'img/hgenral.svg',
      },
      {
        heading: `${t('forHousingCooperative.whatIsIncluded.service.heading2')}`,
        description: t('forHousingCooperative.whatIsIncluded.service.description2', { returnObjects: true }),
        iconUrl: 'img/hBathroom.svg',
      },
      {
        heading: `${t('forHousingCooperative.whatIsIncluded.service.heading3')}`,
        description: t('forHousingCooperative.whatIsIncluded.service.description3', { returnObjects: true }),
        iconUrl: 'img/hkitchen.svg',
      },
      {
        heading: `${t('forHousingCooperative.whatIsIncluded.service.heading4')}`,
        description: t('forHousingCooperative.whatIsIncluded.service.description4', { returnObjects: true }),
        iconUrl: 'img/hgenral.svg',
      },
      {
        heading: `${t('forHousingCooperative.whatIsIncluded.service.heading5')}`,
        description: t('forHousingCooperative.whatIsIncluded.service.description5', { returnObjects: true }),
        iconUrl: 'img/hBathroom.svg',
      },
      {
        heading: `${t('forHousingCooperative.whatIsIncluded.service.heading6')}`,
        description: t('forHousingCooperative.whatIsIncluded.service.description6', { returnObjects: true }),
        iconUrl: 'img/hkitchen.svg',
      },
    ];
  
    return (
      <div className='py-14 bg-[#eae8e1]'>
        <div className='relative'>
          <div className='pt-12 text-5xl font-bold text-red-800'>
            {t('forHousingCooperative.whatIsIncluded.heading.headingPart1')} <span className='text-orange-600'>{t('forHousingCooperative.whatIsIncluded.heading.headingPart2')}</span>?
          </div>
          <div className='w-full mt-8 flex justify-center items-center'>
            <p className='border-black border-2 w-20 hover:w-[550PX] text-center transition-all duration-300 transform origin-center scale-100 delay-200 hover:border-gray-400'></p>
          </div>
          <div className='md:w-4/6 md:ml-[14%] mt-8 text-lg leading-7 '>
          <p>{t('forHousingCooperative.whatIsIncluded.heading.paragraph')} </p>
          </div>
        </div>
  
        <div className='px-2 py-8 flex justify-center'>
          <div className='grid lg:grid-cols-2 w-full max-w-6xl gap-8'>
            {cleanmethods.map((item, index) => (
              <div
                key={index}
                className='col-span-1 accordion-item'
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <h2 className='font-xl font-semibold'>
                  <div className='text-3xl text-gray-800 hover:text-gray-500'>
                    <div className='flex hover:shake'>
                      <img src={item.iconUrl} alt='Icon' className='w-10 font-semibold pr-2 hover:scale-95 transition duration-300 ease-in-out' />
                      <h4 className='mr-2'>{item.heading}</h4>
                      <div className='text-3xl transition-transform duration-300 transform ml-auto' style={{ transform: hoveredIndex === index ? 'rotate(180deg)' : 'rotate(0deg)' }}>
                        <BiSolidChevronDown
                          className={`w-8 h-8 border-2 rounded-full ${hoveredIndex === index ? 'text-yellow-400 border-yellow-400' : 'text-green-500 border-green-500'}`}
                        />
                      </div>
                    </div>
                  </div>
                </h2>
                <div className={`border-l-2 rounded-bl-md dark:border-none overflow-hidden duration-[1500ms] transition-all ${hoveredIndex === index ? 'max-h-[500px]' : 'max-h-0'}`}>
                  <h3 className='text-start text-lg pb-1 font-semibold'>{item.title}</h3>
                  <ul className='list-disc marker:text-blue-700 text-start pl-5 space-y-3 text-gray-700'>
                    {item?.description?.map((desc, i) => (
                      <li key={i}>{desc}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

export default WhatIsIncluded;