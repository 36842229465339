import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CleaningSub from "./subnavbar/CleaningSub";
import MovingSub from "./subnavbar/MovingSub";
import RenovationSub from "./subnavbar/Renovation";
import BussinessSub from "./subnavbar/BussinessSub";

import AboutSub from "./subnavbar/AboutSub";
import { Button, border } from "@chakra-ui/react";
import { FaTimes, FaCaretUp, FaCaretDown } from 'react-icons/fa';

import { useTranslation } from 'react-i18next';

const Navbar = ({ translatePage }) => {

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [showMenu, setShowMenu] = useState(false);
  const [showCleaningSub, setShowCleaningSub] = useState(false);
  const [language, setLanguage] = useState("");
  
  useEffect(() => {
    const languageFromStorage = localStorage.getItem('language');
    if (languageFromStorage) {
      setLanguage(languageFromStorage);
    }
  }, []);

  const [isActive, setIsActive] = useState(false);
  const [selected, setIsSelected] = useState(language ? language : "SV");
  function handleBlur(e) {
    console.log(e);
  }

  function setLanguageInLocalStorage(lang) {
    localStorage.setItem('language', lang);
  }

  function unsetLanguage() {
    localStorage.removeItem('language');
  }
  

  useEffect(() => {
    const checkSize = () => {
      // Set the width you want to check against
      const mediumScreenSize = 768;

      if (window.innerWidth > mediumScreenSize) {
        setShowMenu(false);
      }
    };

    // Call checkSize on mount and on window resize
    checkSize();
    window.addEventListener('resize', checkSize);

    // Cleanup event listener on unmount
    return () => window.removeEventListener('resize', checkSize);
  }, []);


  const [isHover, setisHover] = useState("");
  useEffect(() => {
    let timer;
    if (isHover === "Cleaning" || isHover === "About" || isHover === "Moving" || isHover === "Renovation" || isHover === "For Businesses") {
      timer = setTimeout(() => {
        setShowCleaningSub(true);
      }, 200);
    } else {
      setShowCleaningSub(false);
    }

    // Clear the timer if isHover changes before the delay completes
    return () => clearTimeout(timer);
  }, [isHover]);
  /*   useEffect(() => {
      if (isHover === "Cleaning") {
        const timer = setTimeout(() => {
          setShowCleaningSub(true);
        }, 500);
  
        // Clear the timer if isHover changes before the delay completes
        return () => clearTimeout(timer);
      }
      else if (isHover === "About") {
        const timer = setTimeout(() => {
          setShowCleaningSub(true);
        }, 500);
  
        // Clear the timer if isHover changes before the delay completes
        return () => clearTimeout(timer);
      } else {
        // Reset the state if isHover changes to a different value
        setShowCleaningSub(false);
      }
    }, [isHover]); */
  const names = [
    "Cleaning",
    "Moving",
    "Renovation",
    "Recycling",
    "For Businesses",
    "Our locations",
    "About Hemrfied",
  ];


  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });


    // Introduce a delay (you can adjust the duration)
    const delayDuration = 500; // in milliseconds
    setTimeout(() => {
      // Now, navigate to the desired route
    }, delayDuration);
  };
  return (
    <>
      <nav className="w-screen bg-gray-900   h-[75px] mt-[.3px] z-50  flex items-center px-5 font-semibold  justify-between fixed">
        
        <Link to="/" className="cursor-pointer" onClick={handleClick}>
          <img
            src="img/FSALOGO.svg"

            className="w-20 md:w-40 h-auto hover:scale-95 transition duration-300 ease-in-out"
            alt=""
            srcSet=""

          />
        </Link>

        <div className="absolute inset-y-0 right-4 flex items-center sm:hidden">
            <button
                type="button"
                onClick={() => setShowMenu(!showMenu)}
                className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded={showMenu}
            >

                <svg className={` h-6 w-6 ${showMenu ? 'hidden' : ''}`} fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>

                <FaTimes className={` h-6 w-6 ${showMenu ? '' : 'hidden'}`} />
            </button>
        </div>
        <div className={`md:ml-6 md:block ${showMenu ? 'block' : 'hidden'} md:hidden`}>
          <ul className="absolute flex flex-col mt-[35px] right-10 bg-[rgb(29,33,47)] text-white py-2">
              <li onClick={() => { setShowMenu(!showMenu); window.scrollTo(0, 0); }} className="px-4"><Link to="/MovingService">{t('generalComponents.navBarSub.linkMovingServices')}</Link></li>
              <li onClick={() => { setShowMenu(!showMenu); window.scrollTo(0, 0); }} className="px-4"><Link to="/Move_Out_Cleaning">{t('generalComponents.navBarSub.linkMovingOutCleaning')}</Link></li>
              <li onClick={() => { setShowMenu(!showMenu); window.scrollTo(0, 0); }} className="px-4"><Link to="/Dödsbo">Dödsbo</Link></li>
              <li onClick={() => { setShowMenu(!showMenu); window.scrollTo(0, 0); }} className="px-4"><Link to="/Home_clean">{t('generalComponents.navBarSub.linkHomeCleaning')}</Link></li>
              <li onClick={() => { setShowMenu(!showMenu); window.scrollTo(0, 0); }} className="px-4"><Link to="/Window_clean">{t('generalComponents.navBarSub.linkWindowCleaning')}</Link></li>
              <li onClick={() => { setShowMenu(!showMenu); window.scrollTo(0, 0); }} className="px-4"><Link to="/Deep_Clean">{t('generalComponents.navBarSub.linkDeepCleaning')}</Link></li>
              <li onClick={() => { setShowMenu(!showMenu); window.scrollTo(0, 0); }} className="px-4"><Link to="/home_Renovation">{t('generalComponents.navBarSub.linkHomeRenovation')}</Link></li>
              <li onClick={() => { setShowMenu(!showMenu); window.scrollTo(0, 0); }} className="px-4"><Link to="/Generalhandyman">{t('generalComponents.navBarSub.linkGeneralHandyman')}</Link></li>
              <li onClick={() => { setShowMenu(!showMenu); window.scrollTo(0, 0); }} className="px-4"><Link to="/office_cleaning">{t('generalComponents.navBarSub.linkOfficeCleaning')}</Link></li>
              <li onClick={() => { setShowMenu(!showMenu); window.scrollTo(0, 0); }} className="px-4"><Link to="/for_housing_cooperative">{t('generalComponents.navBarSub.linkForHousingCooperative')}</Link></li>
              <li onClick={() => { setShowMenu(!showMenu); window.scrollTo(0, 0); }} className="px-4"><Link to="/office_relocation">{t('generalComponents.navBarSub.linkOfficeRelocation')}</Link></li>
              <li onClick={() => { setShowMenu(!showMenu); window.scrollTo(0, 0); }} className="px-4"><Link to="/cleaning_as_salery_benifit">{t('generalComponents.navBarSub.linkCleaningasSalaryBenefit')}</Link></li>
              <li onClick={() => { setShowMenu(!showMenu); window.scrollTo(0, 0); }} className="px-4"><Link to="/About">{t('generalComponents.navBarSub.linkAboutFsa')}</Link></li>
              <li onClick={() => { setShowMenu(!showMenu); window.scrollTo(0, 0); }} className="px-4"><Link to="/location">{t('generalComponents.navBarSub.linkOurLocations')}</Link></li>
              <li onClick={() => { setShowMenu(!showMenu); window.scrollTo(0, 0); }} className="px-4"><Link to="/contact">{t('generalComponents.navBarSub.linkContactUs')}</Link></li>
          </ul>
        </div>

        <div className="flex-justify-end">
          <ul className="hidden lg:flex cursor-pointer flex-justify-end  lg:text-lg lg:ml-3 xl:text-lg 2xl:text-xl    md:text-sm  relative">



            <p
              onMouseEnter={() => setisHover("Moving")}
              onMouseLeave={() => setisHover("")}
              className="inline-block relative  py-7 2xl:mx-4"
            >
              <li className="mx-4 xl:mx-4  2xl:mx-8 transition-all duration-500 before:content-[''] before:absolute before:bottom-0 before:left-0 before:w-0 before:h-0.4 before:rounded-full before:opacity-0 before:transition-all before:duration-500 before:bg-gradient-to-r     before:from-pink-700 before:via-pink-700 before:to-pink-700 hover:before:w-full hover:before:opacity-100 hover:text-pink-700 hover:font-semibold  text-white">
                {t('generalComponents.navBar.linkMovingHouse')}
              </li>
            </p>

            <p className=" inline-block relative  py-7  transition-all duration-500 before:content-[''] before:absolute before:bottom-0 before:left-0 before:w-0 before:h-0.4 before:rounded-full before:opacity-0 before:transition-all before:duration-500 before:bg-gradient-to-r     before:from-pink-700 before:via-pink-700 before:to-pink-700 hover:before:w-full hover:before:opacity-100 hover:text-pink-700 hover:font-semibold  text-white">
              <Link to="/Dödsbo" onClick={handleClick}>
                <li className="mx-2 xl:mx-3 2xl:mx-4 inline-block  ">
                  Dödsbo
                </li>
              </Link>
            </p>

            <p
              onMouseEnter={() => setisHover("Cleaning")}
              onMouseLeave={() => setisHover("")}
              className="inline-block relative  py-7   transition-all duration-500 before:content-[''] before:absolute before:bottom-0 before:left-0 before:w-0 before:h-0.4 before:rounded-full before:opacity-0 before:transition-all before:duration-500 before:bg-gradient-to-r      before:from-pink-700 before:via-pink-700 before:to-pink-700 hover:before:w-full hover:before:opacity-100 hover:text-pink-700 hover:font-semibold text-white"
            >
              <li className="mx-4 w-fit xl:mx-4   2xl:mx-4 ">{t('generalComponents.navBar.linkCleaning')}</li>
            </p>

            <p onMouseEnter={() => setisHover("Renovation")}
              onMouseLeave={() => setisHover("")}
              className="inline-block relative  py-7  transition-all duration-500 before:content-[''] before:absolute before:bottom-0 before:left-0 before:w-0 before:h-0.4 before:rounded-full before:opacity-0 before:transition-all before:duration-500 before:bg-gradient-to-r     before:from-pink-700 before:via-pink-700 before:to-pink-700 hover:before:w-full hover:before:opacity-100 hover:text-pink-700 hover:font-semibold  text-white">
              <li className="mx-4 xl:mx-4 2xl:mx-4 inline-block  ">{t('generalComponents.navBar.linkRenovation')}</li>
            </p>

            <p onMouseEnter={() => setisHover("For Businesses")}
              onMouseLeave={() => setisHover("")}
              className="inline-block relative  py-7  transition-all duration-500 before:content-[''] before:absolute before:bottom-0 before:left-0 before:w-0 before:h-0.4 before:rounded-full before:opacity-0 before:transition-all before:duration-500 before:bg-gradient-to-r     before:from-pink-700 before:via-pink-700 before:to-pink-700 hover:before:w-full hover:before:opacity-100 hover:text-pink-700 hover:font-semibold  text-white">
              <li className="mx-4 xl:mx-4 2xl:mx-4 inline-block  ">{t('generalComponents.navBar.linkForBusinesses')}</li>
            </p>


            <p
              onMouseEnter={() => setisHover("About")}
              onMouseLeave={() => setisHover("")}
              className="inline-block relative  py-7  ml-2 transition-all duration-500 before:content-[''] before:absolute before:bottom-0 before:left-0 before:w-0 before:h-0.4 before:rounded-full before:opacity-0 before:transition-all before:duration-500 before:bg-gradient-to-r     before:from-pink-700 before:via-pink-700 before:to-pink-700 hover:before:w-full hover:before:opacity-100 hover:text-pink-700 hover:font-semibold  text-white"
            >
              <li className=" ">{t('generalComponents.navBar.linkAboutFsa')}</li>
            </p>
          </ul>


          {isHover === "Cleaning" && showCleaningSub && <CleaningSub setisHover={setisHover} />}
          {isHover === "About" && showCleaningSub && <AboutSub setisHover={setisHover} />}
          {isHover === "Moving" && showCleaningSub && <MovingSub setisHover={setisHover} />}
          {isHover === "Renovation" && showCleaningSub && <RenovationSub setisHover={setisHover} />}
          {isHover === "For Businesses" && showCleaningSub && <BussinessSub setisHover={setisHover} />}



        </div>

        <div className="flex items-center mr-10 md:mr-8 2xl:text-2xl">

        <Button
          style={{ background: "black" }}
          onClick={(e) => {
            // translatePage("sv");
            // setLanguageInLocalStorage("sv");
            changeLanguage('sv');
            setIsSelected(e.target.textContent);
            setIsActive(!isActive);
          }}
          className="rounded-xl ml-2 md:ml-0 px-3 md:px-4 py-1 overflow-hidden relative group cursor-pointer border-2 font-medium text-[16px] border-white text-black hover:scale-105 duration-[900ms] hover:border-[#003942] hover:text-[white]"
          >
            <span className="absolute w-72 h-0 transition-all duration-[900ms] origin-center rotate-45 -translate-x-20 bg-white top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
            <span className="relative text-xl text-white transition duration-[900ms] group-hover:text-black ease">SV</span>
        </Button>
        <div className="md:inline-block border-r border-zinc-400 h-12 mx-2 hidden"></div>
          <Button
            style={{ background: "black" }}
            onClick={() => {changeLanguage('en')}}
            className="rounded-xl ml-2 md:ml-0 px-3 md:px-4 py-1 overflow-hidden relative group cursor-pointer border-2 font-medium text-[16px] border-white text-black hover:scale-105 duration-[900ms] hover:border-[#003942] hover:text-[white]"
          >
            <span className="absolute w-72 h-0 transition-all duration-[900ms] origin-center rotate-45 -translate-x-20 bg-white top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
            <span className="relative text-xl text-white transition duration-[900ms] group-hover:text-black ease">EN</span>
          </Button>

          {/*  <p className="cursor-pointer  inline-block font-[700] border-opacity-[0.2] mx-2  text-white ">
            EN
          </p> */}
        </div>



      </nav>
    </>
  );
};

export default Navbar;
