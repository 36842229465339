import React, { useEffect } from 'react';
import useTranslatePage from './../../Gernal_Components/TranslatePage';
import Loader from './../../Gernal_Components/Loader';

import Hero from "./Sections/Hero";
import TimeforSimple from "./Sections/TimeforSimpler";
import Tipsinsp from "./Sections/Tips&Insp";

import EmptyingAnEstate from "./Sections/EmptyingAnEstate";
import ValuationOfEstates from "./Sections/ValuationOfEstates";
import FourIconsComponent from "./Sections/FourIconsComponent";
import NetWorthInTheEstate from "./Sections/first-NetWorthInTheEstate";
import EmptyingTheEstate from "./Sections/second-EmptyingTheEstate";
import CleaningOfTheEstate from "./Sections/third-CleaningOfTheEstate";
import FormRecycling from "./Sections/Form-recycling";

// import Appsection from "./Sections/Appsection";
import WhyHermified from "./Sections/WhyHermfid";
import Cleaningtoloc from "./Sections/cleaning_to_loc";
import HelpWith from "./Sections/help_with";
import Review from "./Sections/review";
import WorkAtHemfird from "./Sections/workatHemfird";

const Home = () => {

    const [translatePage, loading] = useTranslatePage();

    useEffect(() => {
        const lang = localStorage.getItem('language');
        if (lang) {
        translatePage(lang);
        }
    }, []);

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const targetPosition = element.offsetTop - 200;
            window.scrollTo({ top: targetPosition, behavior: 'smooth' });
        }
    };
    return (
        <>
        {loading && <Loader />}
            <div>
                <Hero />
                {/* <HelpWith /> */}
                <EmptyingAnEstate/>
                <ValuationOfEstates/>
                <FourIconsComponent  scrollToSection={scrollToSection}/>
                <NetWorthInTheEstate/>
                <EmptyingTheEstate/>
                <CleaningOfTheEstate/>
                <FormRecycling  scrollToSection={scrollToSection}/>
                {/* <Review /> */}
                {/* <Appsection /> */}
                {/* <Tipsinsp /> */}
                {/*  <WorkAtHemfird />
                <Cleaningtoloc /> */}
                <WhyHermified />
                <TimeforSimple />
            </div>
        </>
    );
};

export default Home;
