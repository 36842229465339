import { Link } from "react-router-dom";

import { useTranslation } from 'react-i18next';

const Work = () => {

    const { t } = useTranslation();

    return (
        <div>




            <div class="h-fit bg-[#d1dce7] ">
                <h3 className="text-5xl font-medium pt-28">{t('deepCleaning.howDoesItWork.heading.headingPart1')} <span className='text-red-600 heading'>{t('deepCleaning.howDoesItWork.heading.headingPart2')}</span></h3>

                {/* line content */}
                <div className="w-full  flex justify-center mt-8">

                    <p className=" border-black border-2 w-20 hover:w-[550PX] text-center transition-all duration-300 transform origin-center scale-100 delay-200 hover:border-gray-400" ></p>

                </div>

                {/* row begins */}
                <div className="   mt-14 mx-24 h-fit    pb-24  ">

                    <div className="flex sm:flex-row flex-col justify-between">
                        {/* first row */}
                        <div className="flex my-3 sm:my-0 flex-col ">


                            <div className=" ml-1 flex  flex-col items-center justify-center transform hover:scale-105 duration-300">
                                <Link to={"/"} >
                                    <div className="bg-[hsla(0,0%,100%,.4)]  self-center   h-36 w-36 flex items-center justify-center rounded-full  hover:bg-white rotations">

                                        <img
                                            src="img/wi1.svg" className="h-12" alt="img_icon" />
                                    </div>

                                </Link>

                                <h1 className="font-bold mt-9 text-lg leading-10">{t('deepCleaning.howDoesItWork.card1.heading')} </h1>

                            </div>



                            <p className="text-base">
                                {t('deepCleaning.howDoesItWork.card1.paragraph1')} <br /> {t('deepCleaning.howDoesItWork.card1.paragraph2')}</p>






                        </div>

                        {/* second row */}
                        <div className="flex flex-col my-3 sm:my-0 ">


                            <div className=" ml-1 flex  flex-col items-center justify-centertransform hover:scale-105 duration-300 ">
                                <Link to={"/Moving_Home"} >
                                    <div className="bg-[hsla(0,0%,100%,.4)]  self-center   h-36 w-36 flex items-center justify-center rounded-full  hover:bg-white rotations">


                                        <img
                                            src="img/wi2.svg" className="h-12" alt="img_icon" />


                                    </div>

                                </Link>

                                <h1 className="font-bold mt-9 text-lg leading-10">{t('deepCleaning.howDoesItWork.card2.heading')}</h1>

                            </div>


                            <p className="text-base">{t('deepCleaning.howDoesItWork.card2.paragraph1')} <br /> {t('deepCleaning.howDoesItWork.card2.paragraph2')}<br />{t('deepCleaning.howDoesItWork.card2.paragraph3')} </p>






                        </div>


                        {/* Third row */}

                        <div className="flex  my-3 sm:my-0 flex-col">


                            <div className=" ml-1 flex  flex-col items-center justify-center transform hover:scale-105 duration-300">
                                <Link to={"/Window_clean"} >
                                    <div className="bg-[hsla(0,0%,100%,.4)]  self-center   h-36 w-36 flex items-center justify-center rounded-full  hover:bg-white rotations">


                                        <img
                                            src="img/ha.svg" className="h-12" alt="img_icon" />


                                    </div>
                                </Link>


                                <h1 className="font-bold mt-9 text-lg leading-10">{t('deepCleaning.howDoesItWork.card3.heading')}
                                </h1>
                            </div>



                            <p className="text-base">{t('deepCleaning.howDoesItWork.card3.paragraph1')} <br /> {t('deepCleaning.howDoesItWork.card3.paragraph2')} <br /> {t('deepCleaning.howDoesItWork.card3.paragraph3')} </p>






                        </div>


                    </div>

                </div>
                {/* row ends */}

            </div>

        </div>
    );

}

export default Work;