import { IoIosArrowForward } from "react-icons/io";
import "./cleaningsub.css";
import { Link } from "react-router-dom";

import { useTranslation } from 'react-i18next';

const CleaningSub = ({ setisHover }) => {
  
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    setisHover("")
    // Introduce a delay (you can adjust the duration)
    const delayDuration = 500; // in milliseconds
    setTimeout(() => {
      // Now, navigate to the desired route
    }, delayDuration);
  };

  return (
    <div
      className="absolute w-full bg-gray-100 top-16 right-0 z-50 flex flex-col items-end transition-all duration-700"
      onMouseEnter={() => setisHover("About")}
      onMouseLeave={() => setisHover("")}
    >

      <div className="flex flex-col gap-y-10 px-24 py-8 w-1/2 ">
        <Link className='text-2xl text-start flex items-end' >
          <img className="w-10 hover:shake" src="https://images.ctfassets.net/37vwfxlcawdb/5e2SSlRP2Oxtwh7V8TMlBU/61d589d79881b8faef21103b039c2263/Hemfrid_Piktogram_Flytthja__lp_64x64px-01.svg" alt="" />
          <p className='px-4'>{t('generalComponents.navBarSub.heading5')}</p>
          <IoIosArrowForward className='ml-auto w-9 h-9' />
        </Link>
        <ul className="grid grid-cols-2 gap-8 text-xl">

          <Link
            to="/About"
            onClick={handleClick}
            className="col-span-1 border-b-2 border-gr1 py-2 flex hover:border-rounded"
          >
            <li className="square-border hover:font-semibold">{t('generalComponents.navBarSub.linkAboutFsa')}</li>
            <IoIosArrowForward className="ml-auto w-7 h-7" />
          </Link>
          {/* <Link
            to="/Window_clean"
            onClick={handleClick}
            className="col-span-1 border-b-2 border-gr1 py-2 flex"
          >
            <li className="square-border hover:font-semibold">
              Life at Hemfrid
            </li>
            <IoIosArrowForward className="ml-auto w-7 h-7" />
          </Link> */}
          <Link
            to="/location"
            onClick={handleClick}
            className="col-span-1 border-b-2 border-gr1 py-2 flex"
          >
            <li className="square-border hover:font-semibold">{t('generalComponents.navBarSub.linkOurLocations')}</li>
            <IoIosArrowForward className="ml-auto w-7 h-7" />
          </Link>
          {/* <Link
            to="/MovingService"
            onClick={handleClick}
            className="col-span-1 border-b-2 border-gr1 py-2 flex"
          >
            <li className="square-border hover:font-semibold">
              Work with us
            </li>
            <IoIosArrowForward className="ml-auto w-7 h-7" />
          </Link> */}
          <Link
            to="/contact"
            onClick={handleClick}
            className="col-span-1 border-b-2 border-gr1 py-2 flex"
          >
            <li className="square-border hover:font-semibold">
              {t('generalComponents.navBarSub.linkContactUs')}
            </li>
            <IoIosArrowForward className="ml-auto w-7 h-7" />
          </Link>
          {/* <Link
            to="/MovingService"
            onClick={handleClick}
            className="col-span-1 border-b-2 border-gr1 py-2 flex"
          >
            <li className="square-border hover:font-semibold">
              FAQ’s
            </li>
            <IoIosArrowForward className="ml-auto w-7 h-7" />
          </Link> */}
        </ul>
      </div>
    </div>
  );
};

export default CleaningSub;
