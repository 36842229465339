// const ZIPCODES = 
// [
//     {
//         zipcode: 38447,
//         city : "Karachi"
//     },
//     {
//         zipcode: 39484,
//         city : "DGK"
//     },
//     {
//         zipcode: 32488,
//         city : "Islamabad"
//     },
//     {
//         zipcode: 59459,
//         city : "Lahore"
//     }
// ]

const ZIPCODES = [
  { city: "Märsta", zipcode: 16986 },
  { city: "Märsta", zipcode: 16189 },
  { city: "Märsta", zipcode: 19530 },
  { city: "Märsta", zipcode: 19531 },
  { city: "Märsta", zipcode: 19532 },
  { city: "Märsta", zipcode: 19533 },
  { city: "Märsta", zipcode: 19534 },
  { city: "Märsta", zipcode: 19535 },
  { city: "Märsta", zipcode: 19536 },
  { city: "Märsta", zipcode: 19540 },
  { city: "Märsta", zipcode: 19541 },
  { city: "Märsta", zipcode: 19542 },
  { city: "Märsta", zipcode: 19543 },
  { city: "Märsta", zipcode: 19544 },
  { city: "Märsta", zipcode: 19545 },
  { city: "Märsta", zipcode: 19546 },
  { city: "Märsta", zipcode: 19547 },
  { city: "Märsta", zipcode: 19550 },
  { city: "Märsta", zipcode: 19551 },
  { city: "Märsta", zipcode: 19552 },
  { city: "Märsta", zipcode: 19553 },
  { city: "Märsta", zipcode: 19554 },
  { city: "Märsta", zipcode: 19555 },
  { city: "Märsta", zipcode: 19556 },
  { city: "Märsta", zipcode: 19557 },
  { city: "Märsta", zipcode: 19558 },
  { city: "Märsta", zipcode: 19560 },
  { city: "Märsta", zipcode: 19500 },
  { city: "Märsta", zipcode: 19502 },
  { city: "Märsta", zipcode: 19503 },
  { city: "Märsta", zipcode: 19506 },
  { city: "Märsta", zipcode: 19510 },
  { city: "Märsta", zipcode: 19518 },
  { city: "Märsta", zipcode: 19519 },
  { city: "Märsta", zipcode: 19520 },
  { city: "Märsta", zipcode: 19521 },
  { city: "Märsta", zipcode: 19522 },
  { city: "Märsta", zipcode: 19523 },
  { city: "Märsta", zipcode: 19524 },
  { city: "Märsta", zipcode: 19525 },
  { city: "Af Pontins väg", zipcode: 19565 },
  { city: "Af Pontins väg", zipcode: 19591 },
  { city: "Af Pontins väg", zipcode: 19595 },
  { city: "Akersberga", zipcode: 18420 },
  { city: "Alta", zipcode: 13820 },
  { city: "Alsjo", zipcode: 12500 },
  { city: "Alsjo", zipcode: 12501 },
  { city: "Alsjo", zipcode: 12502 },
  { city: "Alsjo", zipcode: 12503 },
  { city: "Alsjo", zipcode: 12505 },
  { city: "Alsjo", zipcode: 12521 },
  { city: "Alsjo", zipcode: 12522 },
  { city: "Alsjo", zipcode: 12523 },
  { city: "Alsjo", zipcode: 12524 },
  { city: "Alsjo", zipcode: 12525 },
  { city: "Alsjo", zipcode: 12530 },
  { city: "Alsjo", zipcode: 12531 },
  { city: "Alsjo", zipcode: 12532 },
  { city: "Alsjo", zipcode: 12533 },
  { city: "Alsjo", zipcode: 12534 },
  { city: "Alsjo", zipcode: 12535 },
  { city: "Alsjo", zipcode: 12540 },
  { city: "Alsjo", zipcode: 12541 },
  { city: "Alsjo", zipcode: 12542 },
  { city: "Alsjo", zipcode: 12543 },
  { city: "Alsjo", zipcode: 12544 },
  { city: "Alsjo", zipcode: 12546 },
  { city: "Alsjo", zipcode: 12547 },
  { city: "Alsjo", zipcode: 12548 },
  { city: "Alsjo", zipcode: 12551 },
  { city: "Alsjo", zipcode: 12552 },
  { city: "Alsjo", zipcode: 12553 },
  { city: "Alsjo", zipcode: 12554 },
  { city: "Alsjo", zipcode: 12555 },
  { city: "Alsjo", zipcode: 12556 },
  { city: "Alsjo", zipcode: 12557 },
  { city: "Alsjo", zipcode: 12558 },
  { city: "Alsjo", zipcode: 12559 },
  { city: "Alsjo", zipcode: 12560 },
  { city: "Alsjo", zipcode: 12570 },
  { city: "Alsjo", zipcode: 12571 },
  { city: "Alsjo", zipcode: 12572 },
  { city: "Alsjo", zipcode: 12573 },
  { city: "Alsjo", zipcode: 12574 },
  { city: "Alsjo", zipcode: 12586 },
  { city: "Alsjo", zipcode: 12520 },
  { city: "Alsjo", zipcode: 12545 },
  { city: "Arsta", zipcode: 12002 },
  { city: "Arsta", zipcode: 12003 },
  { city: "Arsta", zipcode: 12004 },
  { city: "Arsta", zipcode: 12005 },
  { city: "Arsta", zipcode: 12010 },
  { city: "Arsta", zipcode: 12038 },
  { city: "Arsta", zipcode: 12039 },
  { city: "Arsta", zipcode: 12040 },
  { city: "Arsta", zipcode: 12044 },
  { city: "Arsta", zipcode: 12050 },
  { city: "Arsta", zipcode: 12051 },
  { city: "Arsta", zipcode: 12052 },
  { city: "Arsta", zipcode: 12053 },
  { city: "Arsta", zipcode: 12054 },
  { city: "Arsta", zipcode: 12055 },
  { city: "Arsta", zipcode: 12056 },
  { city: "Arsta", zipcode: 12057 },
  { city: "Arsta", zipcode: 12058 },
  { city: "Arsta", zipcode: 12059 },
  { city: "Arsta", zipcode: 12060 },
  { city: "Årstaängsvägen", zipcode: 11743 },
  { city: "Bagarmossen", zipcode: 12804 },
  { city: "Bagarmossen", zipcode: 12805 },
  { city: "Bagarmossen", zipcode: 12841 },
  { city: "Bagarmossen", zipcode: 12842 },
  { city: "Bagarmossen", zipcode: 12843 },
  { city: "Bagarmossen", zipcode: 12844 },
  { city: "Bagarmossen", zipcode: 12845 },
  { city: "Bagarmossen", zipcode: 12846 },
  { city: "Bagarmossen", zipcode: 12847 },
  { city: "Bagarmossen", zipcode: 12848 },
  { city: "Bandhagen", zipcode: 12400 },
  { city: "Bandhagen", zipcode: 12403 },
  { city: "Bandhagen", zipcode: 12404 },
  { city: "Bandhagen", zipcode: 12421 },
  { city: "Bandhagen", zipcode: 12422 },
  { city: "Bandhagen", zipcode: 12425 },
  { city: "Bandhagen", zipcode: 12430 },
  { city: "Bandhagen", zipcode: 12431 },
  { city: "Bandhagen", zipcode: 12432 },
  { city: "Bandhagen", zipcode: 12433 },
  { city: "Bandhagen", zipcode: 12436 },
  { city: "Bandhagen", zipcode: 12450 },
  { city: "Bandhagen", zipcode: 12451 },
  { city: "Bandhagen", zipcode: 12452 },
  { city: "Bandhagen", zipcode: 12453 },
  { city: "Bandhagen", zipcode: 12454 },
  { city: "Bandhagen", zipcode: 12455 },
  { city: "Bandhagen", zipcode: 12456 },
  { city: "Bandhagen", zipcode: 12457 },
  { city: "Bandhagen", zipcode: 12458 },
  { city: "Bandhagen", zipcode: 12459 },
  { city: "Bandhagen", zipcode: 12460 },
  { city: "Bandhagen", zipcode: 12461 },
  { city: "Bandhagen", zipcode: 12462 },
  { city: "Bandhagen", zipcode: 12463 },
  { city: "Bandhagen", zipcode: 12464 },
  { city: "Bandhagen", zipcode: 12465 },
  { city: "Bandhagen", zipcode: 12466 },
  { city: "Bandhagen", zipcode: 12467 },
  { city: "Bandhagen", zipcode: 12470 },
  { city: "Bandhagen", zipcode: 12471 },
  { city: "Bandhagen", zipcode: 12472 },
  { city: "Bandhagen", zipcode: 12473 },
  { city: "Bandhagen", zipcode: 12474 },
  { city: "Bandhagen", zipcode: 12475 },
  { city: "Bandhagen", zipcode: 12476 },
  { city: "Bandhagen", zipcode: 12420 },
  { city: "Bromma", zipcode: 19700 },
  { city: "Bromma", zipcode: 19720 },
  { city: "Bromma", zipcode: 19721 },
  { city: "Bromma", zipcode: 19722 },
  { city: "Bromma", zipcode: 19725 },
  { city: "Bromma", zipcode: 19730 },
  { city: "Bromma", zipcode: 19731 },
  { city: "Bromma", zipcode: 19732 },
  { city: "Bromma", zipcode: 19733 },
  { city: "Bromma", zipcode: 19734 },
  { city: "Bromma", zipcode: 19735 },
  { city: "Bromma", zipcode: 19736 },
  { city: "Bromma", zipcode: 19740 },
  { city: "Bromma", zipcode: 19791 },
  { city: "Bromma", zipcode: 19792 },
  { city: "Bromma", zipcode: 19793 },
  { city: "Bromma", zipcode: 16100 },
  { city: "Bromma", zipcode: 16102 },
  { city: "Bromma", zipcode: 16103 },
  { city: "Bromma", zipcode: 16104 },
  { city: "Bromma", zipcode: 16105 },
  { city: "Bromma", zipcode: 16107 },
  { city: "Bromma", zipcode: 16111 },
  { city: "Bromma", zipcode: 16124 },
  { city: "Bromma", zipcode: 16125 },
  { city: "Bromma", zipcode: 16126 },
  { city: "Bromma", zipcode: 16184 },
  { city: "Bromma", zipcode: 16120 },
  { city: "Bromma", zipcode: 16700 },
  { city: "Bromma", zipcode: 16714 },
  { city: "Bromma", zipcode: 16715 },
  { city: "Bromma", zipcode: 16717 },
  { city: "Bromma", zipcode: 16719 },
  { city: "Bromma", zipcode: 16721 },
  { city: "Bromma", zipcode: 16722 },
  { city: "Bromma", zipcode: 16724 },
  { city: "Bromma", zipcode: 16731 },
  { city: "Bromma", zipcode: 16732 },
  { city: "Bromma", zipcode: 16733 },
  { city: "Bromma", zipcode: 16735 },
  { city: "Bromma", zipcode: 16736 },
  { city: "Bromma", zipcode: 16737 },
  { city: "Bromma", zipcode: 16738 },
  { city: "Bromma", zipcode: 16739 },
  { city: "Bromma", zipcode: 16740 },
  { city: "Bromma", zipcode: 16741 },
  { city: "Bromma", zipcode: 16743 },
  { city: "Bromma", zipcode: 16744 },
  { city: "Bromma", zipcode: 16745 },
  { city: "Bromma", zipcode: 16751 },
  { city: "Bromma", zipcode: 16752 },
  { city: "Bromma", zipcode: 16753 },
  { city: "Bromma", zipcode: 16754 },
  { city: "Bromma", zipcode: 16755 },
  { city: "Bromma", zipcode: 16756 },
  { city: "Bromma", zipcode: 16757 },
  { city: "Bromma", zipcode: 16761 },
  { city: "Bromma", zipcode: 16762 },
  { city: "Bromma", zipcode: 16763 },
  { city: "Bromma", zipcode: 16764 },
  { city: "Bromma", zipcode: 16765 },
  { city: "Bromma", zipcode: 16766 },
  { city: "Bromma", zipcode: 16767 },
  { city: "Bromma", zipcode: 16771 },
  { city: "Bromma", zipcode: 16772 },
  { city: "Bromma", zipcode: 16773 },
  { city: "Bromma", zipcode: 16774 },
  { city: "Bromma", zipcode: 16775 },
  { city: "Bromma", zipcode: 16776 },
  { city: "Bromma", zipcode: 16780 },
  { city: "Bromma", zipcode: 16781 },
  { city: "Bromma", zipcode: 16720 },
  { city: "Bromma", zipcode: 16758 },
  { city: "Bromma", zipcode: 16800 },
  { city: "Bromma", zipcode: 16810 },
  { city: "Bromma", zipcode: 16827 },
  { city: "Bromma", zipcode: 16830 },
  { city: "Bromma", zipcode: 16831 },
  { city: "Bromma", zipcode: 16832 },
  { city: "Bromma", zipcode: 16833 },
  { city: "Bromma", zipcode: 16834 },
  { city: "Bromma", zipcode: 16835 },
  { city: "Bromma", zipcode: 16836 },
  { city: "Bromma", zipcode: 16837 },
  { city: "Bromma", zipcode: 16838 },
  { city: "Bromma", zipcode: 16839 },
  { city: "Bromma", zipcode: 16840 },
  { city: "Bromma", zipcode: 16841 },
  { city: "Bromma", zipcode: 16842 },
  { city: "Bromma", zipcode: 16843 },
  { city: "Bromma", zipcode: 16844 },
  { city: "Bromma", zipcode: 16845 },
  { city: "Bromma", zipcode: 16846 },
  { city: "Bromma", zipcode: 16847 },
  { city: "Bromma", zipcode: 16848 },
  { city: "Bromma", zipcode: 16849 },
  { city: "Bromma", zipcode: 16850 },
  { city: "Bromma", zipcode: 16851 },
  { city: "Bromma", zipcode: 16852 },
  { city: "Bromma", zipcode: 16853 },
  { city: "Bromma", zipcode: 16854 },
  { city: "Bromma", zipcode: 16855 },
  { city: "Bromma", zipcode: 16856 },
  { city: "Bromma", zipcode: 16857 },
  { city: "Bromma", zipcode: 16858 },
  { city: "Bromma", zipcode: 16859 },
  { city: "Bromma", zipcode: 16860 },
  { city: "Bromma", zipcode: 16861 },
  { city: "Bromma", zipcode: 16862 },
  { city: "Bromma", zipcode: 16863 },
  { city: "Bromma", zipcode: 16864 },
  { city: "Bromma", zipcode: 16865 },
  { city: "Bromma", zipcode: 16866 },
  { city: "Bromma", zipcode: 16867 },
  { city: "Bromma", zipcode: 16868 },
  { city: "Bromma", zipcode: 16869 },
  { city: "Bromma", zipcode: 16870 },
  { city: "Bromma", zipcode: 16871 },
  { city: "Bromma", zipcode: 16872 },
  { city: "Bromma", zipcode: 16873 },
  { city: "Bromma", zipcode: 16874 },
  { city: "Bromma", zipcode: 16875 },
  { city: "Bromma", zipcode: 16876 },
  { city: "Bromma", zipcode: 16877 },
  { city: "Bromma", zipcode: 16878 },
  { city: "Bromma", zipcode: 16880 },
  { city: "Bromma", zipcode: 16820 },
  { city: "Danderyd", zipcode: 18220 },
  { city: "Ekero", zipcode: 17817 },
  { city: "Ekero", zipcode: 17820 },
  { city: "Enskede", zipcode: 12200 },
  { city: "Enskede", zipcode: 12203 },
  { city: "Enskede", zipcode: 12206 },
  { city: "Enskede", zipcode: 12231 },
  { city: "Enskede", zipcode: 12232 },
  { city: "Enskede", zipcode: 12233 },
  { city: "Enskede", zipcode: 12234 },
  { city: "Enskede", zipcode: 12237 },
  { city: "Enskede", zipcode: 12238 },
  { city: "Enskede", zipcode: 12239 },
  { city: "Enskede", zipcode: 12240 },
  { city: "Enskede", zipcode: 12241 },
  { city: "Enskede", zipcode: 12242 },
  { city: "Enskede", zipcode: 12243 },
  { city: "Enskede", zipcode: 12244 },
  { city: "Enskede", zipcode: 12245 },
  { city: "Enskede", zipcode: 12246 },
  { city: "Enskede", zipcode: 12247 },
  { city: "Enskede", zipcode: 12248 },
  { city: "Enskede", zipcode: 12249 },
  { city: "Enskede", zipcode: 12260 },
  { city: "Enskede", zipcode: 12262 },
  { city: "Enskede", zipcode: 12263 },
  { city: "Enskede", zipcode: 12264 },
  { city: "Enskede", zipcode: 12265 },
  { city: "Enskede", zipcode: 12266 },
  { city: "Enskede", zipcode: 12286 },
  { city: "Enskede", zipcode: 12288 },
  { city: "Enskede", zipcode: 12220 },
  { city: "Enskede Gard", zipcode: 12047 },
  { city: "Enskede Gard", zipcode: 12048 },
  { city: "Enskededalen", zipcode: 12114 },
  { city: "Enskededalen", zipcode: 12115 },
  { city: "Enskededalen", zipcode: 12130 },
  { city: "Enskededalen", zipcode: 12131 },
  { city: "Enskededalen", zipcode: 12132 },
  { city: "Enskededalen", zipcode: 12133 },
  { city: "Enskededalen", zipcode: 12134 },
  { city: "Enskededalen", zipcode: 12187 },
  { city: "Farsta", zipcode: 12300 },
  { city: "Farsta", zipcode: 12301 },
  { city: "Farsta", zipcode: 12303 },
  { city: "Farsta", zipcode: 12305 },
  { city: "Farsta", zipcode: 12321 },
  { city: "Farsta", zipcode: 12322 },
  { city: "Farsta", zipcode: 12323 },
  { city: "Farsta", zipcode: 12324 },
  { city: "Farsta", zipcode: 12326 },
  { city: "Farsta", zipcode: 12327 },
  { city: "Farsta", zipcode: 12328 },
  { city: "Farsta", zipcode: 12331 },
  { city: "Farsta", zipcode: 12332 },
  { city: "Farsta", zipcode: 12333 },
  { city: "Farsta", zipcode: 12334 },
  { city: "Farsta", zipcode: 12340 },
  { city: "Farsta", zipcode: 12341 },
  { city: "Farsta", zipcode: 12342 },
  { city: "Farsta", zipcode: 12343 },
  { city: "Farsta", zipcode: 12344 },
  { city: "Farsta", zipcode: 12346 },
  { city: "Farsta", zipcode: 12347 },
  { city: "Farsta", zipcode: 12348 },
  { city: "Farsta", zipcode: 12349 },
  { city: "Farsta", zipcode: 12350 },
  { city: "Farsta", zipcode: 12352 },
  { city: "Farsta", zipcode: 12353 },
  { city: "Farsta", zipcode: 12354 },
  { city: "Farsta", zipcode: 12355 },
  { city: "Farsta", zipcode: 12356 },
  { city: "Farsta", zipcode: 12357 },
  { city: "Farsta", zipcode: 12358 },
  { city: "Farsta", zipcode: 12359 },
  { city: "Farsta", zipcode: 12360 },
  { city: "Farsta", zipcode: 12363 },
  { city: "Farsta", zipcode: 12371 },
  { city: "Farsta", zipcode: 12372 },
  { city: "Farsta", zipcode: 12373 },
  { city: "Farsta", zipcode: 12386 },
  { city: "Farsta", zipcode: 12320 },
  { city: "Farsta", zipcode: 12387 },
  { city: "Fasta", zipcode: 12361 },
  { city: "Gustavsberg", zipcode: 13420 },
  { city: "Gustavsberg", zipcode: 13439 },
  { city: "Hagersten", zipcode: 12600 },
  { city: "Hagersten", zipcode: 12601 },
  { city: "Hagersten", zipcode: 12609 },
  { city: "Hagersten", zipcode: 12626 },
  { city: "Hagersten", zipcode: 12628 },
  { city: "Hagersten", zipcode: 12629 },
  { city: "Hagersten", zipcode: 12630 },
  { city: "Hagersten", zipcode: 12631 },
  { city: "Hagersten", zipcode: 12632 },
  { city: "Hagersten", zipcode: 12633 },
  { city: "Hagersten", zipcode: 12634 },
  { city: "Hagersten", zipcode: 12635 },
  { city: "Hagersten", zipcode: 12636 },
  { city: "Hagersten", zipcode: 12637 },
  { city: "Hagersten", zipcode: 12638 },
  { city: "Hagersten", zipcode: 12639 },
  { city: "Hagersten", zipcode: 12640 },
  { city: "Hagersten", zipcode: 12641 },
  { city: "Hagersten", zipcode: 12642 },
  { city: "Hagersten", zipcode: 12647 },
  { city: "Hagersten", zipcode: 12648 },
  { city: "Hagersten", zipcode: 12649 },
  { city: "Hagersten", zipcode: 12650 },
  { city: "Hagersten", zipcode: 12651 },
  { city: "Hagersten", zipcode: 12652 },
  { city: "Hagersten", zipcode: 12653 },
  { city: "Hagersten", zipcode: 12654 },
  { city: "Hagersten", zipcode: 12655 },
  { city: "Hagersten", zipcode: 12677 },
  { city: "Hagersten", zipcode: 12678 },
  { city: "Hagersten", zipcode: 12679 },
  { city: "Hagersten", zipcode: 12689 },
  { city: "Hagersten", zipcode: 12900 },
  { city: "Hagersten", zipcode: 12904 },
  { city: "Hagersten", zipcode: 12906 },
  { city: "Hagersten", zipcode: 12907 },
  { city: "Hagersten", zipcode: 12918 },
  { city: "Hagersten", zipcode: 12921 },
  { city: "Hagersten", zipcode: 12922 },
  { city: "Hagersten", zipcode: 12923 },
  { city: "Hagersten", zipcode: 12930 },
  { city: "Hagersten", zipcode: 12931 },
  { city: "Hagersten", zipcode: 12932 },
  { city: "Hagersten", zipcode: 12933 },
  { city: "Hagersten", zipcode: 12934 },
  { city: "Hagersten", zipcode: 12935 },
  { city: "Hagersten", zipcode: 12936 },
  { city: "Hagersten", zipcode: 12937 },
  { city: "Hagersten", zipcode: 12938 },
  { city: "Hagersten", zipcode: 12939 },
  { city: "Hagersten", zipcode: 12940 },
  { city: "Hagersten", zipcode: 12941 },
  { city: "Hagersten", zipcode: 12942 },
  { city: "Hagersten", zipcode: 12943 },
  { city: "Hagersten", zipcode: 12944 },
  { city: "Hagersten", zipcode: 12945 },
  { city: "Hagersten", zipcode: 12946 },
  { city: "Hagersten", zipcode: 12947 },
  { city: "Hagersten", zipcode: 12948 },
  { city: "Hagersten", zipcode: 12949 },
  { city: "Hagersten", zipcode: 12950 },
  { city: "Hagersten", zipcode: 12951 },
  { city: "Hagersten", zipcode: 12952 },
  { city: "Hagersten", zipcode: 12953 },
  { city: "Hagersten", zipcode: 12954 },
  { city: "Hagersten", zipcode: 12955 },
  { city: "Hagersten", zipcode: 12956 },
  { city: "Hagersten", zipcode: 12957 },
  { city: "Hagersten", zipcode: 12920 },
  { city: "Årsta Holmar", zipcode: 11842 },
  { city: "Hallstavik", zipcode: 76320 },
  { city: "Handen", zipcode: 13636 },
  { city: "Handen", zipcode: 13637 },
  { city: "Handen", zipcode: 13638 },
  { city: "Haninge", zipcode: 13612 },
  { city: "Haninge", zipcode: 13620 },
  { city: "Hasselby", zipcode: 16500 },
  { city: "Hasselby", zipcode: 16510 },
  { city: "Hasselby", zipcode: 16511 },
  { city: "Hasselby", zipcode: 16512 },
  { city: "Hasselby", zipcode: 16513 },
  { city: "Hasselby", zipcode: 16515 },
  { city: "Hasselby", zipcode: 16516 },
  { city: "Hasselby", zipcode: 16521 },
  { city: "Hasselby", zipcode: 16522 },
  { city: "Hasselby", zipcode: 16523 },
  { city: "Hasselby", zipcode: 16524 },
  { city: "Hasselby", zipcode: 16552 },
  { city: "Hasselby", zipcode: 16553 },
  { city: "Hasselby", zipcode: 16554 },
  { city: "Hasselby", zipcode: 16555 },
  { city: "Hasselby", zipcode: 16556 },
  { city: "Hasselby", zipcode: 16557 },
  { city: "Hasselby", zipcode: 16558 },
  { city: "Hasselby", zipcode: 16559 },
  { city: "Hasselby", zipcode: 16560 },
  { city: "Hasselby", zipcode: 16561 },
  { city: "Hasselby", zipcode: 16562 },
  { city: "Hasselby", zipcode: 16563 },
  { city: "Hasselby", zipcode: 16564 },
  { city: "Hasselby", zipcode: 16565 },
  { city: "Hasselby", zipcode: 16566 },
  { city: "Hasselby", zipcode: 16570 },
  { city: "Hasselby", zipcode: 16571 },
  { city: "Hasselby", zipcode: 16572 },
  { city: "Hasselby", zipcode: 16573 },
  { city: "Hasselby", zipcode: 16574 },
  { city: "Hasselby", zipcode: 16575 },
  { city: "Hasselby", zipcode: 16576 },
  { city: "Hasselby", zipcode: 16577 },
  { city: "Hasselby", zipcode: 16578 },
  { city: "Hasselby", zipcode: 16520 },
  { city: "Huddinge", zipcode: 14120 },
  { city: "Jarfalla", zipcode: 17520 },
  { city: "Jarfalla", zipcode: 17572 },
  { city: "Jarfalla", zipcode: 17700 },
  { city: "Jarfalla", zipcode: 17720 },
  { city: "Jarfalla", zipcode: 17746 },
  { city: "Årstaskogs väg", zipcode: 11756 },
  { city: "Jarna", zipcode: 15320 },
  { city: "Jarna", zipcode: 15326 },
  { city: "Johanneshov", zipcode: 12100 },
  { city: "Johanneshov", zipcode: 12105 },
  { city: "Johanneshov", zipcode: 12106 },
  { city: "Johanneshov", zipcode: 12111 },
  { city: "Johanneshov", zipcode: 12112 },
  { city: "Johanneshov", zipcode: 12113 },
  { city: "Johanneshov", zipcode: 12116 },
  { city: "Johanneshov", zipcode: 12117 },
  { city: "Johanneshov", zipcode: 12118 },
  { city: "Johanneshov", zipcode: 12121 },
  { city: "Johanneshov", zipcode: 12122 },
  { city: "Johanneshov", zipcode: 12123 },
  { city: "Johanneshov", zipcode: 12124 },
  { city: "Johanneshov", zipcode: 12135 },
  { city: "Johanneshov", zipcode: 12136 },
  { city: "Johanneshov", zipcode: 12137 },
  { city: "Johanneshov", zipcode: 12138 },
  { city: "Johanneshov", zipcode: 12139 },
  { city: "Johanneshov", zipcode: 12140 },
  { city: "Johanneshov", zipcode: 12143 },
  { city: "Johanneshov", zipcode: 12144 },
  { city: "Johanneshov", zipcode: 12145 },
  { city: "Johanneshov", zipcode: 12146 },
  { city: "Johanneshov", zipcode: 12147 },
  { city: "Johanneshov", zipcode: 12148 },
  { city: "Johanneshov", zipcode: 12149 },
  { city: "Johanneshov", zipcode: 12150 },
  { city: "Johanneshov", zipcode: 12151 },
  { city: "Johanneshov", zipcode: 12152 },
  { city: "Johanneshov", zipcode: 12153 },
  { city: "Johanneshov", zipcode: 12154 },
  { city: "Johanneshov", zipcode: 12155 },
  { city: "Johanneshov", zipcode: 12156 },
  { city: "Johanneshov", zipcode: 12162 },
  { city: "Johanneshov", zipcode: 12163 },
  { city: "Johanneshov", zipcode: 12177 },
  { city: "Johanneshov", zipcode: 12186 },
  { city: "Johanneshov", zipcode: 12189 },
  { city: "Johanneshov", zipcode: 12119 },
  { city: "Johanneshov", zipcode: 12120 },
  { city: "Kista", zipcode: 16400 },
  { city: "Kista", zipcode: 16406 },
  { city: "Kista", zipcode: 16407 },
  { city: "Kista", zipcode: 16419 },
  { city: "Kista", zipcode: 16421 },
  { city: "Kista", zipcode: 16422 },
  { city: "Kista", zipcode: 16425 },
  { city: "Kista", zipcode: 16426 },
  { city: "Kista", zipcode: 16428 },
  { city: "Kista", zipcode: 16430 },
  { city: "Kista", zipcode: 16431 },
  { city: "Kista", zipcode: 16432 },
  { city: "Kista", zipcode: 16433 },
  { city: "Kista", zipcode: 16434 },
  { city: "Kista", zipcode: 16435 },
  { city: "Kista", zipcode: 16436 },
  { city: "Kista", zipcode: 16437 },
  { city: "Kista", zipcode: 16438 },
  { city: "Kista", zipcode: 16439 },
  { city: "Kista", zipcode: 16440 },
  { city: "Kista", zipcode: 16441 },
  { city: "Kista", zipcode: 16442 },
  { city: "Kista", zipcode: 16443 },
  { city: "Kista", zipcode: 16444 },
  { city: "Kista", zipcode: 16445 },
  { city: "Kista", zipcode: 16446 },
  { city: "Kista", zipcode: 16447 },
  { city: "Kista", zipcode: 16448 },
  { city: "Kista", zipcode: 16451 },
  { city: "Kista", zipcode: 16452 },
  { city: "Kista", zipcode: 16453 },
  { city: "Kista", zipcode: 16455 },
  { city: "Kista", zipcode: 16471 },
  { city: "Kista", zipcode: 16472 },
  { city: "Kista", zipcode: 16473 },
  { city: "Kista", zipcode: 16474 },
  { city: "Kista", zipcode: 16475 },
  { city: "Kista", zipcode: 16476 },
  { city: "Kista", zipcode: 16477 },
  { city: "Kista", zipcode: 16478 },
  { city: "Kista", zipcode: 16479 },
  { city: "Kista", zipcode: 16481 },
  { city: "Kista", zipcode: 16487 },
  { city: "Kista", zipcode: 16488 },
  { city: "Kista", zipcode: 16491 },
  { city: "Kista", zipcode: 16493 },
  { city: "Kista", zipcode: 16494 },
  { city: "Kista", zipcode: 16496 },
  { city: "Kista", zipcode: 16497 },
  { city: "Kista", zipcode: 16499 },
  { city: "Kista", zipcode: 16420 },
  { city: "Kista", zipcode: 16423 },
  { city: "Kista", zipcode: 16424 },
  { city: "Kista", zipcode: 16429 },
  { city: "Kista", zipcode: 16495 },
  { city: "Årsta Skolgränd", zipcode: 11743 },
  { city: "Kungsangen", zipcode: 19600 },
  { city: "Kungsangen", zipcode: 19601 },
  { city: "Kungsangen", zipcode: 19602 },
  { city: "Kungsangen", zipcode: 19620 },
  { city: "Kungsangen", zipcode: 19621 },
  { city: "Kungsangen", zipcode: 19622 },
  { city: "Kungsangen", zipcode: 19623 },
  { city: "Kungsangen", zipcode: 19624 },
  { city: "Kungsangen", zipcode: 19628 },
  { city: "Kungsangen", zipcode: 19630 },
  { city: "Kungsangen", zipcode: 19631 },
  { city: "Kungsangen", zipcode: 19632 },
  { city: "Kungsangen", zipcode: 19633 },
  { city: "Kungsangen", zipcode: 19634 },
  { city: "Kungsangen", zipcode: 19635 },
  { city: "Kungsangen", zipcode: 19636 },
  { city: "Kungsangen", zipcode: 19637 },
  { city: "Kungsangen", zipcode: 19638 },
  { city: "Kungsangen", zipcode: 19639 },
  { city: "Kungsangen", zipcode: 19640 },
  { city: "Kungsangen", zipcode: 19641 },
  { city: "Kungsangen", zipcode: 19680 },
  { city: "Kungsangen", zipcode: 19681 },
  { city: "Kungsangen", zipcode: 19685 },
  { city: "Kungsangen", zipcode: 19691 },
  { city: "Kungsangen", zipcode: 19692 },
  { city: "Kungsangen", zipcode: 19693 },
  { city: "Lidingo", zipcode: 18105 },
  { city: "Lidingo", zipcode: 18120 },
  { city: "Lidingo", zipcode: 18186 },
  { city: "Lidingo", zipcode: 18187 },
  { city: "Artemisgatan", zipcode: 11542 },
  { city: "Nacka", zipcode: 13120 },
  { city: "Nacka", zipcode: 13156 },
  { city: "Nacka", zipcode: 13159 },
  { city: "Nacka", zipcode: 13162 },
  { city: "Nacka", zipcode: 13172 },
  { city: "Nacka", zipcode: 13173 },
  { city: "Nacka", zipcode: 13192 },
  { city: "Nacka", zipcode: 13193 },
  { city: "Norrtalje", zipcode: 76120 },
  { city: "Norsborg", zipcode: 14520 },
  { city: "Nykvarn", zipcode: 15520 },
  { city: "Nykvarn", zipcode: 15535 },
  { city: "Nykvarn", zipcode: 15581 },
  { city: "Nynashamn", zipcode: 14920 },
  { city: "Osmo", zipcode: 14820 },
  { city: "Rimbo", zipcode: 76220 },
  { city: "Ronninge", zipcode: 14420 },
  { city: "Ronninge", zipcode: 14452 },
  { city: "Rosersberg", zipcode: 19000 },
  { city: "Rosersberg", zipcode: 19002 },
  { city: "Rosersberg", zipcode: 19010 },
  { city: "Rosersberg", zipcode: 19011 },
  { city: "Rosersberg", zipcode: 19012 },
  { city: "Rosersberg", zipcode: 19017 },
  { city: "Rosersberg", zipcode: 19020 },
  { city: "Rosersberg", zipcode: 19070 },
  { city: "Rosersberg", zipcode: 19080 },
  { city: "Rosersberg", zipcode: 19081 },
  { city: "Rosersberg", zipcode: 19082 },
  { city: "Rosersberg", zipcode: 19083 },
  { city: "Rosersberg", zipcode: 19084 },
  { city: "Rosersberg", zipcode: 19085 },
  { city: "Rosersberg", zipcode: 19504 },
  { city: "Rosersberg", zipcode: 19570 },
  { city: "Rosersberg", zipcode: 19571 },
  { city: "Rosersberg", zipcode: 19572 },
  { city: "Rosersberg", zipcode: 19595 },
  { city: "Rosersberg", zipcode: 19596 },
  {
    city: "Artillerigatan",
    zipcode: 11445
  },
  {
    city: "Saltsjo-Boo",
    zipcode: 13203
  },
  {
    city: "Saltsjo-Boo",
    zipcode: 13205
  },
  {
    city: "Saltsjo-Boo",
    zipcode: 13285
  },
  {
    city: "Saltsjo-Boo",
    zipcode: 13220
  },
  {
    city: "Saltsjobaden",
    zipcode: 13320
  },
  {
    city: "Saltsjobaden",
    zipcode: 13339
  },
  {
    city: "Sigtuna",
    zipcode: 19300
  },
  {
    city: "Sigtuna",
    zipcode: 19320
  },
  {
    city: "Sigtuna",
    zipcode: 19321
  },
  {
    city: "Sigtuna",
    zipcode: 19322
  },
  {
    city: "Sigtuna",
    zipcode: 19323
  },
  {
    city: "Sigtuna",
    zipcode: 19324
  },
  {
    city: "Sigtuna",
    zipcode: 19328
  },
  {
    city: "Sigtuna",
    zipcode: 19330
  },
  {
    city: "Sigtuna",
    zipcode: 19331
  },
  {
    city: "Sigtuna",
    zipcode: 19332
  },
  {
    city: "Sigtuna",
    zipcode: 19333
  },
  {
    city: "Sigtuna",
    zipcode: 19334
  },
  {
    city: "Sigtuna",
    zipcode: 19335
  },
  {
    city: "Sigtuna",
    zipcode: 19336
  },
  {
    city: "Sigtuna",
    zipcode: 19337
  },
  {
    city: "Sigtuna",
    zipcode: 19340
  },
  {
    city: "Sigtuna",
    zipcode: 19341
  },
  {
    city: "Sigtuna",
    zipcode: 19391
  },
  {
    city: "Skarholmen",
    zipcode: 12700
  },
  {
    city: "Skarholmen",
    zipcode: 12702
  },
  {
    city: "Skarholmen",
    zipcode: 12703
  },
  {
    city: "Skarholmen",
    zipcode: 12721
  },
  {
    city: "Skarholmen",
    zipcode: 12722
  },
  {
    city: "Skarholmen",
    zipcode: 12723
  },
  {
    city: "Skarholmen",
    zipcode: 12724
  },
  {
    city: "Skarholmen",
    zipcode: 12725
  },
  {
    city: "Skarholmen",
    zipcode: 12726
  },
  {
    city: "Skarholmen",
    zipcode: 12730
  },
  {
    city: "Skarholmen",
    zipcode: 12731
  },
  {
    city: "Skarholmen",
    zipcode: 12732
  },
  {
    city: "Skarholmen",
    zipcode: 12733
  },
  {
    city: "Skarholmen",
    zipcode: 12734
  },
  {
    city: "Skarholmen",
    zipcode: 12735
  },
  {
    city: "Skarholmen",
    zipcode: 12736
  },
  {
    city: "Skarholmen",
    zipcode: 12737
  },
  {
    city: "Skarholmen",
    zipcode: 12738
  },
  {
    city: "Skarholmen",
    zipcode: 12739
  },
  {
    city: "Skarholmen",
    zipcode: 12740
  },
  {
    city: "Skarholmen",
    zipcode: 12741
  },
  {
    city: "Skarholmen",
    zipcode: 12742
  },
  {
    city: "Skarholmen",
    zipcode: 12743
  },
  {
    city: "Skarholmen",
    zipcode: 12744
  },
  {
    city: "Skarholmen",
    zipcode: 12745
  },
  {
    city: "Skarholmen",
    zipcode: 12746
  },
  {
    city: "Skarholmen",
    zipcode: 12747
  },
  {
    city: "Skarholmen",
    zipcode: 12748
  },
  {
    city: "Skarholmen",
    zipcode: 12749
  },
  {
    city: "Skarholmen",
    zipcode: 12751
  },
  {
    city: "Skarholmen",
    zipcode: 12761
  },
  {
    city: "Skarholmen",
    zipcode: 12781
  },
  {
    city: "Skarholmen",
    zipcode: 12785
  },
  {
    city: "Skarholmen",
    zipcode: 12786
  },
  {
    city: "Skarholmen",
    zipcode: 12787
  },
  {
    city: "Skarholmen",
    zipcode: 12720
  }
  ,
  {
    city: "Artillerigatan",
    zipcode: 11451
  },
  {
    city: "Skarholmen",
    zipcode: 12760
  },
  {
    city: "Skarpnack",
    zipcode: 12800
  },
  {
    city: "Skarpnack",
    zipcode: 12821
  },
  {
    city: "Skarpnack",
    zipcode: 12822
  },
  {
    city: "Skarpnack",
    zipcode: 12823
  },
  {
    city: "Skarpnack",
    zipcode: 12824
  },
  {
    city: "Skarpnack",
    zipcode: 12830
  },
  {
    city: "Skarpnack",
    zipcode: 12831
  },
  {
    city: "Skarpnack",
    zipcode: 12832
  },
  {
    city: "Skarpnack",
    zipcode: 12833
  },
  {
    city: "Skarpnack",
    zipcode: 12834
  },
  {
    city: "Skarpnack",
    zipcode: 12835
  },
  {
    city: "Skarpnack",
    zipcode: 12836
  },
  {
    city: "Skarpnack",
    zipcode: 12838
  },
  {
    city: "Skarpnack",
    zipcode: 12839
  },
  {
    city: "Skarpnack",
    zipcode: 12820
  },
  {
    city: "Skondal",
    zipcode: 14220
  },
  {
    city: "Skondal",
    zipcode: 12806
  },
  {
    city: "Skondal",
    zipcode: 12807
  },
  {
    city: "Skondal",
    zipcode: 12862
  },
  {
    city: "Skondal",
    zipcode: 12863
  },
  {
    city: "Skondal",
    zipcode: 12864
  },
  {
    city: "Skondal",
    zipcode: 12865
  },
  {
    city: "Skondal",
    zipcode: 12866
  },
  {
    city: "Skondal",
    zipcode: 12867
  },
  {
    city: "Skondal",
    zipcode: 12868
  },
  {
    city: "Skondal",
    zipcode: 12869
  },
  {
    city: "Skondal",
    zipcode: 12885
  },
  {
    city: "Sodertalje",
    zipcode: 15103
  },
  {
    city: "Sodertalje",
    zipcode: 15105
  },
  {
    city: "Sodertalje",
    zipcode: 15107
  },
  {
    city: "Sodertalje",
    zipcode: 15113
  },
  {
    city: "Sodertalje",
    zipcode: 15120
  },
  {
    city: "Sodertalje",
    zipcode: 15156
  },
  {
    city: "Sodertalje",
    zipcode: 15157
  },
  {
    city: "Sodertalje",
    zipcode: 15167
  },
  {
    city: "Sodertalje",
    zipcode: 15184
  },
  {
    city: "Sodertalje",
    zipcode: 15220
  },
  {
    city: "Sodertalje",
    zipcode: 15228
  },
  {
    city: "Sodertalje",
    zipcode: 15259
  },
  {
    city: "Sollentuna",
    zipcode: 19100
  },
  {
    city: "Sollentuna",
    zipcode: 19102
  },
  {
    city: "Sollentuna",
    zipcode: 19118
  },
  {
    city: "Sollentuna",
    zipcode: 19120
  },
  {
    city: "Sollentuna",
    zipcode: 19121
  },
  {
    city: "Sollentuna",
    zipcode: 19122
  },
  {
    city: "Sollentuna",
    zipcode: 19123
  },
  {
    city: "Sollentuna",
    zipcode: 19124
  },
  {
    city: "Sollentuna",
    zipcode: 19126
  },
  {
    city: "Sollentuna",
    zipcode: 19127
  },
  {
    city: "Sollentuna",
    zipcode: 19128
  },
  {
    city: "Sollentuna",
    zipcode: 19129
  },
  {
    city: "Sollentuna",
    zipcode: 19131
  },
  {
    city: "Sollentuna",
    zipcode: 19133
  },
  {
    city: "Sollentuna",
    zipcode: 19134
  },
  {
    city: "Sollentuna",
    zipcode: 19135
  },
  {
    city: "Sollentuna",
    zipcode: 19136
  },
  {
    city: "Sollentuna",
    zipcode: 19137
  },
  {
    city: "Sollentuna",
    zipcode: 19138
  },
  {
    city: "Sollentuna",
    zipcode: 19139
  },
  {
    city: "Sollentuna",
    zipcode: 19140
  },
  {
    city: "Sollentuna",
    zipcode: 19141
  },
  {
    city: "Sollentuna",
    zipcode: 19142
  },
  {
    city: "Sollentuna",
    zipcode: 19143
  },
  {
    city: "Sollentuna",
    zipcode: 19144
  },
  {
    city: "Sollentuna",
    zipcode: 19145
  },
  {
    city: "Sollentuna",
    zipcode: 19146
  },
  {
    city: "Sollentuna",
    zipcode: 19147
  },
  {
    city: "Sollentuna",
    zipcode: 19148
  },
  {
    city: "Sollentuna",
    zipcode: 19149
  },
  {
    city: "Sollentuna",
    zipcode: 19150
  },
  {
    city: "Sollentuna",
    zipcode: 19160
  },
  {
    city: "Sollentuna",
    zipcode: 19161
  },
  {
    city: "Sollentuna",
    zipcode: 19162
  },
  {
    city: "Sollentuna",
    zipcode: 19163
  },
  {
    city: "Sollentuna",
    zipcode: 19164
  },
  {
    city: "Sollentuna",
    zipcode: 19181
  },
  {
    city: "Sollentuna",
    zipcode: 19183
  },
  {
    city: "Sollentuna",
    zipcode: 19184
  },
  {
    city: "Sollentuna",
    zipcode: 19185
  },
  {
    city: "Sollentuna",
    zipcode: 19186
  },
  {
    city: "Sollentuna",
    zipcode: 19189
  },
  {
    city: "Sollentuna",
    zipcode: 19190
  },
  {
    city: "Sollentuna",
    zipcode: 19197
  },
  {
    city: "Sollentuna",
    zipcode: 19200
  },
  {
    city: "Sollentuna",
    zipcode: 19205
  },
  {
    city: "Sollentuna",
    zipcode: 19206
  },
  {
    city: "Sollentuna",
    zipcode: 19207
  },
  {
    city: "Sollentuna",
    zipcode: 19208
  },
  {
    city: "Sollentuna",
    zipcode: 19210
  },
  {
    city: "Sollentuna",
    zipcode: 19220
  },
  {
    city: "Sollentuna",
    zipcode: 19221
  },
  {
    city: "Sollentuna",
    zipcode: 19230
  },
  {
    city: "Sollentuna",
    zipcode: 19232
  },
  {
    city: "Sollentuna",
    zipcode: 19248
  },
  {
    city: "Sollentuna",
    zipcode: 19251
  },
  {
    city: "Sollentuna",
    zipcode: 19252
  },
  {
    city: "Sollentuna",
    zipcode: 19253
  },
  {
    city: "Sollentuna",
    zipcode: 19254
  },
  {
    city: "Sollentuna",
    zipcode: 19255
  },
  {
    city: "Sollentuna",
    zipcode: 19256
  },
  {
    city: "Sollentuna",
    zipcode: 19257
  },
  {
    city: "Sollentuna",
    zipcode: 19258
  },
  {
    city: "Sollentuna",
    zipcode: 19259
  },
  {
    city: "Sollentuna",
    zipcode: 19265
  },
  {
    city: "Sollentuna",
    zipcode: 19266
  },
  {
    city: "Sollentuna",
    zipcode: 19267
  },
  {
    city: "Sollentuna",
    zipcode: 19268
  },
  {
    city: "Sollentuna",
    zipcode: 19269
  },
  {
    city: "Sollentuna",
    zipcode: 19270
  },
  {
    city: "Sollentuna",
    zipcode: 19271
  },
  {
    city: "Sollentuna",
    zipcode: 19272
  },
  {
    city: "Sollentuna",
    zipcode: 19273
  },
  {
    city: "Sollentuna",
    zipcode: 19274
  },
  {
    city: "Sollentuna",
    zipcode: 19275
  },
  {
    city: "Sollentuna",
    zipcode: 19276
  },
  {
    city: "Sollentuna",
    zipcode: 19277
  },
  {
    city: "Sollentuna",
    zipcode: 19278
  },
  {
    city: "Sollentuna",
    zipcode: 19279
  },
  {
    city: "Sollentuna",
    zipcode: 19280
  },
  {
    city: "Sollentuna",
    zipcode: 19281
  },
  {
    city: "Solna",
    zipcode: 16920
  },
  {
    city: "Solna",
    zipcode: 16940
  },
  {
    city: "Solna",
    zipcode: 16974
  },
  {
    city: "Solna",
    zipcode: 16989
  },
  {
    city: "Solna",
    zipcode: 17020
  },
  {
    city: "Solna",
    zipcode: 17084
  },
  {
    city: "Solna",
    zipcode: 17108
  },
  {
    city: "Solna",
    zipcode: 17112
  },
  {
    city: "Solna",
    zipcode: 17120
  },
  {
    city: "Solna",
    zipcode: 17179
  },
  {
    city: "Solna",
    zipcode: 17183
  },
  {
    city: "Solna",
    zipcode: 17188
  },
  {
    city: "Solna",
    zipcode: 17193
  },
  {
    city: "Solna",
    zipcode: 17199
  },
  {
    city: "Solna",
    zipcode: 17330
  },
  {
    city: "Solna",
    zipcode: 17331
  },
  {
    city: "Spanga",
    zipcode: 16300
  },
  {
    city: "Spanga",
    zipcode: 16303
  },
  {
    city: "Spanga",
    zipcode: 16304
  },
  {
    city: "Spanga",
    zipcode: 16305
  },
  {
    city: "Spanga",
    zipcode: 16308
  },
  {
    city: "Spanga",
    zipcode: 16310
  },
  {
    city: "Spanga",
    zipcode: 16319
  },
  {
    city: "Spanga",
    zipcode: 16321
  },
  {
    city: "Spanga",
    zipcode: 16329
  },
  {
    city: "Spanga",
    zipcode: 16340
  },
  {
    city: "Spanga",
    zipcode: 16341
  },
  {
    city: "Spanga",
    zipcode: 16342
  },
  {
    city: "Spanga",
    zipcode: 16343
  },
  {
    city: "Spanga",
    zipcode: 16344
  },
  {
    city: "Spanga",
    zipcode: 16345
  },
  {
    city: "Spanga",
    zipcode: 16346
  },
  {
    city: "Spanga",
    zipcode: 16347
  },
  {
    city: "Spanga",
    zipcode: 16350
  },
  {
    city: "Spanga",
    zipcode: 16351
  },
  {
    city: "Spanga",
    zipcode: 16352
  },
  {
    city: "Spanga",
    zipcode: 16353
  },
  {
    city: "Spanga",
    zipcode: 16354
  },
  {
    city: "Spanga",
    zipcode: 16355
  },
  {
    city: "Spanga",
    zipcode: 16356
  },
  {
    city: "Spanga",
    zipcode: 16357
  },
  {
    city: "Spanga",
    zipcode: 16361
  },
  {
    city: "Spanga",
    zipcode: 16362
  },
  {
    city: "Spanga",
    zipcode: 16363
  },
  {
    city: "Spanga",
    zipcode: 16364
  },
  {
    city: "Spanga",
    zipcode: 16365
  },
  {
    city: "Spanga",
    zipcode: 16366
  },
  {
    city: "Spanga",
    zipcode: 16367
  },
  {
    city: "Spanga",
    zipcode: 16368
  },
  {
    city: "Spanga",
    zipcode: 16370
  },
  {
    city: "Spanga",
    zipcode: 16371
  },
  {
    city: "Spanga",
    zipcode: 16372
  },
  {
    city: "Spanga",
    zipcode: 16373
  },
  {
    city: "Spanga",
    zipcode: 16374
  },
  {
    city: "Spanga",
    zipcode: 16375
  },
  {
    city: "Spanga",
    zipcode: 16376
  },
  {
    city: "Spanga",
    zipcode: 16377
  },
  {
    city: "Spanga",
    zipcode: 16380
  },
  {
    city: "Spanga",
    zipcode: 16385
  },
  {
    city: "Spanga",
    zipcode: 16389
  },
  {
    city: "Spanga",
    zipcode: 16391
  },
  {
    city: "Spanga",
    zipcode: 16320
  },
  {
    city: "Spanga",
    zipcode: 16388
  }

  ,
  {
    city: "Artillerigatan", zipcode: 11530
  },
  {
    city: "Askrikegatan", zipcode: 11557
  },
  {
    city: "Åsögatan", zipcode: 11624
  },
  {
    city: "Åsögatan", zipcode: 11632
  },
  {
    city: "Åsögatan", zipcode: 11829
  },
  {
    city: "Åsötorget", zipcode: 11862
  },
  {
    city: "Assessorsgatan", zipcode: 11857
  },
  { city: "Stockholm", zipcode: 11111 },
  { city: "Stockholm", zipcode: 11120 },
  { city: "Stockholm", zipcode: 11121 },
  { city: "Stockholm", zipcode: 11122 },
  { city: "Stockholm", zipcode: 11123 },
  { city: "Stockholm", zipcode: 11124 },
  { city: "Stockholm", zipcode: 11127 },
  { city: "Stockholm", zipcode: 11128 },
  { city: "Stockholm", zipcode: 11129 },
  { city: "Stockholm", zipcode: 11130 },
  { city: "Stockholm", zipcode: 11131 },
  { city: "Stockholm", zipcode: 11134 },
  { city: "Stockholm", zipcode: 11135 },
  { city: "Stockholm", zipcode: 11136 },
  { city: "Stockholm", zipcode: 11137 },
  { city: "Stockholm", zipcode: 11138 },
  { city: "Stockholm", zipcode: 11139 },
  { city: "Stockholm", zipcode: 11140 },
  { city: "Stockholm", zipcode: 11143 },
  { city: "Stockholm", zipcode: 11144 },
  { city: "Stockholm", zipcode: 11145 },
  { city: "Stockholm", zipcode: 11146 },
  { city: "Stockholm", zipcode: 11147 },
  { city: "Stockholm", zipcode: 11148 },
  { city: "Stockholm", zipcode: 11149 },
  { city: "Stockholm", zipcode: 11150 },
  { city: "Stockholm", zipcode: 11151 },
  { city: "Stockholm", zipcode: 11152 },
  { city: "Stockholm", zipcode: 11153 },
  { city: "Stockholm", zipcode: 11156 },
  { city: "Stockholm", zipcode: 11157 },
  { city: "Stockholm", zipcode: 11160 },
  { city: "Stockholm", zipcode: 11161 },
  { city: "Stockholm", zipcode: 11164 },
  { city: "Stockholm", zipcode: 11214 },
  { city: "Stockholm", zipcode: 11215 },
  { city: "Stockholm", zipcode: 11216 },
  { city: "Stockholm", zipcode: 11217 },
  { city: "Stockholm", zipcode: 11218 },
  { city: "Stockholm", zipcode: 11219 },
  { city: "Stockholm", zipcode: 11220 },
  { city: "Stockholm", zipcode: 11221 },
  { city: "Stockholm", zipcode: 11222 },
  { city: "Stockholm", zipcode: 11223 },
  { city: "Stockholm", zipcode: 11224 },
  { city: "Stockholm", zipcode: 11225 },
  { city: "Stockholm", zipcode: 11226 },
  { city: "Stockholm", zipcode: 11227 },
  { city: "Stockholm", zipcode: 11228 },
  { city: "Stockholm", zipcode: 11229 },
  { city: "Stockholm", zipcode: 11230 },
  { city: "Stockholm", zipcode: 11231 },
  { city: "Stockholm", zipcode: 11232 },
  { city: "Stockholm", zipcode: 11233 },
  { city: "Stockholm", zipcode: 11234 },
  { city: "Stockholm", zipcode: 11235 },
  { city: "Stockholm", zipcode: 11236 },
  { city: "Stockholm", zipcode: 11237 },
  { city: "Stockholm", zipcode: 11238 },
  { city: "Stockholm", zipcode: 11239 },
  { city: "Stockholm", zipcode: 11240 },
  { city: "Stockholm", zipcode: 11241 },
  { city: "Stockholm", zipcode: 11242 },
  { city: "Stockholm", zipcode: 11243 },
  { city: "Stockholm", zipcode: 11244 },
  { city: "Stockholm", zipcode: 11245 },
  { city: "Stockholm", zipcode: 11246 },
  { city: "Stockholm", zipcode: 11247 },
  { city: "Stockholm", zipcode: 11248 },
  { city: "Stockholm", zipcode: 11249 },
  { city: "Stockholm", zipcode: 11250 },
  { city: "Stockholm", zipcode: 11251 },
  { city: "Stockholm", zipcode: 11252 },
  { city: "Stockholm", zipcode: 11253 },
  { city: "Stockholm", zipcode: 11254 },
  { city: "Stockholm", zipcode: 11255 },
  { city: "Stockholm", zipcode: 11256 },
  { city: "Stockholm", zipcode: 11257 },
  { city: "Stockholm", zipcode: 11258 },
  { city: "Stockholm", zipcode: 11259 },
  { city: "Stockholm", zipcode: 11260 },
  { city: "Stockholm", zipcode: 11261 },
  { city: "Stockholm", zipcode: 11262 },
  { city: "Stockholm", zipcode: 11263 },
  { city: "Stockholm", zipcode: 11264 },
  { city: "Stockholm", zipcode: 11265 },
  { city: "Stockholm", zipcode: 11266 },
  { city: "Stockholm", zipcode: 11267 },
  { city: "Stockholm", zipcode: 11268 },
  { city: "Stockholm", zipcode: 11269 },
  { city: "Stockholm", zipcode: 11320 },
  { city: "Stockholm", zipcode: 11321 },
  { city: "Stockholm", zipcode: 11322 },
  { city: "Stockholm", zipcode: 11323 },
  { city: "Stockholm", zipcode: 11324 },
  { city: "Stockholm", zipcode: 11325 },
  { city: "Stockholm", zipcode: 11326 },
  { city: "Stockholm", zipcode: 11327 },
  { city: "Stockholm", zipcode: 11328 },
  { city: "Stockholm", zipcode: 11329 },
  { city: "Stockholm", zipcode: 11330 },
  { city: "Stockholm", zipcode: 11331 },
  { city: "Stockholm", zipcode: 11332 },
  { city: "Stockholm", zipcode: 11333 },
  { city: "Stockholm", zipcode: 11334 },
  { city: "Stockholm", zipcode: 11335 },
  { city: "Stockholm", zipcode: 11336 },
  { city: "Stockholm", zipcode: 11337 },
  { city: "Stockholm", zipcode: 11338 },
  { city: "Stockholm", zipcode: 11339 },
  { city: "Stockholm", zipcode: 11340 },
  { city: "Stockholm", zipcode: 11341 },
  { city: "Stockholm", zipcode: 11342 },
  { city: "Stockholm", zipcode: 11343 },
  { city: "Stockholm", zipcode: 11344 },
  { city: "Stockholm", zipcode: 11345 },
  { city: "Stockholm", zipcode: 11346 },
  { city: "Stockholm", zipcode: 11347 },
  { city: "Stockholm", zipcode: 11348 },
  { city: "Stockholm", zipcode: 11349 },
  { city: "Stockholm", zipcode: 11350 },
  { city: "Stockholm", zipcode: 11351 },
  { city: "Stockholm", zipcode: 11352 },
  { city: "Stockholm", zipcode: 11353 },
  { city: "Stockholm", zipcode: 11354 },
  { city: "Stockholm", zipcode: 11355 },
  { city: "Stockholm", zipcode: 11356 },
  { city: "Stockholm", zipcode: 11357 },
  { city: "Stockholm", zipcode: 11358 },
  { city: "Stockholm", zipcode: 11359 },
  { city: "Stockholm", zipcode: 11360 },
  { city: "Stockholm", zipcode: 11361 },
  { city: "Stockholm", zipcode: 11362 },
  { city: "Stockholm", zipcode: 11363 },
  { city: "Stockholm", zipcode: 11364 },
  { city: "Stockholm", zipcode: 11365 },
  { city: "Stockholm", zipcode: 11366 },
  { city: "Stockholm", zipcode: 11367 },
  { city: "Stockholm", zipcode: 11368 },
  { city: "Stockholm", zipcode: 11369 },
  { city: "Stockholm", zipcode: 11380 },
  { city: "Stockholm", zipcode: 11415 },
  { city: "Stockholm", zipcode: 11416 },
  { city: "Stockholm", zipcode: 11417 },
  { city: "Stockholm", zipcode: 11418 },
  { city: "Stockholm", zipcode: 11419 },
  { city: "Stockholm", zipcode: 11420 },
  { city: "Stockholm", zipcode: 11421 },
  { city: "Stockholm", zipcode: 11422 },
  { city: "Stockholm", zipcode: 11423 },
  { city: "Stockholm", zipcode: 11424 },
  { city: "Stockholm", zipcode: 11425 },
  { city: "Stockholm", zipcode: 11426 },
  { city: "Stockholm", zipcode: 11427 },
  { city: "Stockholm", zipcode: 11428 },
  { city: "Stockholm", zipcode: 11429 },
  { city: "Stockholm", zipcode: 11430 },
  { city: "Stockholm", zipcode: 11431 },
  { city: "Stockholm", zipcode: 11432 },
  { city: "Stockholm", zipcode: 11433 },
  { city: "Stockholm", zipcode: 11434 },
  { city: "Stockholm", zipcode: 11435 },
  { city: "Stockholm", zipcode: 11436 },
  { city: "Stockholm", zipcode: 11437 },
  { city: "Stockholm", zipcode: 11438 },
  { city: "Stockholm", zipcode: 11439 },
  { city: "Stockholm", zipcode: 11440 },
  { city: "Stockholm", zipcode: 11441 },
  { city: "Stockholm", zipcode: 11442 },
  { city: "Stockholm", zipcode: 11443 },
  { city: "Stockholm", zipcode: 11444 },
  { city: "Stockholm", zipcode: 11445 },
  { city: "Stockholm", zipcode: 11446 },
  { city: "Stockholm", zipcode: 11447 },
  { city: "Stockholm", zipcode: 11448 },
  { city: "Stockholm", zipcode: 11449 },
  { city: "Stockholm", zipcode: 11450 },
  { city: "Stockholm", zipcode: 11451 },
  { city: "Stockholm", zipcode: 11452 },
  { city: "Stockholm", zipcode: 11453 },
  { city: "Stockholm", zipcode: 11454 },
  { city: "Stockholm", zipcode: 11455 },
  { city: "Stockholm", zipcode: 11456 },
  { city: "Stockholm", zipcode: 11457 },
  { city: "Stockholm", zipcode: 11458 },
  { city: "Stockholm", zipcode: 11459 },
  { city: "Stockholm", zipcode: 11460 },
  { city: "Stockholm", zipcode: 11520 },
  { city: "Stockholm", zipcode: 11521 },
  { city: "Stockholm", zipcode: 11522 },
  { city: "Stockholm", zipcode: 11523 },
  { city: "Stockholm", zipcode: 11524 },
  { city: "Stockholm", zipcode: 11525 },
  { city: "Stockholm", zipcode: 11526 },
  { city: "Stockholm", zipcode: 11527 },
  { city: "Stockholm", zipcode: 11528 },
  { city: "Stockholm", zipcode: 11529 },
  { city: "Stockholm", zipcode: 11530 },
  { city: "Stockholm", zipcode: 11531 },
  {
    city: "Stockholm-Arlanda",
    zipcode: 19040
  },
  {
    city: "Stockholm-Arlanda",
    zipcode: 19045
  },
  {
    city: "Stockholm-Arlanda",
    zipcode: 19046
  },
  {
    city: "Stockholm-Arlanda",
    zipcode: 19047
  },
  {
    city: "Stockholm-Arlanda",
    zipcode: 19050
  },
  {
    city: "Stockholm-Arlanda",
    zipcode: 19060
  },
  {
    city: "Stockholm-Arlanda",
    zipcode: 19090
  },
  {
    city: "Stockholm-Globen",
    zipcode: 12126
  },
  {
    city: "Stockholm-Globen",
    zipcode: 12127
  },
  {
    city: "Stockholm-Globen",
    zipcode: 12128
  },
  {
    city: "Stockholm-Globen",
    zipcode: 12188
  },
  {
    city: "Sundbyberg",
    zipcode: 17205
  },
  {
    city: "Sundbyberg",
    zipcode: 17214
  },
  {
    city: "Sundbyberg",
    zipcode: 17215
  },
  {
    city: "Sundbyberg",
    zipcode: 17220
  },
  {
    city: "Sundbyberg",
    zipcode: 17420
  },
  {
    city: "Sundbyberg",
    zipcode: 17441
  },
  {
    city: "Sundbyberg",
    zipcode: 17461
  },
  {
    city: "Sundbyberg",
    zipcode: 17462
  },
  {
    city: "Sundbyberg",
    zipcode: 17463
  },
  {
    city: "Sundbyberg",
    zipcode: 17465
  },
  {
    city: "Sundbyberg",
    zipcode: 17466
  },
  {
    city: "Sundbyberg",
    zipcode: 17486
  },
  {
    city: "Sundbyberg",
    zipcode: 17487
  },
  {
    city: "Sundbyberg",
    zipcode: 17488
  },
  {
    city: "Taby",
    zipcode: 18310
  },
  {
    city: "Taby",
    zipcode: 18320
  },
  {
    city: "Taby",
    zipcode: 18368
  },
  {
    city: "Taby",
    zipcode: 18715
  },
  {
    city: "Taby",
    zipcode: 18720
  },
  {
    city: "Taby",
    zipcode: 18726
  },
  {
    city: "Taby",
    zipcode: 18727
  },
  {
    city: "Taby",
    zipcode: 18728
  },
  {
    city: "Taby",
    zipcode: 18729
  },
  {
    city: "Taby",
    zipcode: 18730
  },
  {
    city: "Taby",
    zipcode: 18731
  },
  {
    city: "Taby",
    zipcode: 18732
  },
  {
    city: "Taby",
    zipcode: 18733
  },
  {
    city: "Taby",
    zipcode: 18734
  },
  {
    city: "Taby",
    zipcode: 18735
  },
  {
    city: "Taby",
    zipcode: 18736
  },
  {
    city: "Taby",
    zipcode: 18737
  },
  {
    city: "Taby",
    zipcode: 18738
  },
  {
    city: "Taby",
    zipcode: 18740
  },
  {
    city: "Taby",
    zipcode: 18741
  },
  {
    city: "Taby",
    zipcode: 18742
  },
  {
    city: "Taby",
    zipcode: 18743
  },
  {
    city: "Taby",
    zipcode: 18744
  },
  {
    city: "Taby",
    zipcode: 18745
  },
  {
    city: "Taby",
    zipcode: 18746
  },
  {
    city: "Taby",
    zipcode: 18750
  },
  {
    city: "Taby",
    zipcode: 18751
  },
  {
    city: "Taby",
    zipcode: 18752
  },
  {
    city: "Taby",
    zipcode: 18753
  },
  {
    city: "Taby",
    zipcode: 18754
  },
  {
    city: "Taby",
    zipcode: 18762
  },
  {
    city: "Taby",
    zipcode: 18763
  },
  {
    city: "Taby",
    zipcode: 18764
  },
  {
    city: "Taby",
    zipcode: 18765
  },
  {
    city: "Taby",
    zipcode: 18766
  },
  {
    city: "Taby",
    zipcode: 18767
  },
  {
    city: "Taby",
    zipcode: 18768
  },
  {
    city: "Taby",
    zipcode: 18769
  },
  {
    city: "Taby",
    zipcode: 18770
  },
  {
    city: "Taby",
    zipcode: 18772
  },
  {
    city: "Taby",
    zipcode: 18773
  },
  {
    city: "Taby",
    zipcode: 18774
  },
  {
    city: "Taby",
    zipcode: 18775
  },
  {
    city: "Taby",
    zipcode: 18776
  },
  {
    city: "Taby",
    zipcode: 18777
  },
  {
    city: "Taby",
    zipcode: 18780
  },
  {
    city: "Taby",
    zipcode: 18781
  },
  {
    city: "Taby",
    zipcode: 19281
  },
  {
    city: "Tomteboda",
    zipcode: 17307
  },
  {
    city: "Tomteboda",
    zipcode: 17308
  },
  {
    city: "Tomteboda",
    zipcode: 17320
  },
  {
    city: "Tomteboda",
    zipcode: 17323
  },
  {
    city: "Tomteboda",
    zipcode: 17324
  },
  {
    city: "Tomteboda",
    zipcode: 17325
  },
  {
    city: "Tomteboda",
    zipcode: 17326
  },
  {
    city: "Tomteboda",
    zipcode: 17327
  },
  {
    city: "Tomteboda",
    zipcode: 17328
  },
  {
    city: "Tomteboda",
    zipcode: 17329
  },
  {
    city: "Tullinge",
    zipcode: 14620
  },
  {
    city: "Tumba",
    zipcode: 14720
  },
  {
    city: "Tumba",
    zipcode: 14745
  },
  {
    city: "Tyreso",
    zipcode: 13520
  },
  {
    city: "Upplands Vasby",
    zipcode: 19400
  },
  {
    city: "Upplands Vasby",
    zipcode: 19402
  },
  {
    city: "Upplands Vasby",
    zipcode: 19404
  },
  {
    city: "Upplands Vasby",
    zipcode: 19405
  },
  {
    city: "Upplands Vasby",
    zipcode: 19410
  },
  {
    city: "Upplands Vasby",
    zipcode: 19417
  },
  {
    city: "Upplands Vasby",
    zipcode: 19418
  },
  {
    city: "Upplands Vasby",
    zipcode: 19419
  },
  {
    city: "Upplands Vasby",
    zipcode: 19420
  },
  {
    city: "Upplands Vasby",
    zipcode: 19421
  },
  {
    city: "Upplands Vasby",
    zipcode: 19422
  },
  {
    city: "Upplands Vasby",
    zipcode: 19423
  },
  {
    city: "Upplands Vasby",
    zipcode: 19426
  },
  {
    city: "Upplands Vasby",
    zipcode: 19427
  },
  {
    city: "Upplands Vasby",
    zipcode: 19428
  },
  {
    city: "Upplands Vasby",
    zipcode: 19429
  },
  {
    city: "Upplands Vasby",
    zipcode: 19430
  },
  {
    city: "Upplands Vasby",
    zipcode: 19431
  },
  {
    city: "Upplands Vasby",
    zipcode: 19432
  },
  {
    city: "Upplands Vasby",
    zipcode: 19433
  },
  {
    city: "Upplands Vasby",
    zipcode: 19434
  },
  {
    city: "Upplands Vasby",
    zipcode: 19435
  },
  {
    city: "Upplands Vasby",
    zipcode: 19436
  },
  {
    city: "Upplands Vasby",
    zipcode: 19437
  },
  {
    city: "Upplands Vasby",
    zipcode: 19438
  },
  {
    city: "Upplands Vasby",
    zipcode: 19439
  },
  {
    city: "Upplands Vasby",
    zipcode: 19440
  },
  {
    city: "Upplands Vasby",
    zipcode: 19441
  },
  {
    city: "Upplands Vasby",
    zipcode: 19442
  },
  {
    city: "Upplands Vasby",
    zipcode: 19443
  },
  {
    city: "Upplands Vasby",
    zipcode: 19444
  },
  {
    city: "Upplands Vasby",
    zipcode: 19445
  },
  {
    city: "Upplands Vasby",
    zipcode: 19446
  },
  {
    city: "Upplands Vasby",
    zipcode: 19447
  },
  {
    city: "Upplands Vasby",
    zipcode: 19449
  },
  {
    city: "Upplands Vasby",
    zipcode: 19451
  },
  {
    city: "Upplands Vasby",
    zipcode: 19452
  },
  {
    city: "Upplands Vasby",
    zipcode: 19453
  },
  {
    city: "Upplands Vasby",
    zipcode: 19454
  },
  {
    city: "Upplands Vasby",
    zipcode: 19455
  },
  {
    city: "Upplands Vasby",
    zipcode: 19456
  },
  {
    city: "Upplands Vasby",
    zipcode: 19457
  },
  {
    city: "Upplands Vasby",
    zipcode: 19460
  },
  {
    city: "Upplands Vasby",
    zipcode: 19461
  },
  {
    city: "Upplands Vasby",
    zipcode: 19462
  },
  {
    city: "Upplands Vasby",
    zipcode: 19463
  },
  {
    city: "Upplands Vasby",
    zipcode: 19464
  },
  {
    city: "Upplands Vasby",
    zipcode: 19465
  },
  {
    city: "Upplands Vasby",
    zipcode: 19466
  },
  {
    city: "Upplands Vasby",
    zipcode: 19467
  },
  {
    city: "Upplands Vasby",
    zipcode: 19468
  },
  {
    city: "Upplands Vasby",
    zipcode: 19469
  },
  {
    city: "Upplands Vasby",
    zipcode: 19470
  },
  {
    city: "Upplands Vasby",
    zipcode: 19471
  },
  {
    city: "Upplands Vasby",
    zipcode: 19472
  },
  {
    city: "Upplands Vasby",
    zipcode: 19473
  },
  {
    city: "Upplands Vasby",
    zipcode: 19474
  },
  {
    city: "Upplands Vasby",
    zipcode: 19475
  },
  {
    city: "Upplands Vasby",
    zipcode: 19476
  },
  {
    city: "Upplands Vasby",
    zipcode: 19477
  },
  {
    city: "Upplands Vasby",
    zipcode: 19478
  },
  {
    city: "Upplands Vasby",
    zipcode: 19479
  },
  {
    city: "Upplands Vasby",
    zipcode: 19480
  },
  {
    city: "Upplands Vasby",
    zipcode: 19481
  },
  {
    city: "Upplands Vasby",
    zipcode: 19482
  },
  {
    city: "Upplands Vasby",
    zipcode: 19483
  },
  {
    city: "Upplands Vasby",
    zipcode: 19484
  },
  {
    city: "Upplands Vasby",
    zipcode: 19486
  },
  {
    city: "Upplands Vasby",
    zipcode: 19487
  },
  {
    city: "Upplands Vasby",
    zipcode: 19488
  },
  {
    city: "Upplands Vasby",
    zipcode: 19489
  },
  {
    city: "Upplands Vasby",
    zipcode: 19491
  },
  {
    city: "Upplands Vasby",
    zipcode: 19492
  },
  {
    city: "Upplands Vasby",
    zipcode: 19498
  },
  {
    city: "Vallentuna",
    zipcode: 18620
  },
  {
    city: "Vallentuna",
    zipcode: 18647
  },
  {
    city: "Vallingby",
    zipcode: 16200
  },
  {
    city: "Vallingby",
    zipcode: 16206
  },
  {
    city: "Vallingby",
    zipcode: 16211
  },
  {
    city: "Vallingby",
    zipcode: 16212
  },
  {
    city: "Vallingby",
    zipcode: 16215
  },
  {
    city: "Vallingby",
    zipcode: 16220
  },
  {
    city: "Vallingby",
    zipcode: 16243
  },
  {
    city: "Vallingby",
    zipcode: 16244
  },
  {
    city: "Vallingby",
    zipcode: 16245
  },
  {
    city: "Vallingby",
    zipcode: 16246
  },
  {
    city: "Vallingby",
    zipcode: 16247
  },
  {
    city: "Vallingby",
    zipcode: 16250
  },
  {
    city: "Vallingby",
    zipcode: 16251
  },
  {
    city: "Vallingby",
    zipcode: 16252
  },
  {
    city: "Vallingby",
    zipcode: 16253
  },
  {
    city: "Vallingby",
    zipcode: 16254
  },
  {
    city: "Vallingby",
    zipcode: 16255
  },
  {
    city: "Vallingby",
    zipcode: 16256
  },
  {
    city: "Vallingby",
    zipcode: 16257
  },
  {
    city: "Vallingby",
    zipcode: 16258
  },
  {
    city: "Vallingby",
    zipcode: 16260
  },
  {
    city: "Vallingby",
    zipcode: 16261
  },
  {
    city: "Vallingby",
    zipcode: 16262
  },
  {
    city: "Vallingby",
    zipcode: 16263
  },
  {
    city: "Vallingby",
    zipcode: 16264
  },
  {
    city: "Vallingby",
    zipcode: 16265
  },
  {
    city: "Vallingby",
    zipcode: 16266
  },
  {
    city: "Vallingby",
    zipcode: 16267
  },
  {
    city: "Vallingby",
    zipcode: 16268
  },
  {
    city: "Vallingby",
    zipcode: 16270
  },
  {
    city: "Vallingby",
    zipcode: 16271
  },
  {
    city: "Vallingby",
    zipcode: 16272
  },
  {
    city: "Vallingby",
    zipcode: 16273
  },
  {
    city: "Vallingby",
    zipcode: 16274
  },
  {
    city: "Vallingby",
    zipcode: 16283
  },
  {
    city: "Vallingby",
    zipcode: 16288
  },
  {
    city: "Vallingby",
    zipcode: 16201
  },
  {
    city: "Vallingby",
    zipcode: 16213
  },
  {
    city: "Varby",
    zipcode: 14346
  },
  {
    city: "Varby",
    zipcode: 14320
  },
  {
    city: "Varmdo",
    zipcode: 13900
  },
  {
    city: "Varmdo",
    zipcode: 13920
  },
  {
    city: "Varmdo",
    zipcode: 13931
  },
  {
    city: "Vasterhaninge",
    zipcode: 13720
  },
  {
    city: "Vasterhaninge",
    zipcode: 13742
  },
  {
    city: "Vaxholm",
    zipcode: 18520
  }
  ,
  { city: "Gothenburg", zipcode: 42438 },
  { city: "Gothenburg", zipcode: 42400 },
  { city: "Gothenburg", zipcode: 42401 },
  { city: "Gothenburg", zipcode: 42402 },
  { city: "Gothenburg", zipcode: 42406 },
  { city: "Gothenburg", zipcode: 42410 },
  { city: "Gothenburg", zipcode: 42421 },
  { city: "Gothenburg", zipcode: 42422 },
  { city: "Gothenburg", zipcode: 42423 },
  { city: "Gothenburg", zipcode: 42424 },
  { city: "Gothenburg", zipcode: 42431 },
  { city: "Gothenburg", zipcode: 42432 },
  { city: "Gothenburg", zipcode: 42433 },
  { city: "Gothenburg", zipcode: 42434 },
  { city: "Gothenburg", zipcode: 42435 },
  { city: "Gothenburg", zipcode: 42436 },
  { city: "Gothenburg", zipcode: 42437 },
  { city: "Gothenburg", zipcode: 42439 },
  { city: "Gothenburg", zipcode: 42440 },
  { city: "Gothenburg", zipcode: 42441 },
  { city: "Gothenburg", zipcode: 42442 },
  { city: "Gothenburg", zipcode: 42443 },
  { city: "Gothenburg", zipcode: 42444 },
  { city: "Gothenburg", zipcode: 42445 },
  { city: "Gothenburg", zipcode: 42446 },
  { city: "Gothenburg", zipcode: 42447 },
  { city: "Gothenburg", zipcode: 42448 },
  { city: "Gothenburg", zipcode: 42449 },
  { city: "Gothenburg", zipcode: 42450 },
  { city: "Gothenburg", zipcode: 42453 },
  { city: "Gothenburg", zipcode: 42455 },
  { city: "Gothenburg", zipcode: 42461 },
  { city: "Gothenburg", zipcode: 42465 },
  { city: "Gothenburg", zipcode: 42466 },
  { city: "Gothenburg", zipcode: 42467 },
  { city: "Gothenburg", zipcode: 42468 },
  { city: "Gothenburg", zipcode: 42469 },
  { city: "Gothenburg", zipcode: 42480 },
  { city: "Gothenburg", zipcode: 42456 },
  { city: "Gothenburg", zipcode: 42457 },
  { city: "Gothenburg", zipcode: 42200 },
  { city: "Gothenburg", zipcode: 42202 },
  { city: "Gothenburg", zipcode: 42203 },
  { city: "Gothenburg", zipcode: 42204 },
  { city: "Gothenburg", zipcode: 42210 },
  { city: "Gothenburg", zipcode: 42241 },
  { city: "Gothenburg", zipcode: 42242 },
  { city: "Gothenburg", zipcode: 42243 },
  { city: "Gothenburg", zipcode: 42244 },
  { city: "Gothenburg", zipcode: 42245 },
  { city: "Gothenburg", zipcode: 42246 },
  { city: "Gothenburg", zipcode: 42247 },
  { city: "Gothenburg", zipcode: 42248 },
  { city: "Gothenburg", zipcode: 42249 },
  { city: "Gothenburg", zipcode: 42250 },
  { city: "Gothenburg", zipcode: 42251 },
  { city: "Gothenburg", zipcode: 42252 },
  { city: "Gothenburg", zipcode: 42253 },
  { city: "Gothenburg", zipcode: 42254 },
  { city: "Gothenburg", zipcode: 42255 },
  { city: "Gothenburg", zipcode: 42256 },
  { city: "Gothenburg", zipcode: 42257 },
  { city: "Gothenburg", zipcode: 42258 },
  { city: "Gothenburg", zipcode: 42259 },
  { city: "Gothenburg", zipcode: 42280 },
  { city: "Gothenburg", zipcode: 42500 },
  { city: "Gothenburg", zipcode: 42502 },
  { city: "Gothenburg", zipcode: 42508 },
  { city: "Gothenburg", zipcode: 42530 },
  { city: "Gothenburg", zipcode: 42531 },
  { city: "Gothenburg", zipcode: 42532 },
  { city: "Gothenburg", zipcode: 42533 },
  { city: "Gothenburg", zipcode: 42534 },
  { city: "Gothenburg", zipcode: 42535 },
  { city: "Gothenburg", zipcode: 42536 },
  { city: "Gothenburg", zipcode: 42537 },
  { city: "Gothenburg", zipcode: 42538 },
  { city: "Gothenburg", zipcode: 42539 },
  { city: "Gothenburg", zipcode: 42541 },
  { city: "Gothenburg", zipcode: 42542 },
  { city: "Gothenburg", zipcode: 42543 },
  { city: "Gothenburg", zipcode: 42565 },
  { city: "Gothenburg", zipcode: 43602 },
  { city: "Gothenburg", zipcode: 43650 },
  { city: "Gothenburg", zipcode: 43651 },
  { city: "Gothenburg", zipcode: 43652 },
  { city: "Gothenburg", zipcode: 43653 },
  { city: "Gothenburg", zipcode: 43654 },
  { city: "Gothenburg", zipcode: 43655 },
  { city: "Gothenburg", zipcode: 43656 },
  { city: "Gothenburg", zipcode: 43657 },
  { city: "Gothenburg", zipcode: 43658 },
  { city: "Gothenburg", zipcode: 43081 },
  { city: "Gothenburg", zipcode: 44970 },
  { city: "Gothenburg", zipcode: 42470 },
  { city: "Gothenburg", zipcode: 42471 },
  { city: "Gothenburg", zipcode: 42472 },
  { city: "Gothenburg", zipcode: 42490 },
  { city: "Gothenburg", zipcode: 42491 },
  { city: "Gothenburg", zipcode: 42370 },
  { city: "Gothenburg", zipcode: 42371 },
  { city: "Gothenburg", zipcode: 42372 },
  { city: "Gothenburg", zipcode: 42373 },
  { city: "Gothenburg", zipcode: 43084 },
  { city: "Gothenburg", zipcode: 42300 },
  { city: "Gothenburg", zipcode: 42321 },
  { city: "Gothenburg", zipcode: 42322 },
  { city: "Gothenburg", zipcode: 42323 },
  { city: "Gothenburg", zipcode: 42332 },
  { city: "Gothenburg", zipcode: 42333 },
  { city: "Gothenburg", zipcode: 42334 },
  { city: "Gothenburg", zipcode: 42335 },
  { city: "Gothenburg", zipcode: 42336 },
  { city: "Gothenburg", zipcode: 42337 },
  { city: "Gothenburg", zipcode: 42338 },
  { city: "Gothenburg", zipcode: 42339 },
  { city: "Gothenburg", zipcode: 42340 },
  { city: "Gothenburg", zipcode: 42341 },
  { city: "Gothenburg", zipcode: 42342 },
  { city: "Gothenburg", zipcode: 42343 },
  { city: "Gothenburg", zipcode: 42344 },
  { city: "Gothenburg", zipcode: 42346 },
  { city: "Gothenburg", zipcode: 42347 },
  { city: "Gothenburg", zipcode: 42348 },
  { city: "Gothenburg", zipcode: 42349 },
  { city: "Gothenburg", zipcode: 42350 },
  { city: "Gothenburg", zipcode: 42351 },
  { city: "Gothenburg", zipcode: 42352 },
  { city: "Gothenburg", zipcode: 42353 },
  { city: "Gothenburg", zipcode: 42354 },
  { city: "Gothenburg", zipcode: 42355 },
  { city: "Gothenburg", zipcode: 42356 },
  { city: "Gothenburg", zipcode: 42359 },
  { city: "Gothenburg", zipcode: 42361 },
  { city: "Gothenburg", zipcode: 42363 },
  { city: "Gothenburg", zipcode: 42100 },
  { city: "Gothenburg", zipcode: 42101 },
  { city: "Gothenburg", zipcode: 42102 },
  { city: "Gothenburg", zipcode: 42108 },
  { city: "Gothenburg", zipcode: 42110 },
  { city: "Gothenburg", zipcode: 42121 },
  { city: "Gothenburg", zipcode: 42122 },
  { city: "Gothenburg", zipcode: 42123 },
  { city: "Gothenburg", zipcode: 42126 },
  { city: "Gothenburg", zipcode: 42130 },
  { city: "Gothenburg", zipcode: 42131 },
  { city: "Gothenburg", zipcode: 42132 },
  { city: "Gothenburg", zipcode: 42133 },
  { city: "Gothenburg", zipcode: 42134 },
  { city: "Gothenburg", zipcode: 42135 },
  { city: "Gothenburg", zipcode: 42136 },
  { city: "Gothenburg", zipcode: 42137 },
  { city: "Gothenburg", zipcode: 42138 },
  { city: "Gothenburg", zipcode: 42139 },
  { city: "Gothenburg", zipcode: 42140 },
  { city: "Gothenburg", zipcode: 42141 },
  { city: "Gothenburg", zipcode: 42142 },
  { city: "Gothenburg", zipcode: 42143 },
  { city: "Gothenburg", zipcode: 42144 },
  { city: "Gothenburg", zipcode: 42145 },
  { city: "Gothenburg", zipcode: 42146 },
  { city: "Gothenburg", zipcode: 42147 },
  { city: "Gothenburg", zipcode: 42148 },
  { city: "Gothenburg", zipcode: 42149 },
  { city: "Gothenburg", zipcode: 42150 },
  { city: "Gothenburg", zipcode: 42151 },
  { city: "Gothenburg", zipcode: 42152 },
  { city: "Gothenburg", zipcode: 42153 },
  { city: "Gothenburg", zipcode: 42154 },
  { city: "Gothenburg", zipcode: 42157 },
  { city: "Gothenburg", zipcode: 42158 },
  { city: "Gothenburg", zipcode: 42159 },
  { city: "Gothenburg", zipcode: 42160 },
  { city: "Gothenburg", zipcode: 42161 },
  { city: "Gothenburg", zipcode: 42162 },
  { city: "Gothenburg", zipcode: 42163 },
  { city: "Gothenburg", zipcode: 42164 },
  { city: "Gothenburg", zipcode: 42165 },
  { city: "Gothenburg", zipcode: 42166 },
  { city: "Gothenburg", zipcode: 42167 },
  { city: "Gothenburg", zipcode: 42170 },
  { city: "Gothenburg", zipcode: 42171 },
  { city: "Gothenburg", zipcode: 42172 },
  { city: "Gothenburg", zipcode: 42173 },
  { city: "Gothenburg", zipcode: 42174 },
  { city: "Gothenburg", zipcode: 42600 },
  { city: "Gothenburg", zipcode: 42604 },
  { city: "Gothenburg", zipcode: 42605 },
  { city: "Gothenburg", zipcode: 42650 },
  { city: "Gothenburg", zipcode: 42651 },
  { city: "Gothenburg", zipcode: 42652 },
  { city: "Gothenburg", zipcode: 42653 },
  { city: "Gothenburg", zipcode: 42654 },
  { city: "Gothenburg", zipcode: 42655 },
  { city: "Gothenburg", zipcode: 42656 },
  { city: "Gothenburg", zipcode: 42657 },
  { city: "Gothenburg", zipcode: 42658 },
  { city: "Gothenburg", zipcode: 42659 },
  { city: "Gothenburg", zipcode: 42668 },
  { city: "Gothenburg", zipcode: 42669 },
  { city: "Gothenburg", zipcode: 42670 },
  { city: "Gothenburg", zipcode: 42671 },
  { city: "Gothenburg", zipcode: 42672 },
  { city: "Gothenburg", zipcode: 42673 },
  { city: "Gothenburg", zipcode: 42674 },
  { city: "Gothenburg", zipcode: 42675 },
  { city: "Gothenburg", zipcode: 42676 },
  { city: "Gothenburg", zipcode: 42677 },
  { city: "Gothenburg", zipcode: 42678 },
  { city: "Gothenburg", zipcode: 42679 },
  { city: "Gothenburg", zipcode: 42680 },
  { city: "Gothenburg", zipcode: 43083 },
  { city: "Gothenburg", zipcode: 43084 },
  { city: "Gothenburg", zipcode: 42456 },
  { city: "Gothenburg", zipcode: 42457 },
  { city: "Gothenburg", zipcode: 42200 },
  { city: "Gothenburg", zipcode: 42202 },
  { city: "Gothenburg", zipcode: 42203 },
  { city: "Gothenburg", zipcode: 42204 },
  { city: "Gothenburg", zipcode: 42210 },
  { city: "Gothenburg", zipcode: 42241 },
  { city: "Gothenburg", zipcode: 42242 },
  { city: "Gothenburg", zipcode: 42243 },
  { city: "Gothenburg", zipcode: 42244 },
  { city: "Gothenburg", zipcode: 42245 },
  { city: "Gothenburg", zipcode: 42246 },
  { city: "Gothenburg", zipcode: 42247 },
  { city: "Gothenburg", zipcode: 42248 },
  { city: "Gothenburg", zipcode: 42249 },
  { city: "Gothenburg", zipcode: 42250 },
  { city: "Gothenburg", zipcode: 42251 },
  { city: "Gothenburg", zipcode: 42252 },
  { city: "Gothenburg", zipcode: 42253 },
  { city: "Gothenburg", zipcode: 42254 },
  { city: "Gothenburg", zipcode: 42255 },
  { city: "Gothenburg", zipcode: 42256 },
  { city: "Gothenburg", zipcode: 42257 },
  { city: "Gothenburg", zipcode: 42258 },
  { city: "Gothenburg", zipcode: 42259 },
  { city: "Gothenburg", zipcode: 42280 },
  { city: "Gothenburg", zipcode: 42500 },
  { city: "Gothenburg", zipcode: 42502 },
  { city: "Gothenburg", zipcode: 42508 },
  { city: "Gothenburg", zipcode: 42530 },
  { city: "Gothenburg", zipcode: 42531 },
  { city: "Gothenburg", zipcode: 42532 },
  { city: "Gothenburg", zipcode: 42533 },
  { city: "Gothenburg", zipcode: 42534 },
  { city: "Gothenburg", zipcode: 42535 },
  { city: "Gothenburg", zipcode: 42536 },
  { city: "Gothenburg", zipcode: 42537 },
  { city: "Gothenburg", zipcode: 42538 },
  { city: "Gothenburg", zipcode: 42539 },
  { city: "Gothenburg", zipcode: 42541 },
  { city: "Gothenburg", zipcode: 42542 },
  { city: "Gothenburg", zipcode: 42543 },
  { city: "Gothenburg", zipcode: 42565 },
  { city: "Gothenburg", zipcode: 43602 },
  { city: "Gothenburg", zipcode: 43650 },
  { city: "Gothenburg", zipcode: 43651 },
  { city: "Gothenburg", zipcode: 43652 },
  { city: "Gothenburg", zipcode: 43653 },
  { city: "Gothenburg", zipcode: 43654 },
  { city: "Gothenburg", zipcode: 43655 },
  { city: "Gothenburg", zipcode: 43656 },
  { city: "Gothenburg", zipcode: 43657 },
  { city: "Gothenburg", zipcode: 43658 },
  { city: "Gothenburg", zipcode: 43081 },
  { city: "Gothenburg", zipcode: 44970 },
  { city: "Gothenburg", zipcode: 42470 },
  { city: "Gothenburg", zipcode: 42471 },
  { city: "Gothenburg", zipcode: 42472 },
  { city: "Gothenburg", zipcode: 42490 },
  { city: "Gothenburg", zipcode: 42491 },
  { city: "Gothenburg", zipcode: 42370 },
  { city: "Gothenburg", zipcode: 42371 },
  { city: "Gothenburg", zipcode: 42372 },
  { city: "Gothenburg", zipcode: 42373 },
  { city: "Gothenburg", zipcode: 43084 },
  { city: "Gothenburg", zipcode: 42300 },
  { city: "Gothenburg", zipcode: 42321 },
  { city: "Gothenburg", zipcode: 42322 },
  { city: "Gothenburg", zipcode: 42323 },
  { city: "Gothenburg", zipcode: 42332 },
  { city: "Gothenburg", zipcode: 42333 },
  { city: "Gothenburg", zipcode: 42334 },
  { city: "Gothenburg", zipcode: 42335 },
  { city: "Gothenburg", zipcode: 42336 },
  { city: "Gothenburg", zipcode: 42337 },
  { city: "Gothenburg", zipcode: 42338 },
  { city: "Gothenburg", zipcode: 42339 },
  { city: "Gothenburg", zipcode: 42340 },
  { city: "Gothenburg", zipcode: 42341 },
  { city: "Gothenburg", zipcode: 42342 },
  { city: "Gothenburg", zipcode: 42343 },
  { city: "Gothenburg", zipcode: 42344 },
  { city: "Gothenburg", zipcode: 42345 },
  { city: "Gothenburg", zipcode: 42346 },
  { city: "Gothenburg", zipcode: 42347 },
  { city: "Gothenburg", zipcode: 42348 },
  { city: "Gothenburg", zipcode: 42349 },
  { city: "Gothenburg", zipcode: 42350 },
  { city: "Gothenburg", zipcode: 42351 },
  { city: "Gothenburg", zipcode: 42352 },
  { city: "Gothenburg", zipcode: 42353 },
  { city: "Gothenburg", zipcode: 42354 },
  { city: "Gothenburg", zipcode: 42355 },
  { city: "Gothenburg", zipcode: 42356 },
  { city: "Gothenburg", zipcode: 42357 },
  { city: "Gothenburg", zipcode: 42358 },
  { city: "Gothenburg", zipcode: 42359 },
  { city: "Gothenburg", zipcode: 42361 },
  { city: "Gothenburg", zipcode: 42363 },
  { city: "Gothenburg", zipcode: 42100 },
  { city: "Gothenburg", zipcode: 42101 },
  { city: "Gothenburg", zipcode: 42102 },
  { city: "Gothenburg", zipcode: 42108 },
  { city: "Gothenburg", zipcode: 42110 },
  { city: "Gothenburg", zipcode: 42121 },
  { city: "Gothenburg", zipcode: 42122 },
  { city: "Gothenburg", zipcode: 42123 },
  { city: "Gothenburg", zipcode: 42126 },
  { city: "Gothenburg", zipcode: 42130 },
  { city: "Gothenburg", zipcode: 42131 },
  { city: "Gothenburg", zipcode: 42132 },
  { city: "Gothenburg", zipcode: 42133 },
  { city: "Gothenburg", zipcode: 42134 },
  { city: "Gothenburg", zipcode: 42135 },
  { city: "Gothenburg", zipcode: 42136 },
  { city: "Gothenburg", zipcode: 42137 },
  { city: "Gothenburg", zipcode: 42138 },
  { city: "Gothenburg", zipcode: 42139 },
  { city: "Gothenburg", zipcode: 42140 },
  { city: "Gothenburg", zipcode: 42141 },
  { city: "Gothenburg", zipcode: 42142 },
  { city: "Gothenburg", zipcode: 42143 },
  { city: "Gothenburg", zipcode: 42144 },
  { city: "Gothenburg", zipcode: 42145 },
  { city: "Gothenburg", zipcode: 42146 },
  { city: "Gothenburg", zipcode: 42147 },
  { city: "Gothenburg", zipcode: 42148 },
  { city: "Gothenburg", zipcode: 42149 },
  { city: "Gothenburg", zipcode: 42150 },
  { city: "Gothenburg", zipcode: 42151 },
  { city: "Gothenburg", zipcode: 42152 },
  { city: "Gothenburg", zipcode: 42153 },
  { city: "Gothenburg", zipcode: 42154 },
  { city: "Gothenburg", zipcode: 42157 },
  { city: "Gothenburg", zipcode: 42158 },
  { city: "Gothenburg", zipcode: 42159 },
  { city: "Gothenburg", zipcode: 42160 },
  { city: "Gothenburg", zipcode: 42161 },
  { city: "Gothenburg", zipcode: 42162 },
  { city: "Gothenburg", zipcode: 42163 },
  { city: "Gothenburg", zipcode: 42164 },
  { city: "Gothenburg", zipcode: 42165 },
  { city: "Gothenburg", zipcode: 42166 },
  { city: "Gothenburg", zipcode: 42167 },
  { city: "Gothenburg", zipcode: 42170 },
  { city: "Gothenburg", zipcode: 42171 },
  { city: "Gothenburg", zipcode: 42172 },
  { city: "Gothenburg", zipcode: 42173 },
  { city: "Gothenburg", zipcode: 42174 },
  { city: "Gothenburg", zipcode: 42600 },
  { city: "Gothenburg", zipcode: 42604 },
  { city: "Gothenburg", zipcode: 42605 },
  { city: "Gothenburg", zipcode: 42650 },
  { city: "Gothenburg", zipcode: 42651 },
  { city: "Gothenburg", zipcode: 42652 },
  { city: "Gothenburg", zipcode: 42653 },
  { city: "Gothenburg", zipcode: 42654 },
  { city: "Gothenburg", zipcode: 42655 },
  { city: "Gothenburg", zipcode: 42656 },
  { city: "Gothenburg", zipcode: 42657 },
  { city: "Gothenburg", zipcode: 42658 },
  { city: "Gothenburg", zipcode: 42659 },
  { city: "Gothenburg", zipcode: 42668 },
  { city: "Gothenburg", zipcode: 42669 },
  { city: "Gothenburg", zipcode: 42670 },
  { city: "Gothenburg", zipcode: 42671 },
  { city: "Gothenburg", zipcode: 42672 },
  { city: "Gothenburg", zipcode: 42673 },
  { city: "Gothenburg", zipcode: 42674 },
  { city: "Gothenburg", zipcode: 42675 },
  { city: "Gothenburg", zipcode: 42676 },
  { city: "Gothenburg", zipcode: 42677 },
  { city: "Gothenburg", zipcode: 42678 },
  { city: "Gothenburg", zipcode: 42679 },
  { city: "Gothenburg", zipcode: 42680 },
  { city: "Gothenburg", zipcode: 43083 },
  { city: "Gothenburg", zipcode: 43084 },
  { city: "Gothenburg", zipcode: 42456 },
  { city: "Gothenburg", zipcode: 42457 },
  { city: "Gothenburg", zipcode: 42200 },
  { city: "Gothenburg", zipcode: 42202 },
  { city: "Gothenburg", zipcode: 42203 },
  { city: "Gothenburg", zipcode: 42204 },
  { city: "Gothenburg", zipcode: 42210 },
  { city: "Gothenburg", zipcode: 42241 },
  { city: "Gothenburg", zipcode: 42242 },
  { city: "Gothenburg", zipcode: 42243 },
  { city: "Gothenburg", zipcode: 42244 },
  { city: "Gothenburg", zipcode: 42245 },
  { city: "Gothenburg", zipcode: 42246 },
  { city: "Gothenburg", zipcode: 42247 },
  { city: "Gothenburg", zipcode: 42248 },
  { city: "Gothenburg", zipcode: 42249 },
  { city: "Gothenburg", zipcode: 42250 },
  { city: "Gothenburg", zipcode: 42251 },
  { city: "Gothenburg", zipcode: 42252 },
  { city: "Gothenburg", zipcode: 42253 },
  { city: "Gothenburg", zipcode: 42254 },
  { city: "Gothenburg", zipcode: 42255 },
  { city: "Gothenburg", zipcode: 42256 },
  { city: "Gothenburg", zipcode: 42257 },
  { city: "Gothenburg", zipcode: 42258 },
  { city: "Gothenburg", zipcode: 42259 },
  { city: "Gothenburg", zipcode: 42280 },
  { city: "Gothenburg", zipcode: 42500 },
  { city: "Gothenburg", zipcode: 42502 },
  { city: "Gothenburg", zipcode: 42508 },
  { city: "Gothenburg", zipcode: 42530 },
  { city: "Gothenburg", zipcode: 42531 },
  { city: "Gothenburg", zipcode: 42532 },
  { city: "Gothenburg", zipcode: 42533 },
  { city: "Gothenburg", zipcode: 42534 },
  { city: "Gothenburg", zipcode: 42535 },
  { city: "Gothenburg", zipcode: 42536 },
  { city: "Gothenburg", zipcode: 42537 },
  { city: "Gothenburg", zipcode: 42538 },
  { city: "Gothenburg", zipcode: 42539 },
  { city: "Gothenburg", zipcode: 42541 },
  { city: "Gothenburg", zipcode: 42542 },
  { city: "Gothenburg", zipcode: 42543 },
  { city: "Gothenburg", zipcode: 42565 },
  { city: "Gothenburg", zipcode: 43602 },
  { city: "Gothenburg", zipcode: 43650 },
  { city: "Gothenburg", zipcode: 43651 },
  { city: "Gothenburg", zipcode: 43652 },
  { city: "Gothenburg", zipcode: 43653 },
  { city: "Gothenburg", zipcode: 43654 },
  { city: "Gothenburg", zipcode: 43655 },
  { city: "Gothenburg", zipcode: 43656 },
  { city: "Gothenburg", zipcode: 43657 },
  { city: "Gothenburg", zipcode: 43658 },
  { city: "Gothenburg", zipcode: 43081 },
  { city: "Gothenburg", zipcode: 44970 },
  { city: "Gothenburg", zipcode: 42470 },
  { city: "Gothenburg", zipcode: 42471 },
  { city: "Gothenburg", zipcode: 42472 },
  { city: "Gothenburg", zipcode: 42490 },
  { city: "Gothenburg", zipcode: 42491 },
  { city: "Gothenburg", zipcode: 42370 },
  { city: "Gothenburg", zipcode: 42371 },
  { city: "Gothenburg", zipcode: 42372 },
  { city: "Gothenburg", zipcode: 42373 },
  { city: "Gothenburg", zipcode: 43084 },
  { city: "Gothenburg", zipcode: 42300 },
  { city: "Gothenburg", zipcode: 42321 },
  { city: "Gothenburg", zipcode: 42322 },
  { city: "Gothenburg", zipcode: 42323 },
  { city: "Gothenburg", zipcode: 42332 },
  { city: "Gothenburg", zipcode: 42333 },
  { city: "Gothenburg", zipcode: 42334 },
  { city: "Gothenburg", zipcode: 42335 },
  { city: "Gothenburg", zipcode: 42336 },
  { city: "Gothenburg", zipcode: 42337 },
  { city: "Gothenburg", zipcode: 42338 },
  { city: "Gothenburg", zipcode: 42339 },
  { city: "Gothenburg", zipcode: 42340 },
  { city: "Gothenburg", zipcode: 42341 },
  { city: "Gothenburg", zipcode: 42342 },
  { city: "Gothenburg", zipcode: 42343 },
  { city: "Gothenburg", zipcode: 42344 },
  { city: "Gothenburg", zipcode: 42345 },
  { city: "Gothenburg", zipcode: 42346 },
  { city: "Gothenburg", zipcode: 42347 },
  { city: "Gothenburg", zipcode: 42348 },
  { city: "Gothenburg", zipcode: 42349 },
  { city: "Gothenburg", zipcode: 42350 },
  { city: "Gothenburg", zipcode: 42351 },
  { city: "Gothenburg", zipcode: 42352 },
  { city: "Gothenburg", zipcode: 42353 },
  { city: "Gothenburg", zipcode: 42354 },
  { city: "Gothenburg", zipcode: 42355 },
  { city: "Gothenburg", zipcode: 42356 },
  { city: "Gothenburg", zipcode: 42357 },
  { city: "Gothenburg", zipcode: 42358 },
  { city: "Gothenburg", zipcode: 42359 },
  { city: "Gothenburg", zipcode: 42361 },
  { city: "Gothenburg", zipcode: 42363 },
  { city: "Gothenburg", zipcode: 42100 },
  { city: "Gothenburg", zipcode: 42101 },
  { city: "Gothenburg", zipcode: 42102 },
  { city: "Gothenburg", zipcode: 42108 },
  { city: "Gothenburg", zipcode: 42110 },
  { city: "Gothenburg", zipcode: 42121 },
  { city: "Gothenburg", zipcode: 42122 },
  { city: "Gothenburg", zipcode: 42123 },
  { city: "Gothenburg", zipcode: 42126 },
  { city: "Gothenburg", zipcode: 42130 },
  { city: "Gothenburg", zipcode: 42131 },
  { city: "Gothenburg", zipcode: 42132 },
  { city: "Gothenburg", zipcode: 42133 },
  { city: "Gothenburg", zipcode: 42134 },
  { city: "Gothenburg", zipcode: 42135 },
  { city: "Gothenburg", zipcode: 42136 },
  { city: "Gothenburg", zipcode: 42137 },
  { city: "Gothenburg", zipcode: 42138 },
  { city: "Gothenburg", zipcode: 42139 },
  { city: "Gothenburg", zipcode: 42140 },
  { city: "Gothenburg", zipcode: 42141 },
  { city: "Gothenburg", zipcode: 42142 },
  { city: "Gothenburg", zipcode: 42143 },
  { city: "Gothenburg", zipcode: 42144 },
  { city: "Gothenburg", zipcode: 42145 },
  { city: "Gothenburg", zipcode: 42146 },
  { city: "Gothenburg", zipcode: 42147 },
  { city: "Gothenburg", zipcode: 42148 },
  { city: "Gothenburg", zipcode: 42149 },
  { city: "Gothenburg", zipcode: 42150 },
  { city: "Gothenburg", zipcode: 42151 },
  { city: "Gothenburg", zipcode: 42152 },
  { city: "Gothenburg", zipcode: 42153 },
  { city: "Gothenburg", zipcode: 42154 },
  { city: "Gothenburg", zipcode: 42157 },
  { city: "Gothenburg", zipcode: 42158 },
  { city: "Gothenburg", zipcode: 42159 },
  { city: "Gothenburg", zipcode: 42160 },
  { city: "Gothenburg", zipcode: 42161 },
  { city: "Gothenburg", zipcode: 42162 },
  { city: "Gothenburg", zipcode: 42163 },
  { city: "Gothenburg", zipcode: 42164 },
  { city: "Gothenburg", zipcode: 42165 },
  { city: "Gothenburg", zipcode: 42166 },
  { city: "Gothenburg", zipcode: 42167 },
  { city: "Gothenburg", zipcode: 42170 },
  { city: "Gothenburg", zipcode: 42171 },
  { city: "Gothenburg", zipcode: 42172 },
  { city: "Gothenburg", zipcode: 42173 },
  { city: "Gothenburg", zipcode: 42174 },
  { city: "Gothenburg", zipcode: 42600 },
  { city: "Gothenburg", zipcode: 42604 },
  { city: "Gothenburg", zipcode: 42605 },
  { city: "Gothenburg", zipcode: 42650 },
  { city: "Gothenburg", zipcode: 42651 },
  { city: "Gothenburg", zipcode: 42652 },
  { city: "Gothenburg", zipcode: 42653 },
  { city: "Gothenburg", zipcode: 42654 },
  { city: "Gothenburg", zipcode: 42655 },
  { city: "Gothenburg", zipcode: 42656 },
  { city: "Gothenburg", zipcode: 42657 },
  { city: "Gothenburg", zipcode: 42658 },
  { city: "Gothenburg", zipcode: 42659 },
  { city: "Gothenburg", zipcode: 42668 },
  { city: "Gothenburg", zipcode: 42669 },
  { city: "Gothenburg", zipcode: 42670 },
  { city: "Gothenburg", zipcode: 42671 },
  { city: "Gothenburg", zipcode: 42672 },
  { city: "Gothenburg", zipcode: 42673 },
  { city: "Gothenburg", zipcode: 42674 },
  { city: "Gothenburg", zipcode: 42675 },
  { city: "Gothenburg", zipcode: 42676 },
  { city: "Gothenburg", zipcode: 42677 },
  { city: "Gothenburg", zipcode: 42678 },
  { city: "Gothenburg", zipcode: 42679 },
  { city: "Gothenburg", zipcode: 42680 },
  { city: "Gothenburg", zipcode: 43083 },
  { city: "Västerås", zipcode: 64045 },
  { city: "Västerås", zipcode: 72001 },
  { city: "Västerås", zipcode: 72002 },
  { city: "Västerås", zipcode: 72003 },
  { city: "Västerås", zipcode: 72007 },
  { city: "Västerås", zipcode: 72010 },
  { city: "Västerås", zipcode: 72011 },
  { city: "Västerås", zipcode: 72012 },
  { city: "Västerås", zipcode: 72013 },
  { city: "Västerås", zipcode: 72015 },
  { city: "Västerås", zipcode: 72016 },
  { city: "Västerås", zipcode: 72017 },
  { city: "Västerås", zipcode: 72018 },
  { city: "Västerås", zipcode: 72019 },
  { city: "Västerås", zipcode: 72020 },
  { city: "Västerås", zipcode: 72100 },
  { city: "Västerås", zipcode: 72101 },
  { city: "Västerås", zipcode: 72103 },
  { city: "Västerås", zipcode: 72104 },
  { city: "Västerås", zipcode: 72105 },
  { city: "Västerås", zipcode: 72106 },
  { city: "Västerås", zipcode: 72107 },
  { city: "Västerås", zipcode: 72108 },
  { city: "Västerås", zipcode: 72109 },
  { city: "Västerås", zipcode: 72110 },
  { city: "Västerås", zipcode: 72111 },
  { city: "Västerås", zipcode: 72119 },
  { city: "Västerås", zipcode: 72120 },
  { city: "Västerås", zipcode: 72122 },
  { city: "Västerås", zipcode: 72123 },
  { city: "Västerås", zipcode: 72126 },
  { city: "Västerås", zipcode: 72127 },
  { city: "Västerås", zipcode: 72128 },
  { city: "Västerås", zipcode: 72129 },
  { city: "Västerås", zipcode: 72130 },
  { city: "Västerås", zipcode: 72131 },
  { city: "Västerås", zipcode: 72132 },
  { city: "Västerås", zipcode: 72133 },
  { city: "Västerås", zipcode: 72134 },
  { city: "Västerås", zipcode: 72135 },
  { city: "Västerås", zipcode: 72136 },
  { city: "Västerås", zipcode: 72137 },
  { city: "Västerås", zipcode: 72138 },
  { city: "Västerås", zipcode: 72150 },
  { city: "Västerås", zipcode: 72151 },
  { city: "Västerås", zipcode: 72153 },
  { city: "Västerås", zipcode: 72157 },
  { city: "Västerås", zipcode: 72159 },
  { city: "Västerås", zipcode: 72161 },
  { city: "Västerås", zipcode: 72162 },
  { city: "Västerås", zipcode: 72163 },
  { city: "Västerås", zipcode: 72164 },
  { city: "Västerås", zipcode: 72165 },
  { city: "Västerås", zipcode: 72166 },
  { city: "Västerås", zipcode: 72167 },
  { city: "Västerås", zipcode: 72168 },
  { city: "Västerås", zipcode: 72169 },
  { city: "Västerås", zipcode: 72170 },
  { city: "Västerås", zipcode: 72171 },
  { city: "Västerås", zipcode: 72173 },
  { city: "Västerås", zipcode: 72174 },
  { city: "Västerås", zipcode: 72175 },
  { city: "Västerås", zipcode: 72176 },
  { city: "Västerås", zipcode: 72178 },
  { city: "Västerås", zipcode: 72182 },
  { city: "Västerås", zipcode: 72183 },
  { city: "Västerås", zipcode: 72184 },
  { city: "Västerås", zipcode: 72185 },
  { city: "Västerås", zipcode: 72186 },
  { city: "Västerås", zipcode: 72187 },
  { city: "Västerås", zipcode: 72189 },
  { city: "Västerås", zipcode: 72201 },
  { city: "Västerås", zipcode: 72210 },
  { city: "Västerås", zipcode: 72211 },
  { city: "Västerås", zipcode: 72212 },
  { city: "Västerås", zipcode: 72213 },
  { city: "Västerås", zipcode: 72214 },
  { city: "Västerås", zipcode: 72215 },
  { city: "Västerås", zipcode: 72216 },
  { city: "Västerås", zipcode: 72217 },
  { city: "Västerås", zipcode: 72218 },
  { city: "Västerås", zipcode: 72219 },
  { city: "Västerås", zipcode: 72220 },
  { city: "Västerås", zipcode: 72221 },
  { city: "Västerås", zipcode: 72222 },
  { city: "Västerås", zipcode: 72223 },
  { city: "Västerås", zipcode: 72224 },
  { city: "Västerås", zipcode: 72225 },
  { city: "Västerås", zipcode: 72226 },
  { city: "Västerås", zipcode: 72227 },
  { city: "Västerås", zipcode: 72228 },
  { city: "Västerås", zipcode: 72231 },
  { city: "Västerås", zipcode: 72233 },
  { city: "Västerås", zipcode: 72240 },
  { city: "Västerås", zipcode: 72241 },
  { city: "Västerås", zipcode: 72242 },
  { city: "Västerås", zipcode: 72243 },
  { city: "Västerås", zipcode: 72244 },
  { city: "Västerås", zipcode: 72245 },
  { city: "Västerås", zipcode: 72246 },
  { city: "Västerås", zipcode: 72247 },
  { city: "Västerås", zipcode: 72301 },
  { city: "Västerås", zipcode: 72334 },
  { city: "Västerås", zipcode: 72335 },
  { city: "Västerås", zipcode: 72336 },
  { city: "Västerås", zipcode: 72337 },
  { city: "Västerås", zipcode: 72338 },
  { city: "Västerås", zipcode: 72339 },
  { city: "Västerås", zipcode: 72340 },
  { city: "Västerås", zipcode: 72341 },
  { city: "Västerås", zipcode: 72342 },
  { city: "Västerås", zipcode: 72343 },
  { city: "Västerås", zipcode: 72344 },
  { city: "Västerås", zipcode: 72345 },
  { city: "Västerås", zipcode: 72346 },
  { city: "Västerås", zipcode: 72347 },
  { city: "Västerås", zipcode: 72348 },
  { city: "Västerås", zipcode: 72349 },
  { city: "Västerås", zipcode: 72350 },
  { city: "Västerås", zipcode: 72351 },
  { city: "Västerås", zipcode: 72352 },
  { city: "Västerås", zipcode: 72353 },
  { city: "Västerås", zipcode: 72354 },
  { city: "Västerås", zipcode: 72355 },
  { city: "Västerås", zipcode: 72356 },
  { city: "Västerås", zipcode: 72357 },
  { city: "Västerås", zipcode: 72358 },
  { city: "Västerås", zipcode: 72359 },
  { city: "Västerås", zipcode: 72401 },
  { city: "Västerås", zipcode: 72402 },
  { city: "Västerås", zipcode: 72455 },
  { city: "Västerås", zipcode: 72460 },
  { city: "Västerås", zipcode: 72461 },
  { city: "Västerås", zipcode: 72462 },
  { city: "Västerås", zipcode: 72463 },
  { city: "Västerås", zipcode: 72464 },
  { city: "Västerås", zipcode: 72465 },
  { city: "Västerås", zipcode: 72466 },
  { city: "Västerås", zipcode: 72467 },
  { city: "Västerås", zipcode: 72468 },
  { city: "Västerås", zipcode: 72469 },
  { city: "Västerås", zipcode: 72470 },
  { city: "Västerås", zipcode: 72471 },
  { city: "Västerås", zipcode: 72472 },
  { city: "Västerås", zipcode: 72473 },
  { city: "Västerås", zipcode: 72474 },
  { city: "Västerås", zipcode: 72475 },
  { city: "Västerås", zipcode: 72476 },
  { city: "Västerås", zipcode: 72477 },
  { city: "Västerås", zipcode: 72478 },
  { city: "Västerås", zipcode: 72479 },
  { city: "Västerås", zipcode: 72480 },
  { city: "Västerås", zipcode: 72481 },
  { city: "Västerås", zipcode: 72482 },
  { city: "Västerås", zipcode: 72483 },
  { city: "Västerås", zipcode: 72484 },
  { city: "Västerås", zipcode: 72485 },
  { city: "Västerås", zipcode: 72486 },
  { city: "Västerås", zipcode: 72487 },
  { city: "Västerås", zipcode: 72488 },
  { city: "Västerås", zipcode: 72489 },
  { city: "Västerås", zipcode: 72590 },
  { city: "Västerås", zipcode: 72591 },
  { city: "Västerås", zipcode: 72592 },
  { city: "Västerås", zipcode: 72593 },
  { city: "Västerås", zipcode: 72594 },
  { city: "Västerås", zipcode: 72595 },
  { city: "Västerås", zipcode: 72596 },
  { city: "Västerås", zipcode: 72597 },
  { city: "Västerås", zipcode: 72598 },
  { city: "Västerås", zipcode: 72599 },
  { city: "Malmö", zipcode: 23834 },
  { city: "Malmö", zipcode: 23843 },
  { city: "Malmö", zipcode: 23842 },
  { city: "Malmö", zipcode: 23841 },
  { city: "Malmö", zipcode: 23840 },
  { city: "Malmö", zipcode: 23839 },
  { city: "Malmö", zipcode: 23838 },
  { city: "Malmö", zipcode: 23837 },
  { city: "Malmö", zipcode: 23836 },
  { city: "Malmö", zipcode: 23835 },
  { city: "Malmö", zipcode: 23833 },
  { city: "Malmö", zipcode: 23832 },
  { city: "Malmö", zipcode: 23831 },
  { city: "Malmö", zipcode: 23830 },
  { city: "Malmö", zipcode: 23823 },
  { city: "Malmö", zipcode: 23822 },
  { city: "Malmö", zipcode: 23821 },
  { city: "Malmö", zipcode: 23801 },
  { city: "Uppsala", zipcode: 75272 },
  { city: "Uppsala", zipcode: 75273 },
  { city: "Uppsala", zipcode: 75275 },
  { city: "Uppsala", zipcode: 75219 },
  { city: "Uppsala", zipcode: 75220 },
  { city: "Uppsala", zipcode: 75265 },
  { city: "Uppsala", zipcode: 75268 },
  { city: "Uppsala", zipcode: 75271 },
  { city: "Uppsala", zipcode: 75300 },
  { city: "Uppsala", zipcode: 75301 },
  { city: "Uppsala", zipcode: 75302 },
  { city: "Uppsala", zipcode: 75303 },
  { city: "Uppsala", zipcode: 75309 },
  { city: "Uppsala", zipcode: 75310 },
  { city: "Uppsala", zipcode: 75311 },
  { city: "Uppsala", zipcode: 75312 },
  { city: "Uppsala", zipcode: 75313 },
  { city: "Uppsala", zipcode: 75314 },
  { city: "Uppsala", zipcode: 75315 },
  { city: "Uppsala", zipcode: 75318 },
  { city: "Uppsala", zipcode: 75319 },
  { city: "Uppsala", zipcode: 75320 },
  { city: "Uppsala", zipcode: 75321 },
  { city: "Uppsala", zipcode: 75322 },
  { city: "Uppsala", zipcode: 75323 },
  { city: "Uppsala", zipcode: 75324 },
  { city: "Uppsala", zipcode: 75325 },
  { city: "Uppsala", zipcode: 75326 },
  { city: "Uppsala", zipcode: 75327 },
  { city: "Uppsala", zipcode: 75328 },
  { city: "Uppsala", zipcode: 75329 },
  { city: "Uppsala", zipcode: 75330 },
  { city: "Uppsala", zipcode: 75331 },
  { city: "Uppsala", zipcode: 75332 },
  { city: "Uppsala", zipcode: 75333 },
  { city: "Uppsala", zipcode: 75334 },
  { city: "Uppsala", zipcode: 75335 },
  { city: "Uppsala", zipcode: 75336 },
  { city: "Uppsala", zipcode: 75337 },
  { city: "Uppsala", zipcode: 75340 },
  { city: "Uppsala", zipcode: 75341 },
  { city: "Uppsala", zipcode: 75342 },
  { city: "Uppsala", zipcode: 75350 },
  { city: "Uppsala", zipcode: 75375 },
  { city: "Uppsala", zipcode: 75380 },
  { city: "Uppsala", zipcode: 75381 },
  { city: "Uppsala", zipcode: 75382 },
  { city: "Uppsala", zipcode: 75383 },
  { city: "Uppsala", zipcode: 75308 },
  { city: "Uppsala", zipcode: 75316 },
  { city: "Uppsala", zipcode: 75317 },
  { city: "Uppsala", zipcode: 75400 },
  { city: "Uppsala", zipcode: 75419 },
  { city: "Uppsala", zipcode: 75420 },
  { city: "Uppsala", zipcode: 75421 },
  { city: "Uppsala", zipcode: 75422 },
  { city: "Uppsala", zipcode: 75423 },
  { city: "Uppsala", zipcode: 75424 },
  { city: "Uppsala", zipcode: 75425 },
  { city: "Uppsala", zipcode: 75426 },
  { city: "Uppsala", zipcode: 75427 },
  { city: "Uppsala", zipcode: 75428 },
  { city: "Uppsala", zipcode: 75429 },
  { city: "Uppsala", zipcode: 75430 },
  { city: "Uppsala", zipcode: 75431 },
  { city: "Uppsala", zipcode: 75432 },
  { city: "Uppsala", zipcode: 75433 },
  { city: "Uppsala", zipcode: 75434 },
  { city: "Uppsala", zipcode: 75435 },
  { city: "Uppsala", zipcode: 75436 },
  { city: "Uppsala", zipcode: 75437 },
  { city: "Uppsala", zipcode: 75439 },
  { city: "Uppsala", zipcode: 75440 },
  { city: "Uppsala", zipcode: 75441 },
  { city: "Uppsala", zipcode: 75442 },
  { city: "Uppsala", zipcode: 75444 },
  { city: "Uppsala", zipcode: 75445 },
  { city: "Uppsala", zipcode: 75446 },
  { city: "Uppsala", zipcode: 75447 },
  { city: "Uppsala", zipcode: 75448 },
  { city: "Uppsala", zipcode: 75449 },
  { city: "Uppsala", zipcode: 75450 },
  { city: "Uppsala", zipcode: 75451 },
  { city: "Uppsala", zipcode: 75452 },
  { city: "Uppsala", zipcode: 75460 },
  { city: "Uppsala", zipcode: 75471 },
  { city: "Uppsala", zipcode: 75473 },
  { city: "Uppsala", zipcode: 75474 },
  { city: "Uppsala", zipcode: 75418 },
  { city: "Uppsala", zipcode: 75443 },
  { city: "Uppsala", zipcode: 75453 },
  { city: "Uppsala", zipcode: 75454 },
  { city: "Uppsala", zipcode: 75500 },
  { city: "Uppsala", zipcode: 75550 },
  { city: "Uppsala", zipcode: 75591 },
  { city: "Uppsala", zipcode: 75592 },
  { city: "Uppsala", zipcode: 75593 },
  { city: "Uppsala", zipcode: 75594 },
  { city: "Uppsala", zipcode: 75595 },
  { city: "Uppsala", zipcode: 75596 },
  { city: "Uppsala", zipcode: 75597 },
  { city: "Uppsala", zipcode: 75598 },
  { city: "Uppsala", zipcode: 75600 },
  { city: "Uppsala", zipcode: 75643 },
  { city: "Uppsala", zipcode: 75644 },
  { city: "Uppsala", zipcode: 75645 },
  { city: "Uppsala", zipcode: 75646 },
  { city: "Uppsala", zipcode: 75647 },
  { city: "Uppsala", zipcode: 75648 },
  { city: "Uppsala", zipcode: 75649 },
  { city: "Uppsala", zipcode: 75650 },
  { city: "Uppsala", zipcode: 75651 },
  { city: "Uppsala", zipcode: 75652 },
  { city: "Uppsala", zipcode: 75653 },
  { city: "Uppsala", zipcode: 75654 },
  { city: "Uppsala", zipcode: 75655 },
  { city: "Uppsala", zipcode: 75656 },
  { city: "Uppsala", zipcode: 75657 },
  { city: "Uppsala", zipcode: 75658 },
  { city: "Uppsala", zipcode: 75659 },
  { city: "Uppsala", zipcode: 75642 },
  { city: "Uppsala", zipcode: 75700 },
  { city: "Uppsala", zipcode: 75752 },
  { city: "Uppsala", zipcode: 75753 },
  { city: "Uppsala", zipcode: 75754 },
  { city: "Uppsala", zipcode: 75755 },
  { city: "Uppsala", zipcode: 75756 },
  { city: "Uppsala", zipcode: 75757 },
  { city: "Uppsala", zipcode: 75758 },
  { city: "Uppsala", zipcode: 75759 },
  { city: "Uppsala", zipcode: 75800 },
  { city: "Uppsala", zipcode: 75801 },
  { city: "Uppsala", zipcode: 75802 },
  { city: "Uppsala", zipcode: 75803 },
  { city: "Uppsala", zipcode: 75804 },
  { city: "Uppsala", zipcode: 75806 },
  { city: "Uppsala", zipcode: 75808 },
  { city: "Uppsala", zipcode: 75809 },
  { city: "Uppsala", zipcode: 75810 },
  { city: "Uppsala", zipcode: 75813 },
  { city: "Uppsala", zipcode: 75815 },
  { city: "Uppsala", zipcode: 75816 },
  { city: "Uppsala", zipcode: 75822 },
  { city: "Uppsala", zipcode: 75823 },
  { city: "Uppsala", zipcode: 75824 },
  { city: "Uppsala", zipcode: 75825 },
  { city: "Uppsala", zipcode: 75826 },
  { city: "Uppsala", zipcode: 75852 },
  { city: "Uppsala", zipcode: 75853 },
  { city: "Uppsala", zipcode: 75854 },
  { city: "Uppsala", zipcode: 75855 },
  { city: "Uppsala", zipcode: 75856 },
  { city: "Uppsala", zipcode: 75857 },
  { city: "Uppsala", zipcode: 75871 },
  { city: "Uppsala", zipcode: 75872 },
  { city: "Uppsala", zipcode: 75873 },
  { city: "Uppsala", zipcode: 75900 },
  { city: "Uppsala", zipcode: 74020 },
  { city: "Uppsala", zipcode: 75576 },
  { city: "Uppsala", zipcode: 75577 },
  { city: "Uppsala", zipcode: 75578 },
  { city: "Uppsala", zipcode: 74350 },
  { city: "Uppsala", zipcode: 74392 },
  { city: "Uppsala", zipcode: 74393 },
  { city: "Uppsala", zipcode: 74319 },
  { city: "Uppsala", zipcode: 74793 },
  { city: "Uppsala", zipcode: 74794 },
  { city: "Uppsala", zipcode: 74720 },
  { city: "Uppsala", zipcode: 74022 },
  { city: "Uppsala", zipcode: 74305 },
  { city: "Uppsala", zipcode: 74381 },
  { city: "Uppsala", zipcode: 74386 },
  { city: "Uppsala", zipcode: 74387 },
  { city: "Uppsala", zipcode: 74382 },
  { city: "Uppsala", zipcode: 74620 },
  { city: "Uppsala", zipcode: 74030 },
  { city: "Uppsala", zipcode: 74310 },
  { city: "Uppsala", zipcode: 74361 },
  { city: "Uppsala", zipcode: 74362 },
  { city: "Uppsala", zipcode: 74363 },
  { city: "Uppsala", zipcode: 74364 },
  { city: "Uppsala", zipcode: 74371 },
  { city: "Uppsala", zipcode: 74372 },
  { city: "Uppsala", zipcode: 74373 },
  { city: "Uppsala", zipcode: 74374 },
  { city: "Uppsala", zipcode: 76031 },
  { city: "Uppsala", zipcode: 76297 },
  { city: "Uppsala", zipcode: 76298 },
  { city: "Uppsala", zipcode: 74950 },
  { city: "Uppsala", zipcode: 74520 },
  { city: "Uppsala", zipcode: 74581 },
  { city: "Uppsala", zipcode: 74583 },
  { city: "Uppsala", zipcode: 74586 },
  { city: "Uppsala", zipcode: 74900 },
  { city: "Uppsala", zipcode: 74935 },
  { city: "Uppsala", zipcode: 74940 },
  { city: "Uppsala", zipcode: 74941 },
  { city: "Uppsala", zipcode: 74942 },
  { city: "Uppsala", zipcode: 74943 },
  { city: "Uppsala", zipcode: 74944 },
  { city: "Uppsala", zipcode: 74945 },
  { city: "Uppsala", zipcode: 74946 },
  { city: "Uppsala", zipcode: 74947 },
  { city: "Uppsala", zipcode: 74948 },
  { city: "Uppsala", zipcode: 74949 },
  { city: "Uppsala", zipcode: 74959 },
  { city: "Uppsala", zipcode: 74981 },
  { city: "Uppsala", zipcode: 74719 },
  { city: "Uppsala", zipcode: 74296 },
  { city: "Uppsala", zipcode: 74297 },
  { city: "Uppsala", zipcode: 74951 },
  { city: "Uppsala", zipcode: 74952 },
  { city: "Uppsala", zipcode: 74219 },
  { city: "Uppsala", zipcode: 74120 },
  { city: "Uppsala", zipcode: 74145 },
  { city: "Uppsala", zipcode: 74012 },
  { city: "Uppsala", zipcode: 74198 },
  { city: "Uppsala", zipcode: 74011 },
  { city: "Uppsala", zipcode: 74819 },
  { city: "Uppsala", zipcode: 74241 },
  { city: "Uppsala", zipcode: 74242 },
  { city: "Uppsala", zipcode: 74906 },
  { city: "Uppsala", zipcode: 74960 },
  { city: "Uppsala", zipcode: 74961 },
  { city: "Uppsala", zipcode: 74962 },
  { city: "Uppsala", zipcode: 74963 },
  { city: "Uppsala", zipcode: 74820 },
  { city: "Uppsala", zipcode: 74220 },
  { city: "Uppsala", zipcode: 74232 },
  { city: "Uppsala", zipcode: 76296 },
  { city: "Uppsala", zipcode: 81420 },
  { city: "Uppsala", zipcode: 74394 },
  { city: "Uppsala", zipcode: 81519 },
  { city: "Uppsala", zipcode: 74301 },
  { city: "Uppsala", zipcode: 74321 },
  { city: "Uppsala", zipcode: 74322 },
  { city: "Uppsala", zipcode: 74330 },
  { city: "Uppsala", zipcode: 74332 },
  { city: "Uppsala", zipcode: 74334 },
  { city: "Uppsala", zipcode: 74335 },
  { city: "Uppsala", zipcode: 74340 },
  { city: "Uppsala", zipcode: 74341 },
  { city: "Uppsala", zipcode: 74345 },
  { city: "Uppsala", zipcode: 74391 },
  { city: "Uppsala", zipcode: 74320 },
  { city: "Uppsala", zipcode: 81520 },
  { city: "Uppsala", zipcode: 81535 },
  { city: "Uppsala", zipcode: 81581 },
  { city: "Uppsala", zipcode: 74818 },
  { city: "Uppsala", zipcode: 74151 },
  { city: "Uppsala", zipcode: 74152 },
  { city: "Uppsala", zipcode: 74174 },
  { city: "Uppsala", zipcode: 74176 },
  { city: "Uppsala", zipcode: 74177 },
  { city: "Uppsala", zipcode: 74178 },
  { city: "Uppsala", zipcode: 74395 },
  { city: "Uppsala", zipcode: 75002 },
  { city: "Uppsala", zipcode: 75003 },
  { city: "Uppsala", zipcode: 75006 },
  { city: "Uppsala", zipcode: 75007 },
  { city: "Uppsala", zipcode: 75008 },
  { city: "Uppsala", zipcode: 75009 },
  { city: "Uppsala", zipcode: 75010 },
  { city: "Uppsala", zipcode: 75011 },
  { city: "Uppsala", zipcode: 75013 },
  { city: "Uppsala", zipcode: 75015 },
  { city: "Uppsala", zipcode: 75016 },
  { city: "Uppsala", zipcode: 75017 },
  { city: "Uppsala", zipcode: 75020 },
  { city: "Uppsala", zipcode: 75021 },
  { city: "Uppsala", zipcode: 75022 },
  { city: "Uppsala", zipcode: 75023 },
  { city: "Uppsala", zipcode: 75024 },
  { city: "Uppsala", zipcode: 75025 },
  { city: "Uppsala", zipcode: 75026 },
  { city: "Uppsala", zipcode: 75004 },
  { city: "Uppsala", zipcode: 75100 },
  { city: "Uppsala", zipcode: 75101 },
  { city: "Uppsala", zipcode: 75103 },
  { city: "Uppsala", zipcode: 75104 },
  { city: "Uppsala", zipcode: 75105 },
  { city: "Uppsala", zipcode: 75106 },
  { city: "Uppsala", zipcode: 75108 },
  { city: "Uppsala", zipcode: 75109 },
  { city: "Uppsala", zipcode: 75110 },
  { city: "Uppsala", zipcode: 75120 },
  { city: "Uppsala", zipcode: 75121 },
  { city: "Uppsala", zipcode: 75122 },
  { city: "Uppsala", zipcode: 75123 },
  { city: "Uppsala", zipcode: 75124 },
  { city: "Uppsala", zipcode: 75125 },
  { city: "Uppsala", zipcode: 75126 },
  { city: "Uppsala", zipcode: 75127 },
  { city: "Uppsala", zipcode: 75128 },
  { city: "Uppsala", zipcode: 75129 },
  { city: "Uppsala", zipcode: 75130 },
  { city: "Uppsala", zipcode: 75131 },
  { city: "Uppsala", zipcode: 75132 },
  { city: "Uppsala", zipcode: 75133 },
  { city: "Uppsala", zipcode: 75134 },
  { city: "Uppsala", zipcode: 75135 },
  { city: "Uppsala", zipcode: 75136 },
  { city: "Uppsala", zipcode: 75137 },
  { city: "Uppsala", zipcode: 75138 },
  { city: "Uppsala", zipcode: 75140 },
  { city: "Uppsala", zipcode: 75141 },
  { city: "Uppsala", zipcode: 75142 },
  { city: "Uppsala", zipcode: 75143 },
  { city: "Uppsala", zipcode: 75144 },
  { city: "Uppsala", zipcode: 75145 },
  { city: "Uppsala", zipcode: 75146 },
  { city: "Uppsala", zipcode: 75147 },
  { city: "Uppsala", zipcode: 75148 },
  { city: "Uppsala", zipcode: 75149 },
  { city: "Uppsala", zipcode: 75170 },
  { city: "Uppsala", zipcode: 75171 },
  { city: "Uppsala", zipcode: 75172 },
  { city: "Uppsala", zipcode: 75173 },
  { city: "Uppsala", zipcode: 75174 },
  { city: "Uppsala", zipcode: 75175 },
  { city: "Uppsala", zipcode: 75176 },
  { city: "Uppsala", zipcode: 75181 },
  { city: "Uppsala", zipcode: 75182 },
  { city: "Uppsala", zipcode: 75183 },
  { city: "Uppsala", zipcode: 75184 },
  { city: "Uppsala", zipcode: 75185 },
  { city: "Uppsala", zipcode: 75186 },
  { city: "Uppsala", zipcode: 75187 },
  { city: "Uppsala", zipcode: 75188 },
  { city: "Uppsala", zipcode: 75189 },
  { city: "Uppsala", zipcode: 75200 },
  { city: "Uppsala", zipcode: 75217 },
  { city: "Uppsala", zipcode: 75218 },
  { city: "Uppsala", zipcode: 75221 },
  { city: "Uppsala", zipcode: 75222 },
  { city: "Uppsala", zipcode: 75223 },
  { city: "Uppsala", zipcode: 75224 },
  { city: "Uppsala", zipcode: 75225 },
  { city: "Uppsala", zipcode: 75226 },
  { city: "Uppsala", zipcode: 75227 },
  { city: "Uppsala", zipcode: 75228 },
  { city: "Uppsala", zipcode: 75229 },
  { city: "Uppsala", zipcode: 75230 },
  { city: "Uppsala", zipcode: 75231 },
  { city: "Uppsala", zipcode: 75232 },
  { city: "Uppsala", zipcode: 75233 },
  { city: "Uppsala", zipcode: 75234 },
  { city: "Uppsala", zipcode: 75235 },
  { city: "Uppsala", zipcode: 75236 },
  { city: "Uppsala", zipcode: 75237 },
  { city: "Uppsala", zipcode: 75238 },
  { city: "Uppsala", zipcode: 75239 },
  { city: "Uppsala", zipcode: 75240 },
  { city: "Uppsala", zipcode: 75241 },
  { city: "Uppsala", zipcode: 75242 },
  { city: "Uppsala", zipcode: 75243 },
  { city: "Uppsala", zipcode: 75244 },
  { city: "Uppsala", zipcode: 75257 },
  { city: "Uppsala", zipcode: 75258 },
  { city: "Uppsala", zipcode: 75260 },
  { city: "Uppsala", zipcode: 75261 },
  { city: "Uppsala", zipcode: 75262 },
  { city: "Uppsala", zipcode: 75263 },
  { city: "Uppsala", zipcode: 75264 },
  { city: "Uppsala", zipcode: 75266 },
  { city: "Uppsala", zipcode: 75267 },
  { city: "Uppsala", zipcode: 75269 },
  { city: "Uppsala", zipcode: 75271 },
  { city: "Linköping", zipcode: 58100 },
  { city: "Linköping", zipcode: 58101 },
  { city: "Linköping", zipcode: 58102 },
  { city: "Linköping", zipcode: 58103 },
  { city: "Linköping", zipcode: 58104 },
  { city: "Linköping", zipcode: 58105 },
  { city: "Linköping", zipcode: 58106 },
  { city: "Linköping", zipcode: 58107 },
  { city: "Linköping", zipcode: 58110 },
  { city: "Linköping", zipcode: 58111 },
  { city: "Linköping", zipcode: 58112 },
  { city: "Linköping", zipcode: 58113 },
  { city: "Linköping", zipcode: 58114 },
  { city: "Linköping", zipcode: 58115 },
  { city: "Linköping", zipcode: 58116 },
  { city: "Linköping", zipcode: 58117 },
  { city: "Linköping", zipcode: 58118 },
  { city: "Linköping", zipcode: 58119 },
  { city: "Linköping", zipcode: 58121 },
  { city: "Linköping", zipcode: 58128 },
  { city: "Linköping", zipcode: 58131 },
  { city: "Linköping", zipcode: 58133 },
  { city: "Linköping", zipcode: 58180 },
  { city: "Linköping", zipcode: 58181 },
  { city: "Linköping", zipcode: 58182 },
  { city: "Linköping", zipcode: 58183 },
  { city: "Linköping", zipcode: 58184 },
  { city: "Linköping", zipcode: 58185 },
  { city: "Linköping", zipcode: 58186 },
  { city: "Linköping", zipcode: 58187 },
  { city: "Linköping", zipcode: 58188 },
  { city: "Linköping", zipcode: 58189 },
  { city: "Linköping", zipcode: 58190 },
  { city: "Linköping", zipcode: 58191 },
  { city: "Linköping", zipcode: 58192 },
  { city: "Linköping", zipcode: 58193 },
  { city: "Linköping", zipcode: 58194 },
  { city: "Linköping", zipcode: 58195 },
  { city: "Linköping", zipcode: 58196 },
  { city: "Linköping", zipcode: 58197 },
  { city: "Linköping", zipcode: 58198 },
  { city: "Linköping", zipcode: 58200 },
  { city: "Linköping", zipcode: 58201 },
  { city: "Linköping", zipcode: 58202 },
  { city: "Linköping", zipcode: 58203 },
  { city: "Linköping", zipcode: 58210 },
  { city: "Linköping", zipcode: 58212 },
  { city: "Linköping", zipcode: 58213 },
  { city: "Linköping", zipcode: 58214 },
  { city: "Linköping", zipcode: 58215 },
  { city: "Linköping", zipcode: 58216 },
  { city: "Linköping", zipcode: 58217 },
  { city: "Linköping", zipcode: 58218 },
  { city: "Linköping", zipcode: 58219 },
  { city: "Linköping", zipcode: 58220 },
  { city: "Linköping", zipcode: 58221 },
  { city: "Linköping", zipcode: 58222 },
  { city: "Linköping", zipcode: 58223 },
  { city: "Linköping", zipcode: 58224 },
  { city: "Linköping", zipcode: 58225 },
  { city: "Linköping", zipcode: 58226 },
  { city: "Linköping", zipcode: 58227 },
  { city: "Linköping", zipcode: 58228 },
  { city: "Linköping", zipcode: 58229 },
  { city: "Linköping", zipcode: 58230 },
  { city: "Linköping", zipcode: 58231 },
  { city: "Linköping", zipcode: 58232 },
  { city: "Linköping", zipcode: 58233 },
  { city: "Linköping", zipcode: 58234 },
  { city: "Linköping", zipcode: 58235 },
  { city: "Linköping", zipcode: 58236 },
  { city: "Linköping", zipcode: 58237 },
  { city: "Linköping", zipcode: 58238 },
  { city: "Linköping", zipcode: 58239 },
  { city: "Linköping", zipcode: 58240 },
  { city: "Linköping", zipcode: 58241 },
  { city: "Linköping", zipcode: 58242 },
  { city: "Linköping", zipcode: 58243 },
  { city: "Linköping", zipcode: 58244 },
  { city: "Linköping", zipcode: 58245 },
  { city: "Linköping", zipcode: 58246 },
  { city: "Linköping", zipcode: 58247 },
  { city: "Linköping", zipcode: 58248 },
  { city: "Linköping", zipcode: 58249 },
  { city: "Linköping", zipcode: 58252 },
  { city: "Linköping", zipcode: 58253 },
  { city: "Linköping", zipcode: 58254 },
  { city: "Linköping", zipcode: 58255 },
  { city: "Linköping", zipcode: 58256 },
  { city: "Linköping", zipcode: 58272 },
  { city: "Linköping", zipcode: 58273 },
  { city: "Linköping", zipcode: 58274 },
  { city: "Linköping", zipcode: 58275 },
  { city: "Linköping", zipcode: 58276 },
  { city: "Linköping", zipcode: 58277 },
  { city: "Linköping", zipcode: 58278 },
  { city: "Linköping", zipcode: 58300 },
  { city: "Linköping", zipcode: 58328 },
  { city: "Linköping", zipcode: 58330 },
  { city: "Linköping", zipcode: 58331 },
  { city: "Linköping", zipcode: 58332 },
  { city: "Linköping", zipcode: 58333 },
  { city: "Linköping", zipcode: 58334 },
  { city: "Linköping", zipcode: 58335 },
  { city: "Linköping", zipcode: 58336 },
  { city: "Linköping", zipcode: 58337 },
  { city: "Linköping", zipcode: 58338 },
  { city: "Linköping", zipcode: 58400 },
  { city: "Linköping", zipcode: 58422 },
  { city: "Linköping", zipcode: 58431 },
  { city: "Linköping", zipcode: 58432 },
  { city: "Linköping", zipcode: 58434 },
  { city: "Linköping", zipcode: 58435 },
  { city: "Linköping", zipcode: 58436 },
  { city: "Linköping", zipcode: 58437 },
  { city: "Linköping", zipcode: 58439 },
  { city: "Linköping", zipcode: 58500 },
  { city: "Linköping", zipcode: 58591 },
  { city: "Linköping", zipcode: 58592 },
  { city: "Linköping", zipcode: 58593 },
  { city: "Linköping", zipcode: 58594 },
  { city: "Linköping", zipcode: 58595 },
  { city: "Linköping", zipcode: 58596 },
  { city: "Linköping", zipcode: 58597 },
  { city: "Linköping", zipcode: 58598 },
  { city: "Linköping", zipcode: 58599 },
  { city: "Linköping", zipcode: 58600 },
  { city: "Linköping", zipcode: 58642 },
  { city: "Linköping", zipcode: 58643 },
  { city: "Linköping", zipcode: 58644 },
  { city: "Linköping", zipcode: 58645 },
  { city: "Linköping", zipcode: 58646 },
  { city: "Linköping", zipcode: 58647 },
  { city: "Linköping", zipcode: 58648 },
  { city: "Linköping", zipcode: 58649 },
  { city: "Linköping", zipcode: 58650 },
  { city: "Linköping", zipcode: 58651 },
  { city: "Linköping", zipcode: 58652 },
  { city: "Linköping", zipcode: 58653 },
  { city: "Linköping", zipcode: 58654 },
  { city: "Linköping", zipcode: 58655 },
  { city: "Linköping", zipcode: 58656 },
  { city: "Linköping", zipcode: 58657 },
  { city: "Linköping", zipcode: 58658 },
  { city: "Linköping", zipcode: 58659 },
  { city: "Linköping", zipcode: 58642 },
  { city: "Linköping", zipcode: 58700 },
  { city: "Linköping", zipcode: 58721 },
  { city: "Linköping", zipcode: 58723 },
  { city: "Linköping", zipcode: 58724 },
  { city: "Linköping", zipcode: 58725 },
  { city: "Linköping", zipcode: 58726 },
  { city: "Linköping", zipcode: 58727 },
  { city: "Linköping", zipcode: 58729 },
  { city: "Linköping", zipcode: 58731 },
  { city: "Linköping", zipcode: 58732 },
  { city: "Linköping", zipcode: 58733 },
  { city: "Linköping", zipcode: 58734 },
  { city: "Linköping", zipcode: 58735 },
  { city: "Linköping", zipcode: 58736 },
  { city: "Linköping", zipcode: 58737 },
  { city: "Linköping", zipcode: 58738 },
  { city: "Linköping", zipcode: 58739 },
  { city: "Linköping", zipcode: 58752 },
  { city: "Linköping", zipcode: 58900 },
  { city: "Linköping", zipcode: 58921 },
  { city: "Linköping", zipcode: 58923 },
  { city: "Linköping", zipcode: 58925 },
  { city: "Linköping", zipcode: 58927 },
  { city: "Linköping", zipcode: 58929 },
  { city: "Linköping", zipcode: 58931 },
  { city: "Linköping", zipcode: 58933 },
  { city: "Linköping", zipcode: 58935 },
  { city: "Linköping", zipcode: 58937 },
  { city: "Linköping", zipcode: 58939 },
  { city: "Linköping", zipcode: 58941 },
  { city: "Linköping", zipcode: 58943 },
  { city: "Linköping", zipcode: 58950 },
  { city: "Linköping", zipcode: 58951 },
  { city: "Linköping", zipcode: 58952 },
  { city: "Linköping", zipcode: 58953 },
  { city: "Linköping", zipcode: 58955 },
  { city: "Linköping", zipcode: 58957 },
  { city: "Linköping", zipcode: 58507 },
  { city: "Linköping", zipcode: 58571 },
  { city: "Linköping", zipcode: 58572 },
  { city: "Linköping", zipcode: 58573 },
  { city: "Linköping", zipcode: 58574 },
  { city: "Linköping", zipcode: 58575 },
  { city: "Linköping", zipcode: 58580 },
  { city: "Linköping", zipcode: 59069 },
  { city: "Linköping", zipcode: 59070 },
  { city: "Linköping", zipcode: 59071 },
  { city: "Linköping", zipcode: 59072 },
  { city: "Linköping", zipcode: 59073 },
  { city: "Linköping", zipcode: 59074 },
  { city: "Norrköping", zipcode: 60379 },
  { city: "Norrköping", zipcode: 60380 },
  { city: "Norrköping", zipcode: 60381 },
  { city: "Norrköping", zipcode: 60383 },
  { city: "Norrköping", zipcode: 60385 },
  { city: "Norrköping", zipcode: 60386 },
  { city: "Norrköping", zipcode: 60387 },
  { city: "Norrköping", zipcode: 60400 },
  { city: "Norrköping", zipcode: 60500 },
  { city: "Norrköping", zipcode: 60591 },
  { city: "Norrköping", zipcode: 60592 },
  { city: "Norrköping", zipcode: 60593 },
  { city: "Norrköping", zipcode: 60594 },
  { city: "Norrköping", zipcode: 60595 },
  { city: "Norrköping", zipcode: 60596 },
  { city: "Norrköping", zipcode: 60597 },
  { city: "Norrköping", zipcode: 60598 },
  { city: "Norrköping", zipcode: 60599 },
  { city: "Norrköping", zipcode: 60600 },
  { city: "Norrköping", zipcode: 60700 },
  { city: "Norrköping", zipcode: 60800 },
  { city: "Norrköping", zipcode: 60910 },
  { city: "Norrköping", zipcode: 60911 },
  { city: "Norrköping", zipcode: 61700 },
  { city: "Norrköping", zipcode: 61701 },
  { city: "Norrköping", zipcode: 61710 },
  { city: "Norrköping", zipcode: 61721 },
  { city: "Norrköping", zipcode: 61722 },
  { city: "Norrköping", zipcode: 61730 },
  { city: "Norrköping", zipcode: 61731 },
  { city: "Norrköping", zipcode: 61732 },
  { city: "Norrköping", zipcode: 61734 },
  { city: "Norrköping", zipcode: 61790 },
  { city: "Norrköping", zipcode: 61800 },
  { city: "Norrköping", zipcode: 61801 },
  { city: "Norrköping", zipcode: 61821 },
  { city: "Norrköping", zipcode: 61822 },
  { city: "Norrköping", zipcode: 61823 },
  { city: "Norrköping", zipcode: 61830 },
  { city: "Norrköping", zipcode: 61831 },
  { city: "Norrköping", zipcode: 61832 },
  { city: "Norrköping", zipcode: 61833 },
  { city: "Norrköping", zipcode: 61834 },
  { city: "Norrköping", zipcode: 61835 },
  { city: "Norrköping", zipcode: 61892 },
  { city: "Norrköping", zipcode: 61893 },
  { city: "Norrköping", zipcode: 59078 },
  { city: "Norrköping", zipcode: 59062 },
  { city: "Norrköping", zipcode: 60000 },
  { city: "Norrköping", zipcode: 60002 },
  { city: "Norrköping", zipcode: 60003 },
  { city: "Norrköping", zipcode: 60004 },
  { city: "Norrköping", zipcode: 60005 },
  { city: "Norrköping", zipcode: 60006 },
  { city: "Norrköping", zipcode: 60007 },
  { city: "Norrköping", zipcode: 60011 },
  { city: "Norrköping", zipcode: 60012 },
  { city: "Norrköping", zipcode: 60013 },
  { city: "Norrköping", zipcode: 60014 },
  { city: "Norrköping", zipcode: 60040 },
  { city: "Norrköping", zipcode: 60041 },
  { city: "Norrköping", zipcode: 60042 },
  { city: "Norrköping", zipcode: 60043 },
  { city: "Norrköping", zipcode: 60044 },
  { city: "Norrköping", zipcode: 60045 },
  { city: "Norrköping", zipcode: 60046 },
  { city: "Norrköping", zipcode: 60047 },
  { city: "Norrköping", zipcode: 60100 },
  { city: "Norrköping", zipcode: 60102 },
  { city: "Norrköping", zipcode: 60103 },
  { city: "Norrköping", zipcode: 60104 },
  { city: "Norrköping", zipcode: 60105 },
  { city: "Norrköping", zipcode: 60107 },
  { city: "Norrköping", zipcode: 60110 },
  { city: "Norrköping", zipcode: 60114 },
  { city: "Norrköping", zipcode: 60115 },
  { city: "Norrköping", zipcode: 60116 },
  { city: "Norrköping", zipcode: 60117 },
  { city: "Norrköping", zipcode: 60119 },
  { city: "Norrköping", zipcode: 60160 },
  { city: "Norrköping", zipcode: 60170 },
  { city: "Norrköping", zipcode: 60171 },
  { city: "Norrköping", zipcode: 60172 },
  { city: "Norrköping", zipcode: 60174 },
  { city: "Norrköping", zipcode: 60175 },
  { city: "Norrköping", zipcode: 60176 },
  { city: "Norrköping", zipcode: 60177 },
  { city: "Norrköping", zipcode: 60178 },
  { city: "Norrköping", zipcode: 60179 },
  { city: "Norrköping", zipcode: 60180 },
  { city: "Norrköping", zipcode: 60181 },
  { city: "Norrköping", zipcode: 60182 },
  { city: "Norrköping", zipcode: 60183 },
  { city: "Norrköping", zipcode: 60184 },
  { city: "Norrköping", zipcode: 60185 },
  { city: "Norrköping", zipcode: 60186 },
  { city: "Norrköping", zipcode: 60188 },
  { city: "Norrköping", zipcode: 60189 },
  { city: "Norrköping", zipcode: 60200 },
  { city: "Norrköping", zipcode: 60201 },
  { city: "Norrköping", zipcode: 60202 },
  { city: "Norrköping", zipcode: 60203 },
  { city: "Norrköping", zipcode: 60204 },
  { city: "Norrköping", zipcode: 60205 },
  { city: "Norrköping", zipcode: 60206 },
  { city: "Norrköping", zipcode: 60208 },
  { city: "Norrköping", zipcode: 60209 },
  { city: "Norrköping", zipcode: 60210 },
  { city: "Norrköping", zipcode: 60211 },
  { city: "Norrköping", zipcode: 60212 },
  { city: "Norrköping", zipcode: 60213 },
  { city: "Norrköping", zipcode: 60214 },
  { city: "Norrköping", zipcode: 60215 },
  { city: "Norrköping", zipcode: 60216 },
  { city: "Norrköping", zipcode: 60217 },
  { city: "Norrköping", zipcode: 60218 },
  { city: "Norrköping", zipcode: 60219 },
  { city: "Norrköping", zipcode: 60220 },
  { city: "Norrköping", zipcode: 60221 },
  { city: "Norrköping", zipcode: 60222 },
  { city: "Norrköping", zipcode: 60223 },
  { city: "Norrköping", zipcode: 60224 },
  { city: "Norrköping", zipcode: 60225 },
  { city: "Norrköping", zipcode: 60226 },
  { city: "Norrköping", zipcode: 60227 },
  { city: "Norrköping", zipcode: 60228 },
  { city: "Norrköping", zipcode: 60229 },
  { city: "Norrköping", zipcode: 60230 },
  { city: "Norrköping", zipcode: 60231 },
  { city: "Norrköping", zipcode: 60232 },
  { city: "Norrköping", zipcode: 60233 },
  { city: "Norrköping", zipcode: 60234 },
  { city: "Norrköping", zipcode: 60235 },
  { city: "Norrköping", zipcode: 60236 },
  { city: "Norrköping", zipcode: 60237 },
  { city: "Norrköping", zipcode: 60238 },
  { city: "Norrköping", zipcode: 60239 },
  { city: "Norrköping", zipcode: 60240 },
  { city: "Norrköping", zipcode: 60241 },
  { city: "Norrköping", zipcode: 60242 },
  { city: "Norrköping", zipcode: 60243 },
  { city: "Norrköping", zipcode: 60244 },
  { city: "Norrköping", zipcode: 60245 },
  { city: "Norrköping", zipcode: 60246 },
  { city: "Norrköping", zipcode: 60247 },
  { city: "Norrköping", zipcode: 60248 },
  { city: "Norrköping", zipcode: 60249 },
  { city: "Norrköping", zipcode: 60250 },
  { city: "Norrköping", zipcode: 60251 },
  { city: "Norrköping", zipcode: 60252 },
  { city: "Norrköping", zipcode: 60253 },
  { city: "Norrköping", zipcode: 60254 },
  { city: "Norrköping", zipcode: 60255 },
  { city: "Norrköping", zipcode: 60256 },
  { city: "Norrköping", zipcode: 60257 },
  { city: "Norrköping", zipcode: 60258 },
  { city: "Norrköping", zipcode: 60272 },
  { city: "Norrköping", zipcode: 60273 },
  { city: "Norrköping", zipcode: 60274 },
  { city: "Norrköping", zipcode: 60275 },
  { city: "Norrköping", zipcode: 60276 },
  { city: "Norrköping", zipcode: 60277 },
  { city: "Norrköping", zipcode: 60278 },
  { city: "Norrköping", zipcode: 60279 },
  { city: "Norrköping", zipcode: 60300 },
  { city: "Norrköping", zipcode: 60301 },
  { city: "Norrköping", zipcode: 60302 },
  { city: "Norrköping", zipcode: 60303 },
  { city: "Norrköping", zipcode: 60336 },
  { city: "Norrköping", zipcode: 60337 },
  { city: "Norrköping", zipcode: 60345 },
  { city: "Norrköping", zipcode: 60347 },
  { city: "Norrköping", zipcode: 60348 },
  { city: "Norrköping", zipcode: 60349 },
  { city: "Norrköping", zipcode: 60350 },
  { city: "Norrköping", zipcode: 60351 },
  { city: "Norrköping", zipcode: 60352 },
  { city: "Norrköping", zipcode: 60353 },
  { city: "Norrköping", zipcode: 60354 },
  { city: "Norrköping", zipcode: 60355 },
  { city: "Norrköping", zipcode: 60356 },
  { city: "Norrköping", zipcode: 60357 },
  { city: "Norrköping", zipcode: 60358 },
  { city: "Norrköping", zipcode: 60359 },
  { city: "Norrköping", zipcode: 60360 },
  { city: "Norrköping", zipcode: 60361 },
  { city: "Norrköping", zipcode: 60362 },
  { city: "Norrköping", zipcode: 60363 },
  { city: "Norrköping", zipcode: 60364 },
  { city: "Norrköping", zipcode: 60365 },
  { city: "Norrköping", zipcode: 60366 },
  { city: "Norrköping", zipcode: 60367 },
  { city: "Norrköping", zipcode: 60368 },
  { city: "Norrköping", zipcode: 60369 },
  { city: "Norrköping", zipcode: 60370 },
  { city: "Norrköping", zipcode: 60371 },
  { city: "Norrköping", zipcode: 60372 },
  { city: "Norrköping", zipcode: 60373 },
  { city: "Norrköping", zipcode: 60374 },
  { city: "Norrköping", zipcode: 60375 },
  { city: "Norrköping", zipcode: 60376 },
  { city: "Norrköping", zipcode: 60377 },
  { city: "Norrköping", zipcode: 60378 },
];
const ZIPCODES1 = [
  { city: "Märsta", zipcode: 16986 },
  { city: "Märsta", zipcode: 16189 },
  { city: "Märsta", zipcode: 19530 },
  { city: "Märsta", zipcode: 19531 },
  { city: "Märsta", zipcode: 19532 },
  { city: "Märsta", zipcode: 19533 },
  { city: "Märsta", zipcode: 19534 },
  { city: "Märsta", zipcode: 19535 },
  { city: "Märsta", zipcode: 19536 },
  { city: "Märsta", zipcode: 19540 },
  { city: "Märsta", zipcode: 19541 },
  { city: "Märsta", zipcode: 19542 },
  { city: "Märsta", zipcode: 19543 },
  { city: "Märsta", zipcode: 19544 },
  { city: "Märsta", zipcode: 19545 },
  { city: "Märsta", zipcode: 19546 },
  { city: "Märsta", zipcode: 19547 },
  { city: "Märsta", zipcode: 19550 },
  { city: "Märsta", zipcode: 19551 },
  { city: "Märsta", zipcode: 19552 },
  { city: "Märsta", zipcode: 19553 },
  { city: "Märsta", zipcode: 19554 },
  { city: "Märsta", zipcode: 19555 },
  { city: "Märsta", zipcode: 19556 },
  { city: "Märsta", zipcode: 19557 },
  { city: "Märsta", zipcode: 19558 },
  { city: "Märsta", zipcode: 19560 },
  { city: "Märsta", zipcode: 19500 },
  { city: "Märsta", zipcode: 19502 },
  { city: "Märsta", zipcode: 19503 },
  { city: "Märsta", zipcode: 19506 },
  { city: "Märsta", zipcode: 19510 },
  { city: "Märsta", zipcode: 19518 },
  { city: "Märsta", zipcode: 19519 },
  { city: "Märsta", zipcode: 19520 },
  { city: "Märsta", zipcode: 19521 },
  { city: "Märsta", zipcode: 19522 },
  { city: "Märsta", zipcode: 19523 },
  { city: "Märsta", zipcode: 19524 },
  { city: "Märsta", zipcode: 19525 },
  { city: "Af Pontins väg", zipcode: 19565 },
  { city: "Af Pontins väg", zipcode: 19591 },
  { city: "Af Pontins väg", zipcode: 19595 },
  { city: "Akersberga", zipcode: 18420 },
  { city: "Alta", zipcode: 13820 },
  { city: "Alsjo", zipcode: 12500 },
  { city: "Alsjo", zipcode: 12501 },
  { city: "Alsjo", zipcode: 12502 },
  { city: "Alsjo", zipcode: 12503 },
  { city: "Alsjo", zipcode: 12505 },
  { city: "Alsjo", zipcode: 12521 },
  { city: "Alsjo", zipcode: 12522 },
  { city: "Alsjo", zipcode: 12523 },
  { city: "Alsjo", zipcode: 12524 },
  { city: "Alsjo", zipcode: 12525 },
  { city: "Alsjo", zipcode: 12530 },
  { city: "Alsjo", zipcode: 12531 },
  { city: "Alsjo", zipcode: 12532 },
  { city: "Alsjo", zipcode: 12533 },
  { city: "Alsjo", zipcode: 12534 },
  { city: "Alsjo", zipcode: 12535 },
  { city: "Alsjo", zipcode: 12540 },
  { city: "Alsjo", zipcode: 12541 },
  { city: "Alsjo", zipcode: 12542 },
  { city: "Alsjo", zipcode: 12543 },
  { city: "Alsjo", zipcode: 12544 },
  { city: "Alsjo", zipcode: 12546 },
  { city: "Alsjo", zipcode: 12547 },
  { city: "Alsjo", zipcode: 12548 },
  { city: "Alsjo", zipcode: 12551 },
  { city: "Alsjo", zipcode: 12552 },
  { city: "Alsjo", zipcode: 12553 },
  { city: "Alsjo", zipcode: 12554 },
  { city: "Alsjo", zipcode: 12555 },
  { city: "Alsjo", zipcode: 12556 },
  { city: "Alsjo", zipcode: 12557 },
  { city: "Alsjo", zipcode: 12558 },
  { city: "Alsjo", zipcode: 12559 },
  { city: "Alsjo", zipcode: 12560 },
  { city: "Alsjo", zipcode: 12570 },
  { city: "Alsjo", zipcode: 12571 },
  { city: "Alsjo", zipcode: 12572 },
  { city: "Alsjo", zipcode: 12573 },
  { city: "Alsjo", zipcode: 12574 },
  { city: "Alsjo", zipcode: 12586 },
  { city: "Alsjo", zipcode: 12520 },
  { city: "Alsjo", zipcode: 12545 },
  { city: "Arsta", zipcode: 12002 },
  { city: "Arsta", zipcode: 12003 },
  { city: "Arsta", zipcode: 12004 },
  { city: "Arsta", zipcode: 12005 },
  { city: "Arsta", zipcode: 12010 },
  { city: "Arsta", zipcode: 12038 },
  { city: "Arsta", zipcode: 12039 },
  { city: "Arsta", zipcode: 12040 },
  { city: "Arsta", zipcode: 12044 },
  { city: "Arsta", zipcode: 12050 },
  { city: "Arsta", zipcode: 12051 },
  { city: "Arsta", zipcode: 12052 },
  { city: "Arsta", zipcode: 12053 },
  { city: "Arsta", zipcode: 12054 },
  { city: "Arsta", zipcode: 12055 },
  { city: "Arsta", zipcode: 12056 },
  { city: "Arsta", zipcode: 12057 },
  { city: "Arsta", zipcode: 12058 },
  { city: "Arsta", zipcode: 12059 },
  { city: "Arsta", zipcode: 12060 },
  { city: "Årstaängsvägen", zipcode: 11743 },
  { city: "Bagarmossen", zipcode: 12804 },
  { city: "Bagarmossen", zipcode: 12805 },
  { city: "Bagarmossen", zipcode: 12841 },
  { city: "Bagarmossen", zipcode: 12842 },
  { city: "Bagarmossen", zipcode: 12843 },
  { city: "Bagarmossen", zipcode: 12844 },
  { city: "Bagarmossen", zipcode: 12845 },
  { city: "Bagarmossen", zipcode: 12846 },
  { city: "Bagarmossen", zipcode: 12847 },
  { city: "Bagarmossen", zipcode: 12848 },
  { city: "Bandhagen", zipcode: 12400 },
  { city: "Bandhagen", zipcode: 12403 },
  { city: "Bandhagen", zipcode: 12404 },
  { city: "Bandhagen", zipcode: 12421 },
  { city: "Bandhagen", zipcode: 12422 },
  { city: "Bandhagen", zipcode: 12425 },
  { city: "Bandhagen", zipcode: 12430 },
  { city: "Bandhagen", zipcode: 12431 },
  { city: "Bandhagen", zipcode: 12432 },
  { city: "Bandhagen", zipcode: 12433 },
  { city: "Bandhagen", zipcode: 12436 },
  { city: "Bandhagen", zipcode: 12450 },
  { city: "Bandhagen", zipcode: 12451 },
  { city: "Bandhagen", zipcode: 12452 },
  { city: "Bandhagen", zipcode: 12453 },
  { city: "Bandhagen", zipcode: 12454 },
  { city: "Bandhagen", zipcode: 12455 },
  { city: "Bandhagen", zipcode: 12456 },
  { city: "Bandhagen", zipcode: 12457 },
  { city: "Bandhagen", zipcode: 12458 },
  { city: "Bandhagen", zipcode: 12459 },
  { city: "Bandhagen", zipcode: 12460 },
  { city: "Bandhagen", zipcode: 12461 },
  { city: "Bandhagen", zipcode: 12462 },
  { city: "Bandhagen", zipcode: 12463 },
  { city: "Bandhagen", zipcode: 12464 },
  { city: "Bandhagen", zipcode: 12465 },
  { city: "Bandhagen", zipcode: 12466 },
  { city: "Bandhagen", zipcode: 12467 },
  { city: "Bandhagen", zipcode: 12470 },
  { city: "Bandhagen", zipcode: 12471 },
  { city: "Bandhagen", zipcode: 12472 },
  { city: "Bandhagen", zipcode: 12473 },
  { city: "Bandhagen", zipcode: 12474 },
  { city: "Bandhagen", zipcode: 12475 },
  { city: "Bandhagen", zipcode: 12476 },
  { city: "Bandhagen", zipcode: 12420 },
  { city: "Bromma", zipcode: 19700 },
  { city: "Bromma", zipcode: 19720 },
  { city: "Bromma", zipcode: 19721 },
  { city: "Bromma", zipcode: 19722 },
  { city: "Bromma", zipcode: 19725 },
  { city: "Bromma", zipcode: 19730 },
  { city: "Bromma", zipcode: 19731 },
  { city: "Bromma", zipcode: 19732 },
  { city: "Bromma", zipcode: 19733 },
  { city: "Bromma", zipcode: 19734 },
  { city: "Bromma", zipcode: 19735 },
  { city: "Bromma", zipcode: 19736 },
  { city: "Bromma", zipcode: 19740 },
  { city: "Bromma", zipcode: 19791 },
  { city: "Bromma", zipcode: 19792 },
  { city: "Bromma", zipcode: 19793 },
  { city: "Bromma", zipcode: 16100 },
  { city: "Bromma", zipcode: 16102 },
  { city: "Bromma", zipcode: 16103 },
  { city: "Bromma", zipcode: 16104 },
  { city: "Bromma", zipcode: 16105 },
  { city: "Bromma", zipcode: 16107 },
  { city: "Bromma", zipcode: 16111 },
  { city: "Bromma", zipcode: 16124 },
  { city: "Bromma", zipcode: 16125 },
  { city: "Bromma", zipcode: 16126 },
  { city: "Bromma", zipcode: 16184 },
  { city: "Bromma", zipcode: 16120 },
  { city: "Bromma", zipcode: 16700 },
  { city: "Bromma", zipcode: 16714 },
  { city: "Bromma", zipcode: 16715 },
  { city: "Bromma", zipcode: 16717 },
  { city: "Bromma", zipcode: 16719 },
  { city: "Bromma", zipcode: 16721 },
  { city: "Bromma", zipcode: 16722 },
  { city: "Bromma", zipcode: 16724 },
  { city: "Bromma", zipcode: 16731 },
  { city: "Bromma", zipcode: 16732 },
  { city: "Bromma", zipcode: 16733 },
  { city: "Bromma", zipcode: 16735 },
  { city: "Bromma", zipcode: 16736 },
  { city: "Bromma", zipcode: 16737 },
  { city: "Bromma", zipcode: 16738 },
  { city: "Bromma", zipcode: 16739 },
  { city: "Bromma", zipcode: 16740 },
  { city: "Bromma", zipcode: 16741 },
  { city: "Bromma", zipcode: 16743 },
  { city: "Bromma", zipcode: 16744 },
  { city: "Bromma", zipcode: 16745 },
  { city: "Bromma", zipcode: 16751 },
  { city: "Bromma", zipcode: 16752 },
  { city: "Bromma", zipcode: 16753 },
  { city: "Bromma", zipcode: 16754 },
  { city: "Bromma", zipcode: 16755 },
  { city: "Bromma", zipcode: 16756 },
  { city: "Bromma", zipcode: 16757 },
  { city: "Bromma", zipcode: 16761 },
  { city: "Bromma", zipcode: 16762 },
  { city: "Bromma", zipcode: 16763 },
  { city: "Bromma", zipcode: 16764 },
  { city: "Bromma", zipcode: 16765 },
  { city: "Bromma", zipcode: 16766 },
  { city: "Bromma", zipcode: 16767 },
  { city: "Bromma", zipcode: 16771 },
  { city: "Bromma", zipcode: 16772 },
  { city: "Bromma", zipcode: 16773 },
  { city: "Bromma", zipcode: 16774 },
  { city: "Bromma", zipcode: 16775 },
  { city: "Bromma", zipcode: 16776 },
  { city: "Bromma", zipcode: 16780 },
  { city: "Bromma", zipcode: 16781 },
  { city: "Bromma", zipcode: 16720 },
  { city: "Bromma", zipcode: 16758 },
  { city: "Bromma", zipcode: 16800 },
  { city: "Bromma", zipcode: 16810 },
  { city: "Bromma", zipcode: 16827 },
  { city: "Bromma", zipcode: 16830 },
  { city: "Bromma", zipcode: 16831 },
  { city: "Bromma", zipcode: 16832 },
  { city: "Bromma", zipcode: 16833 },
  { city: "Bromma", zipcode: 16834 },
  { city: "Bromma", zipcode: 16835 },
  { city: "Bromma", zipcode: 16836 },
  { city: "Bromma", zipcode: 16837 },
  { city: "Bromma", zipcode: 16838 },
  { city: "Bromma", zipcode: 16839 },
  { city: "Bromma", zipcode: 16840 },
  { city: "Bromma", zipcode: 16841 },
  { city: "Bromma", zipcode: 16842 },
  { city: "Bromma", zipcode: 16843 },
  { city: "Bromma", zipcode: 16844 },
  { city: "Bromma", zipcode: 16845 },
  { city: "Bromma", zipcode: 16846 },
  { city: "Bromma", zipcode: 16847 },
  { city: "Bromma", zipcode: 16848 },
  { city: "Bromma", zipcode: 16849 },
  { city: "Bromma", zipcode: 16850 },
  { city: "Bromma", zipcode: 16851 },
  { city: "Bromma", zipcode: 16852 },
  { city: "Bromma", zipcode: 16853 },
  { city: "Bromma", zipcode: 16854 },
  { city: "Bromma", zipcode: 16855 },
  { city: "Bromma", zipcode: 16856 },
  { city: "Bromma", zipcode: 16857 },
  { city: "Bromma", zipcode: 16858 },
  { city: "Bromma", zipcode: 16859 },
  { city: "Bromma", zipcode: 16860 },
  { city: "Bromma", zipcode: 16861 },
  { city: "Bromma", zipcode: 16862 },
  { city: "Bromma", zipcode: 16863 },
  { city: "Bromma", zipcode: 16864 },
  { city: "Bromma", zipcode: 16865 },
  { city: "Bromma", zipcode: 16866 },
  { city: "Bromma", zipcode: 16867 },
  { city: "Bromma", zipcode: 16868 },
  { city: "Bromma", zipcode: 16869 },
  { city: "Bromma", zipcode: 16870 },
  { city: "Bromma", zipcode: 16871 },
  { city: "Bromma", zipcode: 16872 },
  { city: "Bromma", zipcode: 16873 },
  { city: "Bromma", zipcode: 16874 },
  { city: "Bromma", zipcode: 16875 },
  { city: "Bromma", zipcode: 16876 },
  { city: "Bromma", zipcode: 16877 },
  { city: "Bromma", zipcode: 16878 },
  { city: "Bromma", zipcode: 16880 },
  { city: "Bromma", zipcode: 16820 },
  { city: "Danderyd", zipcode: 18220 },
  { city: "Ekero", zipcode: 17817 },
  { city: "Ekero", zipcode: 17820 },
  { city: "Enskede", zipcode: 12200 },
  { city: "Enskede", zipcode: 12203 },
  { city: "Enskede", zipcode: 12206 },
  { city: "Enskede", zipcode: 12231 },
  { city: "Enskede", zipcode: 12232 },
  { city: "Enskede", zipcode: 12233 },
  { city: "Enskede", zipcode: 12234 },
  { city: "Enskede", zipcode: 12237 },
  { city: "Enskede", zipcode: 12238 },
  { city: "Enskede", zipcode: 12239 },
  { city: "Enskede", zipcode: 12240 },
  { city: "Enskede", zipcode: 12241 },
  { city: "Enskede", zipcode: 12242 },
  { city: "Enskede", zipcode: 12243 },
  { city: "Enskede", zipcode: 12244 },
  { city: "Enskede", zipcode: 12245 },
  { city: "Enskede", zipcode: 12246 },
  { city: "Enskede", zipcode: 12247 },
  { city: "Enskede", zipcode: 12248 },
  { city: "Enskede", zipcode: 12249 },
  { city: "Enskede", zipcode: 12260 },
  { city: "Enskede", zipcode: 12262 },
  { city: "Enskede", zipcode: 12263 },
  { city: "Enskede", zipcode: 12264 },
  { city: "Enskede", zipcode: 12265 },
  { city: "Enskede", zipcode: 12266 },
  { city: "Enskede", zipcode: 12286 },
  { city: "Enskede", zipcode: 12288 },
  { city: "Enskede", zipcode: 12220 },
  { city: "Enskede Gard", zipcode: 12047 },
  { city: "Enskede Gard", zipcode: 12048 },
  { city: "Enskededalen", zipcode: 12114 },
  { city: "Enskededalen", zipcode: 12115 },
  { city: "Enskededalen", zipcode: 12130 },
  { city: "Enskededalen", zipcode: 12131 },
  { city: "Enskededalen", zipcode: 12132 },
  { city: "Enskededalen", zipcode: 12133 },
  { city: "Enskededalen", zipcode: 12134 },
  { city: "Enskededalen", zipcode: 12187 },
  { city: "Farsta", zipcode: 12300 },
  { city: "Farsta", zipcode: 12301 },
  { city: "Farsta", zipcode: 12303 },
  { city: "Farsta", zipcode: 12305 },
  { city: "Farsta", zipcode: 12321 },
  { city: "Farsta", zipcode: 12322 },
  { city: "Farsta", zipcode: 12323 },
  { city: "Farsta", zipcode: 12324 },
  { city: "Farsta", zipcode: 12326 },
  { city: "Farsta", zipcode: 12327 },
  { city: "Farsta", zipcode: 12328 },
  { city: "Farsta", zipcode: 12331 },
  { city: "Farsta", zipcode: 12332 },
  { city: "Farsta", zipcode: 12333 },
  { city: "Farsta", zipcode: 12334 },
  { city: "Farsta", zipcode: 12340 },
  { city: "Farsta", zipcode: 12341 },
  { city: "Farsta", zipcode: 12342 },
  { city: "Farsta", zipcode: 12343 },
  { city: "Farsta", zipcode: 12344 },
  { city: "Farsta", zipcode: 12346 },
  { city: "Farsta", zipcode: 12347 },
  { city: "Farsta", zipcode: 12348 },
  { city: "Farsta", zipcode: 12349 },
  { city: "Farsta", zipcode: 12350 },
  { city: "Farsta", zipcode: 12352 },
  { city: "Farsta", zipcode: 12353 },
  { city: "Farsta", zipcode: 12354 },
  { city: "Farsta", zipcode: 12355 },
  { city: "Farsta", zipcode: 12356 },
  { city: "Farsta", zipcode: 12357 },
  { city: "Farsta", zipcode: 12358 },
  { city: "Farsta", zipcode: 12359 },
  { city: "Farsta", zipcode: 12360 },
  { city: "Farsta", zipcode: 12363 },
  { city: "Farsta", zipcode: 12371 },
  { city: "Farsta", zipcode: 12372 },
  { city: "Farsta", zipcode: 12373 },
  { city: "Farsta", zipcode: 12386 },
  { city: "Farsta", zipcode: 12320 },
  { city: "Farsta", zipcode: 12387 },
  { city: "Fasta", zipcode: 12361 },
  { city: "Gustavsberg", zipcode: 13420 },
  { city: "Gustavsberg", zipcode: 13439 },
  { city: "Hagersten", zipcode: 12600 },
  { city: "Hagersten", zipcode: 12601 },
  { city: "Hagersten", zipcode: 12609 },
  { city: "Hagersten", zipcode: 12626 },
  { city: "Hagersten", zipcode: 12628 },
  { city: "Hagersten", zipcode: 12629 },
  { city: "Hagersten", zipcode: 12630 },
  { city: "Hagersten", zipcode: 12631 },
  { city: "Hagersten", zipcode: 12632 },
  { city: "Hagersten", zipcode: 12633 },
  { city: "Hagersten", zipcode: 12634 },
  { city: "Hagersten", zipcode: 12635 },
  { city: "Hagersten", zipcode: 12636 },
  { city: "Hagersten", zipcode: 12637 },
  { city: "Hagersten", zipcode: 12638 },
  { city: "Hagersten", zipcode: 12639 },
  { city: "Hagersten", zipcode: 12640 },
  { city: "Hagersten", zipcode: 12641 },
  { city: "Hagersten", zipcode: 12642 },
  { city: "Hagersten", zipcode: 12647 },
  { city: "Hagersten", zipcode: 12648 },
  { city: "Hagersten", zipcode: 12649 },
  { city: "Hagersten", zipcode: 12650 },
  { city: "Hagersten", zipcode: 12651 },
  { city: "Hagersten", zipcode: 12652 },
  { city: "Hagersten", zipcode: 12653 },
  { city: "Hagersten", zipcode: 12654 },
  { city: "Hagersten", zipcode: 12655 },
  { city: "Hagersten", zipcode: 12677 },
  { city: "Hagersten", zipcode: 12678 },
  { city: "Hagersten", zipcode: 12679 },
  { city: "Hagersten", zipcode: 12689 },
  { city: "Hagersten", zipcode: 12900 },
  { city: "Hagersten", zipcode: 12904 },
  { city: "Hagersten", zipcode: 12906 },
  { city: "Hagersten", zipcode: 12907 },
  { city: "Hagersten", zipcode: 12918 },
  { city: "Hagersten", zipcode: 12921 },
  { city: "Hagersten", zipcode: 12922 },
  { city: "Hagersten", zipcode: 12923 },
  { city: "Hagersten", zipcode: 12930 },
  { city: "Hagersten", zipcode: 12931 },
  { city: "Hagersten", zipcode: 12932 },
  { city: "Hagersten", zipcode: 12933 },
  { city: "Hagersten", zipcode: 12934 },
  { city: "Hagersten", zipcode: 12935 },
  { city: "Hagersten", zipcode: 12936 },
  { city: "Hagersten", zipcode: 12937 },
  { city: "Hagersten", zipcode: 12938 },
  { city: "Hagersten", zipcode: 12939 },
  { city: "Hagersten", zipcode: 12940 },
  { city: "Hagersten", zipcode: 12941 },
  { city: "Hagersten", zipcode: 12942 },
  { city: "Hagersten", zipcode: 12943 },
  { city: "Hagersten", zipcode: 12944 },
  { city: "Hagersten", zipcode: 12945 },
  { city: "Hagersten", zipcode: 12946 },
  { city: "Hagersten", zipcode: 12947 },
  { city: "Hagersten", zipcode: 12948 },
  { city: "Hagersten", zipcode: 12949 },
  { city: "Hagersten", zipcode: 12950 },
  { city: "Hagersten", zipcode: 12951 },
  { city: "Hagersten", zipcode: 12952 },
  { city: "Hagersten", zipcode: 12953 },
  { city: "Hagersten", zipcode: 12954 },
  { city: "Hagersten", zipcode: 12955 },
  { city: "Hagersten", zipcode: 12956 },
  { city: "Hagersten", zipcode: 12957 },
  { city: "Hagersten", zipcode: 12920 },
  { city: "Årsta Holmar", zipcode: 11842 },
  { city: "Hallstavik", zipcode: 76320 },
  { city: "Handen", zipcode: 13636 },
  { city: "Handen", zipcode: 13637 },
  { city: "Handen", zipcode: 13638 },
  { city: "Haninge", zipcode: 13612 },
  { city: "Haninge", zipcode: 13620 },
  { city: "Hasselby", zipcode: 16500 },
  { city: "Hasselby", zipcode: 16510 },
  { city: "Hasselby", zipcode: 16511 },
  { city: "Hasselby", zipcode: 16512 },
  { city: "Hasselby", zipcode: 16513 },
  { city: "Hasselby", zipcode: 16515 },
  { city: "Hasselby", zipcode: 16516 },
  { city: "Hasselby", zipcode: 16521 },
  { city: "Hasselby", zipcode: 16522 },
  { city: "Hasselby", zipcode: 16523 },
  { city: "Hasselby", zipcode: 16524 },
  { city: "Hasselby", zipcode: 16552 },
  { city: "Hasselby", zipcode: 16553 },
  { city: "Hasselby", zipcode: 16554 },
  { city: "Hasselby", zipcode: 16555 },
  { city: "Hasselby", zipcode: 16556 },
  { city: "Hasselby", zipcode: 16557 },
  { city: "Hasselby", zipcode: 16558 },
  { city: "Hasselby", zipcode: 16559 },
  { city: "Hasselby", zipcode: 16560 },
  { city: "Hasselby", zipcode: 16561 },
  { city: "Hasselby", zipcode: 16562 },
  { city: "Hasselby", zipcode: 16563 },
  { city: "Hasselby", zipcode: 16564 },
  { city: "Hasselby", zipcode: 16565 },
  { city: "Hasselby", zipcode: 16566 },
  { city: "Hasselby", zipcode: 16570 },
  { city: "Hasselby", zipcode: 16571 },
  { city: "Hasselby", zipcode: 16572 },
  { city: "Hasselby", zipcode: 16573 },
  { city: "Hasselby", zipcode: 16574 },
  { city: "Hasselby", zipcode: 16575 },
  { city: "Hasselby", zipcode: 16576 },
  { city: "Hasselby", zipcode: 16577 },
  { city: "Hasselby", zipcode: 16578 },
  { city: "Hasselby", zipcode: 16520 },
  { city: "Huddinge", zipcode: 14120 },
  { city: "Jarfalla", zipcode: 17520 },
  { city: "Jarfalla", zipcode: 17572 },
  { city: "Jarfalla", zipcode: 17700 },
  { city: "Jarfalla", zipcode: 17720 },
  { city: "Jarfalla", zipcode: 17746 },
  { city: "Årstaskogs väg", zipcode: 11756 },
  { city: "Jarna", zipcode: 15320 },
  { city: "Jarna", zipcode: 15326 },
  { city: "Johanneshov", zipcode: 12100 },
  { city: "Johanneshov", zipcode: 12105 },
  { city: "Johanneshov", zipcode: 12106 },
  { city: "Johanneshov", zipcode: 12111 },
  { city: "Johanneshov", zipcode: 12112 },
  { city: "Johanneshov", zipcode: 12113 },
  { city: "Johanneshov", zipcode: 12116 },
  { city: "Johanneshov", zipcode: 12117 },
  { city: "Johanneshov", zipcode: 12118 },
  { city: "Johanneshov", zipcode: 12121 },
  { city: "Johanneshov", zipcode: 12122 },
  { city: "Johanneshov", zipcode: 12123 },
  { city: "Johanneshov", zipcode: 12124 },
  { city: "Johanneshov", zipcode: 12135 },
  { city: "Johanneshov", zipcode: 12136 },
  { city: "Johanneshov", zipcode: 12137 },
  { city: "Johanneshov", zipcode: 12138 },
  { city: "Johanneshov", zipcode: 12139 },
  { city: "Johanneshov", zipcode: 12140 },
  { city: "Johanneshov", zipcode: 12143 },
  { city: "Johanneshov", zipcode: 12144 },
  { city: "Johanneshov", zipcode: 12145 },
  { city: "Johanneshov", zipcode: 12146 },
  { city: "Johanneshov", zipcode: 12147 },
  { city: "Johanneshov", zipcode: 12148 },
  { city: "Johanneshov", zipcode: 12149 },
  { city: "Johanneshov", zipcode: 12150 },
  { city: "Johanneshov", zipcode: 12151 },
  { city: "Johanneshov", zipcode: 12152 },
  { city: "Johanneshov", zipcode: 12153 },
  { city: "Johanneshov", zipcode: 12154 },
  { city: "Johanneshov", zipcode: 12155 },
  { city: "Johanneshov", zipcode: 12156 },
  { city: "Johanneshov", zipcode: 12162 },
  { city: "Johanneshov", zipcode: 12163 },
  { city: "Johanneshov", zipcode: 12177 },
  { city: "Johanneshov", zipcode: 12186 },
  { city: "Johanneshov", zipcode: 12189 },
  { city: "Johanneshov", zipcode: 12119 },
  { city: "Johanneshov", zipcode: 12120 },
  { city: "Kista", zipcode: 16400 },
  { city: "Kista", zipcode: 16406 },
  { city: "Kista", zipcode: 16407 },
  { city: "Kista", zipcode: 16419 },
  { city: "Kista", zipcode: 16421 },
  { city: "Kista", zipcode: 16422 },
  { city: "Kista", zipcode: 16425 },
  { city: "Kista", zipcode: 16426 },
  { city: "Kista", zipcode: 16428 },
  { city: "Kista", zipcode: 16430 },
  { city: "Kista", zipcode: 16431 },
  { city: "Kista", zipcode: 16432 },
  { city: "Kista", zipcode: 16433 },
  { city: "Kista", zipcode: 16434 },
  { city: "Kista", zipcode: 16435 },
  { city: "Kista", zipcode: 16436 },
  { city: "Kista", zipcode: 16437 },
  { city: "Kista", zipcode: 16438 },
  { city: "Kista", zipcode: 16439 },
  { city: "Kista", zipcode: 16440 },
  { city: "Kista", zipcode: 16441 },
  { city: "Kista", zipcode: 16442 },
  { city: "Kista", zipcode: 16443 },
  { city: "Kista", zipcode: 16444 },
  { city: "Kista", zipcode: 16445 },
  { city: "Kista", zipcode: 16446 },
  { city: "Kista", zipcode: 16447 },
  { city: "Kista", zipcode: 16448 },
  { city: "Kista", zipcode: 16451 },
  { city: "Kista", zipcode: 16452 },
  { city: "Kista", zipcode: 16453 },
  { city: "Kista", zipcode: 16455 },
  { city: "Kista", zipcode: 16471 },
  { city: "Kista", zipcode: 16472 },
  { city: "Kista", zipcode: 16473 },
  { city: "Kista", zipcode: 16474 },
  { city: "Kista", zipcode: 16475 },
  { city: "Kista", zipcode: 16476 },
  { city: "Kista", zipcode: 16477 },
  { city: "Kista", zipcode: 16478 },
  { city: "Kista", zipcode: 16479 },
  { city: "Kista", zipcode: 16481 },
  { city: "Kista", zipcode: 16487 },
  { city: "Kista", zipcode: 16488 },
  { city: "Kista", zipcode: 16491 },
  { city: "Kista", zipcode: 16493 },
  { city: "Kista", zipcode: 16494 },
  { city: "Kista", zipcode: 16496 },
  { city: "Kista", zipcode: 16497 },
  { city: "Kista", zipcode: 16499 },
  { city: "Kista", zipcode: 16420 },
  { city: "Kista", zipcode: 16423 },
  { city: "Kista", zipcode: 16424 },
  { city: "Kista", zipcode: 16429 },
  { city: "Kista", zipcode: 16495 },
  { city: "Årsta Skolgränd", zipcode: 11743 },
  { city: "Kungsangen", zipcode: 19600 },
  { city: "Kungsangen", zipcode: 19601 },
  { city: "Kungsangen", zipcode: 19602 },
  { city: "Kungsangen", zipcode: 19620 },
  { city: "Kungsangen", zipcode: 19621 },
  { city: "Kungsangen", zipcode: 19622 },
  { city: "Kungsangen", zipcode: 19623 },
  { city: "Kungsangen", zipcode: 19624 },
  { city: "Kungsangen", zipcode: 19628 },
  { city: "Kungsangen", zipcode: 19630 },
  { city: "Kungsangen", zipcode: 19631 },
  { city: "Kungsangen", zipcode: 19632 },
  { city: "Kungsangen", zipcode: 19633 },
  { city: "Kungsangen", zipcode: 19634 },
  { city: "Kungsangen", zipcode: 19635 },
  { city: "Kungsangen", zipcode: 19636 },
  { city: "Kungsangen", zipcode: 19637 },
  { city: "Kungsangen", zipcode: 19638 },
  { city: "Kungsangen", zipcode: 19639 },
  { city: "Kungsangen", zipcode: 19640 },
  { city: "Kungsangen", zipcode: 19641 },
  { city: "Kungsangen", zipcode: 19680 },
  { city: "Kungsangen", zipcode: 19681 },
  { city: "Kungsangen", zipcode: 19685 },
  { city: "Kungsangen", zipcode: 19691 },
  { city: "Kungsangen", zipcode: 19692 },
  { city: "Kungsangen", zipcode: 19693 },
  { city: "Lidingo", zipcode: 18105 },
  { city: "Lidingo", zipcode: 18120 },
  { city: "Lidingo", zipcode: 18186 },
  { city: "Lidingo", zipcode: 18187 },
  { city: "Artemisgatan", zipcode: 11542 },
  { city: "Nacka", zipcode: 13120 },
  { city: "Nacka", zipcode: 13156 },
  { city: "Nacka", zipcode: 13159 },
  { city: "Nacka", zipcode: 13162 },
  { city: "Nacka", zipcode: 13172 },
  { city: "Nacka", zipcode: 13173 },
  { city: "Nacka", zipcode: 13192 },
  { city: "Nacka", zipcode: 13193 },
  { city: "Norrtalje", zipcode: 76120 },
  { city: "Norsborg", zipcode: 14520 },
  { city: "Nykvarn", zipcode: 15520 },
  { city: "Nykvarn", zipcode: 15535 },
  { city: "Nykvarn", zipcode: 15581 },
  { city: "Nynashamn", zipcode: 14920 },
  { city: "Osmo", zipcode: 14820 },
  { city: "Rimbo", zipcode: 76220 },
  { city: "Ronninge", zipcode: 14420 },
  { city: "Ronninge", zipcode: 14452 },
  { city: "Rosersberg", zipcode: 19000 },
  { city: "Rosersberg", zipcode: 19002 },
  { city: "Rosersberg", zipcode: 19010 },
  { city: "Rosersberg", zipcode: 19011 },
  { city: "Rosersberg", zipcode: 19012 },
  { city: "Rosersberg", zipcode: 19017 },
  { city: "Rosersberg", zipcode: 19020 },
  { city: "Rosersberg", zipcode: 19070 },
  { city: "Rosersberg", zipcode: 19080 },
  { city: "Rosersberg", zipcode: 19081 },
  { city: "Rosersberg", zipcode: 19082 },
  { city: "Rosersberg", zipcode: 19083 },
  { city: "Rosersberg", zipcode: 19084 },
  { city: "Rosersberg", zipcode: 19085 },
  { city: "Rosersberg", zipcode: 19504 },
  { city: "Rosersberg", zipcode: 19570 },
  { city: "Rosersberg", zipcode: 19571 },
  { city: "Rosersberg", zipcode: 19572 },
  { city: "Rosersberg", zipcode: 19595 },
  { city: "Rosersberg", zipcode: 19596 },
  {
    city: "Artillerigatan",
    zipcode: 11445
  },
  {
    city: "Saltsjo-Boo",
    zipcode: 13203
  },
  {
    city: "Saltsjo-Boo",
    zipcode: 13205
  },
  {
    city: "Saltsjo-Boo",
    zipcode: 13285
  },
  {
    city: "Saltsjo-Boo",
    zipcode: 13220
  },
  {
    city: "Saltsjobaden",
    zipcode: 13320
  },
  {
    city: "Saltsjobaden",
    zipcode: 13339
  },
  {
    city: "Sigtuna",
    zipcode: 19300
  },
  {
    city: "Sigtuna",
    zipcode: 19320
  },
  {
    city: "Sigtuna",
    zipcode: 19321
  },
  {
    city: "Sigtuna",
    zipcode: 19322
  },
  {
    city: "Sigtuna",
    zipcode: 19323
  },
  {
    city: "Sigtuna",
    zipcode: 19324
  },
  {
    city: "Sigtuna",
    zipcode: 19328
  },
  {
    city: "Sigtuna",
    zipcode: 19330
  },
  {
    city: "Sigtuna",
    zipcode: 19331
  },
  {
    city: "Sigtuna",
    zipcode: 19332
  },
  {
    city: "Sigtuna",
    zipcode: 19333
  },
  {
    city: "Sigtuna",
    zipcode: 19334
  },
  {
    city: "Sigtuna",
    zipcode: 19335
  },
  {
    city: "Sigtuna",
    zipcode: 19336
  },
  {
    city: "Sigtuna",
    zipcode: 19337
  },
  {
    city: "Sigtuna",
    zipcode: 19340
  },
  {
    city: "Sigtuna",
    zipcode: 19341
  },
  {
    city: "Sigtuna",
    zipcode: 19391
  },
  {
    city: "Skarholmen",
    zipcode: 12700
  },
  {
    city: "Skarholmen",
    zipcode: 12702
  },
  {
    city: "Skarholmen",
    zipcode: 12703
  },
  {
    city: "Skarholmen",
    zipcode: 12721
  },
  {
    city: "Skarholmen",
    zipcode: 12722
  },
  {
    city: "Skarholmen",
    zipcode: 12723
  },
  {
    city: "Skarholmen",
    zipcode: 12724
  },
  {
    city: "Skarholmen",
    zipcode: 12725
  },
  {
    city: "Skarholmen",
    zipcode: 12726
  },
  {
    city: "Skarholmen",
    zipcode: 12730
  },
  {
    city: "Skarholmen",
    zipcode: 12731
  },
  {
    city: "Skarholmen",
    zipcode: 12732
  },
  {
    city: "Skarholmen",
    zipcode: 12733
  },
  {
    city: "Skarholmen",
    zipcode: 12734
  },
  {
    city: "Skarholmen",
    zipcode: 12735
  },
  {
    city: "Skarholmen",
    zipcode: 12736
  },
  {
    city: "Skarholmen",
    zipcode: 12737
  },
  {
    city: "Skarholmen",
    zipcode: 12738
  },
  {
    city: "Skarholmen",
    zipcode: 12739
  },
  {
    city: "Skarholmen",
    zipcode: 12740
  },
  {
    city: "Skarholmen",
    zipcode: 12741
  },
  {
    city: "Skarholmen",
    zipcode: 12742
  },
  {
    city: "Skarholmen",
    zipcode: 12743
  },
  {
    city: "Skarholmen",
    zipcode: 12744
  },
  {
    city: "Skarholmen",
    zipcode: 12745
  },
  {
    city: "Skarholmen",
    zipcode: 12746
  },
  {
    city: "Skarholmen",
    zipcode: 12747
  },
  {
    city: "Skarholmen",
    zipcode: 12748
  },
  {
    city: "Skarholmen",
    zipcode: 12749
  },
  {
    city: "Skarholmen",
    zipcode: 12751
  },
  {
    city: "Skarholmen",
    zipcode: 12761
  },
  {
    city: "Skarholmen",
    zipcode: 12781
  },
  {
    city: "Skarholmen",
    zipcode: 12785
  },
  {
    city: "Skarholmen",
    zipcode: 12786
  },
  {
    city: "Skarholmen",
    zipcode: 12787
  },
  {
    city: "Skarholmen",
    zipcode: 12720
  }
  ,
  {
    city: "Artillerigatan",
    zipcode: 11451
  },
  {
    city: "Skarholmen",
    zipcode: 12760
  },
  {
    city: "Skarpnack",
    zipcode: 12800
  },
  {
    city: "Skarpnack",
    zipcode: 12821
  },
  {
    city: "Skarpnack",
    zipcode: 12822
  },
  {
    city: "Skarpnack",
    zipcode: 12823
  },
  {
    city: "Skarpnack",
    zipcode: 12824
  },
  {
    city: "Skarpnack",
    zipcode: 12830
  },
  {
    city: "Skarpnack",
    zipcode: 12831
  },
  {
    city: "Skarpnack",
    zipcode: 12832
  },
  {
    city: "Skarpnack",
    zipcode: 12833
  },
  {
    city: "Skarpnack",
    zipcode: 12834
  },
  {
    city: "Skarpnack",
    zipcode: 12835
  },
  {
    city: "Skarpnack",
    zipcode: 12836
  },
  {
    city: "Skarpnack",
    zipcode: 12838
  },
  {
    city: "Skarpnack",
    zipcode: 12839
  },
  {
    city: "Skarpnack",
    zipcode: 12820
  },
  {
    city: "Skondal",
    zipcode: 14220
  },
  {
    city: "Skondal",
    zipcode: 12806
  },
  {
    city: "Skondal",
    zipcode: 12807
  },
  {
    city: "Skondal",
    zipcode: 12862
  },
  {
    city: "Skondal",
    zipcode: 12863
  },
  {
    city: "Skondal",
    zipcode: 12864
  },
  {
    city: "Skondal",
    zipcode: 12865
  },
  {
    city: "Skondal",
    zipcode: 12866
  },
  {
    city: "Skondal",
    zipcode: 12867
  },
  {
    city: "Skondal",
    zipcode: 12868
  },
  {
    city: "Skondal",
    zipcode: 12869
  },
  {
    city: "Skondal",
    zipcode: 12885
  },
  {
    city: "Sodertalje",
    zipcode: 15103
  },
  {
    city: "Sodertalje",
    zipcode: 15105
  },
  {
    city: "Sodertalje",
    zipcode: 15107
  },
  {
    city: "Sodertalje",
    zipcode: 15113
  },
  {
    city: "Sodertalje",
    zipcode: 15120
  },
  {
    city: "Sodertalje",
    zipcode: 15156
  },
  {
    city: "Sodertalje",
    zipcode: 15157
  },
  {
    city: "Sodertalje",
    zipcode: 15167
  },
  {
    city: "Sodertalje",
    zipcode: 15184
  },
  {
    city: "Sodertalje",
    zipcode: 15220
  },
  {
    city: "Sodertalje",
    zipcode: 15228
  },
  {
    city: "Sodertalje",
    zipcode: 15259
  },
  {
    city: "Sollentuna",
    zipcode: 19100
  },
  {
    city: "Sollentuna",
    zipcode: 19102
  },
  {
    city: "Sollentuna",
    zipcode: 19118
  },
  {
    city: "Sollentuna",
    zipcode: 19120
  },
  {
    city: "Sollentuna",
    zipcode: 19121
  },
  {
    city: "Sollentuna",
    zipcode: 19122
  },
  {
    city: "Sollentuna",
    zipcode: 19123
  },
  {
    city: "Sollentuna",
    zipcode: 19124
  },
  {
    city: "Sollentuna",
    zipcode: 19126
  },
  {
    city: "Sollentuna",
    zipcode: 19127
  },
  {
    city: "Sollentuna",
    zipcode: 19128
  },
  {
    city: "Sollentuna",
    zipcode: 19129
  },
  {
    city: "Sollentuna",
    zipcode: 19131
  },
  {
    city: "Sollentuna",
    zipcode: 19133
  },
  {
    city: "Sollentuna",
    zipcode: 19134
  },
  {
    city: "Sollentuna",
    zipcode: 19135
  },
  {
    city: "Sollentuna",
    zipcode: 19136
  },
  {
    city: "Sollentuna",
    zipcode: 19137
  },
  {
    city: "Sollentuna",
    zipcode: 19138
  },
  {
    city: "Sollentuna",
    zipcode: 19139
  },
  {
    city: "Sollentuna",
    zipcode: 19140
  },
  {
    city: "Sollentuna",
    zipcode: 19141
  },
  {
    city: "Sollentuna",
    zipcode: 19142
  },
  {
    city: "Sollentuna",
    zipcode: 19143
  },
  {
    city: "Sollentuna",
    zipcode: 19144
  },
  {
    city: "Sollentuna",
    zipcode: 19145
  },
  {
    city: "Sollentuna",
    zipcode: 19146
  },
  {
    city: "Sollentuna",
    zipcode: 19147
  },
  {
    city: "Sollentuna",
    zipcode: 19148
  },
  {
    city: "Sollentuna",
    zipcode: 19149
  },
  {
    city: "Sollentuna",
    zipcode: 19150
  },
  {
    city: "Sollentuna",
    zipcode: 19160
  },
  {
    city: "Sollentuna",
    zipcode: 19161
  },
  {
    city: "Sollentuna",
    zipcode: 19162
  },
  {
    city: "Sollentuna",
    zipcode: 19163
  },
  {
    city: "Sollentuna",
    zipcode: 19164
  },
  {
    city: "Sollentuna",
    zipcode: 19181
  },
  {
    city: "Sollentuna",
    zipcode: 19183
  },
  {
    city: "Sollentuna",
    zipcode: 19184
  },
  {
    city: "Sollentuna",
    zipcode: 19185
  },
  {
    city: "Sollentuna",
    zipcode: 19186
  },
  {
    city: "Sollentuna",
    zipcode: 19189
  },
  {
    city: "Sollentuna",
    zipcode: 19190
  },
  {
    city: "Sollentuna",
    zipcode: 19197
  },
  {
    city: "Sollentuna",
    zipcode: 19200
  },
  {
    city: "Sollentuna",
    zipcode: 19205
  },
  {
    city: "Sollentuna",
    zipcode: 19206
  },
  {
    city: "Sollentuna",
    zipcode: 19207
  },
  {
    city: "Sollentuna",
    zipcode: 19208
  },
  {
    city: "Sollentuna",
    zipcode: 19210
  },
  {
    city: "Sollentuna",
    zipcode: 19220
  },
  {
    city: "Sollentuna",
    zipcode: 19221
  },
  {
    city: "Sollentuna",
    zipcode: 19230
  },
  {
    city: "Sollentuna",
    zipcode: 19232
  },
  {
    city: "Sollentuna",
    zipcode: 19248
  },
  {
    city: "Sollentuna",
    zipcode: 19251
  },
  {
    city: "Sollentuna",
    zipcode: 19252
  },
  {
    city: "Sollentuna",
    zipcode: 19253
  },
  {
    city: "Sollentuna",
    zipcode: 19254
  },
  {
    city: "Sollentuna",
    zipcode: 19255
  },
  {
    city: "Sollentuna",
    zipcode: 19256
  },
  {
    city: "Sollentuna",
    zipcode: 19257
  },
  {
    city: "Sollentuna",
    zipcode: 19258
  },
  {
    city: "Sollentuna",
    zipcode: 19259
  },
  {
    city: "Sollentuna",
    zipcode: 19265
  },
  {
    city: "Sollentuna",
    zipcode: 19266
  },
  {
    city: "Sollentuna",
    zipcode: 19267
  },
  {
    city: "Sollentuna",
    zipcode: 19268
  },
  {
    city: "Sollentuna",
    zipcode: 19269
  },
  {
    city: "Sollentuna",
    zipcode: 19270
  },
  {
    city: "Sollentuna",
    zipcode: 19271
  },
  {
    city: "Sollentuna",
    zipcode: 19272
  },
  {
    city: "Sollentuna",
    zipcode: 19273
  },
  {
    city: "Sollentuna",
    zipcode: 19274
  },
  {
    city: "Sollentuna",
    zipcode: 19275
  },
  {
    city: "Sollentuna",
    zipcode: 19276
  },
  {
    city: "Sollentuna",
    zipcode: 19277
  },
  {
    city: "Sollentuna",
    zipcode: 19278
  },
  {
    city: "Sollentuna",
    zipcode: 19279
  },
  {
    city: "Sollentuna",
    zipcode: 19280
  },
  {
    city: "Sollentuna",
    zipcode: 19281
  },
  {
    city: "Solna",
    zipcode: 16920
  },
  {
    city: "Solna",
    zipcode: 16940
  },
  {
    city: "Solna",
    zipcode: 16974
  },
  {
    city: "Solna",
    zipcode: 16989
  },
  {
    city: "Solna",
    zipcode: 17020
  },
  {
    city: "Solna",
    zipcode: 17084
  },
  {
    city: "Solna",
    zipcode: 17108
  },
  {
    city: "Solna",
    zipcode: 17112
  },
  {
    city: "Solna",
    zipcode: 17120
  },
  {
    city: "Solna",
    zipcode: 17179
  },
  {
    city: "Solna",
    zipcode: 17183
  },
  {
    city: "Solna",
    zipcode: 17188
  },
  {
    city: "Solna",
    zipcode: 17193
  },
  {
    city: "Solna",
    zipcode: 17199
  },
  {
    city: "Solna",
    zipcode: 17330
  },
  {
    city: "Solna",
    zipcode: 17331
  },
  {
    city: "Spanga",
    zipcode: 16300
  },
  {
    city: "Spanga",
    zipcode: 16303
  },
  {
    city: "Spanga",
    zipcode: 16304
  },
  {
    city: "Spanga",
    zipcode: 16305
  },
  {
    city: "Spanga",
    zipcode: 16308
  },
  {
    city: "Spanga",
    zipcode: 16310
  },
  {
    city: "Spanga",
    zipcode: 16319
  },
  {
    city: "Spanga",
    zipcode: 16321
  },
  {
    city: "Spanga",
    zipcode: 16329
  },
  {
    city: "Spanga",
    zipcode: 16340
  },
  {
    city: "Spanga",
    zipcode: 16341
  },
  {
    city: "Spanga",
    zipcode: 16342
  },
  {
    city: "Spanga",
    zipcode: 16343
  },
  {
    city: "Spanga",
    zipcode: 16344
  },
  {
    city: "Spanga",
    zipcode: 16345
  },
  {
    city: "Spanga",
    zipcode: 16346
  },
  {
    city: "Spanga",
    zipcode: 16347
  },
  {
    city: "Spanga",
    zipcode: 16350
  },
  {
    city: "Spanga",
    zipcode: 16351
  },
  {
    city: "Spanga",
    zipcode: 16352
  },
  {
    city: "Spanga",
    zipcode: 16353
  },
  {
    city: "Spanga",
    zipcode: 16354
  },
  {
    city: "Spanga",
    zipcode: 16355
  },
  {
    city: "Spanga",
    zipcode: 16356
  },
  {
    city: "Spanga",
    zipcode: 16357
  },
  {
    city: "Spanga",
    zipcode: 16361
  },
  {
    city: "Spanga",
    zipcode: 16362
  },
  {
    city: "Spanga",
    zipcode: 16363
  },
  {
    city: "Spanga",
    zipcode: 16364
  },
  {
    city: "Spanga",
    zipcode: 16365
  },
  {
    city: "Spanga",
    zipcode: 16366
  },
  {
    city: "Spanga",
    zipcode: 16367
  },
  {
    city: "Spanga",
    zipcode: 16368
  },
  {
    city: "Spanga",
    zipcode: 16370
  },
  {
    city: "Spanga",
    zipcode: 16371
  },
  {
    city: "Spanga",
    zipcode: 16372
  },
  {
    city: "Spanga",
    zipcode: 16373
  },
  {
    city: "Spanga",
    zipcode: 16374
  },
  {
    city: "Spanga",
    zipcode: 16375
  },
  {
    city: "Spanga",
    zipcode: 16376
  },
  {
    city: "Spanga",
    zipcode: 16377
  },
  {
    city: "Spanga",
    zipcode: 16380
  },
  {
    city: "Spanga",
    zipcode: 16385
  },
  {
    city: "Spanga",
    zipcode: 16389
  },
  {
    city: "Spanga",
    zipcode: 16391
  },
  {
    city: "Spanga",
    zipcode: 16320
  },
  {
    city: "Spanga",
    zipcode: 16388
  }

  ,
  {
    city: "Artillerigatan", zipcode: 11530
  },
  {
    city: "Askrikegatan", zipcode: 11557
  },
  {
    city: "Åsögatan", zipcode: 11624
  },
  {
    city: "Åsögatan", zipcode: 11632
  },
  {
    city: "Åsögatan", zipcode: 11829
  },
  {
    city: "Åsötorget", zipcode: 11862
  },
  {
    city: "Assessorsgatan", zipcode: 11857
  },
  { city: "Stockholm", zipcode: 11111 },
  { city: "Stockholm", zipcode: 11120 },
  { city: "Stockholm", zipcode: 11121 },
  { city: "Stockholm", zipcode: 11122 },
  { city: "Stockholm", zipcode: 11123 },
  { city: "Stockholm", zipcode: 11124 },
  { city: "Stockholm", zipcode: 11127 },
  { city: "Stockholm", zipcode: 11128 },
  { city: "Stockholm", zipcode: 11129 },
  { city: "Stockholm", zipcode: 11130 },
  { city: "Stockholm", zipcode: 11131 },
  { city: "Stockholm", zipcode: 11134 },
  { city: "Stockholm", zipcode: 11135 },
  { city: "Stockholm", zipcode: 11136 },
  { city: "Stockholm", zipcode: 11137 },
  { city: "Stockholm", zipcode: 11138 },
  { city: "Stockholm", zipcode: 11139 },
  { city: "Stockholm", zipcode: 11140 },
  { city: "Stockholm", zipcode: 11143 },
  { city: "Stockholm", zipcode: 11144 },
  { city: "Stockholm", zipcode: 11145 },
  { city: "Stockholm", zipcode: 11146 },
  { city: "Stockholm", zipcode: 11147 },
  { city: "Stockholm", zipcode: 11148 },
  { city: "Stockholm", zipcode: 11149 },
  { city: "Stockholm", zipcode: 11150 },
  { city: "Stockholm", zipcode: 11151 },
  { city: "Stockholm", zipcode: 11152 },
  { city: "Stockholm", zipcode: 11153 },
  { city: "Stockholm", zipcode: 11156 },
  { city: "Stockholm", zipcode: 11157 },
  { city: "Stockholm", zipcode: 11160 },
  { city: "Stockholm", zipcode: 11161 },
  { city: "Stockholm", zipcode: 11164 },
  { city: "Stockholm", zipcode: 11214 },
  { city: "Stockholm", zipcode: 11215 },
  { city: "Stockholm", zipcode: 11216 },
  { city: "Stockholm", zipcode: 11217 },
  { city: "Stockholm", zipcode: 11218 },
  { city: "Stockholm", zipcode: 11219 },
  { city: "Stockholm", zipcode: 11220 },
  { city: "Stockholm", zipcode: 11221 },
  { city: "Stockholm", zipcode: 11222 },
  { city: "Stockholm", zipcode: 11223 },
  { city: "Stockholm", zipcode: 11224 },
  { city: "Stockholm", zipcode: 11225 },
  { city: "Stockholm", zipcode: 11226 },
  { city: "Stockholm", zipcode: 11227 },
  { city: "Stockholm", zipcode: 11228 },
  { city: "Stockholm", zipcode: 11229 },
  { city: "Stockholm", zipcode: 11230 },
  { city: "Stockholm", zipcode: 11231 },
  { city: "Stockholm", zipcode: 11232 },
  { city: "Stockholm", zipcode: 11233 },
  { city: "Stockholm", zipcode: 11234 },
  { city: "Stockholm", zipcode: 11235 },
  { city: "Stockholm", zipcode: 11236 },
  { city: "Stockholm", zipcode: 11237 },
  { city: "Stockholm", zipcode: 11238 },
  { city: "Stockholm", zipcode: 11239 },
  { city: "Stockholm", zipcode: 11240 },
  { city: "Stockholm", zipcode: 11241 },
  { city: "Stockholm", zipcode: 11242 },
  { city: "Stockholm", zipcode: 11243 },
  { city: "Stockholm", zipcode: 11244 },
  { city: "Stockholm", zipcode: 11245 },
  { city: "Stockholm", zipcode: 11246 },
  { city: "Stockholm", zipcode: 11247 },
  { city: "Stockholm", zipcode: 11248 },
  { city: "Stockholm", zipcode: 11249 },
  { city: "Stockholm", zipcode: 11250 },
  { city: "Stockholm", zipcode: 11251 },
  { city: "Stockholm", zipcode: 11252 },
  { city: "Stockholm", zipcode: 11253 },
  { city: "Stockholm", zipcode: 11254 },
  { city: "Stockholm", zipcode: 11255 },
  { city: "Stockholm", zipcode: 11256 },
  { city: "Stockholm", zipcode: 11257 },
  { city: "Stockholm", zipcode: 11258 },
  { city: "Stockholm", zipcode: 11259 },
  { city: "Stockholm", zipcode: 11260 },
  { city: "Stockholm", zipcode: 11261 },
  { city: "Stockholm", zipcode: 11262 },
  { city: "Stockholm", zipcode: 11263 },
  { city: "Stockholm", zipcode: 11264 },
  { city: "Stockholm", zipcode: 11265 },
  { city: "Stockholm", zipcode: 11266 },
  { city: "Stockholm", zipcode: 11267 },
  { city: "Stockholm", zipcode: 11268 },
  { city: "Stockholm", zipcode: 11269 },
  { city: "Stockholm", zipcode: 11320 },
  { city: "Stockholm", zipcode: 11321 },
  { city: "Stockholm", zipcode: 11322 },
  { city: "Stockholm", zipcode: 11323 },
  { city: "Stockholm", zipcode: 11324 },
  { city: "Stockholm", zipcode: 11325 },
  { city: "Stockholm", zipcode: 11326 },
  { city: "Stockholm", zipcode: 11327 },
  { city: "Stockholm", zipcode: 11328 },
  { city: "Stockholm", zipcode: 11329 },
  { city: "Stockholm", zipcode: 11330 },
  { city: "Stockholm", zipcode: 11331 },
  { city: "Stockholm", zipcode: 11332 },
  { city: "Stockholm", zipcode: 11333 },
  { city: "Stockholm", zipcode: 11334 },
  { city: "Stockholm", zipcode: 11335 },
  { city: "Stockholm", zipcode: 11336 },
  { city: "Stockholm", zipcode: 11337 },
  { city: "Stockholm", zipcode: 11338 },
  { city: "Stockholm", zipcode: 11339 },
  { city: "Stockholm", zipcode: 11340 },
  { city: "Stockholm", zipcode: 11341 },
  { city: "Stockholm", zipcode: 11342 },
  { city: "Stockholm", zipcode: 11343 },
  { city: "Stockholm", zipcode: 11344 },
  { city: "Stockholm", zipcode: 11345 },
  { city: "Stockholm", zipcode: 11346 },
  { city: "Stockholm", zipcode: 11347 },
  { city: "Stockholm", zipcode: 11348 },
  { city: "Stockholm", zipcode: 11349 },
  { city: "Stockholm", zipcode: 11350 },
  { city: "Stockholm", zipcode: 11351 },
  { city: "Stockholm", zipcode: 11352 },
  { city: "Stockholm", zipcode: 11353 },
  { city: "Stockholm", zipcode: 11354 },
  { city: "Stockholm", zipcode: 11355 },
  { city: "Stockholm", zipcode: 11356 },
  { city: "Stockholm", zipcode: 11357 },
  { city: "Stockholm", zipcode: 11358 },
  { city: "Stockholm", zipcode: 11359 },
  { city: "Stockholm", zipcode: 11360 },
  { city: "Stockholm", zipcode: 11361 },
  { city: "Stockholm", zipcode: 11362 },
  { city: "Stockholm", zipcode: 11363 },
  { city: "Stockholm", zipcode: 11364 },
  { city: "Stockholm", zipcode: 11365 },
  { city: "Stockholm", zipcode: 11366 },
  { city: "Stockholm", zipcode: 11367 },
  { city: "Stockholm", zipcode: 11368 },
  { city: "Stockholm", zipcode: 11369 },
  { city: "Stockholm", zipcode: 11380 },
  { city: "Stockholm", zipcode: 11415 },
  { city: "Stockholm", zipcode: 11416 },
  { city: "Stockholm", zipcode: 11417 },
  { city: "Stockholm", zipcode: 11418 },
  { city: "Stockholm", zipcode: 11419 },
  { city: "Stockholm", zipcode: 11420 },
  { city: "Stockholm", zipcode: 11421 },
  { city: "Stockholm", zipcode: 11422 },
  { city: "Stockholm", zipcode: 11423 },
  { city: "Stockholm", zipcode: 11424 },
  { city: "Stockholm", zipcode: 11425 },
  { city: "Stockholm", zipcode: 11426 },
  { city: "Stockholm", zipcode: 11427 },
  { city: "Stockholm", zipcode: 11428 },
  { city: "Stockholm", zipcode: 11429 },
  { city: "Stockholm", zipcode: 11430 },
  { city: "Stockholm", zipcode: 11431 },
  { city: "Stockholm", zipcode: 11432 },
  { city: "Stockholm", zipcode: 11433 },
  { city: "Stockholm", zipcode: 11434 },
  { city: "Stockholm", zipcode: 11435 },
  { city: "Stockholm", zipcode: 11436 },
  { city: "Stockholm", zipcode: 11437 },
  { city: "Stockholm", zipcode: 11438 },
  { city: "Stockholm", zipcode: 11439 },
  { city: "Stockholm", zipcode: 11440 },
  { city: "Stockholm", zipcode: 11441 },
  { city: "Stockholm", zipcode: 11442 },
  { city: "Stockholm", zipcode: 11443 },
  { city: "Stockholm", zipcode: 11444 },
  { city: "Stockholm", zipcode: 11445 },
  { city: "Stockholm", zipcode: 11446 },
  { city: "Stockholm", zipcode: 11447 },
  { city: "Stockholm", zipcode: 11448 },
  { city: "Stockholm", zipcode: 11449 },
  { city: "Stockholm", zipcode: 11450 },
  { city: "Stockholm", zipcode: 11451 },
  { city: "Stockholm", zipcode: 11452 },
  { city: "Stockholm", zipcode: 11453 },
  { city: "Stockholm", zipcode: 11454 },
  { city: "Stockholm", zipcode: 11455 },
  { city: "Stockholm", zipcode: 11456 },
  { city: "Stockholm", zipcode: 11457 },
  { city: "Stockholm", zipcode: 11458 },
  { city: "Stockholm", zipcode: 11459 },
  { city: "Stockholm", zipcode: 11460 },
  { city: "Stockholm", zipcode: 11520 },
  { city: "Stockholm", zipcode: 11521 },
  { city: "Stockholm", zipcode: 11522 },
  { city: "Stockholm", zipcode: 11523 },
  { city: "Stockholm", zipcode: 11524 },
  { city: "Stockholm", zipcode: 11525 },
  { city: "Stockholm", zipcode: 11526 },
  { city: "Stockholm", zipcode: 11527 },
  { city: "Stockholm", zipcode: 11528 },
  { city: "Stockholm", zipcode: 11529 },
  { city: "Stockholm", zipcode: 11530 },
  { city: "Stockholm", zipcode: 11531 },
  {
    city: "Stockholm-Arlanda",
    zipcode: 19040
  },
  {
    city: "Stockholm-Arlanda",
    zipcode: 19045
  },
  {
    city: "Stockholm-Arlanda",
    zipcode: 19046
  },
  {
    city: "Stockholm-Arlanda",
    zipcode: 19047
  },
  {
    city: "Stockholm-Arlanda",
    zipcode: 19050
  },
  {
    city: "Stockholm-Arlanda",
    zipcode: 19060
  },
  {
    city: "Stockholm-Arlanda",
    zipcode: 19090
  },
  {
    city: "Stockholm-Globen",
    zipcode: 12126
  },
  {
    city: "Stockholm-Globen",
    zipcode: 12127
  },
  {
    city: "Stockholm-Globen",
    zipcode: 12128
  },
  {
    city: "Stockholm-Globen",
    zipcode: 12188
  },
  {
    city: "Sundbyberg",
    zipcode: 17205
  },
  {
    city: "Sundbyberg",
    zipcode: 17214
  },
  {
    city: "Sundbyberg",
    zipcode: 17215
  },
  {
    city: "Sundbyberg",
    zipcode: 17220
  },
  {
    city: "Sundbyberg",
    zipcode: 17420
  },
  {
    city: "Sundbyberg",
    zipcode: 17441
  },
  {
    city: "Sundbyberg",
    zipcode: 17461
  },
  {
    city: "Sundbyberg",
    zipcode: 17462
  },
  {
    city: "Sundbyberg",
    zipcode: 17463
  },
  {
    city: "Sundbyberg",
    zipcode: 17465
  },
  {
    city: "Sundbyberg",
    zipcode: 17466
  },
  {
    city: "Sundbyberg",
    zipcode: 17486
  },
  {
    city: "Sundbyberg",
    zipcode: 17487
  },
  {
    city: "Sundbyberg",
    zipcode: 17488
  },
  {
    city: "Taby",
    zipcode: 18310
  },
  {
    city: "Taby",
    zipcode: 18320
  },
  {
    city: "Taby",
    zipcode: 18368
  },
  {
    city: "Taby",
    zipcode: 18715
  },
  {
    city: "Taby",
    zipcode: 18720
  },
  {
    city: "Taby",
    zipcode: 18726
  },
  {
    city: "Taby",
    zipcode: 18727
  },
  {
    city: "Taby",
    zipcode: 18728
  },
  {
    city: "Taby",
    zipcode: 18729
  },
  {
    city: "Taby",
    zipcode: 18730
  },
  {
    city: "Taby",
    zipcode: 18731
  },
  {
    city: "Taby",
    zipcode: 18732
  },
  {
    city: "Taby",
    zipcode: 18733
  },
  {
    city: "Taby",
    zipcode: 18734
  },
  {
    city: "Taby",
    zipcode: 18735
  },
  {
    city: "Taby",
    zipcode: 18736
  },
  {
    city: "Taby",
    zipcode: 18737
  },
  {
    city: "Taby",
    zipcode: 18738
  },
  {
    city: "Taby",
    zipcode: 18740
  },
  {
    city: "Taby",
    zipcode: 18741
  },
  {
    city: "Taby",
    zipcode: 18742
  },
  {
    city: "Taby",
    zipcode: 18743
  },
  {
    city: "Taby",
    zipcode: 18744
  },
  {
    city: "Taby",
    zipcode: 18745
  },
  {
    city: "Taby",
    zipcode: 18746
  },
  {
    city: "Taby",
    zipcode: 18750
  },
  {
    city: "Taby",
    zipcode: 18751
  },
  {
    city: "Taby",
    zipcode: 18752
  },
  {
    city: "Taby",
    zipcode: 18753
  },
  {
    city: "Taby",
    zipcode: 18754
  },
  {
    city: "Taby",
    zipcode: 18762
  },
  {
    city: "Taby",
    zipcode: 18763
  },
  {
    city: "Taby",
    zipcode: 18764
  },
  {
    city: "Taby",
    zipcode: 18765
  },
  {
    city: "Taby",
    zipcode: 18766
  },
  {
    city: "Taby",
    zipcode: 18767
  },
  {
    city: "Taby",
    zipcode: 18768
  },
  {
    city: "Taby",
    zipcode: 18769
  },
  {
    city: "Taby",
    zipcode: 18770
  },
  {
    city: "Taby",
    zipcode: 18772
  },
  {
    city: "Taby",
    zipcode: 18773
  },
  {
    city: "Taby",
    zipcode: 18774
  },
  {
    city: "Taby",
    zipcode: 18775
  },
  {
    city: "Taby",
    zipcode: 18776
  },
  {
    city: "Taby",
    zipcode: 18777
  },
  {
    city: "Taby",
    zipcode: 18780
  },
  {
    city: "Taby",
    zipcode: 18781
  },
  {
    city: "Taby",
    zipcode: 19281
  },
  {
    city: "Tomteboda",
    zipcode: 17307
  },
  {
    city: "Tomteboda",
    zipcode: 17308
  },
  {
    city: "Tomteboda",
    zipcode: 17320
  },
  {
    city: "Tomteboda",
    zipcode: 17323
  },
  {
    city: "Tomteboda",
    zipcode: 17324
  },
  {
    city: "Tomteboda",
    zipcode: 17325
  },
  {
    city: "Tomteboda",
    zipcode: 17326
  },
  {
    city: "Tomteboda",
    zipcode: 17327
  },
  {
    city: "Tomteboda",
    zipcode: 17328
  },
  {
    city: "Tomteboda",
    zipcode: 17329
  },
  {
    city: "Tullinge",
    zipcode: 14620
  },
  {
    city: "Tumba",
    zipcode: 14720
  },
  {
    city: "Tumba",
    zipcode: 14745
  },
  {
    city: "Tyreso",
    zipcode: 13520
  },
  {
    city: "Upplands Vasby",
    zipcode: 19400
  },
  {
    city: "Upplands Vasby",
    zipcode: 19402
  },
  {
    city: "Upplands Vasby",
    zipcode: 19404
  },
  {
    city: "Upplands Vasby",
    zipcode: 19405
  },
  {
    city: "Upplands Vasby",
    zipcode: 19410
  },
  {
    city: "Upplands Vasby",
    zipcode: 19417
  },
  {
    city: "Upplands Vasby",
    zipcode: 19418
  },
  {
    city: "Upplands Vasby",
    zipcode: 19419
  },
  {
    city: "Upplands Vasby",
    zipcode: 19420
  },
  {
    city: "Upplands Vasby",
    zipcode: 19421
  },
  {
    city: "Upplands Vasby",
    zipcode: 19422
  },
  {
    city: "Upplands Vasby",
    zipcode: 19423
  },
  {
    city: "Upplands Vasby",
    zipcode: 19426
  },
  {
    city: "Upplands Vasby",
    zipcode: 19427
  },
  {
    city: "Upplands Vasby",
    zipcode: 19428
  },
  {
    city: "Upplands Vasby",
    zipcode: 19429
  },
  {
    city: "Upplands Vasby",
    zipcode: 19430
  },
  {
    city: "Upplands Vasby",
    zipcode: 19431
  },
  {
    city: "Upplands Vasby",
    zipcode: 19432
  },
  {
    city: "Upplands Vasby",
    zipcode: 19433
  },
  {
    city: "Upplands Vasby",
    zipcode: 19434
  },
  {
    city: "Upplands Vasby",
    zipcode: 19435
  },
  {
    city: "Upplands Vasby",
    zipcode: 19436
  },
  {
    city: "Upplands Vasby",
    zipcode: 19437
  },
  {
    city: "Upplands Vasby",
    zipcode: 19438
  },
  {
    city: "Upplands Vasby",
    zipcode: 19439
  },
  {
    city: "Upplands Vasby",
    zipcode: 19440
  },
  {
    city: "Upplands Vasby",
    zipcode: 19441
  },
  {
    city: "Upplands Vasby",
    zipcode: 19442
  },
  {
    city: "Upplands Vasby",
    zipcode: 19443
  },
  {
    city: "Upplands Vasby",
    zipcode: 19444
  },
  {
    city: "Upplands Vasby",
    zipcode: 19445
  },
  {
    city: "Upplands Vasby",
    zipcode: 19446
  },
  {
    city: "Upplands Vasby",
    zipcode: 19447
  },
  {
    city: "Upplands Vasby",
    zipcode: 19449
  },
  {
    city: "Upplands Vasby",
    zipcode: 19451
  },
  {
    city: "Upplands Vasby",
    zipcode: 19452
  },
  {
    city: "Upplands Vasby",
    zipcode: 19453
  },
  {
    city: "Upplands Vasby",
    zipcode: 19454
  },
  {
    city: "Upplands Vasby",
    zipcode: 19455
  },
  {
    city: "Upplands Vasby",
    zipcode: 19456
  },
  {
    city: "Upplands Vasby",
    zipcode: 19457
  },
  {
    city: "Upplands Vasby",
    zipcode: 19460
  },
  {
    city: "Upplands Vasby",
    zipcode: 19461
  },
  {
    city: "Upplands Vasby",
    zipcode: 19462
  },
  {
    city: "Upplands Vasby",
    zipcode: 19463
  },
  {
    city: "Upplands Vasby",
    zipcode: 19464
  },
  {
    city: "Upplands Vasby",
    zipcode: 19465
  },
  {
    city: "Upplands Vasby",
    zipcode: 19466
  },
  {
    city: "Upplands Vasby",
    zipcode: 19467
  },
  {
    city: "Upplands Vasby",
    zipcode: 19468
  },
  {
    city: "Upplands Vasby",
    zipcode: 19469
  },
  {
    city: "Upplands Vasby",
    zipcode: 19470
  },
  {
    city: "Upplands Vasby",
    zipcode: 19471
  },
  {
    city: "Upplands Vasby",
    zipcode: 19472
  },
  {
    city: "Upplands Vasby",
    zipcode: 19473
  },
  {
    city: "Upplands Vasby",
    zipcode: 19474
  },
  {
    city: "Upplands Vasby",
    zipcode: 19475
  },
  {
    city: "Upplands Vasby",
    zipcode: 19476
  },
  {
    city: "Upplands Vasby",
    zipcode: 19477
  },
  {
    city: "Upplands Vasby",
    zipcode: 19478
  },
  {
    city: "Upplands Vasby",
    zipcode: 19479
  },
  {
    city: "Upplands Vasby",
    zipcode: 19480
  },
  {
    city: "Upplands Vasby",
    zipcode: 19481
  },
  {
    city: "Upplands Vasby",
    zipcode: 19482
  },
  {
    city: "Upplands Vasby",
    zipcode: 19483
  },
  {
    city: "Upplands Vasby",
    zipcode: 19484
  },
  {
    city: "Upplands Vasby",
    zipcode: 19486
  },
  {
    city: "Upplands Vasby",
    zipcode: 19487
  },
  {
    city: "Upplands Vasby",
    zipcode: 19488
  },
  {
    city: "Upplands Vasby",
    zipcode: 19489
  },
  {
    city: "Upplands Vasby",
    zipcode: 19491
  },
  {
    city: "Upplands Vasby",
    zipcode: 19492
  },
  {
    city: "Upplands Vasby",
    zipcode: 19498
  },
  {
    city: "Vallentuna",
    zipcode: 18620
  },
  {
    city: "Vallentuna",
    zipcode: 18647
  },
  {
    city: "Vallingby",
    zipcode: 16200
  },
  {
    city: "Vallingby",
    zipcode: 16206
  },
  {
    city: "Vallingby",
    zipcode: 16211
  },
  {
    city: "Vallingby",
    zipcode: 16212
  },
  {
    city: "Vallingby",
    zipcode: 16215
  },
  {
    city: "Vallingby",
    zipcode: 16220
  },
  {
    city: "Vallingby",
    zipcode: 16243
  },
  {
    city: "Vallingby",
    zipcode: 16244
  },
  {
    city: "Vallingby",
    zipcode: 16245
  },
  {
    city: "Vallingby",
    zipcode: 16246
  },
  {
    city: "Vallingby",
    zipcode: 16247
  },
  {
    city: "Vallingby",
    zipcode: 16250
  },
  {
    city: "Vallingby",
    zipcode: 16251
  },
  {
    city: "Vallingby",
    zipcode: 16252
  },
  {
    city: "Vallingby",
    zipcode: 16253
  },
  {
    city: "Vallingby",
    zipcode: 16254
  },
  {
    city: "Vallingby",
    zipcode: 16255
  },
  {
    city: "Vallingby",
    zipcode: 16256
  },
  {
    city: "Vallingby",
    zipcode: 16257
  },
  {
    city: "Vallingby",
    zipcode: 16258
  },
  {
    city: "Vallingby",
    zipcode: 16260
  },
  {
    city: "Vallingby",
    zipcode: 16261
  },
  {
    city: "Vallingby",
    zipcode: 16262
  },
  {
    city: "Vallingby",
    zipcode: 16263
  },
  {
    city: "Vallingby",
    zipcode: 16264
  },
  {
    city: "Vallingby",
    zipcode: 16265
  },
  {
    city: "Vallingby",
    zipcode: 16266
  },
  {
    city: "Vallingby",
    zipcode: 16267
  },
  {
    city: "Vallingby",
    zipcode: 16268
  },
  {
    city: "Vallingby",
    zipcode: 16270
  },
  {
    city: "Vallingby",
    zipcode: 16271
  },
  {
    city: "Vallingby",
    zipcode: 16272
  },
  {
    city: "Vallingby",
    zipcode: 16273
  },
  {
    city: "Vallingby",
    zipcode: 16274
  },
  {
    city: "Vallingby",
    zipcode: 16283
  },
  {
    city: "Vallingby",
    zipcode: 16288
  },
  {
    city: "Vallingby",
    zipcode: 16201
  },
  {
    city: "Vallingby",
    zipcode: 16213
  },
  {
    city: "Varby",
    zipcode: 14346
  },
  {
    city: "Varby",
    zipcode: 14320
  },
  {
    city: "Varmdo",
    zipcode: 13900
  },
  {
    city: "Varmdo",
    zipcode: 13920
  },
  {
    city: "Varmdo",
    zipcode: 13931
  },
  {
    city: "Vasterhaninge",
    zipcode: 13720
  },
  {
    city: "Vasterhaninge",
    zipcode: 13742
  },
  {
    city: "Vaxholm",
    zipcode: 18520
  }
  ,
  { city: "Gothenburg", zipcode: 42438 },
  { city: "Gothenburg", zipcode: 42400 },
  { city: "Gothenburg", zipcode: 42401 },
  { city: "Gothenburg", zipcode: 42402 },
  { city: "Gothenburg", zipcode: 42406 },
  { city: "Gothenburg", zipcode: 42410 },
  { city: "Gothenburg", zipcode: 42421 },
  { city: "Gothenburg", zipcode: 42422 },
  { city: "Gothenburg", zipcode: 42423 },
  { city: "Gothenburg", zipcode: 42424 },
  { city: "Gothenburg", zipcode: 42431 },
  { city: "Gothenburg", zipcode: 42432 },
  { city: "Gothenburg", zipcode: 42433 },
  { city: "Gothenburg", zipcode: 42434 },
  { city: "Gothenburg", zipcode: 42435 },
  { city: "Gothenburg", zipcode: 42436 },
  { city: "Gothenburg", zipcode: 42437 },
  { city: "Gothenburg", zipcode: 42439 },
  { city: "Gothenburg", zipcode: 42440 },
  { city: "Gothenburg", zipcode: 42441 },
  { city: "Gothenburg", zipcode: 42442 },
  { city: "Gothenburg", zipcode: 42443 },
  { city: "Gothenburg", zipcode: 42444 },
  { city: "Gothenburg", zipcode: 42445 },
  { city: "Gothenburg", zipcode: 42446 },
  { city: "Gothenburg", zipcode: 42447 },
  { city: "Gothenburg", zipcode: 42448 },
  { city: "Gothenburg", zipcode: 42449 },
  { city: "Gothenburg", zipcode: 42450 },
  { city: "Gothenburg", zipcode: 42453 },
  { city: "Gothenburg", zipcode: 42455 },
  { city: "Gothenburg", zipcode: 42461 },
  { city: "Gothenburg", zipcode: 42465 },
  { city: "Gothenburg", zipcode: 42466 },
  { city: "Gothenburg", zipcode: 42467 },
  { city: "Gothenburg", zipcode: 42468 },
  { city: "Gothenburg", zipcode: 42469 },
  { city: "Gothenburg", zipcode: 42480 },
  { city: "Gothenburg", zipcode: 42456 },
  { city: "Gothenburg", zipcode: 42457 },
  { city: "Gothenburg", zipcode: 42200 },
  { city: "Gothenburg", zipcode: 42202 },
  { city: "Gothenburg", zipcode: 42203 },
  { city: "Gothenburg", zipcode: 42204 },
  { city: "Gothenburg", zipcode: 42210 },
  { city: "Gothenburg", zipcode: 42241 },
  { city: "Gothenburg", zipcode: 42242 },
  { city: "Gothenburg", zipcode: 42243 },
  { city: "Gothenburg", zipcode: 42244 },
  { city: "Gothenburg", zipcode: 42245 },
  { city: "Gothenburg", zipcode: 42246 },
  { city: "Gothenburg", zipcode: 42247 },
  { city: "Gothenburg", zipcode: 42248 },
  { city: "Gothenburg", zipcode: 42249 },
  { city: "Gothenburg", zipcode: 42250 },
  { city: "Gothenburg", zipcode: 42251 },
  { city: "Gothenburg", zipcode: 42252 },
  { city: "Gothenburg", zipcode: 42253 },
  { city: "Gothenburg", zipcode: 42254 },
  { city: "Gothenburg", zipcode: 42255 },
  { city: "Gothenburg", zipcode: 42256 },
  { city: "Gothenburg", zipcode: 42257 },
  { city: "Gothenburg", zipcode: 42258 },
  { city: "Gothenburg", zipcode: 42259 },
  { city: "Gothenburg", zipcode: 42280 },
  { city: "Gothenburg", zipcode: 42500 },
  { city: "Gothenburg", zipcode: 42502 },
  { city: "Gothenburg", zipcode: 42508 },
  { city: "Gothenburg", zipcode: 42530 },
  { city: "Gothenburg", zipcode: 42531 },
  { city: "Gothenburg", zipcode: 42532 },
  { city: "Gothenburg", zipcode: 42533 },
  { city: "Gothenburg", zipcode: 42534 },
  { city: "Gothenburg", zipcode: 42535 },
  { city: "Gothenburg", zipcode: 42536 },
  { city: "Gothenburg", zipcode: 42537 },
  { city: "Gothenburg", zipcode: 42538 },
  { city: "Gothenburg", zipcode: 42539 },
  { city: "Gothenburg", zipcode: 42541 },
  { city: "Gothenburg", zipcode: 42542 },
  { city: "Gothenburg", zipcode: 42543 },
  { city: "Gothenburg", zipcode: 42565 },
  { city: "Gothenburg", zipcode: 43602 },
  { city: "Gothenburg", zipcode: 43650 },
  { city: "Gothenburg", zipcode: 43651 },
  { city: "Gothenburg", zipcode: 43652 },
  { city: "Gothenburg", zipcode: 43653 },
  { city: "Gothenburg", zipcode: 43654 },
  { city: "Gothenburg", zipcode: 43655 },
  { city: "Gothenburg", zipcode: 43656 },
  { city: "Gothenburg", zipcode: 43657 },
  { city: "Gothenburg", zipcode: 43658 },
  { city: "Gothenburg", zipcode: 43081 },
  { city: "Gothenburg", zipcode: 44970 },
  { city: "Gothenburg", zipcode: 42470 },
  { city: "Gothenburg", zipcode: 42471 },
  { city: "Gothenburg", zipcode: 42472 },
  { city: "Gothenburg", zipcode: 42490 },
  { city: "Gothenburg", zipcode: 42491 },
  { city: "Gothenburg", zipcode: 42370 },
  { city: "Gothenburg", zipcode: 42371 },
  { city: "Gothenburg", zipcode: 42372 },
  { city: "Gothenburg", zipcode: 42373 },
  { city: "Gothenburg", zipcode: 43084 },
  { city: "Gothenburg", zipcode: 42300 },
  { city: "Gothenburg", zipcode: 42321 },
  { city: "Gothenburg", zipcode: 42322 },
  { city: "Gothenburg", zipcode: 42323 },
  { city: "Gothenburg", zipcode: 42332 },
  { city: "Gothenburg", zipcode: 42333 },
  { city: "Gothenburg", zipcode: 42334 },
  { city: "Gothenburg", zipcode: 42335 },
  { city: "Gothenburg", zipcode: 42336 },
  { city: "Gothenburg", zipcode: 42337 },
  { city: "Gothenburg", zipcode: 42338 },
  { city: "Gothenburg", zipcode: 42339 },
  { city: "Gothenburg", zipcode: 42340 },
  { city: "Gothenburg", zipcode: 42341 },
  { city: "Gothenburg", zipcode: 42342 },
  { city: "Gothenburg", zipcode: 42343 },
  { city: "Gothenburg", zipcode: 42344 },
  { city: "Gothenburg", zipcode: 42346 },
  { city: "Gothenburg", zipcode: 42347 },
  { city: "Gothenburg", zipcode: 42348 },
  { city: "Gothenburg", zipcode: 42349 },
  { city: "Gothenburg", zipcode: 42350 },
  { city: "Gothenburg", zipcode: 42351 },
  { city: "Gothenburg", zipcode: 42352 },
  { city: "Gothenburg", zipcode: 42353 },
  { city: "Gothenburg", zipcode: 42354 },
  { city: "Gothenburg", zipcode: 42355 },
  { city: "Gothenburg", zipcode: 42356 },
  { city: "Gothenburg", zipcode: 42359 },
  { city: "Gothenburg", zipcode: 42361 },
  { city: "Gothenburg", zipcode: 42363 },
  { city: "Gothenburg", zipcode: 42100 },
  { city: "Gothenburg", zipcode: 42101 },
  { city: "Gothenburg", zipcode: 42102 },
  { city: "Gothenburg", zipcode: 42108 },
  { city: "Gothenburg", zipcode: 42110 },
  { city: "Gothenburg", zipcode: 42121 },
  { city: "Gothenburg", zipcode: 42122 },
  { city: "Gothenburg", zipcode: 42123 },
  { city: "Gothenburg", zipcode: 42126 },
  { city: "Gothenburg", zipcode: 42130 },
  { city: "Gothenburg", zipcode: 42131 },
  { city: "Gothenburg", zipcode: 42132 },
  { city: "Gothenburg", zipcode: 42133 },
  { city: "Gothenburg", zipcode: 42134 },
  { city: "Gothenburg", zipcode: 42135 },
  { city: "Gothenburg", zipcode: 42136 },
  { city: "Gothenburg", zipcode: 42137 },
  { city: "Gothenburg", zipcode: 42138 },
  { city: "Gothenburg", zipcode: 42139 },
  { city: "Gothenburg", zipcode: 42140 },
  { city: "Gothenburg", zipcode: 42141 },
  { city: "Gothenburg", zipcode: 42142 },
  { city: "Gothenburg", zipcode: 42143 },
  { city: "Gothenburg", zipcode: 42144 },
  { city: "Gothenburg", zipcode: 42145 },
  { city: "Gothenburg", zipcode: 42146 },
  { city: "Gothenburg", zipcode: 42147 },
  { city: "Gothenburg", zipcode: 42148 },
  { city: "Gothenburg", zipcode: 42149 },
  { city: "Gothenburg", zipcode: 42150 },
  { city: "Gothenburg", zipcode: 42151 },
  { city: "Gothenburg", zipcode: 42152 },
  { city: "Gothenburg", zipcode: 42153 },
  { city: "Gothenburg", zipcode: 42154 },
  { city: "Gothenburg", zipcode: 42157 },
  { city: "Gothenburg", zipcode: 42158 },
  { city: "Gothenburg", zipcode: 42159 },
  { city: "Gothenburg", zipcode: 42160 },
  { city: "Gothenburg", zipcode: 42161 },
  { city: "Gothenburg", zipcode: 42162 },
  { city: "Gothenburg", zipcode: 42163 },
  { city: "Gothenburg", zipcode: 42164 },
  { city: "Gothenburg", zipcode: 42165 },
  { city: "Gothenburg", zipcode: 42166 },
  { city: "Gothenburg", zipcode: 42167 },
  { city: "Gothenburg", zipcode: 42170 },
  { city: "Gothenburg", zipcode: 42171 },
  { city: "Gothenburg", zipcode: 42172 },
  { city: "Gothenburg", zipcode: 42173 },
  { city: "Gothenburg", zipcode: 42174 },
  { city: "Gothenburg", zipcode: 42600 },
  { city: "Gothenburg", zipcode: 42604 },
  { city: "Gothenburg", zipcode: 42605 },
  { city: "Gothenburg", zipcode: 42650 },
  { city: "Gothenburg", zipcode: 42651 },
  { city: "Gothenburg", zipcode: 42652 },
  { city: "Gothenburg", zipcode: 42653 },
  { city: "Gothenburg", zipcode: 42654 },
  { city: "Gothenburg", zipcode: 42655 },
  { city: "Gothenburg", zipcode: 42656 },
  { city: "Gothenburg", zipcode: 42657 },
  { city: "Gothenburg", zipcode: 42658 },
  { city: "Gothenburg", zipcode: 42659 },
  { city: "Gothenburg", zipcode: 42668 },
  { city: "Gothenburg", zipcode: 42669 },
  { city: "Gothenburg", zipcode: 42670 },
  { city: "Gothenburg", zipcode: 42671 },
  { city: "Gothenburg", zipcode: 42672 },
  { city: "Gothenburg", zipcode: 42673 },
  { city: "Gothenburg", zipcode: 42674 },
  { city: "Gothenburg", zipcode: 42675 },
  { city: "Gothenburg", zipcode: 42676 },
  { city: "Gothenburg", zipcode: 42677 },
  { city: "Gothenburg", zipcode: 42678 },
  { city: "Gothenburg", zipcode: 42679 },
  { city: "Gothenburg", zipcode: 42680 },
  { city: "Gothenburg", zipcode: 43083 },
  { city: "Gothenburg", zipcode: 43084 },
  { city: "Gothenburg", zipcode: 42456 },
  { city: "Gothenburg", zipcode: 42457 },
  { city: "Gothenburg", zipcode: 42200 },
  { city: "Gothenburg", zipcode: 42202 },
  { city: "Gothenburg", zipcode: 42203 },
  { city: "Gothenburg", zipcode: 42204 },
  { city: "Gothenburg", zipcode: 42210 },
  { city: "Gothenburg", zipcode: 42241 },
  { city: "Gothenburg", zipcode: 42242 },
  { city: "Gothenburg", zipcode: 42243 },
  { city: "Gothenburg", zipcode: 42244 },
  { city: "Gothenburg", zipcode: 42245 },
  { city: "Gothenburg", zipcode: 42246 },
  { city: "Gothenburg", zipcode: 42247 },
  { city: "Gothenburg", zipcode: 42248 },
  { city: "Gothenburg", zipcode: 42249 },
  { city: "Gothenburg", zipcode: 42250 },
  { city: "Gothenburg", zipcode: 42251 },
  { city: "Gothenburg", zipcode: 42252 },
  { city: "Gothenburg", zipcode: 42253 },
  { city: "Gothenburg", zipcode: 42254 },
  { city: "Gothenburg", zipcode: 42255 },
  { city: "Gothenburg", zipcode: 42256 },
  { city: "Gothenburg", zipcode: 42257 },
  { city: "Gothenburg", zipcode: 42258 },
  { city: "Gothenburg", zipcode: 42259 },
  { city: "Gothenburg", zipcode: 42280 },
  { city: "Gothenburg", zipcode: 42500 },
  { city: "Gothenburg", zipcode: 42502 },
  { city: "Gothenburg", zipcode: 42508 },
  { city: "Gothenburg", zipcode: 42530 },
  { city: "Gothenburg", zipcode: 42531 },
  { city: "Gothenburg", zipcode: 42532 },
  { city: "Gothenburg", zipcode: 42533 },
  { city: "Gothenburg", zipcode: 42534 },
  { city: "Gothenburg", zipcode: 42535 },
  { city: "Gothenburg", zipcode: 42536 },
  { city: "Gothenburg", zipcode: 42537 },
  { city: "Gothenburg", zipcode: 42538 },
  { city: "Gothenburg", zipcode: 42539 },
  { city: "Gothenburg", zipcode: 42541 },
  { city: "Gothenburg", zipcode: 42542 },
  { city: "Gothenburg", zipcode: 42543 },
  { city: "Gothenburg", zipcode: 42565 },
  { city: "Gothenburg", zipcode: 43602 },
  { city: "Gothenburg", zipcode: 43650 },
  { city: "Gothenburg", zipcode: 43651 },
  { city: "Gothenburg", zipcode: 43652 },
  { city: "Gothenburg", zipcode: 43653 },
  { city: "Gothenburg", zipcode: 43654 },
  { city: "Gothenburg", zipcode: 43655 },
  { city: "Gothenburg", zipcode: 43656 },
  { city: "Gothenburg", zipcode: 43657 },
  { city: "Gothenburg", zipcode: 43658 },
  { city: "Gothenburg", zipcode: 43081 },
  { city: "Gothenburg", zipcode: 44970 },
  { city: "Gothenburg", zipcode: 42470 },
  { city: "Gothenburg", zipcode: 42471 },
  { city: "Gothenburg", zipcode: 42472 },
  { city: "Gothenburg", zipcode: 42490 },
  { city: "Gothenburg", zipcode: 42491 },
  { city: "Gothenburg", zipcode: 42370 },
  { city: "Gothenburg", zipcode: 42371 },
  { city: "Gothenburg", zipcode: 42372 },
  { city: "Gothenburg", zipcode: 42373 },
  { city: "Gothenburg", zipcode: 43084 },
  { city: "Gothenburg", zipcode: 42300 },
  { city: "Gothenburg", zipcode: 42321 },
  { city: "Gothenburg", zipcode: 42322 },
  { city: "Gothenburg", zipcode: 42323 },
  { city: "Gothenburg", zipcode: 42332 },
  { city: "Gothenburg", zipcode: 42333 },
  { city: "Gothenburg", zipcode: 42334 },
  { city: "Gothenburg", zipcode: 42335 },
  { city: "Gothenburg", zipcode: 42336 },
  { city: "Gothenburg", zipcode: 42337 },
  { city: "Gothenburg", zipcode: 42338 },
  { city: "Gothenburg", zipcode: 42339 },
  { city: "Gothenburg", zipcode: 42340 },
  { city: "Gothenburg", zipcode: 42341 },
  { city: "Gothenburg", zipcode: 42342 },
  { city: "Gothenburg", zipcode: 42343 },
  { city: "Gothenburg", zipcode: 42344 },
  { city: "Gothenburg", zipcode: 42345 },
  { city: "Gothenburg", zipcode: 42346 },
  { city: "Gothenburg", zipcode: 42347 },
  { city: "Gothenburg", zipcode: 42348 },
  { city: "Gothenburg", zipcode: 42349 },
  { city: "Gothenburg", zipcode: 42350 },
  { city: "Gothenburg", zipcode: 42351 },
  { city: "Gothenburg", zipcode: 42352 },
  { city: "Gothenburg", zipcode: 42353 },
  { city: "Gothenburg", zipcode: 42354 },
  { city: "Gothenburg", zipcode: 42355 },
  { city: "Gothenburg", zipcode: 42356 },
  { city: "Gothenburg", zipcode: 42357 },
  { city: "Gothenburg", zipcode: 42358 },
  { city: "Gothenburg", zipcode: 42359 },
  { city: "Gothenburg", zipcode: 42361 },
  { city: "Gothenburg", zipcode: 42363 },
  { city: "Gothenburg", zipcode: 42100 },
  { city: "Gothenburg", zipcode: 42101 },
  { city: "Gothenburg", zipcode: 42102 },
  { city: "Gothenburg", zipcode: 42108 },
  { city: "Gothenburg", zipcode: 42110 },
  { city: "Gothenburg", zipcode: 42121 },
  { city: "Gothenburg", zipcode: 42122 },
  { city: "Gothenburg", zipcode: 42123 },
  { city: "Gothenburg", zipcode: 42126 },
  { city: "Gothenburg", zipcode: 42130 },
  { city: "Gothenburg", zipcode: 42131 },
  { city: "Gothenburg", zipcode: 42132 },
  { city: "Gothenburg", zipcode: 42133 },
  { city: "Gothenburg", zipcode: 42134 },
  { city: "Gothenburg", zipcode: 42135 },
  { city: "Gothenburg", zipcode: 42136 },
  { city: "Gothenburg", zipcode: 42137 },
  { city: "Gothenburg", zipcode: 42138 },
  { city: "Gothenburg", zipcode: 42139 },
  { city: "Gothenburg", zipcode: 42140 },
  { city: "Gothenburg", zipcode: 42141 },
  { city: "Gothenburg", zipcode: 42142 },
  { city: "Gothenburg", zipcode: 42143 },
  { city: "Gothenburg", zipcode: 42144 },
  { city: "Gothenburg", zipcode: 42145 },
  { city: "Gothenburg", zipcode: 42146 },
  { city: "Gothenburg", zipcode: 42147 },
  { city: "Gothenburg", zipcode: 42148 },
  { city: "Gothenburg", zipcode: 42149 },
  { city: "Gothenburg", zipcode: 42150 },
  { city: "Gothenburg", zipcode: 42151 },
  { city: "Gothenburg", zipcode: 42152 },
  { city: "Gothenburg", zipcode: 42153 },
  { city: "Gothenburg", zipcode: 42154 },
  { city: "Gothenburg", zipcode: 42157 },
  { city: "Gothenburg", zipcode: 42158 },
  { city: "Gothenburg", zipcode: 42159 },
  { city: "Gothenburg", zipcode: 42160 },
  { city: "Gothenburg", zipcode: 42161 },
  { city: "Gothenburg", zipcode: 42162 },
  { city: "Gothenburg", zipcode: 42163 },
  { city: "Gothenburg", zipcode: 42164 },
  { city: "Gothenburg", zipcode: 42165 },
  { city: "Gothenburg", zipcode: 42166 },
  { city: "Gothenburg", zipcode: 42167 },
  { city: "Gothenburg", zipcode: 42170 },
  { city: "Gothenburg", zipcode: 42171 },
  { city: "Gothenburg", zipcode: 42172 },
  { city: "Gothenburg", zipcode: 42173 },
  { city: "Gothenburg", zipcode: 42174 },
  { city: "Gothenburg", zipcode: 42600 },
  { city: "Gothenburg", zipcode: 42604 },
  { city: "Gothenburg", zipcode: 42605 },
  { city: "Gothenburg", zipcode: 42650 },
  { city: "Gothenburg", zipcode: 42651 },
  { city: "Gothenburg", zipcode: 42652 },
  { city: "Gothenburg", zipcode: 42653 },
  { city: "Gothenburg", zipcode: 42654 },
  { city: "Gothenburg", zipcode: 42655 },
  { city: "Gothenburg", zipcode: 42656 },
  { city: "Gothenburg", zipcode: 42657 },
  { city: "Gothenburg", zipcode: 42658 },
  { city: "Gothenburg", zipcode: 42659 },
  { city: "Gothenburg", zipcode: 42668 },
  { city: "Gothenburg", zipcode: 42669 },
  { city: "Gothenburg", zipcode: 42670 },
  { city: "Gothenburg", zipcode: 42671 },
  { city: "Gothenburg", zipcode: 42672 },
  { city: "Gothenburg", zipcode: 42673 },
  { city: "Gothenburg", zipcode: 42674 },
  { city: "Gothenburg", zipcode: 42675 },
  { city: "Gothenburg", zipcode: 42676 },
  { city: "Gothenburg", zipcode: 42677 },
  { city: "Gothenburg", zipcode: 42678 },
  { city: "Gothenburg", zipcode: 42679 },
  { city: "Gothenburg", zipcode: 42680 },
  { city: "Gothenburg", zipcode: 43083 },
  { city: "Gothenburg", zipcode: 43084 },
  { city: "Gothenburg", zipcode: 42456 },
  { city: "Gothenburg", zipcode: 42457 },
  { city: "Gothenburg", zipcode: 42200 },
  { city: "Gothenburg", zipcode: 42202 },
  { city: "Gothenburg", zipcode: 42203 },
  { city: "Gothenburg", zipcode: 42204 },
  { city: "Gothenburg", zipcode: 42210 },
  { city: "Gothenburg", zipcode: 42241 },
  { city: "Gothenburg", zipcode: 42242 },
  { city: "Gothenburg", zipcode: 42243 },
  { city: "Gothenburg", zipcode: 42244 },
  { city: "Gothenburg", zipcode: 42245 },
  { city: "Gothenburg", zipcode: 42246 },
  { city: "Gothenburg", zipcode: 42247 },
  { city: "Gothenburg", zipcode: 42248 },
  { city: "Gothenburg", zipcode: 42249 },
  { city: "Gothenburg", zipcode: 42250 },
  { city: "Gothenburg", zipcode: 42251 },
  { city: "Gothenburg", zipcode: 42252 },
  { city: "Gothenburg", zipcode: 42253 },
  { city: "Gothenburg", zipcode: 42254 },
  { city: "Gothenburg", zipcode: 42255 },
  { city: "Gothenburg", zipcode: 42256 },
  { city: "Gothenburg", zipcode: 42257 },
  { city: "Gothenburg", zipcode: 42258 },
  { city: "Gothenburg", zipcode: 42259 },
  { city: "Gothenburg", zipcode: 42280 },
  { city: "Gothenburg", zipcode: 42500 },
  { city: "Gothenburg", zipcode: 42502 },
  { city: "Gothenburg", zipcode: 42508 },
  { city: "Gothenburg", zipcode: 42530 },
  { city: "Gothenburg", zipcode: 42531 },
  { city: "Gothenburg", zipcode: 42532 },
  { city: "Gothenburg", zipcode: 42533 },
  { city: "Gothenburg", zipcode: 42534 },
  { city: "Gothenburg", zipcode: 42535 },
  { city: "Gothenburg", zipcode: 42536 },
  { city: "Gothenburg", zipcode: 42537 },
  { city: "Gothenburg", zipcode: 42538 },
  { city: "Gothenburg", zipcode: 42539 },
  { city: "Gothenburg", zipcode: 42541 },
  { city: "Gothenburg", zipcode: 42542 },
  { city: "Gothenburg", zipcode: 42543 },
  { city: "Gothenburg", zipcode: 42565 },
  { city: "Gothenburg", zipcode: 43602 },
  { city: "Gothenburg", zipcode: 43650 },
  { city: "Gothenburg", zipcode: 43651 },
  { city: "Gothenburg", zipcode: 43652 },
  { city: "Gothenburg", zipcode: 43653 },
  { city: "Gothenburg", zipcode: 43654 },
  { city: "Gothenburg", zipcode: 43655 },
  { city: "Gothenburg", zipcode: 43656 },
  { city: "Gothenburg", zipcode: 43657 },
  { city: "Gothenburg", zipcode: 43658 },
  { city: "Gothenburg", zipcode: 43081 },
  { city: "Gothenburg", zipcode: 44970 },
  { city: "Gothenburg", zipcode: 42470 },
  { city: "Gothenburg", zipcode: 42471 },
  { city: "Gothenburg", zipcode: 42472 },
  { city: "Gothenburg", zipcode: 42490 },
  { city: "Gothenburg", zipcode: 42491 },
  { city: "Gothenburg", zipcode: 42370 },
  { city: "Gothenburg", zipcode: 42371 },
  { city: "Gothenburg", zipcode: 42372 },
  { city: "Gothenburg", zipcode: 42373 },
  { city: "Gothenburg", zipcode: 43084 },
  { city: "Gothenburg", zipcode: 42300 },
  { city: "Gothenburg", zipcode: 42321 },
  { city: "Gothenburg", zipcode: 42322 },
  { city: "Gothenburg", zipcode: 42323 },
  { city: "Gothenburg", zipcode: 42332 },
  { city: "Gothenburg", zipcode: 42333 },
  { city: "Gothenburg", zipcode: 42334 },
  { city: "Gothenburg", zipcode: 42335 },
  { city: "Gothenburg", zipcode: 42336 },
  { city: "Gothenburg", zipcode: 42337 },
  { city: "Gothenburg", zipcode: 42338 },
  { city: "Gothenburg", zipcode: 42339 },
  { city: "Gothenburg", zipcode: 42340 },
  { city: "Gothenburg", zipcode: 42341 },
  { city: "Gothenburg", zipcode: 42342 },
  { city: "Gothenburg", zipcode: 42343 },
  { city: "Gothenburg", zipcode: 42344 },
  { city: "Gothenburg", zipcode: 42345 },
  { city: "Gothenburg", zipcode: 42346 },
  { city: "Gothenburg", zipcode: 42347 },
  { city: "Gothenburg", zipcode: 42348 },
  { city: "Gothenburg", zipcode: 42349 },
  { city: "Gothenburg", zipcode: 42350 },
  { city: "Gothenburg", zipcode: 42351 },
  { city: "Gothenburg", zipcode: 42352 },
  { city: "Gothenburg", zipcode: 42353 },
  { city: "Gothenburg", zipcode: 42354 },
  { city: "Gothenburg", zipcode: 42355 },
  { city: "Gothenburg", zipcode: 42356 },
  { city: "Gothenburg", zipcode: 42357 },
  { city: "Gothenburg", zipcode: 42358 },
  { city: "Gothenburg", zipcode: 42359 },
  { city: "Gothenburg", zipcode: 42361 },
  { city: "Gothenburg", zipcode: 42363 },
  { city: "Gothenburg", zipcode: 42100 },
  { city: "Gothenburg", zipcode: 42101 },
  { city: "Gothenburg", zipcode: 42102 },
  { city: "Gothenburg", zipcode: 42108 },
  { city: "Gothenburg", zipcode: 42110 },
  { city: "Gothenburg", zipcode: 42121 },
  { city: "Gothenburg", zipcode: 42122 },
  { city: "Gothenburg", zipcode: 42123 },
  { city: "Gothenburg", zipcode: 42126 },
  { city: "Gothenburg", zipcode: 42130 },
  { city: "Gothenburg", zipcode: 42131 },
  { city: "Gothenburg", zipcode: 42132 },
  { city: "Gothenburg", zipcode: 42133 },
  { city: "Gothenburg", zipcode: 42134 },
  { city: "Gothenburg", zipcode: 42135 },
  { city: "Gothenburg", zipcode: 42136 },
  { city: "Gothenburg", zipcode: 42137 },
  { city: "Gothenburg", zipcode: 42138 },
  { city: "Gothenburg", zipcode: 42139 },
  { city: "Gothenburg", zipcode: 42140 },
  { city: "Gothenburg", zipcode: 42141 },
  { city: "Gothenburg", zipcode: 42142 },
  { city: "Gothenburg", zipcode: 42143 },
  { city: "Gothenburg", zipcode: 42144 },
  { city: "Gothenburg", zipcode: 42145 },
  { city: "Gothenburg", zipcode: 42146 },
  { city: "Gothenburg", zipcode: 42147 },
  { city: "Gothenburg", zipcode: 42148 },
  { city: "Gothenburg", zipcode: 42149 },
  { city: "Gothenburg", zipcode: 42150 },
  { city: "Gothenburg", zipcode: 42151 },
  { city: "Gothenburg", zipcode: 42152 },
  { city: "Gothenburg", zipcode: 42153 },
  { city: "Gothenburg", zipcode: 42154 },
  { city: "Gothenburg", zipcode: 42157 },
  { city: "Gothenburg", zipcode: 42158 },
  { city: "Gothenburg", zipcode: 42159 },
  { city: "Gothenburg", zipcode: 42160 },
  { city: "Gothenburg", zipcode: 42161 },
  { city: "Gothenburg", zipcode: 42162 },
  { city: "Gothenburg", zipcode: 42163 },
  { city: "Gothenburg", zipcode: 42164 },
  { city: "Gothenburg", zipcode: 42165 },
  { city: "Gothenburg", zipcode: 42166 },
  { city: "Gothenburg", zipcode: 42167 },
  { city: "Gothenburg", zipcode: 42170 },
  { city: "Gothenburg", zipcode: 42171 },
  { city: "Gothenburg", zipcode: 42172 },
  { city: "Gothenburg", zipcode: 42173 },
  { city: "Gothenburg", zipcode: 42174 },
  { city: "Gothenburg", zipcode: 42600 },
  { city: "Gothenburg", zipcode: 42604 },
  { city: "Gothenburg", zipcode: 42605 },
  { city: "Gothenburg", zipcode: 42650 },
  { city: "Gothenburg", zipcode: 42651 },
  { city: "Gothenburg", zipcode: 42652 },
  { city: "Gothenburg", zipcode: 42653 },
  { city: "Gothenburg", zipcode: 42654 },
  { city: "Gothenburg", zipcode: 42655 },
  { city: "Gothenburg", zipcode: 42656 },
  { city: "Gothenburg", zipcode: 42657 },
  { city: "Gothenburg", zipcode: 42658 },
  { city: "Gothenburg", zipcode: 42659 },
  { city: "Gothenburg", zipcode: 42668 },
  { city: "Gothenburg", zipcode: 42669 },
  { city: "Gothenburg", zipcode: 42670 },
  { city: "Gothenburg", zipcode: 42671 },
  { city: "Gothenburg", zipcode: 42672 },
  { city: "Gothenburg", zipcode: 42673 },
  { city: "Gothenburg", zipcode: 42674 },
  { city: "Gothenburg", zipcode: 42675 },
  { city: "Gothenburg", zipcode: 42676 },
  { city: "Gothenburg", zipcode: 42677 },
  { city: "Gothenburg", zipcode: 42678 },
  { city: "Gothenburg", zipcode: 42679 },
  { city: "Gothenburg", zipcode: 42680 },
  { city: "Gothenburg", zipcode: 43083 },
  { city: "Västerås", zipcode: 64045 },
  { city: "Västerås", zipcode: 72001 },
  { city: "Västerås", zipcode: 72002 },
  { city: "Västerås", zipcode: 72003 },
  { city: "Västerås", zipcode: 72007 },
  { city: "Västerås", zipcode: 72010 },
  { city: "Västerås", zipcode: 72011 },
  { city: "Västerås", zipcode: 72012 },
  { city: "Västerås", zipcode: 72013 },
  { city: "Västerås", zipcode: 72015 },
  { city: "Västerås", zipcode: 72016 },
  { city: "Västerås", zipcode: 72017 },
  { city: "Västerås", zipcode: 72018 },
  { city: "Västerås", zipcode: 72019 },
  { city: "Västerås", zipcode: 72020 },
  { city: "Västerås", zipcode: 72100 },
  { city: "Västerås", zipcode: 72101 },
  { city: "Västerås", zipcode: 72103 },
  { city: "Västerås", zipcode: 72104 },
  { city: "Västerås", zipcode: 72105 },
  { city: "Västerås", zipcode: 72106 },
  { city: "Västerås", zipcode: 72107 },
  { city: "Västerås", zipcode: 72108 },
  { city: "Västerås", zipcode: 72109 },
  { city: "Västerås", zipcode: 72110 },
  { city: "Västerås", zipcode: 72111 },
  { city: "Västerås", zipcode: 72119 },
  { city: "Västerås", zipcode: 72120 },
  { city: "Västerås", zipcode: 72122 },
  { city: "Västerås", zipcode: 72123 },
  { city: "Västerås", zipcode: 72126 },
  { city: "Västerås", zipcode: 72127 },
  { city: "Västerås", zipcode: 72128 },
  { city: "Västerås", zipcode: 72129 },
  { city: "Västerås", zipcode: 72130 },
  { city: "Västerås", zipcode: 72131 },
  { city: "Västerås", zipcode: 72132 },
  { city: "Västerås", zipcode: 72133 },
  { city: "Västerås", zipcode: 72134 },
  { city: "Västerås", zipcode: 72135 },
  { city: "Västerås", zipcode: 72136 },
  { city: "Västerås", zipcode: 72137 },
  { city: "Västerås", zipcode: 72138 },
  { city: "Västerås", zipcode: 72150 },
  { city: "Västerås", zipcode: 72151 },
  { city: "Västerås", zipcode: 72153 },
  { city: "Västerås", zipcode: 72157 },
  { city: "Västerås", zipcode: 72159 },
  { city: "Västerås", zipcode: 72161 },
  { city: "Västerås", zipcode: 72162 },
  { city: "Västerås", zipcode: 72163 },
  { city: "Västerås", zipcode: 72164 },
  { city: "Västerås", zipcode: 72165 },
  { city: "Västerås", zipcode: 72166 },
  { city: "Västerås", zipcode: 72167 },
  { city: "Västerås", zipcode: 72168 },
  { city: "Västerås", zipcode: 72169 },
  { city: "Västerås", zipcode: 72170 },
  { city: "Västerås", zipcode: 72171 },
  { city: "Västerås", zipcode: 72173 },
  { city: "Västerås", zipcode: 72174 },
  { city: "Västerås", zipcode: 72175 },
  { city: "Västerås", zipcode: 72176 },
  { city: "Västerås", zipcode: 72178 },
  { city: "Västerås", zipcode: 72182 },
  { city: "Västerås", zipcode: 72183 },
  { city: "Västerås", zipcode: 72184 },
  { city: "Västerås", zipcode: 72185 },
  { city: "Västerås", zipcode: 72186 },
  { city: "Västerås", zipcode: 72187 },
  { city: "Västerås", zipcode: 72189 },
  { city: "Västerås", zipcode: 72201 },
  { city: "Västerås", zipcode: 72210 },
  { city: "Västerås", zipcode: 72211 },
  { city: "Västerås", zipcode: 72212 },
  { city: "Västerås", zipcode: 72213 },
  { city: "Västerås", zipcode: 72214 },
  { city: "Västerås", zipcode: 72215 },
  { city: "Västerås", zipcode: 72216 },
  { city: "Västerås", zipcode: 72217 },
  { city: "Västerås", zipcode: 72218 },
  { city: "Västerås", zipcode: 72219 },
  { city: "Västerås", zipcode: 72220 },
  { city: "Västerås", zipcode: 72221 },
  { city: "Västerås", zipcode: 72222 },
  { city: "Västerås", zipcode: 72223 },
  { city: "Västerås", zipcode: 72224 },
  { city: "Västerås", zipcode: 72225 },
  { city: "Västerås", zipcode: 72226 },
  { city: "Västerås", zipcode: 72227 },
  { city: "Västerås", zipcode: 72228 },
  { city: "Västerås", zipcode: 72231 },
  { city: "Västerås", zipcode: 72233 },
  { city: "Västerås", zipcode: 72240 },
  { city: "Västerås", zipcode: 72241 },
  { city: "Västerås", zipcode: 72242 },
  { city: "Västerås", zipcode: 72243 },
  { city: "Västerås", zipcode: 72244 },
  { city: "Västerås", zipcode: 72245 },
  { city: "Västerås", zipcode: 72246 },
  { city: "Västerås", zipcode: 72247 },
  { city: "Västerås", zipcode: 72301 },
  { city: "Västerås", zipcode: 72334 },
  { city: "Västerås", zipcode: 72335 },
  { city: "Västerås", zipcode: 72336 },
  { city: "Västerås", zipcode: 72337 },
  { city: "Västerås", zipcode: 72338 },
  { city: "Västerås", zipcode: 72339 },
  { city: "Västerås", zipcode: 72340 },
  { city: "Västerås", zipcode: 72341 },
  { city: "Västerås", zipcode: 72342 },
  { city: "Västerås", zipcode: 72343 },
  { city: "Västerås", zipcode: 72344 },
  { city: "Västerås", zipcode: 72345 },
  { city: "Västerås", zipcode: 72346 },
  { city: "Västerås", zipcode: 72347 },
  { city: "Västerås", zipcode: 72348 },
  { city: "Västerås", zipcode: 72349 },
  { city: "Västerås", zipcode: 72350 },
  { city: "Västerås", zipcode: 72351 },
  { city: "Västerås", zipcode: 72352 },
  { city: "Västerås", zipcode: 72353 },
  { city: "Västerås", zipcode: 72354 },
  { city: "Västerås", zipcode: 72355 },
  { city: "Västerås", zipcode: 72356 },
  { city: "Västerås", zipcode: 72357 },
  { city: "Västerås", zipcode: 72358 },
  { city: "Västerås", zipcode: 72359 },
  { city: "Västerås", zipcode: 72401 },
  { city: "Västerås", zipcode: 72402 },
  { city: "Västerås", zipcode: 72455 },
  { city: "Västerås", zipcode: 72460 },
  { city: "Västerås", zipcode: 72461 },
  { city: "Västerås", zipcode: 72462 },
  { city: "Västerås", zipcode: 72463 },
  { city: "Västerås", zipcode: 72464 },
  { city: "Västerås", zipcode: 72465 },
  { city: "Västerås", zipcode: 72466 },
  { city: "Västerås", zipcode: 72467 },
  { city: "Västerås", zipcode: 72468 },
  { city: "Västerås", zipcode: 72469 },
  { city: "Västerås", zipcode: 72470 },
  { city: "Västerås", zipcode: 72471 },
  { city: "Västerås", zipcode: 72472 },
  { city: "Västerås", zipcode: 72473 },
  { city: "Västerås", zipcode: 72474 },
  { city: "Västerås", zipcode: 72475 },
  { city: "Västerås", zipcode: 72476 },
  { city: "Västerås", zipcode: 72477 },
  { city: "Västerås", zipcode: 72478 },
  { city: "Västerås", zipcode: 72479 },
  { city: "Västerås", zipcode: 72480 },
  { city: "Västerås", zipcode: 72481 },
  { city: "Västerås", zipcode: 72482 },
  { city: "Västerås", zipcode: 72483 },
  { city: "Västerås", zipcode: 72484 },
  { city: "Västerås", zipcode: 72485 },
  { city: "Västerås", zipcode: 72486 },
  { city: "Västerås", zipcode: 72487 },
  { city: "Västerås", zipcode: 72488 },
  { city: "Västerås", zipcode: 72489 },
  { city: "Västerås", zipcode: 72590 },
  { city: "Västerås", zipcode: 72591 },
  { city: "Västerås", zipcode: 72592 },
  { city: "Västerås", zipcode: 72593 },
  { city: "Västerås", zipcode: 72594 },
  { city: "Västerås", zipcode: 72595 },
  { city: "Västerås", zipcode: 72596 },
  { city: "Västerås", zipcode: 72597 },
  { city: "Västerås", zipcode: 72598 },
  { city: "Västerås", zipcode: 72599 },
  { city: "Malmö", zipcode: 23834 },
  { city: "Malmö", zipcode: 23843 },
  { city: "Malmö", zipcode: 23842 },
  { city: "Malmö", zipcode: 23841 },
  { city: "Malmö", zipcode: 23840 },
  { city: "Malmö", zipcode: 23839 },
  { city: "Malmö", zipcode: 23838 },
  { city: "Malmö", zipcode: 23837 },
  { city: "Malmö", zipcode: 23836 },
  { city: "Malmö", zipcode: 23835 },
  { city: "Malmö", zipcode: 23833 },
  { city: "Malmö", zipcode: 23832 },
  { city: "Malmö", zipcode: 23831 },
  { city: "Malmö", zipcode: 23830 },
  { city: "Malmö", zipcode: 23823 },
  { city: "Malmö", zipcode: 23822 },
  { city: "Malmö", zipcode: 23821 },
  { city: "Malmö", zipcode: 23801 },
  { city: "Uppsala", zipcode: 75272 },
  { city: "Uppsala", zipcode: 75273 },
  { city: "Uppsala", zipcode: 75275 },
  { city: "Uppsala", zipcode: 75219 },
  { city: "Uppsala", zipcode: 75220 },
  { city: "Uppsala", zipcode: 75265 },
  { city: "Uppsala", zipcode: 75268 },
  { city: "Uppsala", zipcode: 75271 },
  { city: "Uppsala", zipcode: 75300 },
  { city: "Uppsala", zipcode: 75301 },
  { city: "Uppsala", zipcode: 75302 },
  { city: "Uppsala", zipcode: 75303 },
  { city: "Uppsala", zipcode: 75309 },
  { city: "Uppsala", zipcode: 75310 },
  { city: "Uppsala", zipcode: 75311 },
  { city: "Uppsala", zipcode: 75312 },
  { city: "Uppsala", zipcode: 75313 },
  { city: "Uppsala", zipcode: 75314 },
  { city: "Uppsala", zipcode: 75315 },
  { city: "Uppsala", zipcode: 75318 },
  { city: "Uppsala", zipcode: 75319 },
  { city: "Uppsala", zipcode: 75320 },
  { city: "Uppsala", zipcode: 75321 },
  { city: "Uppsala", zipcode: 75322 },
  { city: "Uppsala", zipcode: 75323 },
  { city: "Uppsala", zipcode: 75324 },
  { city: "Uppsala", zipcode: 75325 },
  { city: "Uppsala", zipcode: 75326 },
  { city: "Uppsala", zipcode: 75327 },
  { city: "Uppsala", zipcode: 75328 },
  { city: "Uppsala", zipcode: 75329 },
  { city: "Uppsala", zipcode: 75330 },
  { city: "Uppsala", zipcode: 75331 },
  { city: "Uppsala", zipcode: 75332 },
  { city: "Uppsala", zipcode: 75333 },
  { city: "Uppsala", zipcode: 75334 },
  { city: "Uppsala", zipcode: 75335 },
  { city: "Uppsala", zipcode: 75336 },
  { city: "Uppsala", zipcode: 75337 },
  { city: "Uppsala", zipcode: 75340 },
  { city: "Uppsala", zipcode: 75341 },
  { city: "Uppsala", zipcode: 75342 },
  { city: "Uppsala", zipcode: 75350 },
  { city: "Uppsala", zipcode: 75375 },
  { city: "Uppsala", zipcode: 75380 },
  { city: "Uppsala", zipcode: 75381 },
  { city: "Uppsala", zipcode: 75382 },
  { city: "Uppsala", zipcode: 75383 },
  { city: "Uppsala", zipcode: 75308 },
  { city: "Uppsala", zipcode: 75316 },
  { city: "Uppsala", zipcode: 75317 },
  { city: "Uppsala", zipcode: 75400 },
  { city: "Uppsala", zipcode: 75419 },
  { city: "Uppsala", zipcode: 75420 },
  { city: "Uppsala", zipcode: 75421 },
  { city: "Uppsala", zipcode: 75422 },
  { city: "Uppsala", zipcode: 75423 },
  { city: "Uppsala", zipcode: 75424 },
  { city: "Uppsala", zipcode: 75425 },
  { city: "Uppsala", zipcode: 75426 },
  { city: "Uppsala", zipcode: 75427 },
  { city: "Uppsala", zipcode: 75428 },
  { city: "Uppsala", zipcode: 75429 },
  { city: "Uppsala", zipcode: 75430 },
  { city: "Uppsala", zipcode: 75431 },
  { city: "Uppsala", zipcode: 75432 },
  { city: "Uppsala", zipcode: 75433 },
  { city: "Uppsala", zipcode: 75434 },
  { city: "Uppsala", zipcode: 75435 },
  { city: "Uppsala", zipcode: 75436 },
  { city: "Uppsala", zipcode: 75437 },
  { city: "Uppsala", zipcode: 75439 },
  { city: "Uppsala", zipcode: 75440 },
  { city: "Uppsala", zipcode: 75441 },
  { city: "Uppsala", zipcode: 75442 },
  { city: "Uppsala", zipcode: 75444 },
  { city: "Uppsala", zipcode: 75445 },
  { city: "Uppsala", zipcode: 75446 },
  { city: "Uppsala", zipcode: 75447 },
  { city: "Uppsala", zipcode: 75448 },
  { city: "Uppsala", zipcode: 75449 },
  { city: "Uppsala", zipcode: 75450 },
  { city: "Uppsala", zipcode: 75451 },
  { city: "Uppsala", zipcode: 75452 },
  { city: "Uppsala", zipcode: 75460 },
  { city: "Uppsala", zipcode: 75471 },
  { city: "Uppsala", zipcode: 75473 },
  { city: "Uppsala", zipcode: 75474 },
  { city: "Uppsala", zipcode: 75418 },
  { city: "Uppsala", zipcode: 75443 },
  { city: "Uppsala", zipcode: 75453 },
  { city: "Uppsala", zipcode: 75454 },
  { city: "Uppsala", zipcode: 75500 },
  { city: "Uppsala", zipcode: 75550 },
  { city: "Uppsala", zipcode: 75591 },
  { city: "Uppsala", zipcode: 75592 },
  { city: "Uppsala", zipcode: 75593 },
  { city: "Uppsala", zipcode: 75594 },
  { city: "Uppsala", zipcode: 75595 },
  { city: "Uppsala", zipcode: 75596 },
  { city: "Uppsala", zipcode: 75597 },
  { city: "Uppsala", zipcode: 75598 },
  { city: "Uppsala", zipcode: 75600 },
  { city: "Uppsala", zipcode: 75643 },
  { city: "Uppsala", zipcode: 75644 },
  { city: "Uppsala", zipcode: 75645 },
  { city: "Uppsala", zipcode: 75646 },
  { city: "Uppsala", zipcode: 75647 },
  { city: "Uppsala", zipcode: 75648 },
  { city: "Uppsala", zipcode: 75649 },
  { city: "Uppsala", zipcode: 75650 },
  { city: "Uppsala", zipcode: 75651 },
  { city: "Uppsala", zipcode: 75652 },
  { city: "Uppsala", zipcode: 75653 },
  { city: "Uppsala", zipcode: 75654 },
  { city: "Uppsala", zipcode: 75655 },
  { city: "Uppsala", zipcode: 75656 },
  { city: "Uppsala", zipcode: 75657 },
  { city: "Uppsala", zipcode: 75658 },
  { city: "Uppsala", zipcode: 75659 },
  { city: "Uppsala", zipcode: 75642 },
  { city: "Uppsala", zipcode: 75700 },
  { city: "Uppsala", zipcode: 75752 },
  { city: "Uppsala", zipcode: 75753 },
  { city: "Uppsala", zipcode: 75754 },
  { city: "Uppsala", zipcode: 75755 },
  { city: "Uppsala", zipcode: 75756 },
  { city: "Uppsala", zipcode: 75757 },
  { city: "Uppsala", zipcode: 75758 },
  { city: "Uppsala", zipcode: 75759 },
  { city: "Uppsala", zipcode: 75800 },
  { city: "Uppsala", zipcode: 75801 },
  { city: "Uppsala", zipcode: 75802 },
  { city: "Uppsala", zipcode: 75803 },
  { city: "Uppsala", zipcode: 75804 },
  { city: "Uppsala", zipcode: 75806 },
  { city: "Uppsala", zipcode: 75808 },
  { city: "Uppsala", zipcode: 75809 },
  { city: "Uppsala", zipcode: 75810 },
  { city: "Uppsala", zipcode: 75813 },
  { city: "Uppsala", zipcode: 75815 },
  { city: "Uppsala", zipcode: 75816 },
  { city: "Uppsala", zipcode: 75822 },
  { city: "Uppsala", zipcode: 75823 },
  { city: "Uppsala", zipcode: 75824 },
  { city: "Uppsala", zipcode: 75825 },
  { city: "Uppsala", zipcode: 75826 },
  { city: "Uppsala", zipcode: 75852 },
  { city: "Uppsala", zipcode: 75853 },
  { city: "Uppsala", zipcode: 75854 },
  { city: "Uppsala", zipcode: 75855 },
  { city: "Uppsala", zipcode: 75856 },
  { city: "Uppsala", zipcode: 75857 },
  { city: "Uppsala", zipcode: 75871 },
  { city: "Uppsala", zipcode: 75872 },
  { city: "Uppsala", zipcode: 75873 },
  { city: "Uppsala", zipcode: 75900 },
  { city: "Uppsala", zipcode: 74020 },
  { city: "Uppsala", zipcode: 75576 },
  { city: "Uppsala", zipcode: 75577 },
  { city: "Uppsala", zipcode: 75578 },
  { city: "Uppsala", zipcode: 74350 },
  { city: "Uppsala", zipcode: 74392 },
  { city: "Uppsala", zipcode: 74393 },
  { city: "Uppsala", zipcode: 74319 },
  { city: "Uppsala", zipcode: 74793 },
  { city: "Uppsala", zipcode: 74794 },
  { city: "Uppsala", zipcode: 74720 },
  { city: "Uppsala", zipcode: 74022 },
  { city: "Uppsala", zipcode: 74305 },
  { city: "Uppsala", zipcode: 74381 },
  { city: "Uppsala", zipcode: 74386 },
  { city: "Uppsala", zipcode: 74387 },
  { city: "Uppsala", zipcode: 74382 },
  { city: "Uppsala", zipcode: 74620 },
  { city: "Uppsala", zipcode: 74030 },
  { city: "Uppsala", zipcode: 74310 },
  { city: "Uppsala", zipcode: 74361 },
  { city: "Uppsala", zipcode: 74362 },
  { city: "Uppsala", zipcode: 74363 },
  { city: "Uppsala", zipcode: 74364 },
  { city: "Uppsala", zipcode: 74371 },
  { city: "Uppsala", zipcode: 74372 },
  { city: "Uppsala", zipcode: 74373 },
  { city: "Uppsala", zipcode: 74374 },
  { city: "Uppsala", zipcode: 76031 },
  { city: "Uppsala", zipcode: 76297 },
  { city: "Uppsala", zipcode: 76298 },
  { city: "Uppsala", zipcode: 74950 },
  { city: "Uppsala", zipcode: 74520 },
  { city: "Uppsala", zipcode: 74581 },
  { city: "Uppsala", zipcode: 74583 },
  { city: "Uppsala", zipcode: 74586 },
  { city: "Uppsala", zipcode: 74900 },
  { city: "Uppsala", zipcode: 74935 },
  { city: "Uppsala", zipcode: 74940 },
  { city: "Uppsala", zipcode: 74941 },
  { city: "Uppsala", zipcode: 74942 },
  { city: "Uppsala", zipcode: 74943 },
  { city: "Uppsala", zipcode: 74944 },
  { city: "Uppsala", zipcode: 74945 },
  { city: "Uppsala", zipcode: 74946 },
  { city: "Uppsala", zipcode: 74947 },
  { city: "Uppsala", zipcode: 74948 },
  { city: "Uppsala", zipcode: 74949 },
  { city: "Uppsala", zipcode: 74959 },
  { city: "Uppsala", zipcode: 74981 },
  { city: "Uppsala", zipcode: 74719 },
  { city: "Uppsala", zipcode: 74296 },
  { city: "Uppsala", zipcode: 74297 },
  { city: "Uppsala", zipcode: 74951 },
  { city: "Uppsala", zipcode: 74952 },
  { city: "Uppsala", zipcode: 74219 },
  { city: "Uppsala", zipcode: 74120 },
  { city: "Uppsala", zipcode: 74145 },
  { city: "Uppsala", zipcode: 74012 },
  { city: "Uppsala", zipcode: 74198 },
  { city: "Uppsala", zipcode: 74011 },
  { city: "Uppsala", zipcode: 74819 },
  { city: "Uppsala", zipcode: 74241 },
  { city: "Uppsala", zipcode: 74242 },
  { city: "Uppsala", zipcode: 74906 },
  { city: "Uppsala", zipcode: 74960 },
  { city: "Uppsala", zipcode: 74961 },
  { city: "Uppsala", zipcode: 74962 },
  { city: "Uppsala", zipcode: 74963 },
  { city: "Uppsala", zipcode: 74820 },
  { city: "Uppsala", zipcode: 74220 },
  { city: "Uppsala", zipcode: 74232 },
  { city: "Uppsala", zipcode: 76296 },
  { city: "Uppsala", zipcode: 81420 },
  { city: "Uppsala", zipcode: 74394 },
  { city: "Uppsala", zipcode: 81519 },
  { city: "Uppsala", zipcode: 74301 },
  { city: "Uppsala", zipcode: 74321 },
  { city: "Uppsala", zipcode: 74322 },
  { city: "Uppsala", zipcode: 74330 },
  { city: "Uppsala", zipcode: 74332 },
  { city: "Uppsala", zipcode: 74334 },
  { city: "Uppsala", zipcode: 74335 },
  { city: "Uppsala", zipcode: 74340 },
  { city: "Uppsala", zipcode: 74341 },
  { city: "Uppsala", zipcode: 74345 },
  { city: "Uppsala", zipcode: 74391 },
  { city: "Uppsala", zipcode: 74320 },
  { city: "Uppsala", zipcode: 81520 },
  { city: "Uppsala", zipcode: 81535 },
  { city: "Uppsala", zipcode: 81581 },
  { city: "Uppsala", zipcode: 74818 },
  { city: "Uppsala", zipcode: 74151 },
  { city: "Uppsala", zipcode: 74152 },
  { city: "Uppsala", zipcode: 74174 },
  { city: "Uppsala", zipcode: 74176 },
  { city: "Uppsala", zipcode: 74177 },
  { city: "Uppsala", zipcode: 74178 },
  { city: "Uppsala", zipcode: 74395 },
  { city: "Uppsala", zipcode: 75002 },
  { city: "Uppsala", zipcode: 75003 },
  { city: "Uppsala", zipcode: 75006 },
  { city: "Uppsala", zipcode: 75007 },
  { city: "Uppsala", zipcode: 75008 },
  { city: "Uppsala", zipcode: 75009 },
  { city: "Uppsala", zipcode: 75010 },
  { city: "Uppsala", zipcode: 75011 },
  { city: "Uppsala", zipcode: 75013 },
  { city: "Uppsala", zipcode: 75015 },
  { city: "Uppsala", zipcode: 75016 },
  { city: "Uppsala", zipcode: 75017 },
  { city: "Uppsala", zipcode: 75020 },
  { city: "Uppsala", zipcode: 75021 },
  { city: "Uppsala", zipcode: 75022 },
  { city: "Uppsala", zipcode: 75023 },
  { city: "Uppsala", zipcode: 75024 },
  { city: "Uppsala", zipcode: 75025 },
  { city: "Uppsala", zipcode: 75026 },
  { city: "Uppsala", zipcode: 75004 },
  { city: "Uppsala", zipcode: 75100 },
  { city: "Uppsala", zipcode: 75101 },
  { city: "Uppsala", zipcode: 75103 },
  { city: "Uppsala", zipcode: 75104 },
  { city: "Uppsala", zipcode: 75105 },
  { city: "Uppsala", zipcode: 75106 },
  { city: "Uppsala", zipcode: 75108 },
  { city: "Uppsala", zipcode: 75109 },
  { city: "Uppsala", zipcode: 75110 },
  { city: "Uppsala", zipcode: 75120 },
  { city: "Uppsala", zipcode: 75121 },
  { city: "Uppsala", zipcode: 75122 },
  { city: "Uppsala", zipcode: 75123 },
  { city: "Uppsala", zipcode: 75124 },
  { city: "Uppsala", zipcode: 75125 },
  { city: "Uppsala", zipcode: 75126 },
  { city: "Uppsala", zipcode: 75127 },
  { city: "Uppsala", zipcode: 75128 },
  { city: "Uppsala", zipcode: 75129 },
  { city: "Uppsala", zipcode: 75130 },
  { city: "Uppsala", zipcode: 75131 },
  { city: "Uppsala", zipcode: 75132 },
  { city: "Uppsala", zipcode: 75133 },
  { city: "Uppsala", zipcode: 75134 },
  { city: "Uppsala", zipcode: 75135 },
  { city: "Uppsala", zipcode: 75136 },
  { city: "Uppsala", zipcode: 75137 },
  { city: "Uppsala", zipcode: 75138 },
  { city: "Uppsala", zipcode: 75140 },
  { city: "Uppsala", zipcode: 75141 },
  { city: "Uppsala", zipcode: 75142 },
  { city: "Uppsala", zipcode: 75143 },
  { city: "Uppsala", zipcode: 75144 },
  { city: "Uppsala", zipcode: 75145 },
  { city: "Uppsala", zipcode: 75146 },
  { city: "Uppsala", zipcode: 75147 },
  { city: "Uppsala", zipcode: 75148 },
  { city: "Uppsala", zipcode: 75149 },
  { city: "Uppsala", zipcode: 75170 },
  { city: "Uppsala", zipcode: 75171 },
  { city: "Uppsala", zipcode: 75172 },
  { city: "Uppsala", zipcode: 75173 },
  { city: "Uppsala", zipcode: 75174 },
  { city: "Uppsala", zipcode: 75175 },
  { city: "Uppsala", zipcode: 75176 },
  { city: "Uppsala", zipcode: 75181 },
  { city: "Uppsala", zipcode: 75182 },
  { city: "Uppsala", zipcode: 75183 },
  { city: "Uppsala", zipcode: 75184 },
  { city: "Uppsala", zipcode: 75185 },
  { city: "Uppsala", zipcode: 75186 },
  { city: "Uppsala", zipcode: 75187 },
  { city: "Uppsala", zipcode: 75188 },
  { city: "Uppsala", zipcode: 75189 },
  { city: "Uppsala", zipcode: 75200 },
  { city: "Uppsala", zipcode: 75217 },
  { city: "Uppsala", zipcode: 75218 },
  { city: "Uppsala", zipcode: 75221 },
  { city: "Uppsala", zipcode: 75222 },
  { city: "Uppsala", zipcode: 75223 },
  { city: "Uppsala", zipcode: 75224 },
  { city: "Uppsala", zipcode: 75225 },
  { city: "Uppsala", zipcode: 75226 },
  { city: "Uppsala", zipcode: 75227 },
  { city: "Uppsala", zipcode: 75228 },
  { city: "Uppsala", zipcode: 75229 },
  { city: "Uppsala", zipcode: 75230 },
  { city: "Uppsala", zipcode: 75231 },
  { city: "Uppsala", zipcode: 75232 },
  { city: "Uppsala", zipcode: 75233 },
  { city: "Uppsala", zipcode: 75234 },
  { city: "Uppsala", zipcode: 75235 },
  { city: "Uppsala", zipcode: 75236 },
  { city: "Uppsala", zipcode: 75237 },
  { city: "Uppsala", zipcode: 75238 },
  { city: "Uppsala", zipcode: 75239 },
  { city: "Uppsala", zipcode: 75240 },
  { city: "Uppsala", zipcode: 75241 },
  { city: "Uppsala", zipcode: 75242 },
  { city: "Uppsala", zipcode: 75243 },
  { city: "Uppsala", zipcode: 75244 },
  { city: "Uppsala", zipcode: 75257 },
  { city: "Uppsala", zipcode: 75258 },
  { city: "Uppsala", zipcode: 75260 },
  { city: "Uppsala", zipcode: 75261 },
  { city: "Uppsala", zipcode: 75262 },
  { city: "Uppsala", zipcode: 75263 },
  { city: "Uppsala", zipcode: 75264 },
  { city: "Uppsala", zipcode: 75266 },
  { city: "Uppsala", zipcode: 75267 },
  { city: "Uppsala", zipcode: 75269 },
  { city: "Uppsala", zipcode: 75271 },
  { city: "Linköping", zipcode: 58100 },
  { city: "Linköping", zipcode: 58101 },
  { city: "Linköping", zipcode: 58102 },
  { city: "Linköping", zipcode: 58103 },
  { city: "Linköping", zipcode: 58104 },
  { city: "Linköping", zipcode: 58105 },
  { city: "Linköping", zipcode: 58106 },
  { city: "Linköping", zipcode: 58107 },
  { city: "Linköping", zipcode: 58110 },
  { city: "Linköping", zipcode: 58111 },
  { city: "Linköping", zipcode: 58112 },
  { city: "Linköping", zipcode: 58113 },
  { city: "Linköping", zipcode: 58114 },
  { city: "Linköping", zipcode: 58115 },
  { city: "Linköping", zipcode: 58116 },
  { city: "Linköping", zipcode: 58117 },
  { city: "Linköping", zipcode: 58118 },
  { city: "Linköping", zipcode: 58119 },
  { city: "Linköping", zipcode: 58121 },
  { city: "Linköping", zipcode: 58128 },
  { city: "Linköping", zipcode: 58131 },
  { city: "Linköping", zipcode: 58133 },
  { city: "Linköping", zipcode: 58180 },
  { city: "Linköping", zipcode: 58181 },
  { city: "Linköping", zipcode: 58182 },
  { city: "Linköping", zipcode: 58183 },
  { city: "Linköping", zipcode: 58184 },
  { city: "Linköping", zipcode: 58185 },
  { city: "Linköping", zipcode: 58186 },
  { city: "Linköping", zipcode: 58187 },
  { city: "Linköping", zipcode: 58188 },
  { city: "Linköping", zipcode: 58189 },
  { city: "Linköping", zipcode: 58190 },
  { city: "Linköping", zipcode: 58191 },
  { city: "Linköping", zipcode: 58192 },
  { city: "Linköping", zipcode: 58193 },
  { city: "Linköping", zipcode: 58194 },
  { city: "Linköping", zipcode: 58195 },
  { city: "Linköping", zipcode: 58196 },
  { city: "Linköping", zipcode: 58197 },
  { city: "Linköping", zipcode: 58198 },
  { city: "Linköping", zipcode: 58200 },
  { city: "Linköping", zipcode: 58201 },
  { city: "Linköping", zipcode: 58202 },
  { city: "Linköping", zipcode: 58203 },
  { city: "Linköping", zipcode: 58210 },
  { city: "Linköping", zipcode: 58212 },
  { city: "Linköping", zipcode: 58213 },
  { city: "Linköping", zipcode: 58214 },
  { city: "Linköping", zipcode: 58215 },
  { city: "Linköping", zipcode: 58216 },
  { city: "Linköping", zipcode: 58217 },
  { city: "Linköping", zipcode: 58218 },
  { city: "Linköping", zipcode: 58219 },
  { city: "Linköping", zipcode: 58220 },
  { city: "Linköping", zipcode: 58221 },
  { city: "Linköping", zipcode: 58222 },
  { city: "Linköping", zipcode: 58223 },
  { city: "Linköping", zipcode: 58224 },
  { city: "Linköping", zipcode: 58225 },
  { city: "Linköping", zipcode: 58226 },
  { city: "Linköping", zipcode: 58227 },
  { city: "Linköping", zipcode: 58228 },
  { city: "Linköping", zipcode: 58229 },
  { city: "Linköping", zipcode: 58230 },
  { city: "Linköping", zipcode: 58231 },
  { city: "Linköping", zipcode: 58232 },
  { city: "Linköping", zipcode: 58233 },
  { city: "Linköping", zipcode: 58234 },
  { city: "Linköping", zipcode: 58235 },
  { city: "Linköping", zipcode: 58236 },
  { city: "Linköping", zipcode: 58237 },
  { city: "Linköping", zipcode: 58238 },
  { city: "Linköping", zipcode: 58239 },
  { city: "Linköping", zipcode: 58240 },
  { city: "Linköping", zipcode: 58241 },
  { city: "Linköping", zipcode: 58242 },
  { city: "Linköping", zipcode: 58243 },
  { city: "Linköping", zipcode: 58244 },
  { city: "Linköping", zipcode: 58245 },
  { city: "Linköping", zipcode: 58246 },
  { city: "Linköping", zipcode: 58247 },
  { city: "Linköping", zipcode: 58248 },
  { city: "Linköping", zipcode: 58249 },
  { city: "Linköping", zipcode: 58252 },
  { city: "Linköping", zipcode: 58253 },
  { city: "Linköping", zipcode: 58254 },
  { city: "Linköping", zipcode: 58255 },
  { city: "Linköping", zipcode: 58256 },
  { city: "Linköping", zipcode: 58272 },
  { city: "Linköping", zipcode: 58273 },
  { city: "Linköping", zipcode: 58274 },
  { city: "Linköping", zipcode: 58275 },
  { city: "Linköping", zipcode: 58276 },
  { city: "Linköping", zipcode: 58277 },
  { city: "Linköping", zipcode: 58278 },
  { city: "Linköping", zipcode: 58300 },
  { city: "Linköping", zipcode: 58328 },
  { city: "Linköping", zipcode: 58330 },
  { city: "Linköping", zipcode: 58331 },
  { city: "Linköping", zipcode: 58332 },
  { city: "Linköping", zipcode: 58333 },
  { city: "Linköping", zipcode: 58334 },
  { city: "Linköping", zipcode: 58335 },
  { city: "Linköping", zipcode: 58336 },
  { city: "Linköping", zipcode: 58337 },
  { city: "Linköping", zipcode: 58338 },
  { city: "Linköping", zipcode: 58400 },
  { city: "Linköping", zipcode: 58422 },
  { city: "Linköping", zipcode: 58431 },
  { city: "Linköping", zipcode: 58432 },
  { city: "Linköping", zipcode: 58434 },
  { city: "Linköping", zipcode: 58435 },
  { city: "Linköping", zipcode: 58436 },
  { city: "Linköping", zipcode: 58437 },
  { city: "Linköping", zipcode: 58439 },
  { city: "Linköping", zipcode: 58500 },
  { city: "Linköping", zipcode: 58591 },
  { city: "Linköping", zipcode: 58592 },
  { city: "Linköping", zipcode: 58593 },
  { city: "Linköping", zipcode: 58594 },
  { city: "Linköping", zipcode: 58595 },
  { city: "Linköping", zipcode: 58596 },
  { city: "Linköping", zipcode: 58597 },
  { city: "Linköping", zipcode: 58598 },
  { city: "Linköping", zipcode: 58599 },
  { city: "Linköping", zipcode: 58600 },
  { city: "Linköping", zipcode: 58642 },
  { city: "Linköping", zipcode: 58643 },
  { city: "Linköping", zipcode: 58644 },
  { city: "Linköping", zipcode: 58645 },
  { city: "Linköping", zipcode: 58646 },
  { city: "Linköping", zipcode: 58647 },
  { city: "Linköping", zipcode: 58648 },
  { city: "Linköping", zipcode: 58649 },
  { city: "Linköping", zipcode: 58650 },
  { city: "Linköping", zipcode: 58651 },
  { city: "Linköping", zipcode: 58652 },
  { city: "Linköping", zipcode: 58653 },
  { city: "Linköping", zipcode: 58654 },
  { city: "Linköping", zipcode: 58655 },
  { city: "Linköping", zipcode: 58656 },
  { city: "Linköping", zipcode: 58657 },
  { city: "Linköping", zipcode: 58658 },
  { city: "Linköping", zipcode: 58659 },
  { city: "Linköping", zipcode: 58642 },
  { city: "Linköping", zipcode: 58700 },
  { city: "Linköping", zipcode: 58721 },
  { city: "Linköping", zipcode: 58723 },
  { city: "Linköping", zipcode: 58724 },
  { city: "Linköping", zipcode: 58725 },
  { city: "Linköping", zipcode: 58726 },
  { city: "Linköping", zipcode: 58727 },
  { city: "Linköping", zipcode: 58729 },
  { city: "Linköping", zipcode: 58731 },
  { city: "Linköping", zipcode: 58732 },
  { city: "Linköping", zipcode: 58733 },
  { city: "Linköping", zipcode: 58734 },
  { city: "Linköping", zipcode: 58735 },
  { city: "Linköping", zipcode: 58736 },
  { city: "Linköping", zipcode: 58737 },
  { city: "Linköping", zipcode: 58738 },
  { city: "Linköping", zipcode: 58739 },
  { city: "Linköping", zipcode: 58752 },
  { city: "Linköping", zipcode: 58900 },
  { city: "Linköping", zipcode: 58921 },
  { city: "Linköping", zipcode: 58923 },
  { city: "Linköping", zipcode: 58925 },
  { city: "Linköping", zipcode: 58927 },
  { city: "Linköping", zipcode: 58929 },
  { city: "Linköping", zipcode: 58931 },
  { city: "Linköping", zipcode: 58933 },
  { city: "Linköping", zipcode: 58935 },
  { city: "Linköping", zipcode: 58937 },
  { city: "Linköping", zipcode: 58939 },
  { city: "Linköping", zipcode: 58941 },
  { city: "Linköping", zipcode: 58943 },
  { city: "Linköping", zipcode: 58950 },
  { city: "Linköping", zipcode: 58951 },
  { city: "Linköping", zipcode: 58952 },
  { city: "Linköping", zipcode: 58953 },
  { city: "Linköping", zipcode: 58955 },
  { city: "Linköping", zipcode: 58957 },
  { city: "Linköping", zipcode: 58507 },
  { city: "Linköping", zipcode: 58571 },
  { city: "Linköping", zipcode: 58572 },
  { city: "Linköping", zipcode: 58573 },
  { city: "Linköping", zipcode: 58574 },
  { city: "Linköping", zipcode: 58575 },
  { city: "Linköping", zipcode: 58580 },
  { city: "Linköping", zipcode: 59069 },
  { city: "Linköping", zipcode: 59070 },
  { city: "Linköping", zipcode: 59071 },
  { city: "Linköping", zipcode: 59072 },
  { city: "Linköping", zipcode: 59073 },
  { city: "Linköping", zipcode: 59074 },
  { city: "Norrköping", zipcode: 60379 },
  { city: "Norrköping", zipcode: 60380 },
  { city: "Norrköping", zipcode: 60381 },
  { city: "Norrköping", zipcode: 60383 },
  { city: "Norrköping", zipcode: 60385 },
  { city: "Norrköping", zipcode: 60386 },
  { city: "Norrköping", zipcode: 60387 },
  { city: "Norrköping", zipcode: 60400 },
  { city: "Norrköping", zipcode: 60500 },
  { city: "Norrköping", zipcode: 60591 },
  { city: "Norrköping", zipcode: 60592 },
  { city: "Norrköping", zipcode: 60593 },
  { city: "Norrköping", zipcode: 60594 },
  { city: "Norrköping", zipcode: 60595 },
  { city: "Norrköping", zipcode: 60596 },
  { city: "Norrköping", zipcode: 60597 },
  { city: "Norrköping", zipcode: 60598 },
  { city: "Norrköping", zipcode: 60599 },
  { city: "Norrköping", zipcode: 60600 },
  { city: "Norrköping", zipcode: 60700 },
  { city: "Norrköping", zipcode: 60800 },
  { city: "Norrköping", zipcode: 60910 },
  { city: "Norrköping", zipcode: 60911 },
  { city: "Norrköping", zipcode: 61700 },
  { city: "Norrköping", zipcode: 61701 },
  { city: "Norrköping", zipcode: 61710 },
  { city: "Norrköping", zipcode: 61721 },
  { city: "Norrköping", zipcode: 61722 },
  { city: "Norrköping", zipcode: 61730 },
  { city: "Norrköping", zipcode: 61731 },
  { city: "Norrköping", zipcode: 61732 },
  { city: "Norrköping", zipcode: 61734 },
  { city: "Norrköping", zipcode: 61790 },
  { city: "Norrköping", zipcode: 61800 },
  { city: "Norrköping", zipcode: 61801 },
  { city: "Norrköping", zipcode: 61821 },
  { city: "Norrköping", zipcode: 61822 },
  { city: "Norrköping", zipcode: 61823 },
  { city: "Norrköping", zipcode: 61830 },
  { city: "Norrköping", zipcode: 61831 },
  { city: "Norrköping", zipcode: 61832 },
  { city: "Norrköping", zipcode: 61833 },
  { city: "Norrköping", zipcode: 61834 },
  { city: "Norrköping", zipcode: 61835 },
  { city: "Norrköping", zipcode: 61892 },
  { city: "Norrköping", zipcode: 61893 },
  { city: "Norrköping", zipcode: 59078 },
  { city: "Norrköping", zipcode: 59062 },
  { city: "Norrköping", zipcode: 60000 },
  { city: "Norrköping", zipcode: 60002 },
  { city: "Norrköping", zipcode: 60003 },
  { city: "Norrköping", zipcode: 60004 },
  { city: "Norrköping", zipcode: 60005 },
  { city: "Norrköping", zipcode: 60006 },
  { city: "Norrköping", zipcode: 60007 },
  { city: "Norrköping", zipcode: 60011 },
  { city: "Norrköping", zipcode: 60012 },
  { city: "Norrköping", zipcode: 60013 },
  { city: "Norrköping", zipcode: 60014 },
  { city: "Norrköping", zipcode: 60040 },
  { city: "Norrköping", zipcode: 60041 },
  { city: "Norrköping", zipcode: 60042 },
  { city: "Norrköping", zipcode: 60043 },
  { city: "Norrköping", zipcode: 60044 },
  { city: "Norrköping", zipcode: 60045 },
  { city: "Norrköping", zipcode: 60046 },
  { city: "Norrköping", zipcode: 60047 },
  { city: "Norrköping", zipcode: 60100 },
  { city: "Norrköping", zipcode: 60102 },
  { city: "Norrköping", zipcode: 60103 },
  { city: "Norrköping", zipcode: 60104 },
  { city: "Norrköping", zipcode: 60105 },
  { city: "Norrköping", zipcode: 60107 },
  { city: "Norrköping", zipcode: 60110 },
  { city: "Norrköping", zipcode: 60114 },
  { city: "Norrköping", zipcode: 60115 },
  { city: "Norrköping", zipcode: 60116 },
  { city: "Norrköping", zipcode: 60117 },
  { city: "Norrköping", zipcode: 60119 },
  { city: "Norrköping", zipcode: 60160 },
  { city: "Norrköping", zipcode: 60170 },
  { city: "Norrköping", zipcode: 60171 },
  { city: "Norrköping", zipcode: 60172 },
  { city: "Norrköping", zipcode: 60174 },
  { city: "Norrköping", zipcode: 60175 },
  { city: "Norrköping", zipcode: 60176 },
  { city: "Norrköping", zipcode: 60177 },
  { city: "Norrköping", zipcode: 60178 },
  { city: "Norrköping", zipcode: 60179 },
  { city: "Norrköping", zipcode: 60180 },
  { city: "Norrköping", zipcode: 60181 },
  { city: "Norrköping", zipcode: 60182 },
  { city: "Norrköping", zipcode: 60183 },
  { city: "Norrköping", zipcode: 60184 },
  { city: "Norrköping", zipcode: 60185 },
  { city: "Norrköping", zipcode: 60186 },
  { city: "Norrköping", zipcode: 60188 },
  { city: "Norrköping", zipcode: 60189 },
  { city: "Norrköping", zipcode: 60200 },
  { city: "Norrköping", zipcode: 60201 },
  { city: "Norrköping", zipcode: 60202 },
  { city: "Norrköping", zipcode: 60203 },
  { city: "Norrköping", zipcode: 60204 },
  { city: "Norrköping", zipcode: 60205 },
  { city: "Norrköping", zipcode: 60206 },
  { city: "Norrköping", zipcode: 60208 },
  { city: "Norrköping", zipcode: 60209 },
  { city: "Norrköping", zipcode: 60210 },
  { city: "Norrköping", zipcode: 60211 },
  { city: "Norrköping", zipcode: 60212 },
  { city: "Norrköping", zipcode: 60213 },
  { city: "Norrköping", zipcode: 60214 },
  { city: "Norrköping", zipcode: 60215 },
  { city: "Norrköping", zipcode: 60216 },
  { city: "Norrköping", zipcode: 60217 },
  { city: "Norrköping", zipcode: 60218 },
  { city: "Norrköping", zipcode: 60219 },
  { city: "Norrköping", zipcode: 60220 },
  { city: "Norrköping", zipcode: 60221 },
  { city: "Norrköping", zipcode: 60222 },
  { city: "Norrköping", zipcode: 60223 },
  { city: "Norrköping", zipcode: 60224 },
  { city: "Norrköping", zipcode: 60225 },
  { city: "Norrköping", zipcode: 60226 },
  { city: "Norrköping", zipcode: 60227 },
  { city: "Norrköping", zipcode: 60228 },
  { city: "Norrköping", zipcode: 60229 },
  { city: "Norrköping", zipcode: 60230 },
  { city: "Norrköping", zipcode: 60231 },
  { city: "Norrköping", zipcode: 60232 },
  { city: "Norrköping", zipcode: 60233 },
  { city: "Norrköping", zipcode: 60234 },
  { city: "Norrköping", zipcode: 60235 },
  { city: "Norrköping", zipcode: 60236 },
  { city: "Norrköping", zipcode: 60237 },
  { city: "Norrköping", zipcode: 60238 },
  { city: "Norrköping", zipcode: 60239 },
  { city: "Norrköping", zipcode: 60240 },
  { city: "Norrköping", zipcode: 60241 },
  { city: "Norrköping", zipcode: 60242 },
  { city: "Norrköping", zipcode: 60243 },
  { city: "Norrköping", zipcode: 60244 },
  { city: "Norrköping", zipcode: 60245 },
  { city: "Norrköping", zipcode: 60246 },
  { city: "Norrköping", zipcode: 60247 },
  { city: "Norrköping", zipcode: 60248 },
  { city: "Norrköping", zipcode: 60249 },
  { city: "Norrköping", zipcode: 60250 },
  { city: "Norrköping", zipcode: 60251 },
  { city: "Norrköping", zipcode: 60252 },
  { city: "Norrköping", zipcode: 60253 },
  { city: "Norrköping", zipcode: 60254 },
  { city: "Norrköping", zipcode: 60255 },
  { city: "Norrköping", zipcode: 60256 },
  { city: "Norrköping", zipcode: 60257 },
  { city: "Norrköping", zipcode: 60258 },
  { city: "Norrköping", zipcode: 60272 },
  { city: "Norrköping", zipcode: 60273 },
  { city: "Norrköping", zipcode: 60274 },
  { city: "Norrköping", zipcode: 60275 },
  { city: "Norrköping", zipcode: 60276 },
  { city: "Norrköping", zipcode: 60277 },
  { city: "Norrköping", zipcode: 60278 },
  { city: "Norrköping", zipcode: 60279 },
  { city: "Norrköping", zipcode: 60300 },
  { city: "Norrköping", zipcode: 60301 },
  { city: "Norrköping", zipcode: 60302 },
  { city: "Norrköping", zipcode: 60303 },
  { city: "Norrköping", zipcode: 60336 },
  { city: "Norrköping", zipcode: 60337 },
  { city: "Norrköping", zipcode: 60345 },
  { city: "Norrköping", zipcode: 60347 },
  { city: "Norrköping", zipcode: 60348 },
  { city: "Norrköping", zipcode: 60349 },
  { city: "Norrköping", zipcode: 60350 },
  { city: "Norrköping", zipcode: 60351 },
  { city: "Norrköping", zipcode: 60352 },
  { city: "Norrköping", zipcode: 60353 },
  { city: "Norrköping", zipcode: 60354 },
  { city: "Norrköping", zipcode: 60355 },
  { city: "Norrköping", zipcode: 60356 },
  { city: "Norrköping", zipcode: 60357 },
  { city: "Norrköping", zipcode: 60358 },
  { city: "Norrköping", zipcode: 60359 },
  { city: "Norrköping", zipcode: 60360 },
  { city: "Norrköping", zipcode: 60361 },
  { city: "Norrköping", zipcode: 60362 },
  { city: "Norrköping", zipcode: 60363 },
  { city: "Norrköping", zipcode: 60364 },
  { city: "Norrköping", zipcode: 60365 },
  { city: "Norrköping", zipcode: 60366 },
  { city: "Norrköping", zipcode: 60367 },
  { city: "Norrköping", zipcode: 60368 },
  { city: "Norrköping", zipcode: 60369 },
  { city: "Norrköping", zipcode: 60370 },
  { city: "Norrköping", zipcode: 60371 },
  { city: "Norrköping", zipcode: 60372 },
  { city: "Norrköping", zipcode: 60373 },
  { city: "Norrköping", zipcode: 60374 },
  { city: "Norrköping", zipcode: 60375 },
  { city: "Norrköping", zipcode: 60376 },
  { city: "Norrköping", zipcode: 60377 },
  { city: "Norrköping", zipcode: 60378 },
];
const TIME = [
  "00:00 - 01:00",
  "01:00 - 02:00",
  "02:00 - 03:00",
  "03:00 - 04:00",
  "04:00 - 05:00",
  "05:00 - 06:00",
  "06:00 - 07:00",
  "07:00 - 08:00",
  "08:00 - 09:00",
  "09:00 - 10:00",
  "10:00 - 11:00",
  "11:00 - 12:00",
  "12:00 - 13:00",
  "13:00 - 14:00",
  "14:00 - 15:00",
  "15:00 - 16:00",
  "16:00 - 17:00",
  "17:00 - 18:00",
  "18:00 - 19:00",
  "19:00 - 20:00",
  "20:00 - 21:00",
  "21:00 - 22:00",
  "22:00 - 23:00",
  "23:00 - 24:00",
]
module.exports = {
  ZIPCODES,
  ZIPCODES1,
  TIME
}