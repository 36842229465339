import react, { useState,useEffect } from "react"
import Image1 from '../../../../Assets/images/cleaning-of-the-estate.jpg'
import { FaTimes } from "react-icons/fa"
import { Button } from "@chakra-ui/react"

import { useTranslation } from 'react-i18next';

const CleaningOfTheEstate = () => {
    
    const { t } = useTranslation();

    const [modalOpen, setModalOpen] = useState(false);

    return (
        <div id="cleaning_of_the_estate" className="flex flex-row-full justify-center items-center sm:mt-20">
            <style jsx>{`
                .heading {
                    animation: pulse 1s infinite;
                }
                .preview-button{
                    transition: bottom 0.5s ease;
                }
                .image-div:hover .preview-button{
                    bottom: 20px;
                }
            `}</style>
            <div className="flex flex-col sm:flex-row justify-center items-center w-5/6 mb-16 text-left">
                <div className='w-full sm:w-3/6 overflow-hidden'>
                    <div className="w-full h-[300px] sm:h-[400px] flex justify-center items-end background-cover bg-no-repeat bg-cover overflow-hidden transition-transform transform hover:scale-105 duration-300 image-div relative" style={{backgroundImage:`url(${Image1})`}}>
                    <Button style={{ background: "black" }} onClick={() => setModalOpen(true)} className="h-[30px] rounded-lg px-4 pt-2 overflow-hidden relative bottom-[-50px] group cursor-pointer border-2 font-medium text-[16px] border-white text-black hover:scale-105 duration-[900ms] hover:border-[#003942] hover:text-[white] preview-button">
                        <span className="absolute w-72 h-0 transition-all duration-[900ms] origin-center rotate-45 -translate-x-20 bg-white top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                        <span className="relative text-xl text-white transition duration-[900ms] group-hover:text-black ease mb-2">{t('generalComponents.preview')}</span>
                    </Button>
                    </div>
                </div>
                {/* <div className="w-1/6 h-[200px] background-cover bg-no-repeat bg-cover" style={{backgroundImage:`url(${Image2})`}}>1</div> */}
                <div className="w-full sm:w-1/6 text-[100px] bg-gradient-to-b from-purple-500 via-pink-500 to-red-500 text-transparent bg-clip-text font-bold text-center mt-0">3</div>
                <div className="w-full sm:w-2/6">
                    <div className="text-4xl font-bold text-center sm:text-left text-[#000] mb-4 w-full sm:w-5/6 sm:ml-4">
                        <h2><span className='text-red-600 heading'>{t('dodsbo.third.headingPart1')}</span> {t('dodsbo.third.headingPart2')}</h2>
                    </div>
                    <div className="text-center sm:text-left sm:ml-4 my-2">
                        {t('dodsbo.third.paragraph')}
                    </div>
                </div>
            </div>
            {modalOpen && (
                <div style={{zIndex: 9999}} className="z-5000 fixed top-[30px] left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="fadeIn bg-white p-4 rounded-lg relative z-5001">
                        <div onClick={() => setModalOpen(false)} className='absolute top-0 right-0 m-2 p-2 cursor-pointer rounded-full border-2 border-orange-600 text-orange-600 bg-white text-center'>
                           <FaTimes />
                        </div>
                        <img src={Image1} alt="Full Size" className="h-[500px] rounded-lg"/>
                        <button onClick={() => setModalOpen(false)}>{t('generalComponents.close')}</button>
                    </div>
                </div>
            )}
        </div>
    );

}

export default CleaningOfTheEstate;