import React, { useState, useEffect } from "react";
import useTranslatePage from './../../Gernal_Components/TranslatePage';
import Loader from './../../Gernal_Components/Loader';

import Note from "../MoveoutCleaning/Note";
import CrossIcon from "../../Gernal_Components/CrossIcon";
import { toast, ToastContainer } from "react-toastify";
import emailjs from "emailjs-com";
import { useNavigate } from "react-router";
import { Textarea } from '@chakra-ui/react';
import { Link } from "react-router-dom";

import { useTranslation } from 'react-i18next';

const OfficeRelocationContact = () => {
    
    const { t } = useTranslation();
  
    const [companyName, setCompanyName] = useState("");
    const [corporateNumber, setCorporateNumber] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [email, setEmail] = useState("");
    const [fromStreet, setFromStreet] = useState("");
    const [fromCity, setFromCity] = useState("");
    const [fromArea, setFromArea] = useState("");
    const [toStreet, setToStreet] = useState("");
    const [toCity, setToCity] = useState("");
    const [toArea, setToArea] = useState("");
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const validateForm = () => {
        const errors = {};
        if (!companyName.trim()) errors.companyName = "Company name is required";
        if (!corporateNumber.trim() || !/^\d{10}$/.test(corporateNumber)) errors.corporateNumber = "Corporate number is required and it should be 10 digits";
        if (!firstName.trim()) errors.firstName = "First name is required";
        if (!lastName.trim()) errors.lastName = "Last name is required";
        if (!mobileNumber.trim() || !/^07[\d]{8}$/.test(mobileNumber)) errors.mobileNumber = "Mobile number is required and it should start with 07 and be 10 digits long";
        if (!email.trim() || !/^\S+@\S+\.\S+$/.test(email)) errors.email = "Email address is required and it should be in correct format";
        if (!fromStreet.trim()) errors.fromStreet = "Current Street is required";
        if (!fromCity.trim()) errors.fromCity = "Current City is required";
        if (!fromArea.trim()) errors.fromArea = "Current Area is required";
        if (!toStreet.trim()) errors.toStreet = "New Street is required";
        if (!toCity.trim()) errors.toCity = "New City is required";
        if (!toArea.trim()) errors.toArea = "New Area is required";
        if (!message.trim()) errors.message = "Message is required";
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const [translatePage, loading] = useTranslatePage();
  
      useEffect(() => {
          const lang = localStorage.getItem('language');
          if (lang) {
          translatePage(lang);
          }
      }, []);
    

    const handleSubmit = async () => {
        if (!validateForm()) return;

        try {
            const messageBody = `
            Office Relocation Request:
            Company Name: ${companyName}
            Corporate Number: ${corporateNumber}
            First Name: ${firstName}
            Last Name: ${lastName}
            Mobile Number: ${mobileNumber}
            Current Street Number: ${fromStreet}
            Current City Name: ${fromCity}
            Current Area: ${fromArea}
            New Street Number: ${toStreet}
            New City Name: ${toCity}
            New Area: ${toArea}
            Message: ${message}
            Thank you for your interest in our services! We'll be in touch shortly to discuss how we can assist you further.
            `;
            const templateParams = {
                to_name: `${firstName}  ${lastName} `,
                from_name: "Code Zone",
                to_email: email,
                subject: "Office Relocation Request:",
                message: messageBody,
            };
            const response = await emailjs.send(
                "service_m774pph",
                "template_qsec7n7",
                templateParams,
                "rc1ba_PvLbgEIUjbt"
            );
            console.log("Email sent successfully:", response);
            if (response) {
                toast.success(`${firstName} ${lastName} Your Office Relocation Request Sent Successfully`);
                setCompanyName("");
                setCorporateNumber("");
                setFirstName("");
                setLastName("");
                setMobileNumber("");
                setEmail("");
                setFromStreet("");
                setFromCity("");
                setFromArea("");
                setToStreet("");
                setToCity("");
                setToArea("");
                setMessage("");
                document.getElementById("companyName").value = "";
                document.getElementById("corporateNumber").value = "";
                document.getElementById("firstName").value = "";
                document.getElementById("lastName").value = "";
                document.getElementById("mobileNumber").value = "";
                document.getElementById("email").value = "";
                document.getElementById("fromStreet").value = "";
                document.getElementById("fromCity").value = "";
                document.getElementById("fromArea").value = "";
                document.getElementById("toStreet").value = "";
                document.getElementById("toCity").value = "";
                document.getElementById("toArea").value = "";
                document.getElementById("message").value = "";
                document.getElementById("cat").checked = false;
                document.getElementById("dog").checked = false;
                document.getElementById("other").checked = false;
            }
        } catch (error) {
            console.error("Error sending email:", error);
        }
    };

    return (
        <div className="w-full flex justify-center items-center py-20">
        {loading && <Loader />}
            <div className="w-5/6 mdw-4/6 flex flex-col justify-center items-center">
                <div className="w-full md:w-4/6 flex justify-end">
                    <div className="hover:shake">
                        <CrossIcon handleClickCrossIcon={() => navigate("/office_relocation")}/>
                    </div>
                </div>
                <div className="w-full md:w-4/6 font-normal text-5xl" style={{ fontFamily: "Tiempos Headline,serif" }}>
                {t('contactPages.officeRelocation')}
                </div>
                <div className="w-full mt-8  flex justify-center items-center mb-5">
                    <p className=" border-black border w-20 text-center hover:w-[400PX] hover:border-gray-500  transition-all duration-300 transform origin-center scale-100"></p>
                </div>
                <div className="w-full md:w-4/6 rounded-lg p-4">
                    <label htmlFor="companyName" className="text-xl font-semibold flex justify-left mb-2 ms-1">
                    {t('contactPages.companyName')}
                    </label>
                    <input
                        type="text"
                        id="companyName"
                        onChange={(e) => setCompanyName(e.target.value)}

                        name="companyName"
                        className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                        placeholder="Enter your company name"
                    />
                    {errors.companyName && <div className="text-red-500">{errors.companyName}</div>}
                    <label htmlFor="corporateNumber" className="text-xl font-semibold flex justify-left mb-2 ms-1">
                    {t('contactPages.corporateNumber')}
                    </label>
                    <input
                        type="text"
                        id="corporateNumber"
                        onChange={(e) => setCorporateNumber(e.target.value)}

                        name="corporateNumber"
                        className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                        placeholder="Enter your corporate number"
                    />
                    {errors.corporateNumber && <div className="text-red-500">{errors.corporateNumber}</div>}
                    <label htmlFor="firstName" className="text-xl font-semibold flex justify-left mb-2 ms-1">
                    {t('contactPages.firstName')}:
                    </label>
                    <input
                        type="text"
                        id="firstName"
                        onChange={(e) => setFirstName(e.target.value)}

                        name="firstName"
                        className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                        placeholder="Enter your first name"
                    />
                    {errors.firstName && <div className="text-red-500">{errors.firstName}</div>}
                    <label htmlFor="lastName" className="text-xl font-semibold flex justify-left mb-2 ms-1">
                    {t('contactPages.lastName')}:
                    </label>
                    <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        onChange={(e) => setLastName(e.target.value)}

                        className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                        placeholder="Enter your last name"
                    />
                    {errors.lastName && <div className="text-red-500">{errors.lastName}</div>}
                    <label htmlFor="mobileNumber" className="text-xl font-semibold flex justify-left mb-2 ms-1">
                    {t('contactPages.mobileNumber')}
                    </label>
                    <input
                        type="number"
                        id="mobileNumber"
                        name="mobileNumber"
                        onChange={(e) => setMobileNumber(e.target.value)}

                        className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                        placeholder="Enter your mobile number"
                    />
                    {errors.mobileNumber && <div className="text-red-500">{errors.mobileNumber}</div>}
                    <label htmlFor="email" className="text-xl font-semibold flex justify-left mb-2 ms-1">
                    {t('contactPages.email')}:
                    </label>
                    <input
                        type="email"
                        id="email"
                        onChange={(e) => setEmail(e.target.value)}

                        name="email"
                        className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                        placeholder="Enter your email"
                    />
                    {errors.email && <div className="text-red-500">{errors.email}</div>}
                    <h2 className="text-3xl font-bold">{t('contactPages.weAreMovingFrom')}</h2>
                    <label htmlFor="fromStreet" className="text-xl font-semibold flex justify-left mb-2 ms-1">
                    {t('contactPages.fromStreet')}
                    </label>
                    <input
                        type="text"
                        id="fromStreet"
                        onChange={(e) => setFromStreet(e.target.value)}

                        name="fromStreet"
                        className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                        placeholder="Enter your Street"
                    />
                    {errors.fromStreet && <div className="text-red-500">{errors.fromStreet}</div>}
                    <label htmlFor="fromCity" className="text-xl font-semibold flex justify-left mb-2 ms-1">
                    {t('contactPages.fromCity')}
                    </label>
                    <input
                        type="text"
                        id="fromCity"
                        onChange={(e) => setFromCity(e.target.value)}

                        name="fromCity"
                        className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                        placeholder="Enter your City"
                    />
                    {errors.fromCity && <div className="text-red-500">{errors.fromCity}</div>}
                    <label htmlFor="fromArea" className="text-xl font-semibold flex justify-left mb-2 ms-1">
                    {t('contactPages.fromArea')}
                    </label>
                    <input
                        type="text"
                        id="fromArea"
                        onChange={(e) => setFromArea(e.target.value)}

                        name="fromArea"
                        className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                        placeholder="Enter your Area"
                    />
                    {errors.fromArea && <div className="text-red-500">{errors.fromArea}</div>}
                    <h2 className="text-3xl font-bold">{t('contactPages.weAreMovingTo')}</h2>
                    <label htmlFor="toStreet" className="text-xl font-semibold flex justify-left mb-2 ms-1">
                    {t('contactPages.toStreet')}
                    </label>
                    <input
                        type="text"
                        id="toStreet"
                        onChange={(e) => setToStreet(e.target.value)}

                        name="toStreet"
                        className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                        placeholder="Enter desired street"
                    />
                    {errors.toStreet && <div className="text-red-500">{errors.toStreet}</div>}
                    <label htmlFor="toCity" className="text-xl font-semibold flex justify-left mb-2 ms-1">
                    {t('contactPages.toCity')}
                    </label>
                    <input
                        type="text"
                        id="toCity"
                        onChange={(e) => setToCity(e.target.value)}

                        name="toCity"
                        className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                        placeholder="Enter Desired city"
                    />
                    {errors.toCity && <div className="text-red-500">{errors.toCity}</div>}
                    <label htmlFor="toArea" className="text-xl font-semibold flex justify-left mb-2 ms-1">
                    {t('contactPages.toArea')}
                    </label>
                    <input
                        type="text"
                        id="toArea"
                        onChange={(e) => setToArea(e.target.value)}

                        name="toArea"
                        className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                        placeholder="Enter desired area"
                    />
                    {errors.toArea && <div className="text-red-500">{errors.toArea}</div>}
                    <label
                        htmlFor="message"
                        className="text-xl font-semibold flex justify-left mb-1 ms-1"
                    >
                        {t('contactPages.specificMessage')}
                    </label>
                    <div className="flex items-center mb-3">
                        <Textarea
                            type="text"
                            name="message"
                            onChange={(e) => setMessage(e.target.value)}
                            id="message"
                            className="border w-full"
                            style={{display: 'block',background: '#FFFFFF'}} />
                    </div>
                    {errors.message && <div className="text-red-500">{errors.message}</div>}
                    <div className="mt-10" style={{ display: "flex", justifyContent: "space-evenly" }}>
                        <button
                            style={{ padding: "15px 50px 15px 50px" }}
                            className="bg-[#000] font-bold uppercase text-white p-5 rounded text-bold"
                            onClick={handleSubmit} >
                            {t('contactPages.contactMe')}
                        </button>
                    </div>
                    <div className="flex justify-center items-center mt-6 font-semibold"><p>{t('contactPages.theServiceIsCoveredByFSA')} <Link className="text-blue-600">{t('contactPages.termsAndConditions')}</Link></p></div>
                    <div className="flex justify-center items-center mt-6 text-gray-500"><p>{t('contactPages.fillTheseFieldsToContinue')}</p></div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default OfficeRelocationContact