
import { Link, useNavigate } from "react-router-dom";
import { SiMinutemailer } from "react-icons/si";
import { FiMail } from "react-icons/fi";
import { BiUser } from "react-icons/bi";
import { MdSubject } from "react-icons/md";
import { AiOutlineMessage } from "react-icons/ai";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import emailjs from "emailjs-com";

import { useTranslation } from 'react-i18next';

const Footer = () => {

    const { t, i18n } = useTranslation();
  
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };
  
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [fullName, setFullName] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    const navigate = useNavigate();

    /*  const handleClick = () => {
         // Scroll to the top of the page
         window.scrollTo(0, 0);
 
         // Now, navigate to the desired route
         navigate("/MovingService");
     }; */
    const isValidEmail = (email) => {
        // Email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (
            !fullName ||
            !email ||
            !subject ||
            !message

        ) {
            toast.error("Please fill in all required fields.");
            return;
        }
        if (!isValidEmail(email)) {
            toast.error("Please enter a valid email address.");
            return;
        }


        try {
            const messageBody = `
            Get in touch with us!:
             
                 Name: ${fullName} 
                 Email: ${email}
                 Subject: ${subject}
                 Message: ${message}
                
             `;
            const templateParams = {
                to_name: `${fullName}`,
                from_name: "Code Zone",
                to_email: email,
                subject: "Get in touch with us!",
                message: messageBody,
            };
            const response = await emailjs.send(
                "service_m774pph",
                "template_qsec7n7",
                templateParams,
                "rc1ba_PvLbgEIUjbt"
            );
            console.log("Email sent successfully:", response);
            if (response) {
                toast.success("Email Sent Successfully");

                setEmail("");

                setFullName("");
                setMessage("");
                setSubject("");


                document.getElementById("cat").checked = false;
                document.getElementById("dog").checked = false;
                document.getElementById("other").checked = false;
            }
        } catch (error) {
            console.error("Error sending email:", error);
/*             toast.error("Something Went wrong please try again");
 */        }
    };

    const handleClick = () => {
        // Scroll to the top of the page
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        // Introduce a delay (you can adjust the duration)
        const delayDuration = 500; // in milliseconds
        setTimeout(() => {
            // Now, navigate to the desired route
        }, delayDuration);
    };

    return (
        <>
            <ToastContainer position="top-right" autoClose={5000} />

            <footer className="bg-gray-900 pt-6 w-full flex flex-col items-center">
                <div
                    id="contactUs"
                    className="py-6 w-full max-w-5xl flex flex-col items-center lg:flex-row lg:justify-end  gap-y-10"
                >
                    <div className="lg:mr-auto pr-4 flex flex-col items-start gap-6">
                        <h2 className="text-3xl font-semibold -tracking-[2px] opacity-25 text-white ">
                            {t('generalComponents.footer.quickSupport.heading1')}
                        </h2>
                        <h3 className="text-3xl font-semibold -tracking-[2px] text-white  opacity-100">
                            {t('generalComponents.footer.quickSupport.heading2')}
                        </h3>
                        <h4 className="text-white text-xl font-light opacity-80 text-start">
                            {t('generalComponents.footer.quickSupport.heading3')}
                        </h4>
                        <div className="flex gap-2">
                            <a
                                href="mailto:example@gmail.com"
                                className="hover:text-green-400 cursor-pointer flex transition duration-200 ease-in text-white "
                            >
                                <SiMinutemailer className="w-6 h-6 mr-2  text-green-400" />
                                info@flytt-stad-atervinn.com
                            </a>
                        </div>
                    </div>
                    <form
                        onSubmit={handleSubmit} // Add this line to trigger handleSubmit on form submit
                        className="flex bg-gr flex-col hover:scale-105 transition duration-300 rounded-xl w-11/12 lg:w-1/2 gap-6 py-3 px-4"
                    >
                        <h2 className=" text-gray-300 text-xl font-bold text-start ">
                            {t('generalComponents.footer.form.heading')}
                        </h2>
                        <div className="flex flex-col gap-y-5">
                            <div className="flex relative items-center ">
                                <BiUser className="absolute left-1 w-9 h-9 p-1 text-gray-500 hover:shake" />
                                <input
                                    className="pl-12 rounded bg-gray-200 shadow-lg w-full  p-2 placeholder:text-gr1 "
                                    type="text"
                                    placeholder={`${t('generalComponents.footer.form.placeHolderFirstName')}`}
                                    name="fullName"
                                    value={fullName}
                                    onChange={(e) => setFullName(e.target.value)}
                                    id="fullName"
                                />
                            </div>
                            <div className="flex relative items-center ">
                                <FiMail className=" absolute left-1 w-7 h-7 mr-2 text-gray-500 hover:shake" />
                                <input
                                    className="pl-12 w-full rounded bg-gray-200 shadow-lg   p-2 placeholder:text-gr1 "
                                    placeholder={`${t('generalComponents.footer.form.placeHolderEmailAddress')}`}
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    type="email"
                                    id="email"
                                    name="email"
                                />
                            </div>
                            <div className="flex relative items-center">
                                <MdSubject className="absolute left-1 w-7 h-7  mr-2 text-gray-500 hover:shake" />
                                <input
                                    className="pl-12 w-full rounded bg-gray-200 shadow-lg  p-2 placeholder:text-gr1 "
                                    type="text"
                                    placeholder={`${t('generalComponents.footer.form.placeHolderSubject')}`}
                                    name="subject"
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                    id="subject"
                                />
                            </div>
                            <div className="flex relative items-center">
                                <AiOutlineMessage className="absolute left-1 top-2 w-7 h-7 mr-2 text-gray-500 hover:shake" />
                                <textarea
                                    className="pl-12 w-full p-2 rounded bg-gray-200  shadow-lg   placeholder:text-gr1 "
                                    placeholder={`${t('generalComponents.footer.form.placeHolderMessage')}`}
                                    name="message"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    id="message"
                                ></textarea>
                            </div>
                            <button
                                type="submit" // Change type to "submit" to trigger form submission
                                className="text-white px-4 py-2 rounded-lg bg-sk hover:bg-sk1 transition duration-200"
                            >
                                {`${t('generalComponents.footer.form.sendMessage')}`}
                            </button>
                        </div>
                    </form>
                </div>

                {/*  <div
                id="contactUs"
                className="py-6 w-full max-w-5xl flex flex-col items-center lg:flex-row lg:justify-end  gap-y-10"
            >
                <div className="lg:mr-auto pr-4 flex flex-col items-start gap-6">
                    <h2 className="text-3xl font-semibold -tracking-[2px] opacity-25 text-white ">
                        Get Ecosystem updates in your inbox
                    </h2>
                    <h3 className="text-3xl font-semibold -tracking-[2px] text-white  opacity-100">
                        Quick Support
                    </h3>
                    <h4 className="text-white text-xl font-light opacity-80 text-start">
                        We're here for you, waiting to answer your queries, our team is happy to help you.
                    </h4>
                    <div className="flex gap-2">
                        <a
                            href="mailto:example@gmail.com"
                            className="hover:text-green-400 cursor-pointer flex transition duration-200 ease-in text-white "
                        >
                            <SiMinutemailer className="w-6 h-6 mr-2  text-green-400" />
                            info@flytt-stad-atervinn.com
                        </a>
                    </div>
                </div>
                <form

                    className="flex bg-gr flex-col hover:scale-105 transition duration-300 rounded-xl w-11/12 lg:w-1/2 gap-6 py-3 px-4">
                    <h2 className=" text-gray-300 text-xl font-bold text-start ">
                        Get in touch with us!
                    </h2>
                    <div className="flex flex-col gap-y-5">
                        <div className="flex relative items-center ">
                            <BiUser className="absolute left-1 w-9 h-9 p-1 text-gray-500 hover:shake" />
                            <input
                                className="pl-12 rounded bg-gray-200 shadow-lg w-full  p-2 placeholder:text-gr1 "
                                type="text"
                                placeholder="Full name"
                                name="fullName"
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                                id="fullName"

                            />
                        </div>
                        <div className="flex relative items-center ">
                            <FiMail className=" absolute left-1 w-7 h-7 mr-2 text-gray-500 hover:shake" />
                            <input
                                className="pl-12 w-full rounded bg-gray-200 shadow-lg   p-2 placeholder:text-gr1 "

                                placeholder="Email Address"
                                onChange={(e) => setEmail(e.target.value)}

                                value={email}
                                type="email"
                                id="email"
                                name="email"

                            />
                        </div>
                        <div className="flex relative items-center">
                            <MdSubject className="absolute left-1 w-7 h-7  mr-2 text-gray-500 hover:shake" />
                            <input
                                className="pl-12 w-full rounded bg-gray-200 shadow-lg  p-2 placeholder:text-gr1 "
                                type="text"
                                placeholder="Enter Your Subject"
                                name="subject"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                                id="subject"
                            />
                        </div>
                        <div className="flex relative items-center">
                            <AiOutlineMessage className="absolute left-1 top-2 w-7 h-7 mr-2 text-gray-500 hover:shake" />
                            <textarea
                                className="pl-12 w-full p-2 rounded bg-gray-200  shadow-lg   placeholder:text-gr1 "
                                placeholder="Message"
                                name="message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                id="message"
                            ></textarea>
                        </div>
                        <button
                            className="text-white px-4 py-2 rounded-lg bg-sk hover:bg-sk1 transition duration-200"
                            onSubmit={handleSubmit}
                        >
                            Send Message
                        </button>
                    </div>
                </form>
            </div> */}

                <div
                    id="footer"
                    className="text-start py-10 px-2 w-full border-y  border-gr1  max-w-6xl  grid lg:grid-cols-4 grid-cols-2 gap-4 text-white"
                >
                    <div className="col-span-1 flex flex-col gap-5">
                        <h2 className="font-bold text-xl">{t('generalComponents.footer.linksSection1.heading')}</h2>

                        <Link to="/" onClick={handleClick}>
                            <div className="relative inline-block group">
                                <span className="text-gr1 font-semibold relative z-10 group-hover:text-red-500">
                                    {t('generalComponents.footer.linksSection1.link1')}
                                </span>
                                <div className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-orange-400 to-red-500 transform origin-left scale-x-0 transition-transform ease-out duration-300 group-hover:scale-x-100"></div>
                            </div>
                        </Link>
                        <Link to="/MovingService" onClick={handleClick}>
                            <div className="relative inline-block group">
                                <span className="text-gr1 font-semibold relative z-10 group-hover:text-red-500">
                                    {t('generalComponents.footer.linksSection1.link2')}
                                </span>
                                <div className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-orange-400 to-red-500 transform origin-left scale-x-0 transition-transform ease-out duration-300 group-hover:scale-x-100"></div>
                            </div>
                        </Link>
                        <Link to="/MovingService" onClick={handleClick} >
                            <div className="relative inline-block group">
                                <span className="text-gr1 font-semibold relative z-10 group-hover:text-red-500">
                                    {t('generalComponents.footer.linksSection1.link3')}
                                </span>
                                <div className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-orange-400 to-red-500 transform origin-left scale-x-0 transition-transform ease-out duration-300 group-hover:scale-x-100"></div>
                            </div>
                        </Link>
                        <Link to="/Deep_Clean" onClick={handleClick}>
                            <div className="relative inline-block group">
                                <span className="text-gr1 font-semibold relative z-10 group-hover:text-red-500">
                                    {t('generalComponents.footer.linksSection1.link4')}
                                </span>
                                <div className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-orange-400 to-red-500 transform origin-left scale-x-0 transition-transform ease-out duration-300 group-hover:scale-x-100"></div>
                            </div>
                        </Link>
                    </div>
                    <div className="  col-span-1 flex flex-col gap-5">
                        <h2 className="  font-bold text-xl">{t('generalComponents.footer.linksSection2.heading')}</h2>
                        <Link to="/contact" onClick={handleClick}>
                            <div className="relative inline-block group">
                                <span className="text-gr1 font-semibold relative z-10 group-hover:text-red-500">
                                    Stockholm
                                </span>
                                <div className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-orange-400 to-red-500 transform origin-left scale-x-0 transition-transform ease-out duration-300 group-hover:scale-x-100"></div>
                            </div>
                        </Link>
                        <Link to="/contact" onClick={handleClick}>
                            <div className="relative inline-block group">
                                <span className="text-gr1 font-semibold relative z-10 group-hover:text-red-500">
                                    Gothenburg
                                </span>
                                <div className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-orange-400 to-red-500 transform origin-left scale-x-0 transition-transform ease-out duration-300 group-hover:scale-x-100"></div>
                            </div>
                        </Link>
                        <Link to="/contact" onClick={handleClick}>
                            <div className="relative inline-block group">
                                <span className="text-gr1 font-semibold relative z-10 group-hover:text-red-500">
                                    Västerås
                                </span>
                                <div className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-orange-400 to-red-500 transform origin-left scale-x-0 transition-transform ease-out duration-300 group-hover:scale-x-100"></div>
                            </div>
                        </Link>
                        <Link to="/contact" onClick={handleClick}>
                            <div className="relative inline-block group">
                                <span className="text-gr1 font-semibold relative z-10 group-hover:text-red-500">
                                    Malmö
                                </span>
                                <div className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-orange-400 to-red-500 transform origin-left scale-x-0 transition-transform ease-out duration-300 group-hover:scale-x-100"></div>
                            </div>
                        </Link>
                        <Link to="/contact" onClick={handleClick}>
                            <div className="relative inline-block group">
                                <span className="text-gr1 font-semibold relative z-10 group-hover:text-red-500">
                                    Uppsala
                                </span>
                                <div className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-orange-400 to-red-500 transform origin-left scale-x-0 transition-transform ease-out duration-300 group-hover:scale-x-100"></div>
                            </div>
                        </Link>
                        <Link to="/contact" onClick={handleClick}>
                            <div className="relative inline-block group">
                                <span className="text-gr1 font-semibold relative z-10 group-hover:text-red-500">
                                    Linköping
                                </span>
                                <div className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-orange-400 to-red-500 transform origin-left scale-x-0 transition-transform ease-out duration-300 group-hover:scale-x-100"></div>
                            </div>
                        </Link>
                        <Link to="/contact" onClick={handleClick}>
                            <div className="relative inline-block group">
                                <span className="text-gr1 font-semibold relative z-10 group-hover:text-red-500">
                                    Norrköping
                                </span>
                                <div className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-orange-400 to-red-500 transform origin-left scale-x-0 transition-transform ease-out duration-300 group-hover:scale-x-100"></div>
                            </div>
                        </Link>
                        <Link to="/contact" onClick={handleClick}>
                            <div className="relative inline-block group">
                                <span className="text-gr1 font-semibold relative z-10 group-hover:text-red-500">
                                    Mälardalen
                                </span>
                                <div className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-orange-400 to-red-500 transform origin-left scale-x-0 transition-transform ease-out duration-300 group-hover:scale-x-100"></div>
                            </div>
                        </Link>

                    </div>
                    <div className="  col-span-1 flex flex-col gap-5">
                        <h2 className=" font-bold text-xl ">{t('generalComponents.footer.linksSection3.heading')}</h2>

                        <Link to="/Deep_Clean" onClick={handleClick}>
                            <div className="relative inline-block group">
                                <span className="text-gr1 font-semibold relative z-10 group-hover:text-red-500">
                                    {t('generalComponents.footer.linksSection3.link1')}
                                </span>
                                <div className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-orange-400 to-red-500 transform origin-left scale-x-0 transition-transform ease-out duration-300 group-hover:scale-x-100"></div>
                            </div>
                        </Link>
                        <Link to="/Deep_Clean" onClick={handleClick}>
                            <div className="relative inline-block group">
                                <span className="text-gr1 font-semibold relative z-10 group-hover:text-red-500">
                                    {t('generalComponents.footer.linksSection3.link2')}
                                </span>
                                <div className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-orange-400 to-red-500 transform origin-left scale-x-0 transition-transform ease-out duration-300 group-hover:scale-x-100"></div>
                            </div>
                        </Link>
                        <Link to="/Deep_Clean" onClick={handleClick}>
                            <div className="relative inline-block group">
                                <span className="text-gr1 font-semibold relative z-10 group-hover:text-red-500">
                                    {t('generalComponents.footer.linksSection3.link3')}
                                </span>
                                <div className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-orange-400 to-red-500 transform origin-left scale-x-0 transition-transform ease-out duration-300 group-hover:scale-x-100"></div>
                            </div>
                        </Link>
                        <Link to="/Deep_Clean" onClick={handleClick}>
                            <div className="relative inline-block group">
                                <span className="text-gr1 font-semibold relative z-10 group-hover:text-red-500">
                                    {t('generalComponents.footer.linksSection3.link4')}
                                </span>
                                <div className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-orange-400 to-red-500 transform origin-left scale-x-0 transition-transform ease-out duration-300 group-hover:scale-x-100"></div>
                            </div>
                        </Link>
                        <Link to="/Deep_Clean" onClick={handleClick}>
                            <div className="relative inline-block group">
                                <span className="text-gr1 font-semibold relative z-10 group-hover:text-red-500">
                                    {t('generalComponents.footer.linksSection3.link5')}
                                </span>
                                <div className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-orange-400 to-red-500 transform origin-left scale-x-0 transition-transform ease-out duration-300 group-hover:scale-x-100"></div>
                            </div>
                        </Link>
                        <Link to="/Deep_Clean" onClick={handleClick}>
                            <div className="relative inline-block group">
                                <span className="text-gr1 font-semibold relative z-10 group-hover:text-red-500">
                                    {t('generalComponents.footer.linksSection3.link6')}
                                </span>
                                <div className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-orange-400 to-red-500 transform origin-left scale-x-0 transition-transform ease-out duration-300 group-hover:scale-x-100"></div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-span-1 flex flex-col gap-5">
                        <h2 className="font-bold text-xl">{t('generalComponents.footer.linksSection4.heading')}</h2>
                        <Link to="/MovingService" onClick={handleClick}>
                            <div className="relative inline-block group">
                                <span className="text-[#8c8c8c] font-semibold relative z-10 group-hover:text-red-500">
                                    {t('generalComponents.footer.linksSection4.link1')}
                                </span>
                                <div className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-orange-400 to-red-500 transform origin-left scale-x-0 transition-transform ease-out duration-300 group-hover:scale-x-100"></div>
                            </div>
                        </Link>
                        <a href="mailto:example@gmail.com">
                            <div className="relative inline-block group">
                                <span className="text-gr1 font-semibold relative z-10 group-hover:text-red-500">
                                    {t('generalComponents.footer.linksSection4.link2')}
                                </span>
                                <div className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-orange-400 to-red-500 transform origin-left scale-x-0 transition-transform ease-out duration-300 group-hover:scale-x-100"></div>
                            </div>
                        </a>
                        <Link to="/MovingService" onClick={handleClick}>
                            <div className="relative inline-block group">
                                <span className="text-gr1 font-semibold relative z-10 group-hover:text-red-500">
                                    {t('generalComponents.footer.linksSection4.link3')}
                                </span>
                                <div className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-orange-400 to-red-500 transform origin-left scale-x-0 transition-transform ease-out duration-300 group-hover:scale-x-100"></div>
                            </div>
                        </Link>
                        <Link to="/MovingService" onClick={handleClick}>
                            <div className="relative inline-block group">
                                <span className="text-gr1 font-semibold relative z-10 group-hover:text-red-500">
                                    {t('generalComponents.footer.linksSection4.link4')}
                                </span>
                                <div className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-orange-400 to-red-500 transform origin-left scale-x-0 transition-transform ease-out duration-300 group-hover:scale-x-100"></div>
                            </div>
                        </Link>
                        <Link to="/MovingService" onClick={handleClick}>
                            <div className="relative inline-block group">
                                <span className="text-gr1 font-semibold relative z-10 group-hover:text-red-500">
                                    {t('generalComponents.footer.linksSection4.link5')}
                                </span>
                                <div className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-orange-400 to-red-500 transform origin-left scale-x-0 transition-transform ease-out duration-300 group-hover:scale-x-100"></div>
                            </div>
                        </Link>
                        <Link to="/MovingService" onClick={handleClick}>
                            <div className="relative inline-block group">
                                <span className="text-gr1 font-semibold relative z-10 group-hover:text-red-500">
                                    {t('generalComponents.footer.linksSection4.link6')}
                                </span>
                                <div className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-orange-400 to-red-500 transform origin-left scale-x-0 transition-transform ease-out duration-300 group-hover:scale-x-100"></div>
                            </div>
                        </Link>
                        <Link to="/contact" onClick={handleClick}>
                            <div className="relative inline-block group">
                                <span className="text-gr1 font-semibold relative z-10 group-hover:text-red-500">
                                    {t('generalComponents.footer.linksSection4.link7')}
                                </span>
                                <div className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-orange-400 to-red-500 transform origin-left scale-x-0 transition-transform ease-out duration-300 group-hover:scale-x-100"></div>
                            </div>
                        </Link>
                        <Link to="/MovingService" onClick={handleClick}>
                            <div className="relative inline-block group">
                                <span className="text-gr1 font-semibold relative z-10 group-hover:text-red-500">
                                    {t('generalComponents.footer.linksSection4.link8')}
                                </span>
                                <div className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-orange-400 to-red-500 transform origin-left scale-x-0 transition-transform ease-out duration-300 group-hover:scale-x-100"></div>
                            </div>
                        </Link>
                        <Link to="/MovingService" onClick={handleClick}>
                            <div className="relative inline-block group">
                                <span className="text-gr1 font-semibold relative z-10 group-hover:text-red-500">
                                    {t('generalComponents.footer.linksSection4.link9')}
                                </span>
                                <div className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-orange-400 to-red-500 transform origin-left scale-x-0 transition-transform ease-out duration-300 group-hover:scale-x-100"></div>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="py-10 pl-2 w-full max-w-5xl text-white flex flex-wrap items-center ">
                    <Link to={"/"} onClick={handleClick}>
                        <img
                            src="img/FSALOGO.svg"

                            alt="home"
                            className="w-40 h-auto hover:scale-95 transition duration-300 ease-in-out"
                        />
                    </Link>
                    <div
                        className="text-lg font-semibold rounded-lg py-3 px-4 ml-auto lg:mr-24 bg-sk hover:bg-sk1 transition duration-200"
                    >
                        <Link to={'/contact'} onClick={handleClick}>
                            <button type="submit">{t('generalComponents.footer.bookNow')}</button>
                        </Link>
                    </div>

                </div>

                <div className="flex flex-col items-center w-full  pt-8  pb-3 bg-[#141617] gap-y-4 ">
                    <div className="flex items-center gap-4">
                        <Link to="https://www.facebook.com/">
                            <div className="bg-white p-2 rounded-full cursor-pointer hover:scale-125 transition-all duration-700">
                                <img src="/img/fa.png" alt="home" className="w-5 h-5" />
                            </div>
                        </Link>
                        <Link to="https://www.instagram.com">
                            <div className="bg-white p-2 rounded-full cursor-pointer hover:scale-125 transition duration-700">
                                <img src="/img/in.png" alt="home" className="w-5 h-5" />
                            </div>
                        </Link>
                        <Link to="https://www.linkedin.com">
                            <div className="bg-white p-2 rounded-full cursor-pointer hover:scale-125 transition duration-700">
                                <img src="/img/li.png" alt="home" className="w-5 h-5" />
                            </div>
                        </Link>
                        <Link to="https://x.com/">
                            <div className="bg-white p-2 rounded-full cursor-pointer hover:scale-125 transition duration-700">
                                <img src="/img/tw.png" alt="home" className="w-5 h-5" />
                            </div>
                        </Link>
                        <Link to="https://youtube.com/">
                            <div className="bg-white p-2 rounded-full cursor-pointer hover:scale-125 transition duration-700">
                                <img src="/img/yo.png" alt="home" className="w-5 h-5" />
                            </div>
                        </Link>
                        <a href="whatsapp://send?phone=+46730375702" target="_blank" >
                            <div className="bg-white p-2 rounded-full cursor-pointer hover:scale-125 transition duration-700">
                                <img src="/img/whatsapp.png" alt="home" className="w-5 h-5" />
                            </div>
                        </a>
                    </div>
                    <div className="relative w-full">
                        <p className="text-white text-center">
                            @copyright2024 FlyttStäd&Återvinning
                        </p>
                        {/* <p
                        onClick={() => window.open("https://codezoone.netlify.app/")}
                        className="text-xs -mt-4 font-bold bg-white p-1 px-2 rounded-3xl relative md:absolute right-0 md:right-4 hover:cursor-pointer hover:font-bold text-center hover:bg-green-400 hover:text-white hover:scale-x-105 hover:duration-300"
                    > */}
                        {/* <a href="https://codezoone.netlify.app/" target="_blank">
                            Developed By CodeZone
                        </a> */}
                        {/* </p> */}
                    </div>
                </div>
            </footer>
        </>
    );

}
export default Footer;