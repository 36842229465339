import { useState } from 'react';
import { BiSolidChevronDown } from 'react-icons/bi';

import { useTranslation } from 'react-i18next';

const Includesection = () => {
  
  const { t } = useTranslation();

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const cleanmethods = [
    {
      heading: 'General cleaning in all rooms',
      title: 'Our home cleaning includes the following cleaning services as a standard in all rooms:',
      description: [
        'Vacuuming of floors, moldings, electrical sockets, radiators, carpets, and upholstered textile furniture.',
        'Mopping of floors or other types of cleaning according to agreement.',
        'Wipe off doors and frames where we can access them.',
        'Dry dusting of windowsills and free surfaces.',
        'Dry dusting of lamps, shelves, and picture frames that we can access.',
        'Dry dusting of electronics (not the screen).',
        'Mirror polishing.',
        'Make the beds.',
        'Empty and wipe rubbish bins.',
      ],
      iconUrl: 'img/hgenral.svg',
    },
    {
      heading: 'Bathrooms',
      title: 'We perform general cleaning steps, as well as:',
      description: [
        'Cleaning bathtubs, showers, sinks, and toilets.',
        'Wipe off stains from walls.',
        'Wipe off bathroom cabinets and appliances.',
      ],
      iconUrl: 'img/hBathroom.svg',
    },
    {
      heading: 'Kitchen',
      title: 'We perform general cleaning steps, as well as:',
      description: [
        'Cleaning the sink and kitchen counter, stove, and wall behind the stove and sink.',
        'Cleaning microwave interior and exterior.',
        'Interior and exterior cleaning of cabinets where rubbish bins are located.',
        'Wipe off kitchen doors.',
        'Wipe off the kitchen fan and the outside of other appliances.',
        'Emptying the household rubbish.',
      ],
      iconUrl: 'img/hkitchen.svg',
    },
    {
      heading: 'Our own cleaning products',
      title: 'The cleaning service contract also includes our eco-friendly cleaning products which are delivered to your home and refilled when they run out.',
      iconUrl: 'img/hc.svg',
    },
  ];

  return (
    <div className='py-14 bg-[#eae8e1] flex flex-col justify-center items-center md:flex-none'>
      <div className='relative w-4/5 md:w-full'>
        <div className=' text-5xl  font-bold text-black'>
          {t('aboutFSA.main.headingPart1')} <span className='text-orange-600 heading'>{t('aboutFSA.main.headingPart2')}</span>
        </div>
        <div className='w-4/5 md:w-full mt-10 flex justify-center items-center'>
          <p className='border-black border-2 w-20 hover:w-[550PX] text-center transition-all duration-300 transform origin-center scale-100 delay-200 hover:border-gray-400'></p>
        </div>
        <div className='mt-4 text-lg leading-7 flex justify-center items-center'>
          <p className='font-bold text-2xl w-5/6 md:w-4/6'>{t('aboutFSA.main.paragraph')}</p>


        </div>
      </div>

      {/*  <div className='px-2 py-8 flex justify-center'>
        <div className='grid lg:grid-cols-2 w-full max-w-6xl gap-8'>
          {cleanmethods.map((item, index) => (
            <div
              key={index}
              className='col-span-1 accordion-item'
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <h2 className='font-xl font-semibold'>
                <div className='text-3xl text-gray-800 hover:text-gray-500'>
                  <div className='flex hover:shake'>
                    <img src={item.iconUrl} alt='Icon' className='w-10 font-semibold pr-2 hover:scale-95 transition duration-300 ease-in-out' />
                    <h4 className='heading mr-2'>{item.heading}</h4>
                    <div className='text-3xl transition-transform duration-300 transform ml-auto' style={{ transform: hoveredIndex === index ? 'rotate(180deg)' : 'rotate(0deg)' }}>
                      <BiSolidChevronDown
                        className={`w-8 h-8 border-2 rounded-full ${hoveredIndex === index ? 'text-yellow-400 border-yellow-400' : 'text-green-500 border-green-500'}`}
                      />
                    </div>
                  </div>
                </div>
              </h2>
              <div className={`border-l-2 rounded-bl-md dark:border-none overflow-hidden duration-[1500ms] transition-all ${hoveredIndex === index ? 'max-h-[500px]' : 'max-h-0'}`}>
                <h3 className='text-start text-lg pb-1 font-semibold'>{item.title}</h3>
                <ul className='list-disc marker:text-blue-700 text-start pl-5 space-y-3 text-gray-700'>
                  {item?.description?.map((desc, i) => (
                    <li key={i}>{desc}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div> */}
    </div >
  );
};

export default Includesection;
