import React, { useEffect } from 'react';
import useTranslatePage from './../../Gernal_Components/TranslatePage';
import Loader from './../../Gernal_Components/Loader';

import Hero from "../Home/Sections/Hero";
import TimeforSimple from "./Sections/TimeforSimpler";
import Tipsinsp from "./Sections/Tips&Insp";

// import Appsection from "./Sections/Appsection";
import WhyHermified from "./Sections/WhyHermfid";
import Cleaningtoloc from "./Sections/cleaning_to_loc";
import HelpWith from "./Sections/help_with";
import Review from "./Sections/review";
import WorkAtHemfird from "./Sections/workatHemfird";
import Form from "./Sections/form";

const Home = () => {

  const [translatePage, loading] = useTranslatePage();

    useEffect(() => {
        const lang = localStorage.getItem('language');
        if (lang) {
        translatePage(lang);
        }
    }, []);

  return (
    <>
      {loading && <Loader />}
      <div>
        <Hero />
        <HelpWith />
        <WhyHermified />
        {/* <Review /> */}
        {/* <Appsection /> */}
        {/* <Tipsinsp /> */}
        <WorkAtHemfird />
        <Cleaningtoloc />
        <TimeforSimple />
      </div>
    </>
  );
};

export default Home;
