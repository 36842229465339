import React, { useEffect } from 'react';
import useTranslatePage from './../../Gernal_Components/TranslatePage';
import Loader from './../../Gernal_Components/Loader';

import Navbar from "../../Gernal_Components/Navbar";
import DHero from "./Sections/D_Hero";
import Zipcode from "./Sections/D_zipcode";
import FAQ from "./Sections/Faq";
import Sabina from "./Sections/Sabina";
import Included from "./Sections/included";
import Tips from "./Sections/tips&news";
import Work from "./Sections/work";

const DHome = () => {

  const [translatePage, loading] = useTranslatePage();

    useEffect(() => {
        const lang = localStorage.getItem('language');
        if (lang) {
        translatePage(lang);
        }
    }, []);

  return (
    <div>
    {loading && <Loader />}
      <DHero />
      <Included />
      <Work />
      <FAQ />
      {/* <Sabina /> */}
      {/* <Tips /> */}
      <Zipcode />
    </div>
  );
};

export default DHome;
