import React, { useState, useEffect } from "react";
import useTranslatePage from './../../Gernal_Components/TranslatePage';
import Loader from './../../Gernal_Components/Loader';

import Note from "../MoveoutCleaning/Note";
import CrossIcon from "../../Gernal_Components/CrossIcon";
import { toast, ToastContainer } from "react-toastify";
import emailjs from "emailjs-com";
import { useNavigate } from "react-router";
import { Textarea } from '@chakra-ui/react';
import { Link } from "react-router-dom";

import { useTranslation } from 'react-i18next';

const OfficeRelocationContact = () => {

    const { t } = useTranslation();
  
    const [translatePage, loading] = useTranslatePage();

    useEffect(() => {
        const lang = localStorage.getItem('language');
        if (lang) {
        translatePage(lang);
        }
    }, []);

    const [companyName, setCompanyName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [email, setEmail] = useState("");
    const [employeesCount, setEmployeesCount] = useState("");
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const validateForm = () => {
        const errors = {};
        if (!companyName.trim()) errors.companyName = "Company name is required";
        if (!firstName.trim()) errors.firstName = "First name is required";
        if (!lastName.trim()) errors.lastName = "Last name is required";
        if (!mobileNumber.trim() || !/^07[\d]{8}$/.test(mobileNumber)) errors.mobileNumber = "Mobile number is required and it should start with 07 and be 10 digits long";
        if (!email.trim() || !/^\S+@\S+\.\S+$/.test(email)) errors.email = "Email address is required and it should be in correct format";
        if (!employeesCount) errors.employeesCount = "Please select the number of employees";
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };
    

    const handleSubmit = async () => {
        if (!validateForm()) return;

        try {
            const messageBody = `
            Cleaning As Salery Benifit Request:
            Company Name: ${companyName}
            First Name: ${firstName}
            Last Name: ${lastName}
            Mobile Number: ${mobileNumber}
            Employees Count: ${employeesCount}
            Thank you for your interest in our services! We'll be in touch shortly to discuss how we can assist you further.
            `;
            const templateParams = {
                to_name: `${firstName}  ${lastName} `,
                from_name: "Code Zone",
                to_email: email,
                subject: "Office Cleaning As Salery Benifit Request:",
                message: messageBody,
            };
            const response = await emailjs.send(
                "service_m774pph",
                "template_qsec7n7",
                templateParams,
                "rc1ba_PvLbgEIUjbt"
            );
            console.log("Email sent successfully:", response);
            if (response) {
                toast.success(`${firstName} ${lastName} Your Cleaning As Salery Benifit Request Sent Successfully`);
                setCompanyName("");
                setFirstName("");
                setLastName("");
                setMobileNumber("");
                setEmail("");
                setEmployeesCount("");
                document.getElementById("companyName").value = "";
                document.getElementById("firstName").value = "";
                document.getElementById("lastName").value = "";
                document.getElementById("mobileNumber").value = "";
                document.getElementById("email").value = "";
                document.getElementById("cat").checked = false;
                document.getElementById("dog").checked = false;
                document.getElementById("other").checked = false;
            }
        } catch (error) {
            console.error("Error sending email:", error);
        }
    };

    const fivePlus = "500>";

    return (
        <div className="w-full flex justify-center items-center py-20">
        {loading && <Loader />}
            <div className="w-5/6 mdw-4/6 flex flex-col justify-center items-center">
                <div className="w-full md:w-4/6 flex justify-end">
                    <div className="hover:shake">
                        <CrossIcon handleClickCrossIcon={() => navigate("/cleaning_as_salery_benifit")}/>
                    </div>
                </div>
                <div className="w-full md:w-4/6 font-normal text-5xl" style={{ fontFamily: "Tiempos Headline,serif" }}>
                {t('contactPages.cleaningAsSaleryBenifitHeader')}
                </div>
                <div className="w-full mt-8  flex justify-center items-center mb-5">
                    <p className=" border-black border w-20 text-center hover:w-[400PX] hover:border-gray-500  transition-all duration-300 transform origin-center scale-100"></p>
                </div>
                <div className="w-full md:w-4/6 rounded-lg p-4">
                    <label htmlFor="companyName" className="text-xl font-semibold flex justify-left mb-2 ms-1">
                    {t('contactPages.companyName')}
                    </label>
                    <input
                        type="text"
                        id="companyName"
                        onChange={(e) => setCompanyName(e.target.value)}

                        name="companyName"
                        className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                        placeholder="Enter your company name"
                    />
                    {errors.companyName && <div className="text-red-500">{errors.companyName}</div>}
                    <label htmlFor="firstName" className="text-xl font-semibold flex justify-left mb-2 ms-1">
                    {t('contactPages.firstName')}:
                    </label>
                    <input
                        type="text"
                        id="firstName"
                        onChange={(e) => setFirstName(e.target.value)}

                        name="firstName"
                        className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                        placeholder="Enter your first name"
                    />
                    {errors.firstName && <div className="text-red-500">{errors.firstName}</div>}
                    <label htmlFor="lastName" className="text-xl font-semibold flex justify-left mb-2 ms-1">
                    {t('contactPages.lastName')}:
                    </label>
                    <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        onChange={(e) => setLastName(e.target.value)}

                        className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                        placeholder="Enter your last name"
                    />
                    {errors.lastName && <div className="text-red-500">{errors.lastName}</div>}
                    <label htmlFor="mobileNumber" className="text-xl font-semibold flex justify-left mb-2 ms-1">
                    {t('contactPages.mobileNumber')}
                    </label>
                    <input
                        type="number"
                        id="mobileNumber"
                        name="mobileNumber"
                        onChange={(e) => setMobileNumber(e.target.value)}

                        className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                        placeholder="Enter your mobile number"
                    />
                    {errors.mobileNumber && <div className="text-red-500">{errors.mobileNumber}</div>}
                    <label htmlFor="email" className="text-xl font-semibold flex justify-left mb-2 ms-1">
                    {t('contactPages.email')}:
                    </label>
                    <input
                        type="email"
                        id="email"
                        onChange={(e) => setEmail(e.target.value)}

                        name="email"
                        className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                        placeholder="Enter your email"
                    />
                    {errors.email && <div className="text-red-500">{errors.email}</div>}
                    <label className="text-xl font-semibold flex justify-left mb-2 ms-1">{t('contactPages.howManyEmployees')}</label>
                    <div className="flex items-center">
                        <input
                            type="radio"
                            id="1"
                            name="employeesCount"
                            value="1"
                            checked={employeesCount === "1"}
                            onChange={(e) => setEmployeesCount(e.target.value)}
                        />
                        <label htmlFor="1">1</label>
                    </div>
                    <div className="flex items-center">
                        <input
                            type="radio"
                            id="1-5"
                            name="employeesCount"
                            value="1-5"
                            checked={employeesCount === "1-5"}
                            onChange={(e) => setEmployeesCount(e.target.value)}
                        />
                        <label htmlFor="1-5">1-5</label>
                    </div>
                    <div className="flex items-center">
                        <input
                            type="radio"
                            id="5-10"
                            name="employeesCount"
                            value="5-10"
                            checked={employeesCount === "5-10"}
                            onChange={(e) => setEmployeesCount(e.target.value)}
                        />
                        <label htmlFor="5-10">5-10</label>
                    </div>
                    <div className="flex items-center">
                        <input
                            type="radio"
                            id="10-50"
                            name="employeesCount"
                            value="10-50"
                            checked={employeesCount === "10-50"}
                            onChange={(e) => setEmployeesCount(e.target.value)}
                        />
                        <label htmlFor="10-50">10-50</label>
                    </div>
                    <div className="flex items-center">
                        <input
                            type="radio"
                            id="50-100"
                            name="employeesCount"
                            value="50-100"
                            checked={employeesCount === "50-100"}
                            onChange={(e) => setEmployeesCount(e.target.value)}
                        />
                        <label htmlFor="50-100">50-100</label>
                    </div>
                    <div className="flex items-center">
                        <input
                            type="radio"
                            id="100-500"
                            name="employeesCount"
                            value="100-500"
                            checked={employeesCount === "100-500"}
                            onChange={(e) => setEmployeesCount(e.target.value)}
                        />
                        <label htmlFor="100-500">100-500</label>
                    </div>
                    <div className="flex items-center">
                        <input
                            type="radio"
                            id="500>"
                            name="employeesCount"
                            value="500>"
                            checked={employeesCount === "500>"}
                            onChange={(e) => setEmployeesCount(e.target.value)}
                        />
                        <label htmlFor="500>">{fivePlus}</label>
                    </div>
                    {errors.employeesCount && <div className="text-red-500">{errors.employeesCount}</div>}
                    <div className="mt-10" style={{ display: "flex", justifyContent: "space-evenly" }}>
                        <button
                            style={{ padding: "15px 50px 15px 50px" }}
                            className="bg-[#000] font-bold uppercase text-white p-5 rounded text-bold"
                            onClick={handleSubmit} >
                            {t('contactPages.contactMe')}
                        </button>
                    </div>
                    <div className="flex justify-center items-center mt-6 font-semibold"><p>{t('contactPages.theServiceIsCoveredByFSA')} <Link className="text-blue-600">{t('contactPages.termsAndConditions')}</Link></p></div>
                    <div className="flex justify-center items-center mt-6 text-gray-500"><p>{t('contactPages.fillTheseFieldsToContinue')}</p></div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default OfficeRelocationContact