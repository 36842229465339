
import React, { useEffect, useRef, useState } from "react";
import { ZIPCODES } from "../../../Constants/Constant";

import { useNavigate } from "react-router";

import { useTranslation } from 'react-i18next';
           
const OurService = () => {
    
    const { t } = useTranslation();
  
    const [city, setCity] = useState("");
    const [code, setCode] = useState("");
    const [show, setShow] = useState(false);
    const [selectedService, setSelectedService] = useState("");

    const zipcodeRef = useRef();
    const handleRef = () => {
        zipcodeRef.current.focus();
    };
    const handleServiceSelect = (selectedService) => {
        setSelectedService(selectedService);
        console.log(selectedService)
    };
    const navigate = useNavigate();
    const handleNavigate = () => {

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        let route = "/Booking"; // Default route for Home Cleaning

        switch (selectedService) {
            case "Home Cleaning":
                route = "/Booking";
                break;
            case "Window Cleaning":
                route = "/window_cleaning";
                break;
            case "Deep Cleaning":
                route = "/deep_cleaning";
                break;
            case "Dödsbo":
                route = "/Dödsbo";
                break;
            case "Move Out Cleaning":
                route = "/moving_out_cleaning";
                break;
            case "Moving And MoveOut Cleaning":
                route = "/moving_out_service2";
                break;
            case "Moving Service":
                route = "/moving_out_service1";
                break;
            case "Home Renovation":
                route = "/home_Renovation1";
                break;
            case "General  Handyman":
                route = "/Generalhandyman1";
                break;
            case "Office Cleaning":
                route = "/office_cleaning";
                break;
            case "For Housing Cooperation":
                route = "/for_housing_cooperative";
                break;
            case "Office Relocation":
                route = "/office_relocation";
                break;
            case "Cleaning As Salery Benifit":
                route = "/cleaning_as_salery_benifit";
                break;

            default:
                // Default to /Booking if the selected service is not recognized
                route = "/Booking";
        }

        navigate(route, {
            state: {
                data: {
                    city,
                },
            },
        });
    };
    /* const handleNavigate = () => {

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        let route = "/Booking"; // Default route for Home Cleaning

        switch (selectedService) {
            case "Home Cleaning":
                route = "/Booking";
                break;
            case "Window Cleaning":
                route = "/window_cleaning";
                break;
            case "Deep Cleaning":
                route = "/deep_cleaning";
                break;
            case "Move Out Cleaning":
                route = "/moving_out_cleaning";
                break;
            default:
                // Default to /Booking if the selected service is not recognized
                route = "/Booking";
        }

        navigate(route, {
            state: {
                data: {
                    city,
                },
            },
        });
    }; */
    const handleInputChange = (e) => {
        const enteredZipCode = e.target.value;
        const isValidZipCode = /^[0-9]{1,5}$/.test(enteredZipCode);
        const filteredCity = ZIPCODES.filter(
            (item) => item?.zipcode == enteredZipCode
        );
        if (filteredCity.length > 0) {
            setCode(e.target.value);
            setCity(filteredCity[0]?.city);
            setShow(true);
        } else {
            setCode(e.target.value);
            setShow(false);
        }
    };
    return (
        <div className="bg-[#EAE8E1]">


            <div className=" flex justify-center text-[#1e1e1e] w-full flex-col items-center mb-10">
                <div className="  mt-10   text-black text-5xl font-medium font-['Young Serif']   ">
                    <p className="text-center text-4xl font-bold  ">Check if <span className="text-yellow-500 heading">Our Services are Available</span> in Your Area!
                    </p>
                </div>
                <div className="relative mt-10 mb-3">
                    <input
                        type="text"
                        placeholder="ENTER ZIPCODE HERE"
                        id="text"
                        className="border border-solid text-xl border-black w-full mx-1 font-semibold rounded-md mb-4 p-5 custom-width relative"
                        value={code}
                        maxLength="5"
                        ref={zipcodeRef}
                        onChange={handleInputChange}
                    />
                    {show && city && (
                        <div className="absolute right-6 top-0  py-4 flex items-center">
                            <svg
                                height="24"
                                width="24"
                                viewBox="0 0 24 24"
                                className="fcNb"
                                style={{ fill: "green", marginRight: "5px" }}
                            >
                                <g>
                                    <path d="m12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24zm0 22.286a10.286 10.286 0 1 1 0-20.572 10.286 10.286 0 0 1 0 20.572z"></path>
                                    <path d="m17.4 7.114-6.257 6.24-2.828-2.81a.848.848 0 1 0 -1.2 1.2l3.428 3.428a.824.824 0 0 0 1.2 0l6.857-6.858a.849.849 0 0 0 -1.2-1.2z"></path>
                                </g>
                            </svg>
                            <select
                                value={selectedService}
                                onChange={(e) => handleServiceSelect(e.target.value)}
                                className="flex-1 text-black text-lg text-right rounded-lg placeholder-black font-bold cursor-pointer bg-opacity-90 transform transition-transform delay-200 hover:placeholder-gray-500"
                            >
                                <option value="" disabled className='text-center'> {t('generalComponents.dropDown.dropDownElement1')}</option>
                                <option value="Moving Service" className='text-center'> {t('generalComponents.dropDown.dropDownElement6')}</option>
                                <option value="Move Out Cleaning" className='text-center'> {t('generalComponents.dropDown.dropDownElement5')}</option>
                                <option value="Moving And MoveOut Cleaning" className='text-center'> {t('contactPages.movingAndMoveOutCleaning')}</option>
                                <option value="Office Relocation" className='text-center'> {t('generalComponents.dropDown.dropDownElement11')}</option>
                                <option value="Office Cleaning" className='text-center'> {t('generalComponents.dropDown.dropDownElement9')}</option>
                                <option value="Home Renovation" className='text-center'> {t('generalComponents.dropDown.dropDownElement7')}</option>
                                <option value="General  Handyman" className='text-center'> {t('generalComponents.dropDown.dropDownElement8')}</option>
                                <option value="Home Cleaning" className='text-center'> {t('generalComponents.dropDown.dropDownElement2')}</option>
                                <option value="Window Cleaning" className='text-center'> {t('generalComponents.dropDown.dropDownElement3')}</option>
                                <option value="Deep Cleaning" className='text-center'> {t('generalComponents.dropDown.dropDownElement4')}</option>
                                <option value="Dödsbo" className='text-center'> Dödsbo</option>
                                <option value="For Housing Cooperation" className='text-center'> {t('generalComponents.dropDown.dropDownElement10')}</option>
                                <option value="Cleaning As Salery Benifit" className='text-center'> {t('generalComponents.dropDown.dropDownElement12')}</option>

                            </select>
                            <button
                                className="rounded-xl bg-[#000000] py-1 mx-2 text-white  relative group cursor-pointer border-2  font-medium text-[16px] border-black px-1"
                                onClick={handleNavigate}
                                disabled={!selectedService}
                            >
                                CONTINUE
                            </button>
                            <p className="absolute right-80 mr-3
 top-0 py-5 text-black text-lg font-bold text-right pointer-events-none z-20">{city}</p>

                        </div>

                    )}
                    <div className="w-full border-0" style={{ marginTop: "-10px" }}>
                        {!show && (code.length >= 1 || code.length > 5) && (
                            <p className="text-red-500 text-xs text-center mt-2 flex items-center justify-center">
                                This is not a valid zip code.
                            </p>
                        )}
                        {!show && code.length === 5 && (
                            <p className="text-red-500 text-xs text-center mt-2">
                                Sorry, We are not serving services in your area.
                            </p>
                        )}
                        {show && code.length === 5 && (
                            <p className="text-black-500 text-xl font-semibold text-center">
                                Great! Our Services Are Available in {city}
                            </p>
                        )}
                    </div>
                </div>


                {/*    <div className="relative mt-10 mb-3">

                <div className="flex items-center">
                    <input
                        type="text"
                        placeholder="ENTER ZIPCODE HERE"
                        id="text"
                        className="border w-full  font-semibold  mb-4 p-5 border-[#d5d2c4] custom-width relative "
                        value={code}
                        maxLength="5"
                        ref={zipcodeRef}
                        onChange={handleInputChange}
                    />
                    {show && city && (
                        <div>
                            <div
                                onClick={handleRef}
                                className="absolute right-80 top-4 text-right text-gray-700 pointer-events-none "
                            >


                                <div className="absolute  top-0 flex ">
                                    <svg
                                        height="24"
                                        width="24"
                                        viewBox="0 0 24 24"
                                        className="fcNb"
                                        style={{ fill: "green", }}
                                    >
                                        <g>
                                            <path d="m12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24zm0 22.286a10.286 10.286 0 1 1 0-20.572 10.286 10.286 0 0 1 0 20.572z"></path>
                                            <path d="m17.4 7.114-6.257 6.24-2.828-2.81a.848.848 0 1 0 -1.2 1.2l3.428 3.428a.824.824 0 0 0 1.2 0l6.857-6.858a.849.849 0 0 0 -1.2-1.2z"></path>
                                        </g>
                                    </svg>


                                    

                                </div>


                                <p className="absolute right-10 top-0 text-right text-gray-700 pointer-events-none z-20">{city}</p>




                            </div>
                            <div className="border w-full font-semibold  mb-4 p-5 bg-white relative mr-20 z-20 ">
                                <select
                                    value={selectedService}
                                    onChange={(e) => handleServiceSelect(e.target.value)}

                                    className="flex-1 text-black text-lg text-right rounded-lg placeholder-black font-bold cursor-pointer bg-opacity-90 transform transition-transform delay-200 hover:placeholder-gray-500"
                                >
                                    <option value="" disabled>Select a service</option>
                                    <option value="Home Cleaning">Home Cleaning</option>
                                    <option value="Window Cleaning">Window Cleaning</option>
                                    <option value="Deep Cleaning">Deep Cleaning</option>
                                    <option value="Move Out Cleaning">Move Out Cleaning</option>
                                </select>

                                <button
                                    className=" rounded-xl   bg-[#003942] overflow-hidden relative group cursor-pointer border-2 font-medium text-[16px] border-black px-1 text-white hover:scale-105 duration-[900ms] hover:border-[#003942] hover:text-[white]"

                                    onClick={handleNavigate}
                                    disabled={!selectedService}
                                >
                                    CONTINUE
                                </button>
                            </div>
                        </div>)}
                </div>


                <div
                    className="w-full border-0"
                    style={{ marginTop: "-10px" }}
                >

                    {!show && (code.length >= 1 || code.length > 5) && (
                        <p className="text-red-500 text-xs text-center mt-2 flex items-center justify-center">
                            This is not a valid zip code.
                        </p>
                    )}


                    {!show && code.length === 5 && (
                        <p className="text-red-500 text-xs text-center mt-2">
                            Sorry, We are not serving services  in your
                            area.
                        </p>
                    )}
                    {show && code.length === 5 && (
                        <p className="text-black-500  text-xl font-semibold  text-center ">
                            Great! We Are Provided Services In Your {city}
                        </p>
                    )}
                </div>

            </div> */}




            </div>
        </div >);


}

export default OurService;
