import { Link } from 'react-router-dom';
import React, { useEffect, useRef, useState } from "react";
import { ZIPCODES } from "../../Constants/Constant";
import { useNavigate } from "react-router";
import { NavLink } from 'react-router-dom';

import useTranslatePage from './../../Gernal_Components/TranslatePage';
import Loader from './../../Gernal_Components/Loader';

/* import Image1 from "../../../../Assets/images/New Images/Resized-1.jpeg";
 */

import Image1 from "../../../Assets/images/New Images/Resized-1.jpeg";
import Image4 from "../../../Assets/images/contact-contact.jpeg";
import { FaTimes } from "react-icons/fa"
import { Button } from "@chakra-ui/react"
import Image2 from '../../../Assets/images/Partner_page_Section2___Contact_us_section.jpg'
import Image3 from '../../../Assets/images/salary_benefit.avif'
import map from '../../../Assets/images/staticmap.png'

import { useTranslation } from 'react-i18next';

const Contact = () => {

    const { t } = useTranslation();
    
    const [translatePage, loading] = useTranslatePage();
  
    useEffect(() => {
        const lang = localStorage.getItem('language');
        if (lang) {
        translatePage(lang);
        }
    }, []);  

    const [modalOpen, setModalOpen] = useState(false);

    const [city, setCity] = useState("");
    const [code, setCode] = useState("");
    const [show, setShow] = useState(false);
    const [selectedService, setSelectedService] = useState("");

    const zipcodeRef = useRef();
    const handleRef = () => {
        zipcodeRef.current.focus();
    };
    const handleServiceSelect = (selectedService) => {
        setSelectedService(selectedService);
        console.log(selectedService)
    };
    const navigate = useNavigate();

    const handleNavigate = () => {

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        let route = "/Booking"; // Default route for Home Cleaning

        switch (selectedService) {
            case "Home Cleaning":
                route = "/Booking";
                break;
            case "Window Cleaning":
                route = "/window_cleaning";
                break;
            case "Deep Cleaning":
                route = "/deep_cleaning";
                break;
            case "Dödsbo":
                route = "/Dödsbo";
                break;
            case "Move Out Cleaning":
                route = "/moving_out_cleaning";
                break;
            case "Moving And MoveOut Cleaning":
                route = "/moving_out_service2";
                break;
            case "Moving Service":
                route = "/moving_out_service1";
                break;
            case "Home Renovation":
                route = "/home_Renovation1";
                break;
            case "General  Handyman":
                route = "/Generalhandyman1";
                break;
            case "Office Cleaning":
                route = "/office_cleaning";
                break;
            case "For Housing Cooperation":
                route = "/for_housing_cooperative";
                break;
            case "Office Relocation":
                route = "/office_relocation";
                break;
            case "Cleaning As Salery Benifit":
                route = "/cleaning_as_salery_benifit";
                break;

            default:
                // Default to /Booking if the selected service is not recognized
                route = "/Booking";
        }

        navigate(route, {
            state: {
                data: {
                    city,
                },
            },
        });
    };
    const handleInputChange = (e) => {
        const enteredZipCode = e.target.value;
        const isValidZipCode = /^[0-9]{1,5}$/.test(enteredZipCode);
        const filteredCity = ZIPCODES.filter(
            (item) => item?.zipcode == enteredZipCode
        );
        if (filteredCity.length > 0) {
            setCode(e.target.value);
            setCity(filteredCity[0]?.city);
            setShow(true);
        } else {
            setCode(e.target.value);
            setShow(false);
        }
    };
    const handleClick = () => {
        // Scroll to the top of the page
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        // Introduce a delay (you can adjust the duration)
        const delayDuration = 500; // in milliseconds
        setTimeout(() => {
            // Now, navigate to the desired route
        }, delayDuration);
    };
    return (
        <>
        {loading && <Loader />}
            <div
                className="bg-cover w-full h-full"
                style={{
                    backgroundImage:
                        `url(${Image1})`,
                }}
            >
                <div className=" mr-auto pt-48 md:px-28 px-10 text-white text-5xl font-medium font-['Young Serif'] ">



                    <div className=" flex justify-center text-[black] font-bold w-full flex-col items-center">
                        <div className="  mt-[-4px]   text-black text-xl font-medium font-['Young Serif']   ">
                            <p className="text-4xl font-bold "> <span className='text-red-500'>{t('contact.hero.headingPart1')} </span><span className="text-yellow-500">{t('contact.hero.headingPart2')}</span>
                            </p>
                        </div>
                        <div className="relative mt-5 mb-10">
                            <div className="flex md:items-center md:w-3-6 md:ml-0 ml-[10%] w-4/5 ">
                                <input
                                    type="text"
                                    placeholder="ENTER ZIPCODE HERE"
                                    id="text"
                                    className="border border-solid text-xl border-black w-full mx-1 font-semibold rounded-md mb-5 p-5 custom-width relative"
                                    value={code}
                                    maxLength="5"
                                    ref={zipcodeRef}
                                    onChange={handleInputChange}
                                />
                                {show && city && (
                                    <div className="absolute right-0 pb-4 flex items-center">
                                        <p className="text-lg font-bold mr-4 text-green-500">{city}</p>
                                        <select
                                            value={selectedService}
                                            onChange={(e) => handleServiceSelect(e.target.value)}
                                            className="text-black text-lg text-right rounded-lg placeholder-black font-bold cursor-pointer bg-opacity-90 transform transition-transform delay-200 hover:placeholder-gray-500"
                                        >
                                            <option value="" disabled className='text-center'> {t('generalComponents.dropDown.dropDownElement1')}</option>
                                            <option value="Moving Service" className='text-center'> {t('generalComponents.dropDown.dropDownElement6')}</option>
                                            <option value="Move Out Cleaning" className='text-center'> {t('generalComponents.dropDown.dropDownElement5')}</option>
                                            <option value="Moving And MoveOut Cleaning" className='text-center'> {t('contactPages.movingAndMoveOutCleaning')}</option>
                                            <option value="Office Relocation" className='text-center'> {t('generalComponents.dropDown.dropDownElement11')}</option>
                                            <option value="Office Cleaning" className='text-center'> {t('generalComponents.dropDown.dropDownElement9')}</option>
                                            <option value="Home Renovation" className='text-center'> {t('generalComponents.dropDown.dropDownElement7')}</option>
                                            <option value="General  Handyman" className='text-center'> {t('generalComponents.dropDown.dropDownElement8')}</option>
                                            <option value="Home Cleaning" className='text-center'> {t('generalComponents.dropDown.dropDownElement2')}</option>
                                            <option value="Window Cleaning" className='text-center'> {t('generalComponents.dropDown.dropDownElement3')}</option>
                                            <option value="Deep Cleaning" className='text-center'> {t('generalComponents.dropDown.dropDownElement4')}</option>
                                            <option value="Dödsbo" className='text-center'> Dödsbo</option>
                                            <option value="For Housing Cooperation" className='text-center'> {t('generalComponents.dropDown.dropDownElement10')}</option>
                                            <option value="Cleaning As Salery Benifit" className='text-center'> {t('generalComponents.dropDown.dropDownElement12')}</option>
                                        </select>
                                        <button
                                            className="rounded-xl bg-[#000000] py-1 mx-2 text-white relative group cursor-pointer border-2 font-medium text-[16px] border-black px-1"
                                            onClick={handleNavigate}
                                            disabled={!selectedService}
                                        >
                                            CONTINUE
                                        </button>
                                    </div>
                                )}
                            </div>

                            <div className="w-full border-0" style={{ marginTop: "-10px" }}>
                                {!show && (code.length >= 1 || code.length > 5) && (
                                    <p className="text-red-500 text-xs text-center mt-2 flex items-center justify-center">
                                        This is not a valid zip code.
                                    </p>
                                )}
                                {!show && code.length === 5 && (
                                    <p className="text-red-500 text-xs text-center mt-2">
                                        Sorry, We are not serving services in your area.
                                    </p>
                                )}
                                {show && code.length === 5 && (
                                    <p className="text-black text-2xl font-semibold text-center">
                                        <span className='text-yellow-500'>Great!</span> Our Services Are Available in <span className='font-bold text-2xl text-pink-900'>{city}</span>
                                    </p>
                                )}
                            </div>
                        </div>

                    </div>
                </div >
            </div >

            <div className="w-full flex flex-col md:flex-row justify-center md:justify-between items-center md:items-between py-10" style={{ background: '#d1dce7' }}>
                    <div className='w-[100%] h-auto lg:w-[666px] lg:h-[550px] overflow-hidden ml-0 md:ml-auto'>
                        <div className="w-[100%] h-[400px] md:h-[550px] lg:w-[666px] flex justify-center items-end background-cover bg-no-repeat bg-cover overflow-hidden transition-transform transform hover:scale-105 duration-300 image-div relative" style={{backgroundImage:`url(${Image4})`}}>
                            <Button style={{ background: "black" }} onClick={() => setModalOpen(true)} className="h-[30px] rounded-lg px-4 pt-2 overflow-hidden relative bottom-[-50px] group cursor-pointer border-2 font-medium text-[16px] border-white text-black hover:scale-105 duration-[900ms] hover:border-[#003942] hover:text-[white] preview-button">
                                <span className="absolute w-72 h-0 transition-all duration-[900ms] origin-center rotate-45 -translate-x-20 bg-white top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                                <span className="relative text-xl text-white transition duration-[900ms] group-hover:text-black ease mb-2">{t('generalComponents.preview')}</span>
                            </Button>
                        </div>
                    </div>
                    {modalOpen && (
                        <div style={{zIndex: 9999}} className="z-5000 fixed top-[30px] left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
                            <div className="fadeIn bg-white p-4 rounded-lg relative z-5001">
                                <div onClick={() => setModalOpen(false)} className='absolute top-0 right-0 m-2 p-2 cursor-pointer rounded-full border-2 border-orange-600 text-orange-600 bg-white text-center'>
                                <FaTimes />
                                </div>
                                <img src={Image4} alt="Full Size" className="h-[500px] rounded-lg"/>
                                <button onClick={() => setModalOpen(false)}>{t('generalComponents.close')}</button>
                            </div>
                        </div>
                    )}
                    <div className='w-5/6 md:w-1/2 flex align-center flex-col justify-center md:ml-5' >
                        <div className='text-center md:text-left text-5xl font-medium' style={{ fontFamily: 'Tiempos Headline,serif', }}>{t('contact.contactUs.heading.headingPart1')} <span className='text-yellow-500 heading'>{t('contact.contactUs.heading.headingPart1')}</span>?</div>
                        <div className="w-full mt-5 flex justify-center md:justify-left items-center md:items-left mb-5">
                            <p className="border-black border w-20  text-center md:text-left "></p>
                        </div>
                        <p className='justify-left text-center md:text-left front-black font-semibold text-base' style={{ width: '90%' }}>
                            {t('contact.contactUs.content1')}
                        </p>

                        <p className='justify-left text-center md:text-left font-normal text-base' style={{ width: '90%' }}>{t('contact.contactUs.content2')} </p>
                        <p className='justify-left text-center md:text-left font-normal text-base' style={{ width: '90%' }}>{t('contact.contactUs.content3')}</p>
                        <p className='justify-left text-center md:text-left front-black font-semibold text-base mt-2' style={{ width: '90%' }}>
                            {t('contact.contactUs.content4')}
                        </p>

                        <p className='justify-left text-center md:text-left font-bold text-base' style={{ width: '90%' }}>+46730375702</p>
                        <p className='justify-left text-center md:text-left font-bold text-base' style={{ width: '90%' }}>+46760780356</p>
                        <p className='justify-left text-center md:text-left front-black font-semibold text-base mt-2' style={{ width: '90%' }}>
                            {t('contact.contactUs.content5')}
                        </p>

                        <p className='justify-left text-center md:text-left font-bold text-base' style={{ width: '90%' }}>info@flytt-stad-atervinn.com</p>
                        <p className='justify-left text-center md:text-left front-black font-semibold text-base mt-2' style={{ width: '90%' }}>{t('contact.contactUs.content6')} </p>
                        <p className='justify-left text-center md:text-left font-bold text-base' style={{ width: '90%' }}>{t('contact.contactUs.content7')}</p>

                    </div>
            </div>


            <div className='bg-[#eae8e1] flex justify-center items-center'>
            <div className="w-5/6 md:w-full relative font-['Roboto'] ">

                <div className="hover:transform scale-90  text-5xl">


                    <div className="pt-36 font-bold">{t('generalComponents.helpWith.headingPart1')} <span className="text-red-500">{t('generalComponents.helpWith.headingPart2')}</span>{t('generalComponents.helpWith.headingPart3')}</div>
                    <div class="w-full flex justify-center mt-8 relative group ">
                        <p class="border-black hover:border-white border-2 w-20 hover:w-[1000PX] text-center transition-all duration-300 transform origin-center scale-100 "></p>
                    </div>
                </div>



                <div className="flex justify-center items-center flex-col xl:mx-10 lg:mx-7 2xl:mx-80 mt-16 h-auto">
                    <div className="flex flex-col lg:flex-row items-center w-fit" >




                        <div className="lg:mr-20 mr-0">
                            <div className="flex items-start relative">
                                <Link to="/MovingService" onClick={() => window.scrollTo(0, 0)}>
                                    <img src="img/ha.svg" className="w-8 inline-block hover:shake" alt="" />
                                </Link>
                                <Link to="/MovingService" className="ml-12" onClick={() => window.scrollTo(0, 0)}>
                                    <p className="inline-block text-2xl" >{t('generalComponents.helpWith.linkMovingServices')}</p>
                                </Link>
                                <Link to="/MovingService" onClick={() => window.scrollTo(0, 0)}>
                                    <p className="text-center text-4xl absolute end-0 bottom-3 cursor-pointer">
                                        <svg height="24" viewBox="0 0 9 15" width="24" className="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}>
                                            <path d="m.958.995 6.51 6.51-6 6" fill="none" strokeWidth="2"></path>
                                        </svg>
                                    </p>
                                </Link>
                            </div>
                            <p className="border-[#d5d2c4] border-b-2 my-4 pl-[450px]"></p>

                        </div>


                        <div>
                            <div className="flex items-start relative">
                                <Link to="/Home_clean" onClick={() => window.scrollTo(0, 0)}>
                                    <img className="inline-block h-12 hover:shake" src="img/de3.svg" alt="" />
                                </Link>
                                <Link to="/Home_clean" onClick={() => window.scrollTo(0, 0)}>
                                    <p className="text-2xl inline-block ml-11">{t('generalComponents.helpWith.linkHomeCleaning')}</p>
                                </Link>
                                <Link to="/Home_clean" onClick={() => window.scrollTo(0, 0)}>
                                    <p className="text-center text-4xl absolute end-0 bottom-3 cursor-pointer">
                                        <svg height="24" viewBox="0 0 9 15" width="24" className="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}>
                                            <path d="m.958.995 6.51 6.51-6 6" fill="none" strokeWidth="2"></path>
                                        </svg>
                                    </p>
                                </Link>
                            </div>
                            <p className="border-[#d5d2c4] border-b-2 my-4 pl-[450px]"></p>
                        </div>


                    </div>


                    <div className="flex flex-col lg:flex-row items-center w-fit">

                        <div className="lg:mr-20 mr-0">
                            <div className="flex items-start relative">
                                <Link to="/Move_Out_Cleaning" onClick={() => window.scrollTo(0, 0)}>
                                    <img className="h-12 inline-block"
                                        src='img/f.svg'
                                        class="w-10 inline-block hover:shake" alt="" />
                                </Link>
                                <Link to="/Move_Out_Cleaning" onClick={() => window.scrollTo(0, 0)}>

                                    <p className="text-2xl inline-block ml-10">{t('generalComponents.helpWith.linkMovingOutCleaning')}</p>
                                </Link>
                                <Link to="/Move_Out_Cleaning" onClick={() => window.scrollTo(0, 0)}>
                                    <p className="text-center text-4xl   absolute  end-0 top-1 cursor-pointer"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                </Link>
                            </div>
                            <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center pl-[450px] flex-wrap " ></p>
                        </div>
                        <div className="">

                            <div className="flex items-end relative">
                                <Link to="/Deep_Clean" onClick={() => window.scrollTo(0, 0)}>

                                    <img className="h-12 inline-block"
                                        src='img/genral.svg'
                                        class="w-8 inline-block hover:shake" alt="" />

                                    <p className="text-2xl inline-block ml-11">{t('generalComponents.helpWith.linkDeepCleaning')}</p>


                                    <p className="text-center text-4xl   absolute bottom-3 end-0"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>

                                </Link></div>


                            <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center pr-[450px] flex-wrap" ></p>
                        </div>
                    </div>
                    <div className="flex  flex-col lg:flex-row items-center w-fit">

                        <div className="lg:mr-20 mr-0">

                            <div className="flex items-start relative">
                                <Link to="/moving_out_service2" onClick={() => window.scrollTo(0, 0)}>
                                    <img className="h-12 inline-block"
                                        src='img/wi2.svg'
                                        class="h-10 inline-block hover:shake" alt="" />
                                </Link>
                                <Link to="/moving_out_service2" onClick={() => window.scrollTo(0, 0)}>
                                    <p className="inline-block text-2xl ml-10">{t('generalComponents.helpWith.linkMoveService&MoveoutCleaning')}</p>
                                </Link>
                                <Link to="/moving_out_service2" onClick={() => window.scrollTo(0, 0)}>
                                    <p className="text-center text-4xl   absolute  end-0 top-2 cursor-pointer"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                </Link>
                                <Link />
                            </div>




                            <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center pl-[450px]" ></p>
                        </div>


                        <div>
                            <div className="flex items-start relative flex-wrap">
                                <Link to="/Window_clean" onClick={() => window.scrollTo(0, 0)}>
                                    <img className="h-10 inline-block"
                                        src='img/t.svg'
                                        class="w-10 inline-block hover:shake" alt="" />

                                    <p className="text-2xl inline-block ml-10">{t('generalComponents.helpWith.linkWindowCleaning')}</p>
                                    <p className="text-center text-4xl top-2  absolute end-0"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                </Link>
                            </div>

                            <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center pr-[450px]" ></p>
                        </div>

                    </div>


                    <div className="flex flex-col lg:flex-row items-center w-fit">

                        <div className="lg:mr-20 mr-0">
                            <div className="flex items-start relative">
                                <Link to="/office_relocation" onClick={() => window.scrollTo(0, 0)}>
                                <img className="h-12 inline-block"
                                    src='img/n.svg'
                                    class="w-8 inline-block hover:shake" alt="" />
                                <p className="text-2xl inline-block ml-12 ">{t('generalComponents.helpWith.linkOfficeRelocation')}</p>
                                <p className="text-center text-4xl   absolute end-0 top-2 cursor-pointer"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                </Link>
                            </div>


                            <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center pl-[450px]" ></p>
                        </div>
                        <div>
                            <div className="flex items-start relative">
                                <Link to="/Dödsbo" onClick={() => window.scrollTo(0, 0)}>

                                    <img className="h-12 inline-block"
                                        src='img/n3.svg'
                                        class="h-10 inline-block hover:shake" alt="" />

                                    <p className="text-2xl inline-block ml-10">Dödsbo</p>
                                    <p className="text-center text-4xl  top-2 absolute end-0"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                </Link>
                            </div>

                            <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center pr-[450px]" ></p>
                        </div>


                    </div>
                    <div className="flex flex-col lg:flex-row items-center w-fit">

                        <div className="lg:mr-20 mr-0">
                            <div className="flex items-start relative">
                                <Link to="/office_cleaning" onClick={() => window.scrollTo(0, 0)}>
                                <img className="h-12 inline-block"
                                    src='img/wi4.svg'
                                    class="w-10 inline-block hover:shake" alt="" />
                                <p className="text-2xl inline-block ml-10 ">{t('generalComponents.helpWith.linkOfficeCleaning')}</p>
                                <p className="text-center text-4xl   absolute end-0 top-2 cursor-pointer"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                </Link>
                            </div>


                            <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center pl-[450px]" ></p>
                        </div>
                        <div>
                            <div className="flex items-start relative">
                            <Link to="/for_housing_cooperative" onClick={() => window.scrollTo(0, 0)}>
                                    <img className="h-12 inline-block"
                                        src='img/wi4.svg'
                                        class="h-10 inline-block hover:shake" alt="" />
                                <p className="text-2xl inline-block ml-10">{t('generalComponents.helpWith.linkForHousingCooperative')}</p>
                                <p className="text-center text-4xl  top-2 absolute end-0"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                </Link>
                            </div>

                            <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center pr-[450px]" ></p>
                        </div>


                    </div>
                    <div>
                        <div className="flex flex-col lg:flex-row items-center w-fit">

                            <div className="lg:mr-20 mr-0">
                                <div className="flex items-start relative">
                                    <Link to="/home_Renovation" onClick={() => window.scrollTo(0, 0)}>
                                        <img className="h-12 inline-block"
                                            src='img/wi4.svg'
                                            class="w-10 inline-block hover:shake" alt="" />
                                        <p className="text-2xl inline-block ml-10 ">{t('generalComponents.helpWith.linkHomeRenovation')}</p>
                                        <p className="text-center text-4xl   absolute end-0 top-2 cursor-pointer"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                    </Link></div>


                                <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center pl-[450px]" ></p>
                                <div className="flex items-start relative">
                                    <Link to="/Generalhandyman" onClick={() => window.scrollTo(0, 0)}>
                                        <img src="img/ha.svg" className="w-8 inline-block hover:shake" alt="" />
                                    </Link>
                                    <Link to="/Generalhandyman" className="ml-12" onClick={() => window.scrollTo(0, 0)}>
                                        <p className="inline-block text-2xl" >{t('generalComponents.helpWith.linkGeneralHandyman')}</p>
                                    </Link>
                                    <Link to="/Generalhandyman" onClick={() => window.scrollTo(0, 0)}>
                                        <p className="text-center text-4xl absolute end-0 bottom-3 cursor-pointer">
                                            <svg height="24" viewBox="0 0 9 15" width="24" className="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}>
                                                <path d="m.958.995 6.51 6.51-6 6" fill="none" strokeWidth="2"></path>
                                            </svg>
                                        </p>
                                    </Link>
                                </div>
                                <p className="border-[#d5d2c4] border-b-2 my-4 pl-[450px]"></p>

                            </div>

                            <div>
                                <div className="flex items-start relative">
                                    <Link to="/Generalhandyman" onClick={() => window.scrollTo(0, 0)}>
                                        <img className="h-12 inline-block"
                                            src='img/wi4.svg'
                                            class="h-10 inline-block hover:shake" alt="" />
                                        <p className="text-2xl inline-block ml-10">{t('generalComponents.helpWith.linkCleaningasSalaryBenefit')}</p>
                                        <p className="text-center text-4xl  top-2 absolute end-0"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                    </Link>
                                </div>
                                <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center pr-[450px]" ></p>
                                <div className="flex items-start relative">
                                    <a href="/Moving_Home">
                                        <img className="h-12 inline-block"
                                            src='img/wi4.svg'
                                            class="h-10 inline-block hover:shake" alt="" />
                                    </a>
                                    <p className="text-2xl inline-block ml-10">{t('generalComponents.helpWith.linkAllServices')}</p>
                                    <a href="/Moving_Home">
                                        <p className="text-center text-4xl  top-2 absolute end-0"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                    </a>
                                </div>
                                <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center pr-[450px]" ></p>
                            </div>


                        </div>
                    </div>

                </div>
            </div>
        </div>

            <div class="flex flex-col md:flex-row justify-center items-center">
                <div className='flex align-center flex-col justify-center my-10 md:my-0'>
                    <div className='text-center md:text-left  text-5xl font-medium' style={{ fontFamily: 'Tiempos Headline,serif' }}>{t('contact.location.content1')}</div>
                    <div className="w-full mt-8 flex justify-center md:justify-left items-center md:items-left mb-5">
                        <p className="border-black border w-20 text-left"></p>
                    </div>
                    <br />
                    <p className='justify-center md:justify-left text-center md:text-left font-normal ps-5 text-base flex items-center' style={{ width: '90%' }}>
                        <svg width="36" height="36" xmlns="http://www.w3.org/2000/svg" fill="#1e1e1e" viewBox="0 0 16 16"><path d="M8,15H8a.49.49,0,0,1-.42-.24L3.93,8.88c-.07-.1-.38-.64-.38-.64A4.91,4.91,0,0,1,3,6,5,5,0,0,1,13,6a4.88,4.88,0,0,1-.54,2.22v0s-.3.54-.39.66L8.42,14.76A.49.49,0,0,1,8,15ZM4.44,7.78s.24.44.32.54L8,13.55l3.23-5.2c.08-.12.3-.51.34-.58A3.91,3.91,0,0,0,12,6,4,4,0,1,0,4.44,7.78ZM12,8h0Z" /><path d="M8,8a2,2,0,1,1,2-2A2,2,0,0,1,8,8ZM8,5A1,1,0,1,0,9,6,1,1,0,0,0,8,5Z" /></svg>
                        <NavLink to='/new-partener' className='ps-9'>
                            <p className='font-semibold text-2xl'>{t('contact.location.content2')}</p>
                            <p className='text-2xl'>Dalgatan 5b lgh 1101 195 47 ,Märsta</p>
                        </NavLink>.
                    </p>
                    <br />
                    <br />
                    <p className='justify-center md:justify-left text-center md:text-left font-normal ps-5 text-base flex items-center' style={{ width: '90%' }}>
                        <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none"><circle cx="8" cy="8" r="6.5" stroke="#1E1E1E" /><path d="M7.5 5V8.5L10.5 11" stroke="#1E1E1E" stroke-linecap="round" stroke-linejoin="round" /></svg>
                        <NavLink to='/new-partener' className='ps-9'>
                            <p className='font-semibold text-2xl'>{t('contact.location.content3')}</p>
                            <p className='text-2xl'>Mo-Fr: 08-17, Sa-Su: {t('contact.location.content4')}</p>
                        </NavLink>.
                    </p>
                    <br />
                    <br />
                    <Link to="mailto:your@email.com">
                        <p className='justify-center md:justify-left text-center md:text-left font-normal ps-5 text-base flex items-center' style={{ width: '90%' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="36" fill="#1e1e1e" height="32" viewBox="0 0 32 32" ><clipPath id="a"><path d="m0 0h32v32h-32z" /></clipPath><g clip-path="url(#a)" fill="#1e1e1e"><path d="m32 15.9998c.0025-3.4936-1.1385-6.89206-3.2488-9.67629-2.1103-2.78424-5.074-4.80124-8.4382-5.742971-3.3643-.941734-6.9444-.75644-10.1935.527581-3.24913 1.28403-5.98867 3.59621-7.80027 6.58342-1.811608 2.98726-2.595697 6.48526-2.2325268 9.95996.3631698 3.4747 1.8536368 6.735 4.2437768 9.2831 2.39015 2.5481 5.54859 4.2438 8.99302 4.8282 3.4444.5844 6.9854.0255 10.0822-1.5916l7.1772 1.7829c.09.0222.1842.0222.2743 0 .1498.0018.2985-.0278.4362-.0868.1378-.0591.2617-.1463.3638-.2561.143-.1352.2478-.3056.304-.4942s.0617-.3887.016-.5801l-1.8058-7.1771c1.1906-2.2714 1.8177-4.7956 1.8286-7.36zm-4.16 7.5428 1.44 5.7372-5.7371-1.44c-.2864-.0679-.5877-.0272-.8457.1143-2.8893 1.6195-6.2671 2.1387-9.5093 1.4617-3.24231-.6771-6.13-2.5047-8.12963-5.1452s-2.97608-5.9155-2.74897-9.2199c.22712-3.3044 1.64245-6.415 3.98454-8.75709s5.45266-3.75742 8.75706-3.98454c3.3045-.22712 6.5794.74934 9.2199 2.74897s4.4681 4.88732 5.1452 8.12956c.6771 3.2423.1579 6.6201-1.4617 9.5093-.1415.2581-.1822.5594-.1143.8457z" /><path d="m9.14289 18.2858c1.26241 0 2.28571-1.0234 2.28571-2.2857 0-1.2624-1.0233-2.2857-2.28571-2.2857-1.26236 0-2.28571 1.0233-2.28571 2.2857 0 1.2623 1.02335 2.2857 2.28571 2.2857z" /><path d="m15.9999 18.2858c1.2624 0 2.2858-1.0234 2.2858-2.2857 0-1.2624-1.0234-2.2857-2.2858-2.2857-1.2623 0-2.2857 1.0233-2.2857 2.2857 0 1.2623 1.0234 2.2857 2.2857 2.2857z" /><path d="m22.8571 18.2858c1.2624 0 2.2857-1.0234 2.2857-2.2857 0-1.2624-1.0233-2.2857-2.2857-2.2857-1.2623 0-2.2857 1.0233-2.2857 2.2857 0 1.2623 1.0234 2.2857 2.2857 2.2857z" /></g></svg>
                            <NavLink to="mailto:your@email.com" className='ps-9'>
                                <p className='font-semibold text-2xl'>{t('contact.location.content5')}</p>
                                <p className='text-2xl'> info@flytt-stad-atervinn.com</p>
                            </NavLink>.
                        </p>
                    </Link>
                    <br />
                    <br />
                        <p className='justify-center md:justify-left text-center md:text-left font-normal ps-5 text-base flex items-center' style={{ width: '90%' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="#1e1e1e" height="32" viewBox="0 0 20 32" width="32"><clipPath id="a"><path d="m.857056 0h18.2857v32h-18.2857z" /></clipPath><g clip-path="url(#a)" fill="#1e1e1e"><path d="m15.7149.00080005h-11.4286c-.45367-.01260945-.90515.06745315-1.32682.23529195-.42168.167839-.80469.419926-1.12561.740846-.32092.320922-.57301.703932-.74084 1.125602-.167843.42168-.247906.87316-.235296 1.32683v25.14283c-.01261.4537.067453.9052.235296 1.3269.16783.4216.41992.8046.74084 1.1256.32092.3209.70393.573 1.12561.7408.42167.1678.87315.2479 1.32682.2353h11.4286c.4537.0126.9051-.0675 1.3268-.2353s.8047-.4199 1.1256-.7408c.3209-.321.573-.704.7409-1.1256.1678-.4217.2479-.8732.2352-1.3269v-25.14283c.0127-.45367-.0674-.90515-.2352-1.32683-.1679-.42167-.42-.80468-.7409-1.125602-.3209-.32092-.7039-.573007-1.1256-.740846-.4217-.1678388-.8731-.2479014-1.3268-.23529195zm-11.4286 2.28570995h11.4286c.1536-.01405.3084.00586.4534.05833.1451.05246.2768.13619.3859.24526.109.10906.1927.24078.2452.38582.0525.14505.0724.29985.0583.45345v21.71433h-13.71425v-21.71433c-.01406-.1536.00586-.3084.05832-.45345.05246-.14504.1362-.27676.24526-.38582.10906-.10907.24079-.1928.38583-.24526.14504-.05247.29985-.07238.45344-.05833zm11.4286 27.42859h-11.4286c-.15359.014-.3084-.0059-.45344-.0583-.14504-.0525-.27677-.1362-.38583-.2453s-.1928-.2408-.24526-.3858c-.05246-.1451-.07238-.2999-.05832-.4535v-1.1428h13.71425v1.1428c.0141.1536-.0058.3084-.0583.4535-.0525.145-.1362.2767-.2452.3858-.1091.1091-.2408.1928-.3859.2453-.145.0524-.2998.0723-.4534.0583z" /><path d="m8.85709 6.85725h2.28571c.3031 0 .5938-.12041.8081-.33474.2144-.21433.3348-.50502.3348-.80812s-.1204-.59379-.3348-.80812c-.2143-.21433-.505-.33474-.8081-.33474h-2.28571c-.3031 0-.59379.12041-.80812.33474s-.33474.50502-.33474.80812.12041.59379.33474.80812.50502.33474.80812.33474z" /></g></svg>
                            <NavLink to='/new-partener' className='ps-9'>
                                <p className='font-semibold text-2xl'>{t('contact.location.content6')}</p>
                                <p className='text-2xl'>+46730375702 <br /> +46760780356</p>
                            </NavLink>

                        </p>
                </div>
                {/*                 <img src={map} style={{ width: '666px', height: '650px' }} alt='hemfred-marketing section 2' />
 */}
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2017.3807536302377!2d17.85314637645592!3d59.626669487185374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465fbc16cc3bd0ff%3A0xf9fcc296cf8de420!2sDalgatan%205B%2C%201101%2C%20195%2047%20M%C3%A4rsta%2C%20Sweden!5e0!3m2!1sen!2s!4v1709103786217!5m2!1sen!2s" width="666px" height="650px" allowFullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            
            <div className="md:mx-auto w-screen max-w-full py-10" style={{ background: '#dde6e0' }}>
                <div className='flex items-center flex-col justify-center py-20'>
                    <div className='text-center text-6xl font-medium' style={{ fontFamily: 'Tiempos Headline,serif' }}><span className='text-red-500 heading'>{t('contact.sustainability.heading')}</span></div>
                    <div className="w-full mt-8 flex justify-center items-center mb-5">
                        <p className="border-black border w-20 text-center"></p>
                    </div>
                    <p className='mx-auto text-2xl'>
                        {t('contact.sustainability.paragraph1')}</p>
                    <p className='mx-auto text-2xl'>{t('contact.sustainability.paragraph2')}
                    </p>
                    <br />
                    <Link to="/" onClick={handleClick}>
                        <button className='px-20 py-3 rounded border border-black' >{t('contact.sustainability.button')}</button>
                    </Link> </div>
            </div>
        </>
    );
}

export default Contact;
