import { useState } from "react";
import { BiSolidChevronDown } from "react-icons/bi";

import { useTranslation } from 'react-i18next';

const FAQ = () => {
    
    const { t } = useTranslation();

    const [activeIndex, setActiveIndex] = useState();
    const handleSetIndex = (index) => {
        setActiveIndex(index);
    };
    const faqs = [
        {
            question: `${t('generalHandyman.faq.questions.question1')}`,
            answer: t('generalHandyman.faq.questions.answer1', { returnObjects: true }),
        },
        {
            question: `${t('generalHandyman.faq.questions.question2')}`,
            answer: t('generalHandyman.faq.questions.answer2', { returnObjects: true }),
        },
        {
            question: `${t('generalHandyman.faq.questions.question3')}`,
            answer: t('generalHandyman.faq.questions.answer3', { returnObjects: true }),
        },
        {
            question: `${t('generalHandyman.faq.questions.question4')}`,
            answer: t('generalHandyman.faq.questions.answer4', { returnObjects: true }),
        },
        {
            question: `${t('generalHandyman.faq.questions.question5')}`,
            answer: t('generalHandyman.faq.questions.answer5', { returnObjects: true }),
        }
    ];
    return (
        <section id="faq">
            <div className="relative py-10 md:py-24 bg-gray-100 w-full flex flex-col items-center gap-8 ">
                <div>
                    <div className="relative bg-gray-100 min-h-full w-full flex flex-col items-center gap-8 ">
                        <h2 className="font-bold text-5xl mb-3">
                            {t('generalHandyman.faq.heading.headingPart1')} <span className="text-red-600">{t('generalHandyman.faq.heading.headingPart2')}</span>{" "}
                        </h2>
                        {faqs.map(({ question, answer }, i) => (
                            <div
                                className="bg-white rounded-lg  w-4/5 shadow-lg hover:shadow-2xl group gap-8"
                                onMouseLeave={() => setActiveIndex()}
                                onMouseEnter={() => handleSetIndex(i)}
                            >
                                <div className="flex justify-between items-center my-3  px-4 sm:px-8 h-10 cursor-pointer">
                                    <h3 className="lg:text-xl md:text-xl sm:text-xl text-xs  pb-2 text-black font-semibold">{question}</h3>
                                    <div className="text-3xl group-hover:rotate-180 transition duration-1500 ml-auto">
                                        {/* Right Icon */}
                                        <BiSolidChevronDown className="w-8 h-8  border-2 rounded-full text-green-500 border-green-500  group-hover:border-yellow-400 group-hover:text-yellow-400 " />
                                    </div>
                                </div>
                                <div
                                    x-ref="tab"
                                    className={`border-l-2 rounded-bl-md border-yellow-500 dark:border-none overflow-hidden duration-[1500ms] transition-all ${activeIndex === i ? "max-h-[500px]" : "max-h-0 "
                                        }`}
                                >
                                    <p className="text-start px-4 sm:px-8 py-2  p-3 text-xl font-light text-gray-700">
                                        {answer}
                                    </p>
                                </div>
                            </div>

                        ))}
                    </div>
                </div>
            </div>
        </section>
    );

}

export default FAQ;