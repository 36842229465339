import { useTranslation } from 'react-i18next';

const ValuationOfEstates = () => {
    
    const { t } = useTranslation();

    return (
        <div id="valuation_of_estates" className="flex flex-col justify-center items-center sm:mt-24 mb-16 text-left">
            <style jsx>{`
                .heading:hover {
                    animation: pulse 1s infinite;
                }
            `}</style>
            <div className="text-5xl font-bold text-center text-black mb-4 sm:mb-8 w-5/6">
                <h2><span className="text-orange-600">{t('dodsbo.valuationOfEstates.headingPart1')}</span> {t('dodsbo.valuationOfEstates.headingPart2')}</h2>
            </div>
            <div className="w-5/6">
                <div className="text-center sm:text-left sm:ml-4 my-2">
                    {t('dodsbo.valuationOfEstates.paragraph1')}
                </div>
                <div className="text-center sm:text-left sm:ml-4">
                    {t('dodsbo.valuationOfEstates.paragraph2')}
                </div>
            </div>
        </div>
    );

}

export default ValuationOfEstates;