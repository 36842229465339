import React, { useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { SiMinutemailer } from "react-icons/si";
import { FiMail } from "react-icons/fi";
import { BiUser } from "react-icons/bi";
import { MdSubject } from "react-icons/md";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineMessage } from "react-icons/ai";
import { FaCity } from "react-icons/fa";
import { FaTreeCity } from "react-icons/fa6";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import emailjs from "emailjs-com";

import { useTranslation } from 'react-i18next';

const FormRecycling = () => {
    
    const { t } = useTranslation();

    const callUsRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [fullName, setFullName] = useState("");
    const [surName, setSurName] = useState("");
    const [telephone, setTelephone] = useState("");
    const [city, setCity] = useState("");
    const [company, setCompany] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    const navigate = useNavigate();

    /*  const handleClick = () => {
         // Scroll to the top of the page
         window.scrollTo(0, 0);
 
         // Now, navigate to the desired route
         navigate("/MovingService");
     }; */
    const isValidEmail = (email) => {
        // Email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (
            !fullName ||
            !email ||
            // !subject ||
            !message

        ) {
            toast.error("Please fill in all required fields.");
            return;
        }
        if (!isValidEmail(email)) {
            toast.error("Please enter a valid email address.");
            return;
        }


        try {
            const messageBody = `
            Get in touch with us!:
             
                 Name: ${fullName} 
                 Email: ${email}
                 Subject: ${subject}
                 Message: ${message}
                
             `;
            const templateParams = {
                to_name: `${fullName}`,
                from_name: "Code Zone",
                to_email: email,
                subject: "Get in touch with us!",
                message: messageBody,
            };
            const response = await emailjs.send(
                "service_m774pph",
                "template_qsec7n7",
                templateParams,
                "rc1ba_PvLbgEIUjbt"
            );
            console.log("Email sent successfully:", response);
            if (response) {
                toast.success("Email Sent Successfully");

                setEmail("");

                setFullName("");
                setMessage("");
                setSubject("");


                document.getElementById("cat").checked = false;
                document.getElementById("dog").checked = false;
                document.getElementById("other").checked = false;
            }
        } catch (error) {
            console.error("Error sending email:", error);
/*             toast.error("Something Went wrong please try again");
 */        }
    };

    const handleClick = () => {
        // Scroll to the top of the page
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        // Introduce a delay (you can adjust the duration)
        const delayDuration = 500; // in milliseconds 
        setTimeout(() => {
            // Now, navigate to the desired route
        }, delayDuration);
    };

    return (
        <div className='w-full flex flex-col justify-center items-center sm:pt-10 ' id="free-quote">
            <form
            onSubmit={handleSubmit} 
            className="w-2/3 flex bg-gray-600 flex-col hover:scale-105 transition duration-300 rounded-xl gap-6 py-3 px-4 mb-20">
            <h2 className=" text-gray-300 text-4xl text-center font-bold ">
                {t('dodsbo.form.headingPart1')} <span className='text-red-600'>{t('dodsbo.form.headingPart2')}</span>
            </h2>
            <div className="flex flex-col gap-y-5">

                <div className="flex flex-col md:flex-row relative items-center gap-2">
                    <div className="flex relative items-center w-full"> {/* Wrap both inputs in a div with flex display */}
                        <BiUser className="absolute left-1 w-9 h-9 p-1 text-gray-500 hover:shake" />
                        <input
                            className="pl-12 rounded bg-gray-200 shadow-lg w-full p-2 placeholder:text-gr1"
                            type="text"
                            placeholder={`${t('dodsbo.form.placeHolderFirstName')}`}
                            name="fullName"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            id="fullName"
                        />
                    </div>
                    <div className="flex relative items-center w-full"> {/* Wrap both inputs in a div with flex display */}
                        <BiUser className="absolute left-1 w-9 h-9 p-1 text-gray-500 hover:shake" />
                        <input
                            className="pl-12 rounded bg-gray-200 shadow-lg w-full p-2 placeholder:text-gr1"
                            type="text"
                            placeholder={`${t('dodsbo.form.placeHolderLastName')}`}
                            name="surName"
                            value={surName}
                            onChange={(e) => setSurName(e.target.value)}
                            id="surName"
                        />
                    </div>
                </div>
                <div className="flex flex-col md:flex-row relative items-center gap-2">
                    <div className="flex relative items-center w-full"> {/* Wrap both inputs in a div with flex display */}
                        <FiMail className=" absolute left-1 w-7 h-7 mr-2 text-gray-500 hover:shake" />
                        <input
                            className="pl-12 rounded bg-gray-200 shadow-lg w-full p-2 placeholder:text-gr1"
                            placeholder={`${t('dodsbo.form.placeHolderEmailAddress')}`}
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            type="email"
                            id="email"
                            name="email"
                        />
                    </div>
                    <div className="flex relative items-center w-full"> {/* Wrap both inputs in a div with flex display */}
                        <BsTelephone className=" absolute left-1 w-7 h-7 mr-2 text-gray-500 hover:shake" />
                        <input
                            className="pl-12 rounded bg-gray-200 shadow-lg w-full p-2 placeholder:text-gr1"
                            placeholder={`${t('dodsbo.form.placeHolderTelephone')}`}
                            onChange={(e) => setTelephone(e.target.value)}
                            value={telephone}
                            type="telephone"
                            id="telephone"
                            name="telephone"
                        />
                    </div>
                </div>
                <div className="flex flex-col md:flex-row relative items-center gap-2">
                    <div className="flex relative items-center w-full"> {/* Wrap both inputs in a div with flex display */}
                        <FaCity className=" absolute left-1 w-7 h-7 mr-2 text-gray-500 hover:shake" />
                        <input
                            className="pl-12 rounded bg-gray-200 shadow-lg w-full p-2 placeholder:text-gr1"
                            placeholder={`${t('dodsbo.form.placeHolderCityName')}`}
                            onChange={(e) => setCity(e.target.value)}
                            value={city}
                            type="city"
                            id="city"
                            name="city"
                        />
                    </div>
                    <div className="flex relative items-center w-full">
                        <FaTreeCity className=" absolute left-1 w-7 h-7 mr-2 text-gray-500 hover:shake" />
                        <input
                            className="pl-12 rounded bg-gray-200 shadow-lg w-full p-2 placeholder:text-gr1"
                            placeholder={`${t('dodsbo.form.placeHolderCompanyName')}`}
                            onChange={(e) => setCompany(e.target.value)}
                            value={company}
                            type="company"
                            id="company"
                            name="company"
                        />
                    </div>
                </div>

                <div className="flex relative items-center">
                    <AiOutlineMessage className="absolute left-1 top-2 w-7 h-7 mr-2 text-gray-500 hover:shake" />
                    <textarea
                        className="pl-12 w-full p-2 rounded bg-gray-200  shadow-lg   placeholder:text-gr1 "
                        placeholder={`${t('dodsbo.form.placeHolderMessage')}`}
                        name="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        id="message"
                    ></textarea>
                </div>
                <button
                    type="submit" // Change type to "submit" to trigger form submission
                    className="text-white px-4 py-2 rounded-lg bg-gradient-to-r from-red-600 to-orange-600 hover:from-orange-600 hover:to-red-600 transition duration-[500]"
                >
                    {t('dodsbo.form.sendMessage')}
                </button>
            </div>
        </form>
        
            <div id="call-us" ref={callUsRef} className='w-4/6 sm:w-2/3 flex flex-col justify-center items-center bg-gray-300 py-10 rounded-2xl'>
                <h2 className='text-5xl text-red-600 font-bold mb-10'>{`${t('dodsbo.call.headingPart1')}`} <span className="text-black">{`${t('dodsbo.call.headingPart2')}`}</span></h2>
                <a href="tel:+46730375702" target="_blank" className='px-14 py-8 text-white font-bold bg-gradient-to-r from-green-600 to-green-400 hover:from-green-400 hover:to-green-600 transition duration-[500] rounded-xl'>+46730375702</a>
            </div>
        </div>
    )
}

export default FormRecycling 