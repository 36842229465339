import React, { useEffect } from 'react';
import useTranslatePage from './../../Gernal_Components/TranslatePage';
import Loader from './../../Gernal_Components/Loader';

import Hero from "./Sections/Hero";
import WhatIsIncluded from "./Sections/WhatIsIncluded";
import ThreeSteps from "./Sections/ThreeSteps"
import FAQs from "./Sections/FAQs";
import SimplifyYourLife from "./Sections/SimplifyYourLife";

const OfficeCleaning = () => {

    const [translatePage, loading] = useTranslatePage();

    useEffect(() => {
        const lang = localStorage.getItem('language');
        if (lang) {
        translatePage(lang);
        }
    }, []);

    return (
        <>
            {loading && <Loader />}
            <div>
                <Hero />
                <WhatIsIncluded />
                <ThreeSteps />
                <FAQs />
                <SimplifyYourLife />
            </div>
        </>
    );
};

export default OfficeCleaning;
