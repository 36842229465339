import React, { useState, useEffect } from "react";
/* import "../../../Assets/css/window_cleaning.css"; */
import "../../../../Assets/css/window_cleaning.css"
/* import CrossIcon from "../../Gernal_Components/CrossIcon";

 *//* import CrossIcon from "../../../Gernal_Components/Cross *
/* import CrossIcon from "../../Gernal_Components/CrossIcon"; */
import CrossIcon from "../../../Gernal_Components/CrossIcon";

import { useNavigate } from "react-router";
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
} from '@chakra-ui/react';
import { toast, ToastContainer } from "react-toastify";

import emailjs from "emailjs-com";
import Note from "../../MoveoutCleaning/Note";
import Faq1 from "../../MoveoutCleaning/faq1";

import { useTranslation } from 'react-i18next';

const MovingOutCleaning = ({ filteredCity1 }) => {
    
    const { t } = useTranslation();

    console.log("first", filteredCity1)

    const navigate = useNavigate()
    const [streetAddress, setStreetAddress] = useState("");

    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [selectedfromDate, setSelectedFromDate] = useState('');
    const [selectedfromTime, setSelectedFromTime] = useState('');
    const [toaddress, setToaddress] = useState("");
    const [fromaddress, setFromaddress] = useState("");
    const [message, setMessage] = useState('')
    const [walkingdistance, setWalkingdistance] = useState('')
    const [floor, setFloor] = useState("")
    const [email, setEmail] = useState("");
    const [lastName, setLastName] = useState("");
    const [firstName, setFirstName] = useState("");

    const [sqm, setSqm] = useState(0);
    const [fromsqm, setFromSqm] = useState("");



    const [total, setTotal] = useState('');
    const [alltotal, setAllTotal] = useState(0);
    const [fromtotal, setFromTotal] = useState(0);



    /* useEffect(() => {
        const all = fromtotal + total;
        setAllTotal(all);
    }, [fromtotal, total]); */


    /*  const hanldefromsqm = (event) => {
         setFromSqm(event.target.value);
         const formula = ((2000 + fromsqm * 100) * 0.5);
         setFromTotal(formula);
     }; */
    const handleFloor = (value) => {
        setFloor(value);
        console.log(value);
    }
    const handleAddress = (value) => {
        setStreetAddress(value);
        console.log(value);
    };
    const handleWalkingdistance = (value) => {
        setWalkingdistance(value);
        console.log(value);
    };

    const hanldesqm = (event) => {
        const newSqm = event.target.value;
        setSqm(newSqm);
    };
    useEffect(() => {
        console.log(sqm); // Log updated sqm value
        const formula = ((sqm * 70) * 0.5);
        setTotal(formula);
        console.log(total); // Log updated total value
    }, [sqm]);
    const isValidEmail = (email) => {
        // Email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async () => {
        if (

            !streetAddress ||
            !floor ||
            !walkingdistance ||
            !fromaddress ||
            !sqm ||
            !selectedDate ||
            !selectedTime ||
            !firstName ||
            !lastName ||
            !email ||
            !message

        ) {
            toast.error("Please fill in all required fields.");
            return;
        }
        if (!isValidEmail(email)) {
            toast.error("Please enter a valid email address.");
            return;
        }


        try {
            const messageBody = `
                 MoveOut Cleaning:
                 Moving From:
                 Street address: ${fromaddress}
                 Residence: ${streetAddress}
                 Floor: ${floor}
                 Walking Distance: ${walkingdistance}
                 Sqm For Moving Service: ${sqm}
                 Date : ${selectedDate}
                 Time : ${selectedTime}
                 First Name : ${firstName}
                 Last Name:${lastName}
                 Email:${email}
                 Message:${message}
    
                 Thank you for your interest in our services! We'll be in touch shortly to discuss how we can assist you further.
    
                 
             `;
            const templateParams = {
                to_name: `${firstName} ${lastName}`,
                from_name: "Code Zone",
                to_email: email,
                subject: "New General Handyman Request",
                message: messageBody,
            };
            const response = await emailjs.send(
                "service_m774pph",
                "template_qsec7n7",
                templateParams,
                "rc1ba_PvLbgEIUjbt"
            );
            console.log("Email sent successfully:", response);
            if (response) {
                toast.success(`${firstName} ${lastName} Your Request Sent Successfully`);
                setToaddress("");
                setStreetAddress("");

                setFloor("");

                setFirstName("");
                setLastName("");
                setWalkingdistance("");

                setMessage("");
                setSqm("");
                document.getElementById("cat").checked = false;
                document.getElementById("dog").checked = false;
                document.getElementById("other").checked = false;
            }
        } catch (error) {
            console.error("Error sending email:", error);
        }
    };



    /* 
        const hanldesqm = (event) => {
            setSqm(event.target.value);
            console.log(sqm)
            const formula = 2000 + (sqm * 70);
            setTotal(formula);
            console.log(total)
        }; */
    /*  const handlechhandlechangeange = (event) => {
         setSqm(event.target.value);
         console.log(sqm)
     } */
    const handlechangeaddresss = (event) => {
        setFromaddress(event.target.value);
    };
    const handlechangeaddress = (event) => {
        setToaddress(event.target.value);
    };
    const handlefromDateChange = (event) => {
        setSelectedFromDate(event.target.value);
    };

    // Event handler for time input change
    const handlefromTimeChange = (event) => {
        setSelectedFromTime(event.target.value);
    };
    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    // Event handler for time input change
    const handleTimeChange = (event) => {
        setSelectedTime(event.target.value);
    };
    return (
        <div
            className="container mx-auto my-auto pt-10 mb-20"
            style={{ maxWidth: "70%" }}
        >
            <div
                style={{ display: "grid", gridTemplateColumns: "70% 30%", gap: "30px" }}
            >
                <div className="text-center px-2">
                    <div className="flex justify-end hover:shake">
                        <CrossIcon handleClickCrossIcon={() => navigate("/Move_Out_Cleaning")} />
                    </div>
                    <div
                        className=" mt-2 font-normal text-5xl"
                        style={{ fontFamily: "Tiempos Headline,serif" }}
                    >
                        {t('contactPages.moveOutCleaning')}
                    </div>


                    <div className="w-full mt-8  flex justify-center items-center mb-5">
                        <p className=" border-black border w-20 text-center hover:w-[400PX] hover:border-gray-500  transition-all duration-300 transform origin-center scale-100"></p>
                    </div>

                    <div className="mt-3 rounded-lg p-4">
                        {/* <label
                            htmlFor="message"
                            className="text-2xl font-semibold flex justify-left mb-2 font-normal ms-1"
                        >
                            Moving
                        </label> */}
                        {/* <label
                            htmlFor="message"
                            className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
                        >
                            Street address
                        </label>
                        <div className="flex items-center mb-3">
                            <input
                                type="text"
                                value={toaddress}
                                onChange={handlechangeaddress}
                                id="text"
                                className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                            />
                        </div>

                        <label
                            htmlFor="message"
                            className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
                        >
                            Type of residence
                        </label>
                        <div className="flex items-center mb-3">

                            <select id="residenceDropdown" className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                            >
                                <option value="" disabled selected hidden>Select an option</option>

                                <option value="Villa/Terraced house
">Villa/Terraced house
                                </option>
                                <option value="Apartment">Apartment</option>
                                <option value="Storage unit">Storage unit</option>
                            </select>
                        </div>
                        <label
                            htmlFor="message"
                            className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
                        >
                            Floor
                        </label>
                        <div className="flex items-center mb-3">

                            <select id="residenceDropdown" className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                            >
                                <option value="" disabled selected hidden>Select an option</option>

                                <option value="First">First</option>
                                <option value="Scecond">Second</option>
                                <option value="Third">Third</option>
                                <option value="Fourth">Fourth</option>

                                <option value="Fifth">Fifth</option>

                                <option value="Sixth">Sixth</option>

                                <option value="Seventh">Seventh</option>
                                <option value="Higher than Seven">Higher than Seven</option>


                            </select>
                        </div>

                        <label
                            htmlFor="message"
                            className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
                        >
                            Walking distance
                        </label>

                        <div className="flex items-center mb-3">

                            <select id="residenceDropdown" className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                            >
                                <option value="" disabled selected hidden>Select an option</option>

                                <option value="2-10m">2-10m</option>
                                <option value="10-20m">10-20m</option>
                                <option value="20-40m">20-40m</option>
                                <option value="50-100m">50-100m</option>

                                <option value="100-200m">100-200m</option>

                                <option value="More than 200m">More than 200m</option>



                            </select>
                        </div>
                        <label htmlFor="date" className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1">
                            Date:
                        </label>
                        <div className="flex items-center mb-3">
                            <input
                                type="date"
                                id="date"
                                value={selectedfromDate}
                                onChange={handlefromDateChange}
                                className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                            />
                        </div>
                        <label htmlFor="time" className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1">
                            Time:
                        </label>
                        <div className="flex items-center mb-3">
                            <input
                                type="time"
                                id="time"
                                value={selectedfromTime}
                                onChange={handlefromTimeChange}
                                className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                            />
                        </div>
                        <label
                            htmlFor="message"
                            className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
                        >
                            Sqm For moving Service
                        </label>

                        <div className="flex items-center mb-3">
                            <input
                                type="number"
                                value={fromsqm}
                                onChange={hanldefromsqm}
                                id="number"
                                className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                            />
                        </div> */}
                        {/* <label
                            htmlFor="message"
                            className="text-2xl font-semibold flex justify-left mb-2 font-normal ms-1"
                        >
                            Moving Out Cleaning
                        </label> */}
                        <label
                            htmlFor="message"
                            className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
                        >
                            {t('contactPages.streetAddress')}
                        </label>
                        <div className="flex items-center mb-3">
                            <input
                                type="text"
                                value={fromaddress}
                                onChange={handlechangeaddresss}
                                id="text"
                                className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                            />
                        </div>

                        <label
                            htmlFor="message"
                            className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
                        >
                            {t('contactPages.typeOfResidence')}
                        </label>
                        <div className="flex items-center mb-3">

                            <select id="residenceDropdown" className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                                onChange={(e) => handleAddress(e.target.value)} >
                                <option value="" disabled selected hidden>Select an option</option>

                                <option value="Villa/Terraced house
">Villa/Terraced house
                                </option>
                                <option value="Apartment">Apartment</option>
                                <option value="Storage unit">Storage unit</option>
                            </select>
                        </div>
                        <label
                            htmlFor="message"
                            className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
                        >
                            {t('contactPages.floor')}
                        </label>
                        <div className="flex items-center mb-3">

                            <select id="residenceDropdown" className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                                onChange={(e) => handleFloor(e.target.value)}>
                                <option value="" disabled selected hidden>Select an option</option>

                                <option value="First">First</option>
                                <option value="Scecond">Second</option>
                                <option value="Third">Third</option>
                                <option value="Fourth">Fourth</option>

                                <option value="Fifth">Fifth</option>

                                <option value="Sixth">Sixth</option>

                                <option value="Seventh">Seventh</option>
                                <option value="Higher than Seven">Higher than Seven</option>


                            </select>
                        </div>

                        <label
                            htmlFor="message"
                            className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
                        >
                            {t('contactPages.walkingDistance')}
                        </label>

                        <div className="flex items-center mb-3">

                            <select id="residenceDropdown" className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                                onChange={(e) => handleWalkingdistance(e.target.value)} >
                                <option value="" disabled selected hidden>Select an option</option>

                                <option value="2-10m">2-10m</option>
                                <option value="10-20m">10-20m</option>
                                <option value="20-40m">20-40m</option>
                                <option value="50-100m">50-100m</option>

                                <option value="100-200m">100-200m</option>

                                <option value="More than 200m">More than 200m</option>



                            </select>
                        </div>
                        <label
                            htmlFor="message"
                            className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
                        >
                            {t('contactPages.sqmForMovingService')}
                        </label>

                        <div className="flex items-center mb-3">
                            <input
                                type="number"
                                value={sqm}
                                onChange={hanldesqm}
                                id="number"
                                className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                            />
                        </div>

                        <label htmlFor="date" className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1">
                        `{t('contactPages.date')}:
                        </label>
                        <div className="flex items-center mb-3">
                            <input
                                type="date"
                                id="date"
                                value={selectedDate}
                                onChange={handleDateChange}
                                className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                            />
                        </div>
                        <label htmlFor="time" className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1">
                        {t('contactPages.time')}:
                        </label>
                        <div className="flex items-center mb-3">
                            <input
                                type="time"
                                id="time"
                                value={selectedTime}
                                onChange={handleTimeChange}
                                className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                            />
                        </div>

                        {/* Add First Name Input */}
                        <label htmlFor="firstName" className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1">
                        {t('contactPages.firstName')}:
                        </label>
                        <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            onChange={(e) => setFirstName(e.target.value)}
                            className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                            placeholder="Enter your first name"
                        />

                        <label htmlFor="lastName" className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1">
                        {t('contactPages.lastName')}:
                        </label>
                        <input
                            type="text"
                            id="lastName"
                            onChange={(e) => setLastName(e.target.value)}
                            name="lastName"
                            className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                            placeholder="Enter your last name"
                        />

                        <label htmlFor="email" className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1">
                        {t('contactPages.email')}:
                        </label>
                        <input
                            type="email"
                            id="email"
                            onChange={(e) => setEmail(e.target.value)}

                            name="email"
                            className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                            placeholder="Enter your email"
                        />
                    </div>



                    <div className="mt-[-2px] rounded-lg p-2">
                        <label
                            htmlFor="message"
                            className="text-xl font-semibold flex justify-left mb-1 font-normal ms-1"
                        >
                            {t('contactPages.specificMessage')}
                        </label>
                        <div className="flex items-center mb-3">
                            <input
                                type="text"
                                name="message"
                                onChange={(e) => setMessage(e.target.value)}

                                id="message"
                                className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                            />
                        </div>
                    </div>


                    <div className="p-2">

                        <div
                            className="mt-10"
                            style={{ display: "flex", justifyContent: "space-evenly" }}
                        >
                            <button
                                style={{ padding: "15px 50px 15px 50px" }}
                                className="bg-[#000] font-bold uppercase text-white p-5 rounded text-bold"

                                onClick={handleSubmit}
                            >
                                {t('contactPages.next')}
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    className="mt-20 pb-5"
                    style={{
                        backgroundColor: "#d1dce7",
                        width: "300px",
                        height: "350px",
                        borderRadius: "10px"
                    }}
                >
                    <div className="flex-col mt-5 px-5">
                        <p
                            className="text-2xl text-normal text-[#003b5c] text-left"
                            style={{ fontFamily: "Tiempos Headline,serif" }}
                        >
                            {t('contactPages.yourBooking')}
                        </p>
                    </div>
                    {/*  <div className="flex mt-5 px-5">
                        <p className="text-lg text-normal text-[#003b5c] text-left flex-1">
                            Moving
                        </p>
                        <p className="text-gl font-bold text-[#003b5c]">{toaddress}</p>
                    </div> */}

                    {/* <div className="flex  px-5">
                        <p className="text-lg text-normal text-[#003b5c] text-left flex-1">
                            Date
                        </p>
                        <p className="text-gl font-bold text-[#003b5c]">{selectedfromDate}</p>
                    </div>
                    <div className="flex  px-5">
                        <p className="text-lg text-normal text-[#003b5c] text-left flex-1">
                            Time
                        </p>
                        <p className="text-gl font-bold text-[#003b5c]">{selectedfromTime}</p>
                    </div> */}


                    <div className="w-full mt-3 flex justify-center px-5 items-center mb-5">
                        <p className=" border-[#003b5c] border opacity-10 w-full text-center "></p>
                    </div>
                    <div className="flex mt-5 px-5">
                        <p className="text-lg text-normal text-[#003b5c] text-left flex-1">
                        {t('contactPages.movingOutCleaning')}
                        </p>
                        {/*                         <p className="text-gl font-bold text-[#003b5c]">{fromaddress}</p>
 */}                    </div>
                    {/* <div className="w-full mt-3 flex justify-center px-5 items-center mb-5">
            <p className=" border-[#003b5c] border opacity-10 w-full text-center "></p>
          </div> */}
                    <div className="flex  px-5">
                        <p className="text-lg text-normal text-[#003b5c] text-left flex-1">
                        {t('contactPages.date')}
                        </p>
                        <p className="text-gl font-bold text-[#003b5c]">{selectedDate}</p>
                    </div>
                    <div className="flex  px-5">
                        <p className="text-lg text-normal text-[#003b5c] text-left flex-1">
                        {t('contactPages.time')}
                        </p>
                        <p className="text-gl font-bold text-[#003b5c]">{selectedTime}</p>
                    </div>


                    <div className="w-full mt-3 flex justify-center px-5 items-center mb-5">
                        <p className=" border-[#003b5c] border opacity-10 w-full text-center "></p>
                    </div>
                    <div className="flex mt-5 px-5">
                        <div className="flex-1">
                            <p className="text-lg text-[#003b5c] text-left flex-1">{t('contactPages.movingOutCleaning')} {t('contactPages.total')}</p>
                            <p className="text-sm text-[#003b5c] text-left flex-1 ms-1">
                            {t('contactPages.withRUTReduction')}
                            </p>
                        </div>
                        <p className="text-xl font-semibold text-[#003b5c] my-auto">
                            {total} SEK
                        </p>
                    </div>
                    {/* <div className="flex mt-5 px-5">
                        <div className="flex-1">
                            <p className="text-lg text-[#003b5c] text-left flex-1">Moving Service Total</p>
                            <p className="text-sm text-[#003b5c] text-left flex-1 ms-1">
                                with RUT-deduction
                            </p>
                        </div>
                        <p className="text-xl font-semibold text-[#003b5c] my-auto">
                            {fromtotal} SEK
                        </p>
                    </div> */}
                    {/*   <div className="w-full mt-3 flex justify-center px-5 items-center mb-5">
                        <p className=" border-[#003b5c] border opacity-10 w-full text-center "></p>
                    </div> */}
                    {/* <div className="flex mt-5 px-5">
                        <div className="flex-1">
                            <p className="text-lg text-[#003b5c] text-left flex-1">Total Amount</p>
                            <p className="text-sm text-[#003b5c] text-left flex-1 ms-1">
                                with RUT-deduction
                            </p>
                        </div>
                        <p className="text-xl font-semibold text-[#003b5c] my-auto">
                            {alltotal} SEK
                        </p>
                    </div> */}
                    <div className="w-full mt-3 flex justify-center px-5 items-center mb-5">
                        <p className=" border-[#003b5c] border opacity-10 w-full text-center "></p>
                    </div>
                    <div className="flex mt-5 mb-3 px-5">
                        <p
                            className="text-normal text-normal underline text-[#003b5c] text-left"
                            style={{ display: "inline-block" }}
                        >
                            {t('contactPages.addDiscountCode')}
                        </p>


                    </div>
                    <Faq1 />


                </div>


            </div>
        </div >
    );
};

export default MovingOutCleaning;










