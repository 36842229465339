import React from 'react';
import { Vortex, BallTriangle } from 'react-loader-spinner';

const Loader = () => (
    <div className="loader-overlay absolute inset-0 flex items-center justify-center z-50 bg-gray-500 bg-opacity-50">
        <div className="loader flex flex-col justify-center items-center">
            <Vortex
                visible={true}
                height={80}
                width={80}
                ariaLabel="vortex-loading"
                wrapperStyle={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999 }}
                wrapperClassName="vortex-wrapper"
                colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
            />
            <div className='absolute z-51 flex justify-center items-center bg-gray-300 rounded-lg p-6'>
                <h2 className='text-xl text-orange-600 font-bold'>Translating Text</h2>
                <div className="loader flex items-center justify-center">
                    <BallTriangle
                        color="#FFFFFF"
                        height={30}
                        width={30}
                        className="ml-2"
                    />
                </div>
            </div>
        </div>
    </div>
);

export default Loader;
