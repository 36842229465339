import React, { useEffect } from 'react';
import useTranslatePage from './../../Gernal_Components/TranslatePage';
import Loader from './../../Gernal_Components/Loader';

import CleaningAsSaleryBenifitMain from "./Sections/CleaningAsSaleryBenifitMain"
import CleaningGoodForYourHealth from "./Sections/CleaningGoodForYourHealth"
import FAQs from "./Sections/FAQs";
import SimplifyYourLife from "./Sections/SimplifyYourLife";

const CleaningAsSaleryBenifit = () => {

    const [translatePage, loading] = useTranslatePage();

    useEffect(() => {
        const lang = localStorage.getItem('language');
        if (lang) {
        translatePage(lang);
        }
    }, []);

    return (
        <>
        {loading && <Loader />}
            <div>
                <CleaningAsSaleryBenifitMain />
                <CleaningGoodForYourHealth />
                <FAQs />
                <SimplifyYourLife />
            </div>
        </>
    );
};

export default CleaningAsSaleryBenifit;
