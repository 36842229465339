import React, { useState } from 'react';
import CrossIcon from "../../Gernal_Components/CrossIcon";
import { useNavigate, useLocation } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import emailjs from "emailjs-com";
import { Textarea } from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';

const MovingOutService2 = () => {
    
    const { t } = useTranslation();
  
    const [step, setStep] = useState(1);
    const [fromsqm, setFromsqm] = useState("");
    const [fromaddress, setFromAddress] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [streetAddress, setStreetAddress] = useState("");
    const [postalCode, setPostalCode] = useState("");

    const [job1, setJob1] = useState("")

    const navigate = useNavigate();
    const [job, setJob] = useState(true);
    const [selectedJob, setSelectedJob] = useState("");
    const [selectedSubcategories, setSelectedSubcategories] = useState([]);
    const [permit, setPermit] = useState("");
    const [property, setProperty] = useState("");
    const [help, setHelp] = useState("");
    const [budget, setBudget] = useState("");
    const [agreeToOffers, setAgreeToOffers] = useState(false);


    const handleBudget = (value) => {
        setBudget(value);
        console.log(value);
    };
    const location = useLocation();
    const data = location?.state?.data.code;
    console.log(data)
    const handleHelp = (value) => {
        setHelp(value);
        console.log(value);
    };
    const handleProperty = (value) => {
        setProperty(value);
        console.log(value);
    };
    const handlePermit = (value) => {
        setPermit(value);
        console.log(value);
    };
    const handleJob = (selectedJob) => {
        setSelectedJob(selectedJob);
        setJob(selectedJob === "Complete renovation" ? false : true);
    };
    /* const handlePermit = () => {
        // If a job is selected, display checkboxes
    }; */
    /*  const handleJob = (selectedJob) => {
         setSelectedJob(selectedJob);
         setJob(selectedJob === "Complete renovation" ? false : true);
         console.log(selectedJob)
     }; */

    const handleSubcategory = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setSelectedSubcategories([...selectedSubcategories, value]);
        } else {
            setSelectedSubcategories(selectedSubcategories.filter(subcategory => subcategory !== value));
            console.log(selectedSubcategories);

        }
        console.log(selectedSubcategories);

    };


    /* 
        const handleJob = (selectedJob) => {
            if (selectedJob === "Complete renovation") {
    
                setJob(false); // If "Complete renovation" is selected, set job to false
            } else {
                setJob(true); // Otherwise, set job to true
            }
            setJob1(selectedJob)
    
    
        };
        const handlejob1 = (e) => {
            setJob1(e.target.value);
            console.log(job1)
        }; */

    const handlechangeaddresss = (e) => {
        setFromAddress(e.target.value);
        console.log(fromaddress)
    };
    const isValidEmail = (email) => {
        // Email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleNext = () => {
        if (step === 1) {
            // Perform validation for step 1
            if (!selectedJob || !fromaddress || !help || !budget || !selectedSubcategories || !permit || !property) {
                toast("Please fill in all required fields.");
                return;
            }
            setStep(2);
        } else if (step === 2) {
            // Perform validation for step 2
            if (!name || !email || !phoneNumber || !streetAddress || !postalCode) {
                toast("Please fill in all required fields.");
                return;
            }
            // If all fields are filled, you can submit the form or navigate to the next page
            // SubmitForm();
            // navigateToNextPage();
            // For demonstration purposes, I'm just logging the form data
            console.log("Form submitted:", { selectedJob, fromaddress, help, budget, selectedSubcategories, permit, property, name, email, phoneNumber, streetAddress, postalCode });

            if (!isValidEmail(email)) {
                toast.error("Please enter a valid email address.");
                return;
            }
            try {
                const messageBody = `
                 Home Renovation
                Job type: ${selectedJob}
                Subcategory: ${selectedSubcategories}
                Permit: ${permit}
                Property: ${property}
                Help: ${help}
                Budget: ${budget}
                Other Information: ${fromaddress}
                Name: ${name}
                Email Address: ${email}
                Telephone number: ${phoneNumber}
                Street Address: ${streetAddress}
                Postal Code: ${postalCode}

                Thank you for your interest in our services! We'll be in touch shortly to discuss how we can assist you further.

                
                
            `;
                const templateParams = {
                    to_name: `${name} `,
                    from_name: "Code Zone",
                    to_email: email,
                    subject: "Home Renovation",
                    message: messageBody,
                };
                const response = emailjs.send(
                    "service_m774pph",
                    "template_qsec7n7",
                    templateParams,
                    "rc1ba_PvLbgEIUjbt"
                );
                console.log("Email sent successfully:", response);

                if (response) {
                    toast.success(`${name} Your Request Sent Successfully`);
                    setAgreeToOffers(false);
                    setName("");
                    setHelp("");
                    setEmail("");
                    setPostalCode("");
                    setBudget("");
                    setFromAddress("");
                    setJob("");
                    setStreetAddress("");
                    setSelectedJob("");
                    document.getElementById("cat").checked = false;
                    document.getElementById("dog").checked = false;
                    document.getElementById("other").checked = false;
                }
            } catch (error) {
                console.error("Error sending email:", error);
/*             toast.error("Something Went wrong please try again");
 */        }
        }

    };

    return (
        <>
            <ToastContainer position="top-right" autoClose={5000} />

            <div className="container mx-auto p-5 text-center my-auto pt-10 mb-20"
                style={{ maxWidth: "70%" }}>


                {step === 1 && (




                    <div className="container ">

                        <div className="mt-3 rounded-lg p-4">
                            <div className="flex justify-end hover:shake">
                                <CrossIcon handleClickCrossIcon={() => navigate("/home_Renovation")} />
                            </div>
                            <div className="mt-2 font-normal text-5xl" style={{ fontFamily: "Tiempos Headline,serif" }}>
                            {t('contactPages.homeRenovation')}
                            </div>
                            <div className="w-full mt-8  flex justify-center items-center mb-5">
                                <p className=" border-black border w-20 text-center hover:w-[400PX] hover:border-gray-500  transition-all duration-300 transform origin-center scale-100"></p>
                            </div>
                            <div>
                                <label htmlFor="message" className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1">
                                {t('contactPages.selectJobType')}
                                </label>
                                <div className="flex items-center mb-3">
                                    <select id="residenceDropdown" onChange={(e) => handleJob(e.target.value)} className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]">
                                        <option value="" disabled selected hidden>Choose</option>
                                        <option value="Complete renovation">Complete renovation</option>
                                        <option value="Renovation/Rebuilding">Renovation/Rebuilding</option>
                                    </select>
                                </div>
                            </div>
                            <>
                                
                                {job ? (
                                    <>
                                        <label htmlFor="message" className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1">
                                        {t('contactPages.selectCategory')}
                                        </label>
                                        <div className="flex items-center mb-3">
                                            <select id="residenceDropdown" className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]" onChange={(e) => setSelectedSubcategories([e.target.value])}>
                                                <option value="" disabled selected hidden>Choose</option>
                                                <option value="Terrace/conservatory/balcony" >Terrace/conservatory/balcony</option>
                                                <option value="Facade/facade/renovation">Facade/facade/renovation</option>
                                                <option value="Kitchen/kitchen renovation">Kitchen/kitchen renovation</option>
                                                <option value="Bathroom">Bathroom</option>
                                                <option value="Roof Lift">Roof Lift</option>
                                                <option value="Miscellaneous">Miscellaneous</option>
                                            </select>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <label htmlFor="message" className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1">
                                        {t('contactPages.selectCategory')} <p className="justify-left">(Choose at least 3 areas)</p>
                                        </label>
                                        <div className="text-xl font-semibold text-start mb-2 font-normal">
                                            <div>
                                                <input type="checkbox" id="paintingCheckbox" name="areas" value="Painting" className="" onChange={handleSubcategory} />
                                                <label htmlFor="paintingCheckbox"> Painting</label>
                                            </div>
                                            <div>
                                                <input type="checkbox" id="electricalCheckbox" name="areas" value="Electrical wiring" className="mr-2" onChange={handleSubcategory} />
                                                <label htmlFor="electricalCheckbox">Electrical wiring</label>
                                            </div>
                                            <div>
                                                <input type="checkbox" id="floorCheckbox" name="areas" value="Floor work" className="mr-2" onChange={handleSubcategory} />
                                                <label htmlFor="floorCheckbox">Floor work</label>
                                            </div>
                                            <div>
                                                <input type="checkbox" id="ceilingCheckbox" name="areas" value="Ceiling" className="mr-2" onChange={handleSubcategory} />
                                                <label htmlFor="ceilingCheckbox">Ceiling</label>
                                            </div>
                                            <div>
                                                <input type="checkbox" id="kitchenCheckbox" name="areas" value="Kitchen" className="mr-2" onChange={handleSubcategory} />
                                                <label htmlFor="kitchenCheckbox">Kitchen</label>
                                            </div>
                                            <div>
                                                <input type="checkbox" id="bathCheckbox" name="areas" value="Bath" className="mr-2" onChange={handleSubcategory} />
                                                <label htmlFor="bathCheckbox">Bath</label>
                                            </div>
                                            <div>
                                                <input type="checkbox" id="wallCheckbox" name="areas" value="Wall/insulation" className="mr-2" onChange={handleSubcategory} />
                                                <label htmlFor="wallCheckbox">Wall/insulation</label>
                                            </div>
                                            <div>
                                                <input type="checkbox" id="plumbingCheckbox" name="areas" value="Plumbing/pipes" className="mr-2" onChange={handleSubcategory} />
                                                <label htmlFor="plumbingCheckbox">Plumbing/pipes</label>
                                            </div>
                                            <div>
                                                <input type="checkbox" id="punchingCheckbox" name="areas" value="Punching/Tearing" className="mr-2" onChange={handleSubcategory} />
                                                <label htmlFor="punchingCheckbox">Punching/Tearing</label>
                                            </div>
                                            <div>
                                                <input type="checkbox" id="miscellaneousCheckbox" name="areas" value="Miscellaneous" className="mr-2" onChange={handleSubcategory} />
                                                <label htmlFor="miscellaneousCheckbox">Miscellaneous</label>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>





                            <label
                                htmlFor="message"
                                className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
                            >
                                {t('contactPages.isThereABuildingPermit')}
                            </label>
                            <div className="flex items-center mb-3">
                                <select id="residenceDropdown" className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]" onChange={(e) => handlePermit(e.target.value)}>
                                    <option value="" disabled selected hidden>Choose</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No,that is not necessary">No, that is not necessary</option>
                                    <option value="No,but submitted">No, but submitted</option>
                                    <option value="No, would like help with this">No, would like help with this</option>
                                </select>
                            </div>
                            <label
                                htmlFor="message"
                                className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
                            >
                                {t('contactPages.typeOfProperty')}
                            </label>

                            <div className="flex items-center mb-3">

                                <select id="residenceDropdown" className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                                    onChange={(e) => handleProperty(e.target.value)}>
                                    <option value="" disabled selected hidden>Choose</option>

                                    <option value="Villa">Villa</option>
                                    <option value="Terraced house">Terraced house</option>
                                    <option value="Applies to the entire BRF">Applies to the entire BRF</option>
                                    <option value="Commercial property">Commercial property</option>

                                    <option value="Private aparment">Private aparment</option>

                                    <option value="Other">Other</option>



                                </select>
                            </div>
                            <label
                                htmlFor="message"
                                className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
                            >
                                {t('contactPages.whenDoYouWantHelp')}                       </label>

                            <div className="flex items-center mb-3">

                                <select id="residenceDropdown" className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                                    onChange={(e) => handleHelp(e.target.value)}   >
                                    <option value="" disabled selected hidden>Choose</option>

                                    <option value="As soon as possible">As soon as possible</option>
                                    <option value="Within 3 months">Within 3 months</option>
                                    <option value="Within 6 months">Within 6 months</option>
                                    <option value="Within 12 months">Within 12 months</option>
                                </select>
                            </div>
                            <label
                                htmlFor="message"
                                className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
                            >
                                {t('contactPages.estimatedBudget')}                            </label>

                            <div className="flex items-center mb-3">

                                <select id="residenceDropdown" className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                                    onChange={(e) => handleBudget(e.target.value)} >
                                    <option value="" disabled selected hidden>Choose</option>

                                    <option value="Less than SEK 10,000">Less than SEK 10,000</option>
                                    <option value="Less than SEK 25,000">Less than SEK 25,000</option>
                                    <option value="SEK 25,000-SEK 50,000">SEK 25,000-SEK 50,000</option>
                                    <option value="SEK 50,000-SEK 100,000">SEK 50,000-SEK 100,000</option>
                                    <option value="More than SEK 100,000">More than SEK 100,000</option>
                                    <option value="Do not want to specify">Do not want to specify</option>

                                </select>
                            </div>
                            <label
                                htmlFor="message"
                                className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
                            >
                                {t('contactPages.doYouHaveAnyOther')}
                            </label>
                            <div className="flex items-center mb-3">
                                <Textarea
                                    type="text"
                                    value={fromaddress}
                                    onChange={handlechangeaddresss}
                                    id="text"
                                    className="border w-full mx-1 rounded-md mb-4 p-5 bg-[#FFFFFF] "
                                    style={{
                                        display: 'block',
                                        background: '#FFFFFF' // Set to block to make it vertically long
                                    }}
                                />

                            </div>





                        </div>
                    </div>
                )}

                {step === 2 && (
                    <div className="container mt-20">
                        <div className="flex justify-end hover:shake">
                            <CrossIcon handleClickCrossIcon={() => navigate("/home_Renovation")} />
                        </div>
                        <div className="mt-2 font-normal text-5xl" style={{ fontFamily: "Tiempos Headline,serif" }}>
                        {t('contactPages.contactDetails')}

                        </div>
                        <div className="w-full mt-8  flex justify-center items-center mb-5">
                            <p className=" border-black border w-20 text-center hover:w-[400PX] hover:border-gray-500  transition-all duration-300 transform origin-center scale-100"></p>
                        </div>

                        <div className="mt-3 rounded-lg p-4">
                            {/* Your form inputs for step 1 */}
                            <label
                                htmlFor="message"
                                className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
                            >
                                {t('contactPages.firstName')}*
                            </label>
                            <div className="flex items-center mb-3">
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    id="text"
                                    className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                                />
                            </div>
                            <label
                                htmlFor="message"
                                className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
                            >
                                {t('contactPages.emailAddress')}*                       </label>

                            <div className="flex items-center mb-3">
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    id="email"
                                    className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                                />
                            </div>
                            <label
                                htmlFor="message"
                                className="text-xl font-semibold flex justify-left mb-1 font-normal ms-1"
                            >
                                {t('contactPages.phoneNumber')}*                        </label>
                            <div className="flex items-center mb-3">
                                <input
                                    type="number"
                                    name="phoneNumber"
                                    onChange={(e) => setPhoneNumber(e.target.value)}

                                    id="phoneNumber"
                                    className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                                />
                            </div>
                            <label
                                htmlFor="message"
                                className="text-xl font-semibold flex justify-left mb-1 font-normal ms-1"
                            >
                               {t('contactPages.street')}                                </label>
                            <div className="flex items-center mb-3">
                                <input
                                    type="text"
                                    name="streetAddress"
                                    onChange={(e) => setStreetAddress(e.target.value)}

                                    id="streetAddress"
                                    className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                                />
                            </div>
                            <label
                                htmlFor="message"
                                className="text-xl font-semibold flex justify-left mb-1 font-normal ms-1"
                            >
                                {t('contactPages.bookYourHomeCleaning')}*                               </label>
                            <div className="flex items-center mb-3">
                                <input
                                    type="text"
                                    name="postalCode"
                                    value={data}
                                    onChange={(e) => setPostalCode(e.target.value)}

                                    id="postalCode"
                                    className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                                />
                            </div>
                        </div>
                        <p className="text-2xl text-left mb-4 font-semibold">{t('contactPages.approval')}</p>

                        <div className="mb-4 pr-10 my-auto flex">
                            <input
                                type="checkbox"
                                id="agreeToOffers"
                                name="agreeToOffers"
                                checked={agreeToOffers}
                                onChange={() => setAgreeToOffers(!agreeToOffers)}

                                className="mr-2 h-8 w-8 flex text-start "
                            />
                            <label
                                htmlFor="agreeToOffers"
                                className="text-xl flex text-start ml-2 mb-4"
                            >
                                {t('contactPages.iAgreeToRecieve')}
                            </label>
                        </div>
                    </div>

                )}

                <div className="p-2">
                    <div className="mt-10" style={{ display: "flex", justifyContent: "space-evenly" }}>
                        <button
                            onClick={handleNext}
                            style={{ padding: "15px 50px 15px 50px" }}
                            className="bg-[#000] font-bold uppercase text-white p-5 rounded text-bold"
                        >
                            {step === 1 ? `${t('contactPages.next')}` : `${t('contactPages.send')}`}
                        </button>
                    </div>
                </div>
            </div >
        </>
    );
};

export default MovingOutService2;
