import React, { useEffect, useState } from "react";
import useTranslatePage from './../../Gernal_Components/TranslatePage';
import Loader from './../../Gernal_Components/Loader';

import ExclimatryMark from "../../../Assets/images/exclimaterymar.png";
import "../../../Assets/css/window_cleaning.css";
import WindowGuideModa from "./section/WindowGuideModa";
import WindowGuideModal from "./section/WindowGuideModa";
import { useDisclosure } from "@chakra-ui/react";
import CrossIcon from "../../Gernal_Components/CrossIcon";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import emailjs from "emailjs-com";

import { useTranslation } from 'react-i18next';

const WindowCleaning = () => {
  
  const { t } = useTranslation();

  const [translatePage, loading] = useTranslatePage();

    useEffect(() => {
        const lang = localStorage.getItem('language');
        if (lang) {
        translatePage(lang);
        }
    }, []);

  const [numberOfWindows, setNumberOfWindows] = useState(0);
  const { onOpen } = useDisclosure();
  const navigate = useNavigate();
  const [showWithoutMullions, setShowWithoutMullions] = useState(0);
  const [showWithMullions, setShowWithMullions] = useState(0);
  const [showOverhangedWindows, setShowOverhangedWindows] = useState(0);
  const [sides, setSides] = useState("Double-sided – 4 sides");
  const [showGlassedBalcony, setShowGlassedBalcony] = useState(0);
  const [handover, setHandover] = useState("");
  const [handover1, setHandover1] = useState("");
  const [message, setMessage] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [discount, setDiscount] = useState(221);
  const [windowCleaningPrice, setWindowCleaningPrice] = useState(0);
  const [windowCleaningPrice1, setWindowCleaningPrice1] = useState(0);
  const [windowCleaningPrice2, setWindowCleaningPrice2] = useState(0);
  const [windowCleaningPrice3, setWindowCleaningPrice3] = useState(0);

  const [showWindowGuide, setShowWindowGuide] = useState(false);

  const handleHandoverChange = (value) => {
    setHandover(value);
  };

  const calculateWindowCleaningPrice = (totalWindows) => {
    // Initial base price
    let price = 0;

    // Check different scenarios
    if (totalWindows >= 1 && totalWindows <= 16) {
      price = 1050;
    } else if (totalWindows >= 17 && totalWindows <= 19) {
      price = 1225;
    } else if (totalWindows >= 20 && totalWindows <= 22) {
      price = 1400;
    } else if (totalWindows >= 23 && totalWindows <= 25) {
      price = 1575;
    } else if (totalWindows >= 26 && totalWindows <= 28) {
      price = 1750;
    } else if (totalWindows >= 29 && totalWindows <= 31) {
      price = 1925;
    } else if (totalWindows >= 32 && totalWindows <= 34) {
      price = 2100;
    } /* else if (totalWindows >= 35 && totalWindows <= 37) {
      price = 1914;
    } else if (totalWindows >= 38 && totalWindows <= 40) {
      price = 2061;
    } else if (totalWindows >= 41 && totalWindows <= 43) {
      price = 2208;
    } else if (totalWindows >= 44 && totalWindows <= 46) {
      price = 2356;
    } else if (totalWindows >= 47 && totalWindows <= 49) {
      price = 2503;
    } else if (totalWindows >= 50 && totalWindows <= 52) {
      price = 2650;
    } else if (totalWindows >= 53 && totalWindows <= 55) {
      price = 2797;
    } */
    return price;
  };
  const calculateWindowCleaningPrice1 = (totalWindows) => {
    // Initial base price
    let price = 0;

    // Check different scenarios
    if (totalWindows >= 1 && totalWindows <= 8) {
      price = 1050;
    } else if (totalWindows === 9) {
      price = 1225;
    } else if (totalWindows >= 10 && totalWindows <= 11) {
      price = 1400;
    } else if (totalWindows === 12) {
      price = 1575;
    } else if (totalWindows >= 13 && totalWindows <= 14) {
      price = 1750;
    } else if (totalWindows === 15) {
      price = 1925;
    } else if (totalWindows >= 16 && totalWindows <= 17) {
      price = 2100;
    } else if (totalWindows === 18) {
      price = 2275;
    } else if (totalWindows >= 19 && totalWindows <= 20) {
      price = 2450;
    } else if (totalWindows === 21) {
      price = 2625;
    } else if (totalWindows >= 22 && totalWindows <= 23) {
      price = 2800;
    }
    return price;
  };
  const calculateWindowCleaningPrice2 = (totalWindows) => {
    // Initial base price
    let price = 0;

    // Check different scenarios
    if (totalWindows >= 1 && totalWindows <= 8) {
      price = 1050;
    } else if (totalWindows === 9) {
      price = 1225;
    } else if (totalWindows >= 10 && totalWindows <= 11) {
      price = 1400;
    } else if (totalWindows === 12) {
      price = 1575;
    } else if (totalWindows >= 13 && totalWindows <= 14) {
      price = 1750;
    } else if (totalWindows === 15) {
      price = 1925;
    } else if (totalWindows >= 16 && totalWindows <= 17) {
      price = 2100;
    } else if (totalWindows === 18) {
      price = 2275;
    } else if (totalWindows >= 19 && totalWindows <= 20) {
      price = 2450;
    } else if (totalWindows === 21) {
      price = 2625;
    } else if (totalWindows >= 22 && totalWindows <= 23) {
      price = 2800;
    }
    return price;
  };
  const calculateWindowCleaningPrice3 = (totalWindows) => {
    // Initial base price
    let price = 0;

    // Check different scenarios
    if (totalWindows === 1) {
      price = 1050;
    } else if (totalWindows === 2) {
      price = 1225;
    } else if (totalWindows === 3) {
      price = 1750;
    } else if (totalWindows === 4) {
      price = 2275;
    } else if (totalWindows === 5) {
      price = 2800;
    } else if (totalWindows === 6) {
      price = 3325;
    } else if (totalWindows === 7) {
      price = 3850;
    } else if (totalWindows === 8) {
      price = 4375;
    } else if (totalWindows === 9) {
      price = 4900;
    } else if (totalWindows === 10) {
      price = 5425;
    } else if (totalWindows === 11) {
      price = 5950;
    } else if (totalWindows === 12) {
      price = 6475;
    } else if (totalWindows === 13) {
      price = 7000;
    } else if (totalWindows === 14) {
      price = 7525;
    }
    return price;
  };

  const handleClickOpenInfo = () => {
    setShowWindowGuide(true);
    // onOpen(true)
  };

  const handleCloseOpenInfo = () => {
    setShowWindowGuide(false);
  };
  const navigatetoBack = () => {
    navigate("/Window_clean");
  };

  useEffect(() => {
    const totalWindows =
      /*  showGlassedBalcony +
       showOverhangedWindows +
       showWithMullions + */
      showWithoutMullions;
    const totalWindows1 =

      showWithMullions;
    const totalWindows2 =

      showOverhangedWindows;
    const totalWindows3 =

      showGlassedBalcony;


    // Calculate the window cleaning price when the number of windows changes
    const windowCleaningFinalPrice = calculateWindowCleaningPrice(totalWindows);
    setWindowCleaningPrice(windowCleaningFinalPrice);
    const windowCleaningFinalPrice1 = calculateWindowCleaningPrice1(totalWindows1);
    setWindowCleaningPrice1(windowCleaningFinalPrice1);
    const windowCleaningFinalPrice2 = calculateWindowCleaningPrice2(totalWindows2);
    setWindowCleaningPrice2(windowCleaningFinalPrice2);
    const windowCleaningFinalPrice3 = calculateWindowCleaningPrice3(totalWindows3);
    setWindowCleaningPrice3(windowCleaningFinalPrice3);
    // Calculate total price by subtracting the discount
    const total = windowCleaningFinalPrice + windowCleaningFinalPrice1 + windowCleaningFinalPrice2 + windowCleaningFinalPrice3 - discount;

    setTotalPrice(total);
  }, [
    discount,
    showWithoutMullions,
    showWithMullions,
    showOverhangedWindows,
    showGlassedBalcony,
  ]);
  const isValidEmail = (email) => {
    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    if (
      !showWithoutMullions ||
      !showWithMullions ||

      !showOverhangedWindows ||
      !showGlassedBalcony


      /* 
      !confirmEmail ||
      !campaignCode ||
      !kind ||
      !agreeToOffers */
    ) {
      toast.error("Please fill in all required fields.");
      return;
    }
    /* if (!isValidEmail(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    if (email !== confirmEmail) {
      toast.error("Email and Confirm Email must match.");
      return;
    } */
    try {
      const messageBody = `
            Window Cleaning Request:
        
            Without mullions: ${showWithoutMullions},
            With mullions: ${showWithMullions}
            Overhanged windows: ${showOverhangedWindows} 
            Glassed Balcony: ${showGlassedBalcony}
            Message: ${message}

            Thank you for your interest in our services! We'll be in touch shortly to discuss how we can assist you further.

            
        `;

      const templateParams = {
        to_name: ``,
        from_name: "Code Zone",
        to_email: "codezone67@gmail.com",
        subject: "Window Cleaning Request",
        message: messageBody,
      };

      const response = await emailjs.send(
        "service_m774pph",
        "template_qsec7n7",
        templateParams,
        "rc1ba_PvLbgEIUjbt"
      );
      console.log("Email sent successfully:", response);
      if (response) {
        toast.success("Window Cleaning Request Sent Successfully");
        /*   setAgreeToOffers(false);
          setDoorCode("");
          setCity("");
          setEmail("");
          setConfirmEmail("");
          setDoorCode("");
          setFirstName("");
          setLastName("");
          setPhoneNumber("");
          setStreet("");
          setKind("");
          setCampaignCode(""); */
        document.getElementById("cat").checked = false;
        document.getElementById("dog").checked = false;
        document.getElementById("other").checked = false;
      }
    } catch (error) {
      console.error("Error sending email:", error);
/*             toast.error("Something Went wrong please try again");
*/        }
  };

  return (
    <>
        {loading && <Loader />}
      <div
        className="container mx-auto my-auto pt-10 mb-20"
        style={{ maxWidth: "70%" }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "70% 30%",
            gap: "30px",
          }}
        >
          <div className="text-center">
            <span className="flex justify-end hover:shake">
              <CrossIcon handleClickCrossIcon={navigatetoBack} />
            </span>

            <div
              className=" font-normal text-5xl"
              style={{ fontFamily: "Tiempos Headline,serif" }}
            >
              {t('contactPages.windowCleaning')}
            </div>
            <div className="w-full mt-8  flex justify-center items-center mb-5">
              <p className=" border-black border w-20 text-center hover:w-[400PX] hover:border-gray-500  transition-all duration-300 transform origin-center scale-100"></p>
            </div>
            <div className="mt-20">
              <div className="flex space-x-2 mt-5 text-left">
                <p className="text-2xl font-semibold mr-4">
                  {t('contactPages.numberOfWindows')}
                </p>
                <p
                  className="text-sm font-medium"
                  style={{ marginLeft: "auto" }}
                  onClick={handleClickOpenInfo}
                >
                  <a href="#" className="flex gap-1">
                    <img
                      src={ExclimatryMark}
                      alt=""
                      className="w-4 h-4 self-center"
                    />
                    <p>{t('contactPages.countWindows')}</p>
                  </a>
                </p>
              </div>
            </div>
            <div className="container mt-4">
              <div className="border rounded-md mb-4 p-5 border-[#d5d2c4] bg-[#FFFFFF]">
                <div className="flex">
                  <div className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="26"
                      viewBox="0 0 17 26"
                      width="17"
                    >
                      <path
                        d="m13.8461538 24h-12.69230765c-.73478365 0-1.15384615-.447-1.15384615-1.2v-21.6c0-.753.4190625-1.2 1.15384615-1.2h12.69230765c.7347837 0 1.1538462.447 1.1538462 1.2v21.6c0 .753-.4190625 1.2-1.1538462 1.2z"
                        fill="none"
                        stroke="#1e1e1e"
                        stroke-width="1.5"
                        transform="translate(1 1)"
                      />
                    </svg>
                    <p className="text-xl font-normal ml-4">{t('contactPages.withoutMullions')}</p>
                  </div>
                  <div
                    className="flex items-center"
                    style={{ marginLeft: "auto" }}
                  >
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setShowWithoutMullions(
                          showWithoutMullions > 0 ? showWithoutMullions - 1 : 0
                        )
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4 items-center">
                      {showWithoutMullions}
                    </p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() =>
                        setShowWithoutMullions(showWithoutMullions + 1)
                      }
                    >
                      +
                    </button>
                  </div>
                </div>
                {showWithoutMullions > 0 && (
                  <div>
                    <div>
                      <div className="w-full mt-4 flex justify-center items-center">
                        <p className=" border-gray-200 border w-full text-center hover:w-[400PX] hover:border-gray-500  transition-all duration-300 transform origin-center scale-100"></p>
                      </div>
                      <p className="text-xl text-left font-semibold my-3">
                        How many sides need cleaning?*
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <p className="text-lg flex gap-2 font-normal">
                        <input
                          type="radio"
                          name="withoutMillonSides"
                          checked
                          value={"Double-sided – 4 sides"}
                          className=" w-7 ml-auto"
                          id="double-side"
                        />
                        <label
                          className="text-lg cursor-pointer"
                          htmlFor="double-side"
                        >
                          {"Double-sided – 4 sides"}
                        </label>
                      </p>
                      <p className="text-lg flex gap-2 font-normal">
                        <input
                          type="radio"
                          name="withoutMillonSides"
                          value={"Single-sided – 2 sides"}
                          className="w-7 ml-auto"
                          id="single-side"
                        />
                        <label
                          className="text-lg cursor-pointer"
                          htmlFor="single-side"
                        >
                          {" "}
                          {"Single-sided – 2 sides"}
                        </label>
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className="border rounded-md mb-4 p-5 border-[#d5d2c4] bg-[#FFFFFF]">
                <div className="flex">
                  <div className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="26"
                      viewBox="0 0 17 26"
                      width="17"
                    >
                      <g
                        fill="none"
                        fill-rule="evenodd"
                        stroke="#1e1e1e"
                        stroke-width="1.5"
                        transform="translate(1 1)"
                      >
                        <path d="m13.8461538 24h-12.69230765c-.73478365 0-1.15384615-.447-1.15384615-1.2v-21.6c0-.753.4190625-1.2 1.15384615-1.2h12.69230765c.7347837 0 1.1538462.447 1.1538462 1.2v21.6c0 .753-.4190625 1.2-1.1538462 1.2z" />
                        <g stroke-linecap="round">
                          <path d="m0 16.2h12.3699095" />
                          <path d="m0 7.8h15" />
                          <path d="m7.18934911.12307692v23.63076928" />
                        </g>
                      </g>
                    </svg>
                    <p className="text-xl font-normal ml-4">{t('contactPages.withMullions')}</p>
                  </div>
                  <div
                    className="flex items-center"
                    style={{ marginLeft: "auto" }}
                  >
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setShowWithMullions(
                          showWithMullions > 0 ? showWithMullions - 1 : 0
                        )
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4 flex items-center">
                      {showWithMullions}
                    </p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setShowWithMullions(showWithMullions + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
                {showWithMullions > 0 && (
                  <div>
                    <div>
                      <div className="w-full mt-4 flex justify-center items-center">
                        <p className=" border-gray-200 border w-full text-center hover:w-[400PX] hover:border-gray-500  transition-all duration-300 transform origin-center scale-100"></p>
                      </div>
                      <p className="text-xl text-left font-semibold my-3">
                        How many sides need cleaning?*
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <p className="text-lg flex gap-2 font-normal">
                        <input
                          type="radio"
                          name="withMillonSides"
                          value={"Double-sided – 4 sides"}
                          checked
                          className="w-7 ml-auto"
                          id="double-side"
                        />
                        <label
                          className="text-lg cursor-pointer"
                          htmlFor="double-side"
                        >
                          {"Double-sided – 4 sides"}
                        </label>
                      </p>
                      <p className="text-lg flex gap-2 font-normal">
                        <input
                          type="radio"
                          name="withMillonSides"
                          value={"Single-sided – 2 sides"}
                          className="w-7 ml-auto"
                          id="single-side"
                        />
                        <label
                          className="text-lg cursor-pointer"
                          htmlFor="single-side"
                        >
                          {" "}
                          {"Single-sided – 2 sides"}
                        </label>
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className="border rounded-md mb-4 p-5 border-[#d5d2c4] bg-[#FFFFFF]">
                <div className="flex">
                  <div className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="26"
                      viewBox="0 0 17 26"
                      width="17"
                    >
                      <g
                        fill="none"
                        fill-rule="evenodd"
                        stroke="#1e1e1e"
                        stroke-width="1.5"
                        transform="translate(1 1)"
                      >
                        <path d="m13.8461538 24h-12.69230765c-.73478365 0-1.15384615-.447-1.15384615-1.2v-21.6c0-.753.4190625-1.2 1.15384615-1.2h12.69230765c.7347837 0 1.1538462.447 1.1538462 1.2v21.6c0 .753-.4190625 1.2-1.1538462 1.2z" />
                        <path d="m3 7.2h11.3699095" stroke-linecap="round" />
                      </g>
                    </svg>
                    <p className="text-xl font-normal ml-4">
                    {t('contactPages.overHangedWindows')}
                    </p>
                  </div>
                  <div
                    className="flex items-center"
                    style={{ marginLeft: "auto" }}
                  >
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setShowOverhangedWindows(
                          showOverhangedWindows > 0
                            ? showOverhangedWindows - 1
                            : 0
                        )
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4">
                      {showOverhangedWindows}
                    </p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() =>
                        setShowOverhangedWindows(showOverhangedWindows + 1)
                      }
                    >
                      +
                    </button>
                  </div>
                </div>
                {showOverhangedWindows > 0 && (
                  <div>
                    <div>
                      <div className="w-full mt-4 flex justify-center items-center">
                        <p className=" border-gray-200 border w-full text-center hover:w-[400PX] hover:border-gray-500  transition-all duration-300 transform origin-center scale-100"></p>
                      </div>
                      <p className="text-xl text-left font-semibold my-3">
                        How many sides need cleaning?*
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <p className="text-lg flex gap-2 font-normal">
                        <input
                          type="radio"
                          name="overHangedWindows"
                          checked
                          value={"Double-sided – 4 sides"}
                          className=" w-7 ml-auto"
                          id="double-side"
                        />
                        <label
                          className="text-lg cursor-pointer"
                          htmlFor="double-side"
                        >
                          {"Double-sided – 4 sides"}
                        </label>
                      </p>
                      <p className="text-lg flex gap-2 font-normal">
                        <input
                          type="radio"
                          name="overHangedWindows"
                          value={"Single-sided – 2 sides"}
                          className="w-7 ml-auto"
                          id="single-side"
                        />
                        <label
                          className="text-lg cursor-pointer"
                          htmlFor="single-side"
                        >
                          {" "}
                          {"Single-sided – 2 sides"}
                        </label>
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className="border rounded-md mb-4 p-5 border-[#d5d2c4] bg-[#FFFFFF]">
                <div className="flex">
                  <div className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="26"
                      viewBox="0 0 26 26"
                      width="26"
                    >
                      <g
                        fill="none"
                        fill-rule="evenodd"
                        stroke="#1e1e1e"
                        stroke-width="1.5"
                        transform="translate(1 1)"
                      >
                        <path d="m22.5480862 23.7538462h-21.2194462c-.66658461 0-1.20516923-.5395693-1.20516923-1.2061539v-21.21846153c0-.66658462.53858462-1.20615385 1.20516923-1.20615385h21.2194462c.6665846 0 1.2061538.53956923 1.2061538 1.20615385v21.21846153c0 .6665846-.5395692 1.2061539-1.2061538 1.2061539z" />
                        <g stroke-linecap="round">
                          <path d="m.12307692 14.7692308h23.63076928" />
                          <path d="m8 3.46153846v10.81538464" />
                          <path d="m15.3846154.12307692v14.15384618" />
                        </g>
                      </g>
                    </svg>
                    <p className="text-xl font-normal ml-4">{t('contactPages.glassedBalcony')}</p>
                  </div>
                  <div
                    className="flex items-center"
                    style={{ marginLeft: "auto" }}
                  >
                    <button
                      className="negative-icon opacity-40"
                      onClick={() =>
                        setShowGlassedBalcony(
                          showGlassedBalcony > 0 ? showGlassedBalcony - 1 : 0
                        )
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4">
                      {showGlassedBalcony}
                    </p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() =>
                        setShowGlassedBalcony(showGlassedBalcony + 1)
                      }
                    >
                      +
                    </button>
                  </div>
                </div>
                {showGlassedBalcony > 0 && (
                  <div>
                    <div>
                      <div className="w-full mt-4 flex justify-center items-center">
                        <p className=" border-gray-200 border w-full text-center hover:w-[400PX] hover:border-gray-500  transition-all duration-300 transform origin-center scale-100"></p>
                      </div>
                      <p className="text-xl text-left font-semibold my-3">
                        Type of balcony?*
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <p className="text-lg flex gap-2 font-normal">
                        <input
                          type="radio"
                          name="glassedBalcony"
                          checked
                          value={"Glassed balcony"}
                          className=" w-7 ml-auto"
                          id="Glassed-balcony"
                        />
                        <label
                          className="text-lg cursor-pointer"
                          htmlFor="Glassed-balcony"
                        >
                          {"Glassed balcony"}
                        </label>
                      </p>
                      <p className="text-lg flex gap-2 font-normal">
                        <input
                          type="radio"
                          name="glassedBalcony"
                          value={"Glass railing only"}
                          className="w-7 ml-auto"
                          id="Galss-railing"
                        />
                        <label
                          className="text-lg cursor-pointer"
                          htmlFor="Galss-railing"
                        >
                          {" "}
                          {"Glass railing only"}
                        </label>
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-3 rounded-lg p-4">
              <p className="text-2xl text-left mb-5 font-semibold">
              {t('contactPages.shouldSomethingBeAdded')}
              </p>
              <div className="flex items-center mb-6">
                <input
                  type="checkbox"
                  id="frame_wash"
                  name="pets"
                  className="mr-4 h-6 w-6 cursor-pointer border-gray-400 rounded-xl"
                />
                <label className="text-lg cursor-pointer" htmlFor="frame_wash">
                {t('contactPages.cleanWindowFrames')}
                </label>
              </div>

              <div className="flex items-center mb-6">
                <input
                  type="checkbox"
                  id="ladder"
                  name="pets"
                  className="mr-4 h-6 w-6 cursor-pointer border-gray-400 rounded-xl"
                />
                <label className="text-lg cursor-pointer" htmlFor="ladder">
                  {t('contactPages.ladderNeeded')}
                </label>
              </div>
              <p className="text-2xl text-left mb-2 font-normal">
              {t('contactPages.isThereAnythingElse')}
              </p>
              <div className="flex items-center mb-6">

                <input
                  type="text"
                  id="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="border w-full rounded-md mb-4 p-5 border-[#d5d2c4]"
                />
              </div>
              <p className=" border-[#d6d2c4] border ms-2"></p>
            </div>

            <div className="mt-3 rounded-lg p-4">
              <p className="text-xl text-left mb-5 font-semibold">
              {t('contactPages.doYouHavePets')}
              </p>
              <div className="flex items-center mb-6">
                <input
                  type="checkbox"
                  id="cat"
                  name="pets"
                  className="mr-4 h-6 w-6 cursor-pointer border-gray-400 rounded-xl"
                />
                <label className="text-lg cursor-pointer" htmlFor="cat">
                {t('contactPages.cat')}
                </label>
              </div>

              <div className="flex items-center mb-6">
                <input
                  type="checkbox"
                  id="dog"
                  name="pets"
                  className="mr-4 h-6 w-6 cursor-pointer border-gray-400 rounded-xl"
                />
                <label className="text-lg cursor-pointer" htmlFor="dog">
                {t('contactPages.dog')}
                </label>
              </div>
              <div className="flex items-center mb-6">
                <input
                  type="checkbox"
                  id="other"
                  name="pets"
                  className="mr-4 h-6 w-6 cursor-pointer border-gray-400 rounded-xl"
                />
                <label className="text-lg cursor-pointer" htmlFor="other">
                {t('contactPages.otherAnimal')}
                </label>
              </div>
            </div>

            <h4 className="font-semibold text-left mb-3 text-xl">{t('contactPages.access')}</h4>
            <div className="border rounded-md mb-2 p-5 border-[#d5d2c4] bg-white">
              {/* Radio Button 1 */}
              <div className="flex items-center justify-between mb-2">
                <label
                  htmlFor="open"
                  className="cursor-pointer flex items-center justify-between w-full"
                >
                  <p className="text-md font-normal">
                  {t('contactPages.IwillOpen')}
                  </p>
                  <div
                    className={`w-7 h-7 rounded-full border border-gray-300 flex items-center justify-center ${handover === "open" ? "bg-black" : "bg-white"
                      }`}
                  >
                    {handover === "open" && (
                      <svg
                        className="w-4 h-4 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        ></path>
                      </svg>
                    )}
                  </div>
                </label>
                <input
                  type="radio"
                  id="open"
                  name="handover"
                  checked={handover === "open"}
                  onChange={() => handleHandoverChange("open")}
                  className="hidden"
                />
              </div>

              <p className="mb-2 border-[#d6d2c4] border"></p>

              {/* Radio Button 2 */}
              <div className="flex my-auro">
                <label
                  className="text-xl cursor-pointer flex items-center jutify-between w-full font-normal"
                  htmlFor="leave"
                >
                  <p className="text-left">
                  {t('contactPages.iWillLeave')}
                  </p>
                  <div
                    className={`w-8 h-7 rounded-full border border-gray-300 flex items-center justify-center ${handover === "leave" ? "bg-black" : "bg-white"
                      }`}
                  >
                    {handover === "leave" && (
                      <svg
                        className="w-4 h-4 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        ></path>
                      </svg>
                    )}
                  </div>
                </label>
                <input
                  type="radio"
                  name="handover"
                  value={"leave"}
                  checked={handover === "leave"}
                  onChange={() => handleHandoverChange("leave")}
                  className="hidden"
                  id="leave"
                />
              </div>

              <p className="mb-2 border-[#d6d2c4] border"></p>

              {/* Radio Button 3 */}
              <div className="flex my-auto">
                <label
                  className="text-xl cursor-pointer flex items-center justify-between w-full font-normal"
                  htmlFor="customer"
                >
                  <p className="text-left">
                  {t('contactPages.iAmACustomer')}
                  </p>
                  <div
                    className={`w-7 h-7 rounded-full border border-gray-300 flex items-center justify-center ${handover === "customer" ? "bg-black" : "bg-white"
                      }`}
                  >
                    {handover === "customer" && (
                      <svg
                        className="w-4 h-4 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        ></path>
                      </svg>
                    )}
                  </div>
                </label>
                <input
                  type="radio"
                  name="handover"
                  value={"customer"}
                  checked={handover === "customer"}
                  onChange={() => handleHandoverChange("customer")}
                  className="hidden"
                  id="customer"
                />
              </div>
            </div>

            <p className="text-4 text-left italic my-2">
              <a href="https://www.google.com/maps/place/Dalgatan+5B,+1101,+195+47+M%C3%A4rsta,+Sweden/@59.6266695,17.8531464,17z/data=!3m1!4b1!4m5!3m4!1s0x465fbc16cc3bd0ff:0xf9fcc296cf8de420!8m2!3d59.6266668!4d17.8557213?entry=ttu" className="font-semibold underline">
              {t('contactPages.here')}
              </a>{" "}
              {t('contactPages.youWillFind')}
            </p>
            <div
              className="mt-10"
              style={{ display: "flex", justifyContent: "space-evenly" }}
            >
              <button
                style={{ padding: "15px 50px 15px 50px" }}
                className="bg-[#000] font-bold uppercase text-white p-5 rounded text-bold"
                onClick={handleSubmit}
              >
                {t('contactPages.moveOn')}
              </button>
            </div>
          </div>
          <div
            className="mt-20 pb-5"
            style={{
              backgroundColor: "#d1dce7",
              width: "300px",
              height: "450px"
            }}
          >
            <div className="flex-col mt-5 px-5">
              <p
                className="text-2xl text-normal text-[#003b5c] text-left"
                style={{ fontFamily: "Tiempos Headline,serif" }}
              >
                {t('contactPages.yourBooking')}
              </p>
            </div>
            <div className="flex mt-5 px-5">
              <p className="text-lg text-normal text-[#003b5c] text-left flex-1">
              {t('contactPages.withoutMullions')}
              </p>
              <p className="text-gl font-bold text-[#003b5c]">
                {windowCleaningPrice} kr
              </p>
            </div>
            <div className="w-full mt-3 flex justify-center px-5 items-center mb-5">
              <p className=" border-[#003b5c] border opacity-10 w-full text-center "></p>
            </div>
            <div className="flex mt-5 px-5">
              <p className="text-lg text-normal text-[#003b5c] text-left flex-1">
              {t('contactPages.withMullions')}
              </p>
              <p className="text-gl font-bold text-[#003b5c]">
                {windowCleaningPrice1} kr
              </p>
            </div>
            <div className="w-full mt-3 flex justify-center px-5 items-center mb-5">
              <p className=" border-[#003b5c] border opacity-10 w-full text-center "></p>
            </div>
            <div className="flex mt-5 px-5">
              <p className="text-lg text-normal text-[#003b5c] text-left flex-1">
              {t('contactPages.overHangedWindows')}
              </p>
              <p className="text-gl font-bold text-[#003b5c]">
                {windowCleaningPrice2} kr
              </p>
            </div>
            <div className="w-full mt-3 flex justify-center px-5 items-center mb-5">
              <p className=" border-[#003b5c] border opacity-10 w-full text-center "></p>
            </div>
            <div className="flex mt-5 px-5">
              <p className="text-lg text-normal text-[#003b5c] text-left flex-1">
              {t('contactPages.glassedBalcony')}
              </p>
              <p className="text-gl font-bold text-[#003b5c]">
                {windowCleaningPrice3} kr
              </p>
            </div>
            <div className="w-full mt-3 flex justify-center px-5 items-center mb-5">
              <p className=" border-[#003b5c] border opacity-10 w-full text-center "></p>
            </div>
            <div className="flex mt-5 px-5">
              <p
                className="text-normal text-normal text-[#003b5c] px-1 bg-[#e8eef3] border text-left"
                style={{ display: "inline-block" }}
              >
                {t('contactPages.discountCode')}
              </p>
              <p className="text-xl font-semibold text-right flex-1 text-[#003b5c]">
                -221 kr
              </p>
            </div>
            <div className="w-full mt-3 flex justify-center px-5 items-center mb-5">
              <p className=" border-[#003b5c] border opacity-10 w-full text-center "></p>
            </div>
            {/* <div className="mt-5 px-5">
              <p className="text-xl font-semibold text-[#003b5c] text-left">
                Date and time
              </p>
              <p className="text-xl text-left font-semibold">-</p>
            </div>
            <div className="w-full mt-3 flex justify-center px-5 items-center mb-5">
              <p className=" border-[#003b5c] border opacity-10 w-full text-center "></p>
            </div> */}
            <div className="flex mt-5 px-5">
              <div className="flex-1">
                <p className="text-lg text-[#003b5c] text-left flex-1">{t('contactPages.total')}</p>
                <p className="text-sm text-[#003b5c] text-left flex-1 ms-1">
                {t('contactPages.withRUTReduction')}
                </p>
              </div>
              <p className="text-xl font-semibold text-[#003b5c] my-auto">
                {totalPrice} kr
              </p>
            </div>
            {/*  <div className="w-full mt-3 flex justify-center px-5 items-center mb-5">
              <p className=" border-[#003b5c] border opacity-10 w-full text-center "></p>
            </div> */}
            {/* <div className="flex mt-5 px-5">
              <p
                className="text-normal text-normal text-[#003b5c] text-left"
                style={{ display: "inline-block" }}
              >
                Discount code
              </p>
              <p className="text-normal font-normal text-right flex-1 text-[#003b5c] underline">
                FP1123
              </p>
            </div> */}
          </div>
        </div>
      </div>
      {showWindowGuide && (
        <WindowGuideModal
          showWindowGuide={showWindowGuide}
          handleCloseOpenInfo={handleCloseOpenInfo}
        />
      )}
    </>
  );
};

export default WindowCleaning;
