import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";

import { ZIPCODES } from "../../../Constants/Constant";

import Image1 from '../../../../Assets/images/Cleaning-As-Salary-Benefit-2.jpeg'
import { FaTimes } from "react-icons/fa"
import { Button } from "@chakra-ui/react"

import { useTranslation } from 'react-i18next';

const SimplifyYourLife = () => {

    const { t } = useTranslation();
  
    const [modalOpen, setModalOpen] = useState(false);

    const [city, setCity] = useState("");
    const [code, setCode] = useState("");
    const zipcodeRef = useRef();
    const [hideInitialForm, setHideInitialForm] = useState(false);
    const [selectedService, setSelectedService] = useState("");
    const [showSquareMeterInput, setShowSquareMeterInput] = useState(false);
    const [squareMeter, setSquareMeter] = useState("");
    const [show, setShow] = useState(false);
    const handleCitySelection = () => {
        setHideInitialForm(true);
    };
    const handleServiceSelect = (selectedService) => {
        setSelectedService(selectedService);
        setShowSquareMeterInput(true);
    };
    const navigate = useNavigate();
    const handleSquareMeterInputChange = (e) => {
        setSquareMeter(e.target.value);
    };
    const handleNavigate = () => {

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        let route = "/Booking"; // Default route for Home Cleaning

        switch (selectedService) {
            case "Home Cleaning":
                route = "/Booking";
                break;
            case "Window Cleaning":
                route = "/window_cleaning";
                break;
            case "Deep Cleaning":
                route = "/deep_cleaning";
                break;
            case "Dödsbo":
                route = "/Dödsbo";
                break;
            case "Move Out Cleaning":
                route = "/moving_out_cleaning";
                break;
            case "Moving And MoveOut Cleaning":
                route = "/moving_out_service2";
                break;
            case "Moving Service":
                route = "/moving_out_service1";
                break;
            case "Home Renovation":
                route = "/home_Renovation1";
                break;
            case "General  Handyman":
                route = "/Generalhandyman1";
                break;
            case "Office Cleaning":
                route = "/office_cleaning";
                break;
            case "For Housing Cooperation":
                route = "/for_housing_cooperative";
                break;
            case "Office Relocation":
                route = "/office_relocation";
                break;
            case "Cleaning As Salery Benifit":
                route = "/cleaning_as_salery_benifit";
                break;

            default:
                // Default to /Booking if the selected service is not recognized
                route = "/Booking";
        }

        navigate(route, {
            state: {
                data: {
                    city,
                },
            },
        });
    };
   
    const handleInputChange = (e) => {
        const enteredZipCode = e.target.value;
        const isValidZipCode = /^[0-9]{1,5}$/.test(enteredZipCode);
        const filteredCity = ZIPCODES.filter(
            (item) => item?.zipcode == enteredZipCode
        );
        if (filteredCity.length > 0) {
            setCode(e.target.value);
            setCity(filteredCity[0]?.city);
            setShow(true);
        } else {
            setCode(e.target.value);
            setShow(false);
        }
    };
    const handleRef = () => {
        zipcodeRef.current.focus();
    };

    const isButtonDisabled = !city || !code || code.length > 5 || code.length <= 4;

    return (
        <div className="  bg-[#eae8e1] overflow-clip font-['Roboto']">

            <div className="flex flex-col-reverse lg:flex-row  items-center">
                {/* first content */}

                <div className=" my-20 px-4 sm:px-0 md:px-4 lg:px-20  text-black text-4xl  font-['Young Serif']    ">
                    <p className="text-start">{t('generalComponents.readToSimplifyYOurLife.headingPart1')}</p>
                    <p className="text-start mt-1">{t('generalComponents.readToSimplifyYOurLife.headingPart2')}</p>

                    <div className="w-full  flex justify-start mt-8 hover:w-40">
                        <p className=" border-black border-2 w-20" ></p>
                    </div>

                    <div>{!hideInitialForm && (
                        <>
                            {" "}
                            <div className="flex  hover:shadow-black text-lg font-bold hover:border-none hover:text-green-500 transform hover:scale-90 transition-transform delay-200">
                                <input
                                    className="flex-1 bg-white focus:border-none focus:outline-none text-black text-right text-lg p-3 rounded-l-lg mt-10 placeholder-black font-bold cursor-pointer bg-opacity-80 transform transition-transform delay-200 hover:placeholder-gray-500"
                                    type="number"
                                    placeholder={`${t('generalComponents.hero.headingPart1')}`}
                                    value={code}
                                    maxLength="5"
                                    ref={zipcodeRef}
                                    onChange={handleInputChange}
                                />

                                <div
                                    onClick={handleRef}
                                    className="flex-1 flex items-center justify-between bg-white focus:border-none focus:outline-none text-black text-lg p-3 rounded-r-lg mt-10 placeholder-black font-bold cursor-pointer bg-opacity-80 transform transition-transform delay-200 hover:placeholder-gray-500"
                                >
                                    {show && city && (
                                        <>
                                            <p className="text-left">{city} </p>
                                            <p>
                                                <svg
                                                    height="24"
                                                    width="24"
                                                    viewBox="0 0 24 24"
                                                    class="fcNb"
                                                    style={{ fill: "green" }}
                                                >
                                                    <g>
                                                        <path d="m12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24zm0 22.286a10.286 10.286 0 1 1 0-20.572 10.286 10.286 0 0 1 0 20.572z"></path>
                                                        <path d="m17.4 7.114-6.257 6.24-2.828-2.81a.848.848 0 1 0 -1.2 1.2l3.428 3.428a.824.824 0 0 0 1.2 0l6.857-6.858a.849.849 0 0 0 -1.2-1.2z"></path>
                                                    </g>
                                                </svg>
                                            </p>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div
                                className="w-full border-0"
                                style={{ marginTop: "-10px" }}
                            >
                                {!show && (code.length >= 1 || code.length > 5) && (
                                    <p className="text-red-500 text-xs text-center mt-2 flex items-center justify-center">
                                        {t('generalComponents.dropDown.error1')}
                                    </p>
                                )}

                                {!show && code.length === 5 && (
                                    <p className="text-red-500 text-xs text-center mt-2">
                                        {t('generalComponents.dropDown.error2')}
                                    </p>
                                )}
                            </div>
                            <div className="flex justify-center items-center  mt-4 p-5 hover:shadow-lg hover:shadow-black bg-[#1e1e1e] text-lg font-bold  text-white rounded-md hover:text-green-500 transform hover:scale-90 transition-transform delay-200">
                                <button
                                    className={`flex justify-center items-center  hover:shadow-lg hover:shadow-black bg-[#1e1e1e] text-lg font-bold  text-white rounded-md ${isButtonDisabled || (!show && code.length === 5)
                                        ? "cursor-not-allowed opacity-50"
                                        : "hover:text-green-500 transform hover:scale-90 transition-transform delay-200"
                                        }`} onClick={handleCitySelection}
                                    disabled={isButtonDisabled}
                                >
                                    {t('generalComponents.hero.chooseServices')}
                                </button>
                                <p className="text-center text-4xl  self-end absolute end-0 mr-4 ">
                                    <svg
                                        height="20"
                                        viewBox="0 0 9 15"
                                        width="20"
                                        class="csw9N_C5 fiwAtSA"
                                        style={{ stroke: "#fff" }}
                                    >
                                        <path
                                            d="m.958.995 6.51 6.51-6 6"
                                            fill="none"
                                            stroke-width="2"
                                        ></path>
                                    </svg>
                                </p>
                            </div>
                        </>
                    )}
                        {hideInitialForm && (
                            <div>
                                <select
                                    value={selectedService}
                                    onChange={(e) => handleServiceSelect(e.target.value)}
                                    className="flex-1 w-5/6  text-black text-lg p-4 rounded-lg placeholder-black font-bold cursor-pointer bg-opacity-90 transform transition-transform delay-200 hover:placeholder-gray-500"
                                >
                                    <option value="" disabled className='text-center'> {t('generalComponents.dropDown.dropDownElement1')}</option>
                                    <option value="Moving Service" className='text-center'> {t('generalComponents.dropDown.dropDownElement6')}</option>
                                    <option value="Move Out Cleaning" className='text-center'> {t('generalComponents.dropDown.dropDownElement5')}</option>
                                    <option value="Moving And MoveOut Cleaning" className='text-center'> {t('contactPages.movingAndMoveOutCleaning')}</option>
                                    <option value="Office Relocation" className='text-center'> {t('generalComponents.dropDown.dropDownElement11')}</option>
                                    <option value="Office Cleaning" className='text-center'> {t('generalComponents.dropDown.dropDownElement9')}</option>
                                    <option value="Home Renovation" className='text-center'> {t('generalComponents.dropDown.dropDownElement7')}</option>
                                    <option value="General  Handyman" className='text-center'> {t('generalComponents.dropDown.dropDownElement8')}</option>
                                    <option value="Home Cleaning" className='text-center'> {t('generalComponents.dropDown.dropDownElement2')}</option>
                                    <option value="Window Cleaning" className='text-center'> {t('generalComponents.dropDown.dropDownElement3')}</option>
                                    <option value="Deep Cleaning" className='text-center'> {t('generalComponents.dropDown.dropDownElement4')}</option>
                                    <option value="Dödsbo" className='text-center'> Dödsbo</option>
                                    <option value="For Housing Cooperation" className='text-center'> {t('generalComponents.dropDown.dropDownElement10')}</option>
                                    <option value="Cleaning As Salery Benifit" className='text-center'> {t('generalComponents.dropDown.dropDownElement12')}</option>
                                </select>
                                <div className="flex justify-center items-center  bg-opacity-80 hover:shadow-lg hover:shadow-black bg-[#1e1e1e] text-lg font-bold mt-4 p-5 rounded-md hover:text-green-500 transform hover:scale-90 transition-transform delay-200">
                                    <button
                                        className={`text-center text-['#fff']`}
                                        onClick={handleNavigate}
                                        disabled={!selectedService}
                                    >
                                        {t('generalComponents.hero.continueButton')}
                                    </button>
                                    <p className="text-center text-4xl  self-end absolute end-0 mr-4 ">
                                        <svg
                                            height="20"
                                            viewBox="0 0 9 15"
                                            width="20"
                                            class="csw9N_C5 fiwAtSA"
                                            style={{ stroke: "#fff" }}
                                        >
                                            <path
                                                d="m.958.995 6.51 6.51-6 6"
                                                fill="none"
                                                stroke-width="2"
                                            ></path>
                                        </svg>
                                    </p>
                                </div>
                            </div>
                        )}</div>

                </div>

                {/* second content */}
                <div className='w-[100%] h-auto lg:w-[800px] lg:h-[550px] overflow-hidden ml-0 md:ml-auto'>
                    <div className="w-[100%] h-[400px] md:h-[550px] lg:w-[800px] flex justify-center items-end background-cover bg-no-repeat bg-cover overflow-hidden transition-transform transform hover:scale-105 duration-300 image-div relative" style={{backgroundImage:`url(${Image1})`}}>
                        <Button style={{ background: "black" }} onClick={() => setModalOpen(true)} className="h-[30px] rounded-lg px-4 pt-2 overflow-hidden relative bottom-[-50px] group cursor-pointer border-2 font-medium text-[16px] border-white text-black hover:scale-105 duration-[900ms] hover:border-[#003942] hover:text-[white] preview-button">
                            <span className="absolute w-72 h-0 transition-all duration-[900ms] origin-center rotate-45 -translate-x-20 bg-white top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                            <span className="relative text-xl text-white transition duration-[900ms] group-hover:text-black ease mb-2">{t('generalComponents.preview')}</span>
                        </Button>
                    </div>
                </div>

            </div>

            {modalOpen && (
                <div style={{zIndex: 9999}} className="z-5000 fixed top-[30px] left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="fadeIn bg-white p-4 rounded-lg relative z-5001">
                        <div onClick={() => setModalOpen(false)} className='absolute top-0 right-0 m-2 p-2 cursor-pointer rounded-full border-2 border-orange-600 text-orange-600 bg-white text-center'>
                        <FaTimes />
                        </div>
                        <img src={Image1} alt="Full Size" className="h-[500px] rounded-lg"/>
                        <button onClick={() => setModalOpen(false)}>{t('generalComponents.close')}</button>
                    </div>
                </div>
            )}
        </div>

    );

}
export default SimplifyYourLife;