import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const HelpWith1 = () => {

    const { t, i18n } = useTranslation();
  
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };

    return (

        <div className="bg-white relative font-['Roboto'] pb-10">
            <div className="hover:transform scale-90  text-5xl">


                <div className="pt-36 font-bold">{t('home.cleaningToLocation.headingPart1')} <span className='text-red-500'>{t('home.cleaningToLocation.headingPart2')}</span></div>
                <div class="w-full flex justify-center mt-8 relative group ">
                    <p class="border-black hover:border-white border-2 w-20 hover:w-[1000PX] text-center transition-all duration-300 transform origin-center scale-100 "></p>
                </div>
                {/*                 <p className='text-xl '>We are happy to help you with everything from recurring cleaning to easier handy work and <br /> moving,  so you can focus on what is important to you.

                </p> */}
            </div>

            {/* <div className="mt-8 text-lg leading-7  ">
                <p>Sometimes there are too many tasks and too few hours. That's why there is</p>
                <p>sofas. We help you with everything from cleaning to easier handy work!</p>
            </div> */}

            <div className="flex justify-center items-center flex-col xl:mx-10 lg:mx-7 2xl:mx-80 mt-16 h-auto">
                <div className="flex flex-col lg:flex-row items-center w-fit" >


                    {/* <div className="lg:mr-20  mr-0">
                        <div class=" flex items-start  relative">
                            <a href="/Home_clean">
                                <img src='img/ha.svg'
                                    class="w-8 inline-block hover:shake" alt="" />
                            </a>


                            <p className="inline-block text-2xl  ml-12" href="/Home_clean" >Home Cleaning</p>
                            <a href="/Home_clean">
                                <p className="text-center text-4xl   absolute  end-0 bottom-3  cursor-pointer"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                            </a>
                        </div>
                        <p className="border-[#d5d2c4] border-b-2  my-4  pl-[450px]" ></p>
                    </div> */}

                    <div className="lg:mr-20 mr-0">
                        <div className="flex items-start relative">
                            <Link to="/Home_clean" onClick={() => window.scrollTo(0, 0)}>
                                <img src="img/ha.svg" className="w-8 inline-block hover:shake" alt="" />
                            </Link>
                            <Link to="/Home_clean" className="ml-12" onClick={() => window.scrollTo(0, 0)}>
                                <p className="inline-block text-2xl" >Stockholm</p>
                            </Link>
                            <Link to="/Home_clean" onClick={() => window.scrollTo(0, 0)}>
                                <p className="text-center text-4xl absolute end-0 bottom-3 cursor-pointer">
                                    <svg height="24" viewBox="0 0 9 15" width="24" className="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}>
                                        <path d="m.958.995 6.51 6.51-6 6" fill="none" strokeWidth="2"></path>
                                    </svg>
                                </p>
                            </Link>
                        </div>
                        <p className="border-[#d5d2c4] border-b-2 my-4 pl-[450px]"></p>
                    </div>

                    {/*  <div>
                        <div class=" flex items-start  relative">
                            <a href="/Home_clean">
                                <img className=" inline-block  h-12"
                                    src='img/de3.svg'
                                    class="w-8 inline-block hover:shake" alt="" />
                            </a>
                            <p className="text-2xl inline-block  ml-11">Gardening help</p>
                            <a href="/Home_clean">
                                <p className="text-center text-4xl   absolute end-0 bottom-3 cursor-pointer"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                            </a>
                        </div>


                        <p className="border-[#d5d2c4] border-b-2  my-4  pl-[450px]" ></p>


                    </div> */}
                    <div>
                        <div className="flex items-start relative">
                            <Link to="/Home_clean" onClick={() => window.scrollTo(0, 0)}>
                                <img className="inline-block h-12" src="img/de3.svg" alt="" />
                            </Link>
                            <Link to="/Home_clean" onClick={() => window.scrollTo(0, 0)}>
                                <p className="text-2xl inline-block ml-11">Gothenburg</p>
                            </Link>
                            <Link to="/Home_clean" onClick={() => window.scrollTo(0, 0)}>
                                <p className="text-center text-4xl absolute end-0 bottom-3 cursor-pointer">
                                    <svg height="24" viewBox="0 0 9 15" width="24" className="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}>
                                        <path d="m.958.995 6.51 6.51-6 6" fill="none" strokeWidth="2"></path>
                                    </svg>
                                </p>
                            </Link>
                        </div>
                        <p className="border-[#d5d2c4] border-b-2 my-4 pl-[450px]"></p>
                    </div>


                </div>


                <div className="flex flex-col lg:flex-row items-center w-fit">

                    <div className="lg:mr-20 mr-0">
                        <div className="flex items-start relative">
                            <Link to="/Deep_Clean" onClick={() => window.scrollTo(0, 0)}>
                                <img className="h-12 inline-block"
                                    src='img/f.svg'
                                    class="w-10 inline-block hover:shake" alt="" />
                            </Link>
                            <Link to="/Deep_Clean" onClick={() => window.scrollTo(0, 0)}>

                                <p className="text-2xl inline-block ml-10">Vasteras</p>
                            </Link>
                            <Link to="/Deep_Clean" onClick={() => window.scrollTo(0, 0)}>
                                <p className="text-center text-4xl   absolute  end-0 top-1 cursor-pointer"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                            </Link>
                        </div>
                        <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center pl-[450px] flex-wrap " ></p>
                    </div>
                    <div className="">

                        <div className="flex items-end relative">
                            <img className="h-12 inline-block"
                                src='img/genral.svg'
                                class="w-8 inline-block hover:shake" alt="" />
                            <p className="text-2xl inline-block ml-11">Malmo</p>
                            <p className="text-center text-4xl   absolute bottom-3 end-0"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                        </div>


                        <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center pr-[450px] flex-wrap" ></p>
                    </div>
                </div>
                <div className="flex  flex-col lg:flex-row items-center w-fit">

                    <div className="lg:mr-20 mr-0">

                        <div className="flex items-start relative">
                            <Link to="/Window_clean" onClick={() => window.scrollTo(0, 0)}>
                                <img className="h-12 inline-block"
                                    src='img/wi2.svg'
                                    class="h-10 inline-block hover:shake" alt="" />
                            </Link>
                            <Link to="/Window_clean" onClick={() => window.scrollTo(0, 0)}>
                                <p className="inline-block text-2xl ml-10">Uppsala</p>
                            </Link>
                            <Link to="/Window_clean" onClick={() => window.scrollTo(0, 0)}>
                                <p className="text-center text-4xl   absolute  end-0 top-2 cursor-pointer"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                            </Link>
                            <Link />
                        </div>




                        <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center pl-[450px]" ></p>
                    </div>


                    <div>
                        <div className="flex items-start relative flex-wrap">
                            <Link to="/MovingService" onClick={() => window.scrollTo(0, 0)}>
                                <img className="h-10 inline-block"
                                    src='img/t.svg'
                                    class="w-10 inline-block hover:shake" alt="" />

                                <p className="text-2xl inline-block ml-10">Linkoping </p>
                                <p className="text-center text-4xl top-2  absolute end-0"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                            </Link>
                        </div>

                        <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center pr-[450px]" ></p>
                    </div>

                </div>


                <div className="flex flex-col lg:flex-row items-center w-fit">

                    <div className="lg:mr-20 mr-0">
                        <div className="flex items-start relative">
                            <img className="h-12 inline-block"
                                src='img/n.svg'
                                class="w-8 inline-block hover:shake" alt="" />
                            <p className="text-2xl inline-block ml-12 ">Norrkoping</p>
                            <p className="text-center text-4xl   absolute end-0 top-2 cursor-pointer"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                        </div>


                        <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center pl-[450px]" ></p>
                    </div>
                    <div>
                        <div className="flex items-start relative">
                            <Link to="/Move_Out_Cleaning" onClick={() => window.scrollTo(0, 0)}>

                                <img className="h-12 inline-block"
                                    src='img/n3.svg'
                                    class="h-10 inline-block hover:shake" alt="" />

                                <p className="text-2xl inline-block ml-10">Malardalen</p>
                                <p className="text-center text-4xl  top-2 absolute end-0"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                            </Link>
                        </div>

                        <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center pr-[450px]" ></p>
                    </div>


                </div>
                {/* <div className="flex flex-col lg:flex-row items-center w-fit">

                    <div className="lg:mr-20 mr-0">
                        <div className="flex items-start relative">
                            <img className="h-12 inline-block"
                                src='img/wi4.svg'
                                class="w-10 inline-block hover:shake" alt="" />
                            <p className="text-2xl inline-block ml-10 ">Office Cleaning</p>
                            <p className="text-center text-4xl   absolute end-0 top-2 cursor-pointer"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                        </div>


                        <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center pl-[450px]" ></p>
                    </div>
                    <div>
                        <div className="flex items-start relative">
                            <a href="/Moving_Home">
                                <img className="h-12 inline-block"
                                    src='img/wi4.svg'
                                    class="h-10 inline-block hover:shake" alt="" />
                            </a>
                            <p className="text-2xl inline-block ml-10">Office Relocation</p>
                            <a href="/Moving_Home">
                                <p className="text-center text-4xl  top-2 absolute end-0"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                            </a>
                        </div>

                        <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center pr-[450px]" ></p>
                    </div>


                </div>
                <div>
                    <div className="flex flex-col lg:flex-row items-center w-fit">

                        <div className="lg:mr-20 mr-0">
                            <div className="flex items-start relative">
                                <img className="h-12 inline-block"
                                    src='img/wi4.svg'
                                    class="w-10 inline-block hover:shake" alt="" />
                                <p className="text-2xl inline-block ml-10 ">Renovation</p>
                                <p className="text-center text-4xl   absolute end-0 top-2 cursor-pointer"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                            </div>


                            <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center pl-[450px]" ></p>
                        </div>
                        <div>
                            <div className="flex items-start relative">
                                <a href="/Moving_Home">
                                    <img className="h-12 inline-block"
                                        src='img/wi4.svg'
                                        class="h-10 inline-block hover:shake" alt="" />
                                </a>
                                <p className="text-2xl inline-block ml-10">For Businesses</p>
                                <a href="/Moving_Home">
                                    <p className="text-center text-4xl  top-2 absolute end-0"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                </a>
                            </div>

                            <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center pr-[450px]" ></p>
                        </div>


                    </div>
                </div> */}

            </div>

            {/* <div className="flex flex-col lg:flex-row items-center w-fit">

                <div className="lg:mr-20 ml-[33%]">
                    <div className="flex items-start relative">
                        <a href="/Home_clean">
                            <img src='img/wi4.svg'
                                class="w-10 inline-block hover:shake " alt="" />
                        </a>


                        <p className="inline-block text-2xl  ml-8">Office Cleaning</p>
                        <a href="/Home_clean">
                            <p className="text-center text-4xl   absolute  end-0 bottom-3  cursor-pointer"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                        </a>
                    </div>
                    <p className="border-[#d5d2c4] border-b-2  my-4  pl-[450px]" ></p>
                </div>
            </div> */}
        </div>

    );


}

export default HelpWith1;