import React, { useEffect } from 'react';
import useTranslatePage from './../../Gernal_Components/TranslatePage';
import Loader from './../../Gernal_Components/Loader';

import Tipsinsp from "../Home/Sections/Tips&Insp";
import StepS from "./sections/3setps";
import Emma from "./sections/Emma";
import AskedSection from "./sections/FrequentlyAsked";
import Hero from "./sections/Hero_section";
import Includesection from "./sections/includesection";
import Premium from "./sections/premium";
import TNews from "./sections/tips&news";
import ZipeCode from "./sections/zipcode";

const Home_Cleaning = () => {

  const [translatePage, loading] = useTranslatePage();

    useEffect(() => {
        const lang = localStorage.getItem('language');
        if (lang) {
        translatePage(lang);
        }
    }, []);

  return (
    <div>
      {loading && <Loader />}
      <Hero />
      <Includesection />
      <StepS />
      <Premium />
      <AskedSection />
      {/* <Emma /> */}
      {/* <TNews /> */}
      <ZipeCode />
    </div>
  );
};

export default Home_Cleaning;
