import { IoIosArrowForward } from 'react-icons/io';
import './cleaningsub.css'
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const BussinessSub = ({ setisHover }) => {
  
    const { t, i18n } = useTranslation();
  
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };
  
    const handleClick = () => {
        // Scroll to the top of the page
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        setisHover("")

        // Introduce a delay (you can adjust the duration)
        const delayDuration = 500; // in milliseconds
        setTimeout(() => {
            // Now, navigate to the desired route
        }, delayDuration);
    };
    return (
        <div className='absolute w-full bg-gray-100 top-16 left-0 z-50 flex flex-col items-start transition-all duration-700'
            onMouseEnter={() => setisHover("For Businesses")}
            onMouseLeave={() => setisHover('')}
        >
            <div className='flex flex-col gap-y-10 pl-64 py-8 w-1/2'>
                <Link className='text-2xl text-start flex items-end' >
                    <img className="w-10 hover:shake" src="https://images.ctfassets.net/37vwfxlcawdb/5e2SSlRP2Oxtwh7V8TMlBU/61d589d79881b8faef21103b039c2263/Hemfrid_Piktogram_Flytthja__lp_64x64px-01.svg" alt="" />
                    <p className='px-4'>{t('generalComponents.navBarSub.heading4')}</p>
                    <IoIosArrowForward className='ml-auto w-9 h-9' />
                </Link>
                <ul className='grid grid-cols-2 gap-8 text-xl'>
                    <Link to='/office_cleaning' onClick={handleClick} className='col-span-1 border-b-2 border-gr1 py-2 flex hover:border-rounded'>
                        <li className="square-border hover:font-semibold">{t('generalComponents.navBarSub.linkOfficeCleaning')}</li>
                        <IoIosArrowForward className='ml-auto w-7 h-7' />
                    </Link>
                    <Link to='/for_housing_cooperative' onClick={handleClick} className='col-span-1 border-b-2 border-gr1 py-2 flex'>
                        <li className="square-border hover:font-semibold">{t('generalComponents.navBarSub.linkForHousingCooperative')}
                        </li>
                        <IoIosArrowForward className='ml-auto w-7 h-7' />
                    </Link>
                    <Link to='/office_relocation' onClick={handleClick} className='col-span-1 border-b-2 border-gr1 py-2 flex'>
                        <li className="square-border hover:font-semibold">{t('generalComponents.navBarSub.linkOfficeRelocation')}</li>
                        <IoIosArrowForward className='ml-auto w-7 h-7' />
                    </Link>
                    <Link to='/cleaning_as_salery_benifit' onClick={handleClick} className='col-span-1 border-b-2 border-gr1 py-2 flex'>
                        <li className="square-border hover:font-semibold">{t('generalComponents.navBarSub.linkCleaningasSalaryBenefit')}</li>
                        <IoIosArrowForward className='ml-auto w-7 h-7' />
                    </Link>
                </ul>



            </div>

        </div>
    );
}

export default BussinessSub;