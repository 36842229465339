import React, { useState, useEffect } from 'react';
import Loader from './Loader'; // Import your Loader component
import axios from 'axios'; // Don't forget to import axios

const useTranslatePage = () => {
  const [loading, setLoading] = useState(false);
  const [targetLanguage, setLanguage] = useState(localStorage.getItem('language') || 'en');

  const translatePage = async (targetLanguage) => {
    setLoading(true); // Show loader
  
    // Get all the text nodes on the page
    const textNodes = getTextNodesIn(document.body);
  
    // Loop through each text node and translate its content
    for (const node of textNodes) {
      // Skip if the node is empty or only contains whitespace
      if (!node.nodeValue.trim()) continue;
  
      await axios.post('http://localhost:5000/translate', {
        q: node.nodeValue,
        source: "en",
        target: targetLanguage,
      })
      .then(response => {
        // Replace the original text with the translated text
        node.nodeValue = response.data.translatedText;
      })
      .catch(error => {
        console.error("Error translating text: ", error);
      });
    }
  
    // Set the new language
    localStorage.setItem('language', targetLanguage);
  
    setLoading(false); // Hide loader after translation is done
  };
  
  
  // Helper function to get all text nodes in an element
  function getTextNodesIn(node) {
    let textNodes = [];
    if (node.nodeType === Node.TEXT_NODE) {
      textNodes.push(node);
    } else {
      let children = node.childNodes;
      for (let i = 0; i < children.length; i++) {
        textNodes.push(...getTextNodesIn(children[i]));
      }
    }
    return textNodes;
  }

  return [translatePage, loading];
};

export default useTranslatePage;
