import { Link } from "react-router-dom";

import { useTranslation } from 'react-i18next';

const StepS = () => {

    const { t } = useTranslation();

    return (<div>

        <div class="h-fit bg-[#eae8e1]  ">
            <h3 className="text-5xl font-bold pt-28">{t('aboutFSA.threeSteps.heading.headingPart1')} <span className="animate-bounce text-orange-600 heading">{t('aboutFSA.threeSteps.heading.headingPart2')}</span></h3>

            {/* line content */}
            <div className="w-full  flex justify-center mt-8">

                <p className=" border-black border-2 w-20  hover:w-[600PX] text-center transition-all duration-300 transform origin-center scale-100 delay-200 hover:border-gray-100" ></p>

            </div>

            {/* row begins */}
            <div className="   mt-14 mx-24 h-fit    pb-24  ">

                <div className="flex sm:flex-row flex-col justify-between">
                    {/* first row */}
                    <div className="flex my-3 sm:my-0 flex-col ">


                        <div className=" ml-1 flex  flex-col items-center justify-center transform hover:scale-105 duration-300">
                            <Link to={'/'} >
                                <div className="bg-[hsla(0,0%,100%,.4)]  self-center   h-36 w-36 flex items-center justify-center rounded-full hover:bg-white rotations">
                                    <img
                                        src="img/h3.svg"
                                        className="h-12" alt="img_icon" />
                                </div>
                            </Link>
                            <h1 className="font-bold mt-9 text-lg leading-10">{t('aboutFSA.threeSteps.card1.heading1')} <br />{t('aboutFSA.threeSteps.card1.heading2')}</h1>


                        </div>



                        <p className="text-base">{t('aboutFSA.threeSteps.card1.paragraph1')} <br /> {t('aboutFSA.threeSteps.card1.paragraph2')} <br />{t('aboutFSA.threeSteps.card1.paragraph3')} <br /> {t('aboutFSA.threeSteps.card1.paragraph4')} </p>





                    </div>

                    {/* second row */}
                    <div className="flex flex-col my-3 sm:my-0 ">


                        <div className=" ml-1 flex  flex-col items-center justify-center transform hover:scale-105 duration-300">
                            <Link to={"/README.mdMoving_Home"} >
                                <div className="bg-[hsla(0,0%,100%,.4)] hover:bg-white self-center   h-36 w-36 flex items-center justify-center rounded-full rotations">


                                    <img
                                        src="img/wi.svg"

                                        className="h-12" alt="img_icon" />


                                </div>

                            </Link>

                            <h1 className="font-bold mt-9 text-lg leading-10 ">{t('aboutFSA.threeSteps.card2.heading')}</h1>

                        </div>


                        <p className="text-base mt-2">{t('aboutFSA.threeSteps.card2.paragraph1')} <br />  {t('aboutFSA.threeSteps.card2.paragraph2')}  <br /> {t('aboutFSA.threeSteps.card2.paragraph3')}  <br /> {t('aboutFSA.threeSteps.card2.paragraph4')} </p>






                    </div>


                    {/* Third row */}

                    <div className="flex  my-3 sm:my-0 flex-col">


                        <div className=" ml-1 flex  flex-col items-center justify-center transform hover:scale-105 duration-300">
                            <Link to={"/Deep_Clean"}>
                                <div className="bg-[hsla(0,0%,100%,.4)] hover:bg-white self-center   h-36 w-36 flex items-center justify-center rounded-full rotations">


                                    <img
                                        src="img/ha.svg"
                                        className="h-12" />


                                </div>
                            </Link>



                            <h1 className="font-bold mt-9 text-lg leading-10">{t('aboutFSA.threeSteps.card3.heading')}
                            </h1>

                        </div>



                        <p className="text-base">{t('aboutFSA.threeSteps.card3.paragraph1')}<br /> {t('aboutFSA.threeSteps.card3.paragraph2')}<br />
                            {t('aboutFSA.threeSteps.card3.paragraph3')} <br /> {t('aboutFSA.threeSteps.card3.paragraph4')}
                        </p>






                    </div>


                </div>

            </div>
            {/* row ends */}

        </div>
    </div>);

}

export default StepS;