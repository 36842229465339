import React, { useEffect } from 'react';
import useTranslatePage from './../../Gernal_Components/TranslatePage';
import Loader from './../../Gernal_Components/Loader';

import Includesection from "./section/includesection";
import Anders from "./section/Anderstaby";
import Hero from "./section/Hero";
import Work from "./section/How_Does_it_work";
import Letus from "./section/Letus_everyday";
import Cleaningtips from "./section/cleaningtips";
import Faq from "./section/Faq";

const W_Home = () => {

  const [translatePage, loading] = useTranslatePage();

    useEffect(() => {
        const lang = localStorage.getItem('language');
        if (lang) {
        translatePage(lang);
        }
    }, []);

  return (
    <div>
      {loading && <Loader />}
      <Hero />
      <Includesection />
      <Work />
      <Faq />
      <Letus />
    </div>
  );
};

export default W_Home;
