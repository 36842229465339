import React, { useState, useEffect } from "react";
import useTranslatePage from './../../Gernal_Components/TranslatePage';
import Loader from './../../Gernal_Components/Loader';

import Note from "../MoveoutCleaning/Note";
import CrossIcon from "../../Gernal_Components/CrossIcon";
import { toast, ToastContainer } from "react-toastify";
import emailjs from "emailjs-com";
import { useNavigate } from "react-router";
import { Textarea } from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';

const Recycling = () => {
    
  const { t } = useTranslation();

    const [translatePage, loading] = useTranslatePage();
  
      useEffect(() => {
          const lang = localStorage.getItem('language');
          if (lang) {
          translatePage(lang);
          }
      }, []);
  
    const [message, setMessage] = useState('');
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async () => {
        if (

            !firstName ||
            !lastName ||
            !email ||
            !message
        ) {
            toast.error("Please fill in all required fields.");
            return;
        }

        try {
            const messageBody = `
            Recycling Request:
            First Name: ${firstName}
            Last Name: ${lastName}
            Message: ${message}
            Thank you for your interest in our services! We'll be in touch shortly to discuss how we can assist you further.
            `;

            const templateParams = {
                to_name: `${firstName}  ${lastName} `,
                from_name: "Code Zone",
                to_email: email,
                subject: "Recycling Request:",
                message: messageBody,
            };

            const response = await emailjs.send(
                "service_m774pph",
                "template_qsec7n7",
                templateParams,
                "rc1ba_PvLbgEIUjbt"
            );
            console.log("Email sent successfully:", response);
            if (response) {
                toast.success(`${firstName} ${lastName} Your Recycling Request Sent Successfully`);

                setEmail("");
                setMessage("");
                setFirstName("");
                setLastName("");

                document.getElementById("cat").checked = false;
                document.getElementById("dog").checked = false;
                document.getElementById("other").checked = false;
            }
        } catch (error) {
            console.error("Error sending email:", error);
        }
    };

    return (
        <div
            className="flex justify-center mx-auto my-auto pt-10 mb-20"
        >
        {loading && <Loader />}
            <div className="w-5/6 md:w-3/6 pt-20">
                <div className="text-center px-2">
                    <div className="flex justify-end hover:shake">
                        <CrossIcon handleClickCrossIcon={() => navigate("/Dödsbo")} />
                    </div>
                    <div
                        className=" mt-2 font-normal text-5xl"
                        style={{ fontFamily: "Tiempos Headline,serif" }}
                    >
                        {t('contactPages.dodsbo')}
                    </div>


                    <div className="w-full mt-8  flex justify-center items-center mb-5">
                        <p className=" border-black border w-20 text-center hover:w-[400PX] hover:border-gray-500  transition-all duration-300 transform origin-center scale-100"></p>
                    </div>

                    <div className="mt-3 rounded-lg p-4">

                        {/* Add First Name Input */}
                        <label htmlFor="firstName" className="text-xl font-semibold flex justify-left mb-2 ms-1">
                        {t('contactPages.firstName')}:
                        </label>
                        <input
                            type="text"
                            id="firstName"
                            onChange={(e) => setFirstName(e.target.value)}

                            name="firstName"
                            className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                            placeholder="Enter your first name"
                        />

                        <label htmlFor="lastName" className="text-xl font-semibold flex justify-left mb-2 ms-1">
                        {t('contactPages.lastName')}:
                        </label>
                        <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            onChange={(e) => setLastName(e.target.value)}

                            className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                            placeholder="Enter your last name"
                        />

                        <label htmlFor="email" className="text-xl font-semibold flex justify-left mb-2 ms-1">
                        {t('contactPages.email')}:
                        </label>
                        <input
                            type="email"
                            id="email"
                            onChange={(e) => setEmail(e.target.value)}

                            name="email"
                            className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                            placeholder="Enter your email"
                        />
                    </div>



                    <div className="mt-[-2px] rounded-lg p-2">
                        <label
                            htmlFor="message"
                            className="text-xl font-semibold flex justify-left mb-1 ms-1"
                        >
                            {t('contactPages.specificMessage')}
                        </label>
                        <div className="flex items-center mb-3">
                            <Textarea
                                type="text"
                                name="message"
                                onChange={(e) => setMessage(e.target.value)}

                                id="message"
                                className="border w-full mx-1 rounded-md mb-4 p-5 bg-green-500 "
                                style={{
                                    display: 'block',
                                    background: '#FFFFFF' // Set to block to make it vertically long
                                }} />

                        </div>
                    </div>


                    <div className="p-2">

                        <div
                            className="mt-10"
                            style={{ display: "flex", justifyContent: "space-evenly" }}
                        >
                            <button
                                style={{ padding: "15px 50px 15px 50px" }}
                                className="bg-[#000] font-bold uppercase text-white p-5 rounded text-bold"
                                onClick={handleSubmit} >
                                {t('contactPages.send')}
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div >
    )
}

export default Recycling