import react, { useState,useEffect } from "react"
import { Link } from "react-router-dom";
import Image1 from "./../../../../Assets/images/Three-Steps-Vertical-Image-Housing.jpeg"
import { FaTimes } from "react-icons/fa"
import { Button } from "@chakra-ui/react"

import { useTranslation } from 'react-i18next';

const ThreeSteps = () => {

    const { t } = useTranslation();

    const [modalOpen, setModalOpen] = useState(false);

    return (
        <div className="w-full flex justify-center items-center">
            <style jsx>{`
                .heading {
                    animation: pulse 1s infinite;
                }
                .preview-button{
                    transition: bottom 0.5s ease;
                }
                .image-div:hover .preview-button{
                    bottom: 20px;
                }
            `}</style>
            <div className="w-full md:w-4/6 flex flex-col md:flex-row-reverse justify-center items-center py-8 md:py-16">
                <div className="w-4/5 md:w-2/5 h-[300px] sm:h-[600px] flex justify-center items-end py-8 md:py-0"><div className="w-full h-full bg-cover flex justify-center items-end overflow-hidden transition-transform transform hover:scale-105 duration-300 image-div relative" style={{backgroundImage : `url(${Image1})`, backgroundSize: 'cover'}}>
                <Button style={{ background: "black" }} onClick={() => setModalOpen(true)} className="h-[30px] rounded-lg px-4 pt-2 overflow-hidden relative bottom-[-50px] group cursor-pointer border-2 font-medium text-[16px] border-white text-black hover:scale-105 duration-[900ms] hover:border-[#003942] hover:text-[white] preview-button">
                        <span className="absolute w-72 h-0 transition-all duration-[900ms] origin-center rotate-45 -translate-x-20 bg-white top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                        <span className="relative text-xl text-white transition duration-[900ms] group-hover:text-black ease mb-2">{t('generalComponents.preview')}</span>
                    </Button>    
                </div></div>
                <div className="w-full md:w-3/5 flex flex-col justify-center items-center">
                    <div className="w-4/5"><h2 className="text-3xl md:text-5xl font-bold mb-4 md:text-left">{t('homeCleaning.threeSteps.heading.headingPart1')} <span className="text-red-600 heading">{t('homeCleaning.threeSteps.heading.headingPart2')}</span> {t('homeCleaning.threeSteps.heading.headingPart3')}.</h2></div>
                    <div className="w-4/5 text-center md:text-left flex flex-row">
                        <div className="w-1/6 text-[100px] bg-gradient-to-b from-purple-500 via-pink-500 to-red-500 text-transparent bg-clip-text font-bold text-center">1</div>
                        <div className="w-5/6 flex flex-col">
                            <h3 className="text-2xl font-bold my-4 md:mt-8 md:mb-4">{t('homeCleaning.threeSteps.card1.heading')}</h3>
                            <p className="font-semibold my-4">{t('homeCleaning.threeSteps.card1.paragraph')}</p>
                        </div>
                    </div>
                    <div className="w-4/5 text-center md:text-left flex flex-row">
                        <div className="w-1/6 text-[100px] bg-gradient-to-b from-purple-500 via-pink-500 to-red-500 text-transparent bg-clip-text font-bold text-center">2</div>
                        <div className="w-5/6 flex flex-col">
                            <h3 className="text-2xl font-bold my-4 md:mt-8 md:mb-4">{t('homeCleaning.threeSteps.card2.heading')}</h3>
                            <p className="font-semibold my-4">{t('homeCleaning.threeSteps.card2.paragraph')}</p>
                        </div>
                    </div>
                    <div className="w-4/5 text-center md:text-left flex flex-row">
                        <div className="w-1/6 text-[100px] bg-gradient-to-b from-purple-500 via-pink-500 to-red-500 text-transparent bg-clip-text font-bold text-center">3</div>
                        <div className="w-5/6 flex flex-col">
                            <h3 className="text-2xl font-bold my-4 md:mt-8 md:mb-4">{t('homeCleaning.threeSteps.card3.heading')}</h3>
                            <p className="font-semibold my-4">{t('homeCleaning.threeSteps.card3.paragraph')}</p>
                        </div>
                    </div>
                </div>
            </div>
            {modalOpen && (
                <div style={{zIndex: 9999}} className="z-5000 fixed top-[30px] left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="fadeIn bg-white p-4 rounded-lg relative z-5001">
                        <div onClick={() => setModalOpen(false)} className='absolute top-0 right-0 m-2 p-2 cursor-pointer rounded-full border-2 border-orange-600 text-orange-600 bg-white text-center'>
                           <FaTimes />
                        </div>
                        <img src={Image1} alt="Full Size" className="h-[500px] rounded-lg"/>
                        <button onClick={() => setModalOpen(false)}>{t('generalComponents.close')}</button>
                    </div>
                </div>
            )}
        </div>
    );}

export default ThreeSteps;