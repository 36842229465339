import react, { useState,useEffect } from "react"
import Image1 from '../../../../Assets/images/emptying-estate.jpg'
import { FaTimes } from "react-icons/fa"

import { useTranslation } from 'react-i18next';

const EmptyingAnEstate = () => {
    
    const { t } = useTranslation();

    const [modalOpen, setModalOpen] = useState(false);

    return (
        <div id="emptying_an_estate" className="flex flex-col justify-center items-center mt-20 sm:mt-24 mb-16">
            <style jsx>{`
                .heading {
                    animation: pulse 1s infinite;
                }
                .preview-button{
                    transition: bottom 0.5s ease;
                }
                .image-div:hover .preview-button{
                    bottom: 20px;
                }
            `}</style>
            <div className="text-5xl font-bold text-[#000] mb-4 md:mb-14">
                <h2><span className='text-[#8637ba] heading'>{t('dodsbo.emptyingAnEstate.headingPart1')}</span> {t('dodsbo.emptyingAnEstate.headingPart2')}</h2>
            </div>
            <div className="flex flex-col md:flex-row w-5/6">
                <div className='h-[300px] w-full md:w-1/3 overflow-hidden'>
                    <div className="w-full h-full background-cover bg-no-repeat bg-cover transition-transform transform hover:scale-105 duration-300 image-div relative"style={{backgroundImage:`url(${Image1})`}}>
                        <button onClick={() => setModalOpen(true)} className="px-2 py-1 bg-black text-white rounded-md absolute bottom-[-50px] preview-button transition-opacity transform hover:opacity-30 duration-300">{t('generalComponents.preview')}</button>
                    </div>
                </div>
                <div className="w-full flex justify-center items-center md:w-2/3">
                    <div className="text-center md:leading-8 md:text-left md:ml-4">
                    {t('dodsbo.emptyingAnEstate.paragraph')}
                    </div>
                </div>
            </div>
            {modalOpen && (
                <div style={{zIndex: 9999}} className="z-5000 fixed top-[30px] left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="fadeIn bg-white p-4 rounded-lg relative z-5001">
                        <div onClick={() => setModalOpen(false)} className='absolute top-0 right-0 m-2 p-2 cursor-pointer rounded-full border-2 border-orange-600 text-orange-600 bg-white text-center'>
                           <FaTimes />
                        </div>
                        <img src={Image1} alt="Full Size" className="h-[500px] rounded-lg"/>
                        <button onClick={() => setModalOpen(false)}>{t('generalComponents.close')}</button>
                    </div>
                </div>
            )}
        </div>
    );

}

export default EmptyingAnEstate;