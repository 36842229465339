import { LinkedCamera } from '@mui/icons-material';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const Hero = () => {

  const { t } = useTranslation();

  return (
    <div className='w-full flex flex-col justify-center items-center pt-[200px] pb-32 md:pb-32 bg-gray-300'>
      <div className='w-5/6 md:w-3/6 flex flex-col justify-center items-center text-center'>
        <div className='text-6xl font-bold'><h1>{t('cleaningAsSaleryBenifit.main.heading')}</h1></div>
        <div className='w-full my-8 flex justify-center items-center'>
            <p className='border-black border-2 w-20 hover:w-[550PX] text-center transition-all duration-300 transform origin-center scale-100 delay-200 hover:border-gray-400'></p>
        </div>
        <div className='text-[18px] font-semibold'><p>{t('cleaningAsSaleryBenifit.main.paragraph')}</p></div>
        <Link to="/cleaning_as_salery_benifit_contact" className='mt-8 py-4 px-8 bg-black text-[30px] text-white font-bold rounded-lg transition hover:scale-105 duration-300'>{t('cleaningAsSaleryBenifit.main.button')}</Link>
      </div>
    </div>
  );
};

export default Hero;
