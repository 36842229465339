import React, { useRef, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import { TIME } from '../../Constants/Constant';
import { useLocation, useNavigate } from 'react-router';

import { useTranslation } from 'react-i18next';

const CalendarComponent = () => {
    
    const { t } = useTranslation();

    const [value, onChange] = useState(new Date());
    const [time, setTime] = useState('')
    const [selectedTimeIndex, setSelectedTimeIndex] = useState(null);

    const selectedDate = new Date(value);
    const timeBtn = useRef()
    const options = { weekday: 'long', day: 'numeric', month: 'long' };
    const date = new Intl.DateTimeFormat('en-US', options).format(selectedDate);
    const location = useLocation();
    const navigate = useNavigate()
    const data = location?.state?.data;
    const newData = [data, date, time];
    const handleNavigate = () => {
        navigate('/home_cleaning', {
            state: {
                data: newData || []
            }
        });
    }
    const handleTimeChange = (index) => {
        setSelectedTimeIndex(index);
        setTime(TIME[index]);
    };
    return (
        <div className="container mx-auto text-center">
            <div
                style={{ display: 'grid', gridTemplateColumns: '70% 30%' }}
                className="mx-auto mb-10"
            >

                <div>
                    {/*  <div className="  text-center">
                        <div className="icons_bodyByVisit ">
                            <span
                                className="cross_icon_box"
                                onClick={() => navigate("/Booking")}
                            >
                                <svg
                                    height="15"
                                    viewBox="0 0 9 15"
                                    width="15"
                                    className="csbbM95O hiH3WVS irER7"
                                >
                                    <path
                                        d="m.958.995 6.51 6.51-6 6"
                                        fill="none"
                                        stroke-width="2"
                                    ></path>
                                </svg>
                            </span>

                            <span
                                className="cross_icon_box"
                                onClick={() => navigate("/Home_clean")}
                            >
                                <svg viewBox="0 0 60 60" class="csbbM95O hiH3WVS">
                                    <g
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-miterlimit="10"
                                        stroke-width="8"
                                    >
                                        <path d="m5 5 50 50"></path>
                                        <path d="m5 55 50-50"></path>
                                    </g>
                                </svg>
                            </span>
                        </div>
                    </div> */}

                    <div className=" mt-5 text-center">
                        <div className="flex justify-center" style={{ maxWidth: '70%', margin: '0 auto' }}>
                            <span
                                className="cross_icon_box hover:shake"
                                onClick={() => navigate("/Booking")}
                                style={{ marginLeft: '10%', marginRight: 'auto' }}
                            >
                                <svg
                                    height="15"
                                    viewBox="0 0 9 15"
                                    width="15"
                                    className="csbbM95O hiH3WVS irER7"
                                >
                                    <path
                                        d="m.958.995 6.51 6.51-6 6"
                                        fill="none"
                                        strokeWidth="2"
                                    ></path>
                                </svg>
                            </span>

                            <span
                                className="cross_icon_box hover:shake"
                                onClick={() => navigate("/Home_clean")}
                                style={{ marginLeft: 'auto', marginRight: '10%' }}
                            >
                                <svg viewBox="0 0 60 60" className="csbbM95O hiH3WVS">
                                    <g
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-miterlimit="10"
                                        strokeWidth="8"
                                    >
                                        <path d="m5 5 50 50"></path>
                                        <path d="m5 55 50-50"></path>
                                    </g>
                                </svg>
                            </span>
                        </div>
                    </div>

                    <div className=" font-normal text-5xl">{t('contactPages.selectDateAndTime')}</div>
                    <div className="w-full my-8  flex justify-center items-center">

                        <p className=" border-black border w-20 text-center hover:w-[400PX] hover:border-gray-500  transition-all duration-300 transform origin-center scale-100" ></p>

                    </div>
                    <div className="w-full mt-8 flex justify-center items-center mb-5">
                        <Calendar className="text-center justify-center" onChange={onChange} value={value} />
                    </div>
                    <div className="text-xs justify-center px-5 mt-3" style={{ display: 'grid', gridTemplateColumns: 'auto auto auto auto', gap: '30px' }}>
                        {
                            TIME?.map((time, index) => {
                                return <input className={`px-5 py-3 rounded-lg`} style={{
                                    cursor: 'pointer',
                                    background: selectedTimeIndex === index ? 'black' : 'transparent',
                                    color: selectedTimeIndex === index ? 'white' : 'black',
                                    border: '1px solid black',
                                }}
                                    onClick={(e) => handleTimeChange(index)} type='button' value={time} key={index} ref={timeBtn}
                                />
                            })
                        }
                    </div>
                    <button className='px-20 py-3 bg-black text-white rounded-lg group overflow-hidden relative mt-5' onClick={handleNavigate}>
                        <span className="absolute w-64 h-0 transition-all duration-[700ms] origin-center rotate-45 -translate-x-16 bg-[#9e478e] top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                        <span className="relative text-white transition duration-[700ms] group-hover:text-white ease">
                        {t('contactPages.next')}
                        </span>
                    </button>
                </div>
                <div>
                    <div className='mt-20' style={{ backgroundColor: '#d1dce7', width: '300px', height: '300px' }}>
                        <div className="d-flex flex-col">
                            <p className="text-2xl text-left ml-3 mb-1">{t('contactPages.summary')}</p>
                            <p className="text-lg text-left ml-3">{t('contactPages.homeCleaning')}</p>
                            <p className="text-xl text-left ml-3">{t('contactPages.everyOtherWeek')}</p>
                            <hr
                                className="border w-2/3 mx-auto mt-3"
                                style={{ color: 'black', height: '1px', backgroundColor: '#a3bad0' }}
                            />
                            <p className="text-xl font-semibold text-left my-auto pl-3 py-2">{t('contactPages.homeVisit')}</p>
                            <p className="text-base text-left px-5 mt-3">{date}</p>
                            <p className="text-lg text-left px-5 mt-1">{time}</p>
                            <hr
                                className="border w-2/3 mx-auto mt-3"
                                style={{ color: 'black', height: '1px', backgroundColor: '#a3bad0' }}
                            />
                            <p className='text-lg text-left ml-3'>{t('contactPages.startingPrice')}</p>
                            <p className='text-xs text-left ml-3'>{t('contactPages.withRUTReduction')}</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default CalendarComponent;
