import { Link } from "react-router-dom";

import { useTranslation } from 'react-i18next';

const Work = () => {

    const { t } = useTranslation();

    return (
        <div>

            {/* <div className="w-full bg-cover "style={{ backgroundImage: "url('https://images.ctfassets.net/37vwfxlcawdb/4O2QGPHHml4qRJa29CYTXb/0b5bbc12687421dafbd7685898fd3eb3/Fonster-blomma-16-9.jpg?q=50&fm=webp&w=2560')", height: "45vw" }}>
</div> */}


            <div class="h-fit bg-[#d1dce7] ">
                <h3 className="text-5xl font-medium pt-28"><span className="animate-pulse duration-200 text-gray-500">{t('windowCleaning.howDoesItWork.heading.headingPart1')} </span>{t('windowCleaning.howDoesItWork.heading.headingPart2')} <span className="text-orange-600 heading">{t('windowCleaning.howDoesItWork.heading.headingPart3')}</span></h3>

                {/* line content */}
                <div className="w-full  flex justify-center mt-8">

                    <p className=" border-black border-2 w-20 hover:w-[550PX] text-center transition-all duration-300 transform origin-center scale-100 delay-200 hover:border-gray-400" ></p>


                </div>

                {/* row begins */}
                <div className="   mt-14 mx-24 h-fit    pb-24  ">

                    <div className="flex md:flex-row  flex-col gap-4 justify-between">
                        {/* first row */}
                        <div className="flex flex-col ">

                            <div className=" flex  flex-col items-center justify-center transform hover:scale-105 duration-300">
                                <Link to={"/Home_clean"} >
                                    <div className="bg-[hsla(0,0%,100%,.4)]  self-center   h-36 w-36 flex items-center justify-center rounded-full  hover:bg-white rotations">
                                        <img
                                            src="img/Book Online.svg"
                                            className="h-12" alt="img_icon" />
                                    </div>
                                </Link>
                                <h1 className="font-bold mt-9 text-lg leading-10">{t('windowCleaning.howDoesItWork.card1.heading')}</h1>
                            </div>
                            <p className="text-base ">{t('windowCleaning.howDoesItWork.card1.paragraph1')} <br /> {t('windowCleaning.howDoesItWork.card1.paragraph2')} <br /> {t('windowCleaning.howDoesItWork.card1.paragraph3')} <br /> {t('windowCleaning.howDoesItWork.card1.paragraph4')} </p>
                        </div>

                        {/* second row */}
                        <div className="flex flex-col ">


                            <div className=" ml-1 flex  flex-col items-center justify-center transform hover:scale-105 duration-300">
                                <Link to={"/"} >
                                    <div className="bg-[hsla(0,0%,100%,.4)]  self-center   h-36 w-36 flex items-center justify-center rounded-full  hover:bg-white rotations">

                                        <img
                                            src="img/before.svg" className="h-12" alt="img_icon" />

                                    </div>
                                </Link>

                                <h1 className="font-bold mt-9 text-lg leading-10">{t('windowCleaning.howDoesItWork.card2.heading')}</h1>

                            </div>


                            <p className="text-base">{t('windowCleaning.howDoesItWork.card2.paragraph1')} <br /> {t('windowCleaning.howDoesItWork.card2.paragraph2')} <br /> {t('windowCleaning.howDoesItWork.card2.paragraph3')} <br /> {t('windowCleaning.howDoesItWork.card2.paragraph4')}</p>

                        </div>


                        {/* Third row */}

                        <div className="flex  flex-col">


                            <div className=" ml-1 flex  flex-col items-center justify-center transform hover:scale-105 duration-300">
                                <Link to={"/Deep_Clean"} >
                                    <div className="bg-[hsla(0,0%,100%,.4)]  self-center   h-36 w-36 flex items-center justify-center rounded-full  hover:bg-white rotations">


                                        <img
                                            src="img/wcr.svg" className="h-12" alt="img_icon" />


                                    </div>
                                </Link>



                                <h1 className="font-bold mt-9 text-lg leading-10">{t('windowCleaning.howDoesItWork.card3.heading')}
                                </h1>

                            </div>



                            <p className="text-base">{t('windowCleaning.howDoesItWork.card3.paragraph1')} <br /> {t('windowCleaning.howDoesItWork.card3.paragraph2')} <br /> {t('windowCleaning.howDoesItWork.card3.paragraph3')} </p>






                        </div>


                    </div>

                </div>
                {/* row ends */}

            </div>

        </div >
    );

}

export default Work;