import React, { useState, useEffect } from "react";
import { BiSolidChevronDown } from "react-icons/bi"
const Note = () => {
    const [activeIndex, setActiveIndex] = useState();
    const handleSetIndex = (index) => {
        setActiveIndex(index);
    };
    const Note = [
        {
            question: "Note to Customers: Heavy Items Notification",
            answer: "Dear Valued Customer,As part of our commitment to ensuring a smooth and efficient moving experience for you, we kindly request your attention regarding the transportation of heavy items exceeding 100 kilograms. If you have heavy items such as furniture, Piano, machinery, or any other bulky belongings that weigh greater than 100 kilograms, we kindly ask that you inform us in advance during the booking process. This notification allows us to adequately prepare our moving team and ensure the safety of your items during transportation. Please be advised that the transportation of heavy items exceeding 100 kilograms may incur additional charges due to the specialized equipment and resources required for their safe handling and transportation. Once you have notified us of the presence of heavy items, we will provide you with a transparent overview of any additional costs associated with their transportation."
        }

    ]
    return (
        <div
            className="mt-20 pb-5"
            style={{
                width: "450px",
                height: "690px",
            }}>
            {Note.map(({ title, question, answer }, i) => (
                <div
                    className="bg-white rounded-lg  w-full shadow-lg hover:shadow-2xl group gap-8"
                    onMouseLeave={() => setActiveIndex()}
                    onMouseEnter={() => handleSetIndex(i)}
                >
                    <div className="flex justify-between items-center my-2  px-4 sm:px-8 h-14 cursor-pointer">
                        <h3 className={`lg:text-xl md:text-xl sm:text-xl text-xs pb-2 font-semibold mr-2 mt-3 `}>{question}</h3>
                        <div className="text-3xl group-hover:rotate-180 transition duration-1500 ml-auto">

                            <BiSolidChevronDown className="w-8 h-8  border-2 rounded-full text-green-500 border-green-500  group-hover:border-yellow-400 group-hover:text-yellow-400 " />
                        </div>
                    </div>
                    <div
                        x-ref="tab"
                        className={`border-l-2 rounded-bl-md border-yellow-500 dark:border-none overflow-hidden duration-[1500ms] transition-all ${activeIndex === i ? "max-h-[700px]" : "max-h-0 "
                            }`}
                    >
                        <p className="text-start px-4 sm:px-8 py-2  p-3 text-xl font-light text-gray-700">
                            {answer}
                        </p>
                    </div>
                </div>

            ))}
        </div>
    )
}

export default Note