import React, { useState, useEffect } from "react";
import useTranslatePage from './../../Gernal_Components/TranslatePage';
import Loader from './../../Gernal_Components/Loader';

import "../../../Assets/css/window_cleaning.css";
import CrossIcon from "../../Gernal_Components/CrossIcon";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import { BiSolidChevronDown } from "react-icons/bi";
import Note from "../MoveoutCleaning/Note";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from '@chakra-ui/react';
import emailjs from "emailjs-com";
import Faq1 from "../MoveoutCleaning/faq1";

import { useTranslation } from 'react-i18next';

const MovingOutService = () => {
  
  const { t } = useTranslation();

  const [activeIndex, setActiveIndex] = useState();
  const handleSetIndex = (index) => {
    setActiveIndex(index);
  };
  /* const Note = [
    {
      question: "Note to Customers: Heavy Items Notification",
      answer: "Dear Valued Customer,As part of our commitment to ensuring a smooth and efficient moving experience for you, we kindly request your attention regarding the transportation of heavy items exceeding 100 kilograms. If you have heavy items such as furniture, Piano, machinery, or any other bulky belongings that weigh greater than 100 kilograms, we kindly ask that you inform us in advance during the booking process. This notification allows us to adequately prepare our moving team and ensure the safety of your items during transportation. Please be advised that the transportation of heavy items exceeding 100 kilograms may incur additional charges due to the specialized equipment and resources required for their safe handling and transportation. Once you have notified us of the presence of heavy items, we will provide you with a transparent overview of any additional costs associated with their transportation."
    }

  ] */

  const [translatePage, loading] = useTranslatePage();

    useEffect(() => {
        const lang = localStorage.getItem('language');
        if (lang) {
        translatePage(lang);
        }
    }, []);

  const navigate = useNavigate()
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [toaddress, setToaddress] = useState("");
  const [fromaddress, setFromaddress] = useState("");
  const [sqm, setSqm] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [streetAddress1, setStreetAddress1] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [floor, setFloor] = useState("")
  const [floor1, setFloor1] = useState("")
  const [walkingdistance, setWalkingdistance] = useState('')
  const [walkingdistance1, setWalkingdistance1] = useState('')
  const [message, setMessage] = useState('')


  const [handover, setHandover] = useState("open");
  const [wantCleaning, setWantCleaning] = useState("yes");

  const [ovenCleaning, setOvenCleaning] = useState(0);
  const [fridgeCleaning, setFridgeCleaning] = useState(0);
  const [ovenCleaning1, setOvenCleaning1] = useState(0);
  const [fridgeCleaning1, setFridgeCleaning1] = useState(0);
  const [ovenCleaning2, setOvenCleaning2] = useState(0);
  const [fridgeCleaning2, setFridgeCleaning2] = useState(0);
  const [ovenCleaning3, setOvenCleaning3] = useState(0);
  const [fridgeCleaning3, setFridgeCleaning3] = useState(0);
  const [ovenCleaning4, setOvenCleaning4] = useState(0);
  const [fridgeCleaning4, setFridgeCleaning4] = useState(0);
  const [ovenCleaning5, setOvenCleaning5] = useState(0);
  const [ovenCleaning6, setOvenCleaning6] = useState(0);
  const [ovenCleaning7, setOvenCleaning7] = useState(0);
  const [ovenCleaning8, setOvenCleaning8] = useState(0);

  const [ovenCleaning11, setOvenCleaning11] = useState(0);
  const [ovenCleaning12, setOvenCleaning12] = useState(0);
  const [ovenCleaning13, setOvenCleaning13] = useState(0);
  const [ovenCleaning14, setOvenCleaning14] = useState(0);
  const [ovenCleaning15, setOvenCleaning15] = useState(0);
  const [ovenCleaning16, setOvenCleaning16] = useState(0);
  const [ovenCleaning17, setOvenCleaning17] = useState(0);
  const [ovenCleaning18, setOvenCleaning18] = useState(0);



  const [fridgeCleaning5, setFridgeCleaning5] = useState(0);
  const [fridgeCleaning6, setFridgeCleaning6] = useState(0);
  const [fridgeCleaning7, setFridgeCleaning7] = useState(0);
  const [fridgeCleaning8, setFridgeCleaning8] = useState(0);

  const [fridgeCleaning11, setFridgeCleaning11] = useState(0);
  const [fridgeCleaning12, setFridgeCleaning12] = useState(0);
  const [fridgeCleaning13, setFridgeCleaning13] = useState(0);
  const [fridgeCleaning14, setFridgeCleaning14] = useState(0);
  const [fridgeCleaning15, setFridgeCleaning15] = useState(0);
  const [fridgeCleaning16, setFridgeCleaning16] = useState(0);
  const [fridgeCleaning17, setFridgeCleaning17] = useState(0);
  const [fridgeCleaning18, setFridgeCleaning18] = useState(0);


  const [windowCleaning, setWindowCleaning] = useState(false);
  const [storstadningPrice, setStorstadningPrice] = useState("");
  const [total, setTotal] = useState(0);
  const [totalItem, setTotalItem] = useState("")
  const [totalbed, setTotalBed] = useState("")
  const [totalItem1, setTotalItem1] = useState("")
  const [totalbed1, setTotalBed1] = useState("")
  const handleHandoverChange = (value) => {
    setHandover(value);
  };

  const handleCleaningChange = (value) => {
    setWantCleaning(value);
  };
  const isValidEmail = (email) => {
    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    if (
      !toaddress ||
      !streetAddress ||
      !floor1 ||
      !walkingdistance ||
      !fromaddress ||
      !streetAddress1 ||
      !floor1 ||
      !walkingdistance1 ||
      !sqm ||
      !selectedDate ||
      !selectedTime ||
      !firstName ||
      !lastName ||
      !email ||
      !message ||
      !total

    ) {
      toast.error("Please fill in all required fields.");
      return;
    }
    if (!isValidEmail(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }


    try {
      const messageBody = `
             New Moving Service:
             
             Moving From:
             Street address: ${toaddress}
             Residence: ${streetAddress}
             Floor: ${floor}
             Walking Distance: ${walkingdistance}

             Moving To: 
             Street address: ${fromaddress}
             Residence: ${streetAddress1}
             Floor: ${floor1}
             Walking Distance: ${walkingdistance1}
             Sqm For Moving Service: ${sqm}
             Date : ${selectedDate}
             Time : ${selectedTime}
             First Name : ${firstName}
             Last Name:${lastName}
             Email:${email}
             Message:${message}

             Total Price:${message} SEK

             Thank you for your interest in our services! We'll be in touch shortly to discuss how we can assist you further.

             
         `;
      const templateParams = {
        to_name: `${firstName} ${lastName}`,
        from_name: "Code Zone",
        to_email: email,
        subject: "New General Handyman Request",
        message: messageBody,
      };
      const response = await emailjs.send(
        "service_m774pph",
        "template_qsec7n7",
        templateParams,
        "rc1ba_PvLbgEIUjbt"
      );
      console.log("Email sent successfully:", response);
      if (response) {
        toast.success(`${firstName} ${lastName} Your Request Sent Successfully`);
        setToaddress("");
        setStreetAddress("");
        setStreetAddress1("");
        setFloor("");
        setFloor1("");
        setFirstName("");
        setLastName("");
        setWalkingdistance("");
        setWalkingdistance1("");
        setMessage("");
        setSqm("");
        document.getElementById("cat").checked = false;
        document.getElementById("dog").checked = false;
        document.getElementById("other").checked = false;
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };


  useEffect(() => {
    const discountCodeValue = 221;

    const ovenCleaningCost = ovenCleaning * 563;
    const fridgeCleaningCost = fridgeCleaning * 299;
    const ovenCleaningCost1 = ovenCleaning1 * 553;
    const ovenCleaningCost2 = ovenCleaning2 * 553;
    const ovenCleaningCost3 = ovenCleaning3 * 553;
    const ovenCleaningCost4 = ovenCleaning4 * 553;
    const ovenCleaningCost5 = ovenCleaning5 * 553;
    const ovenCleaningCost6 = ovenCleaning6 * 553;
    const ovenCleaningCost7 = ovenCleaning7 * 553;
    const ovenCleaningCost8 = ovenCleaning8 * 553;

    const fridgeCleaningCost1 = fridgeCleaning1 * 329;
    const fridgeCleaningCost2 = fridgeCleaning2 * 329;
    const fridgeCleaningCost3 = fridgeCleaning3 * 329;
    const fridgeCleaningCost4 = fridgeCleaning4 * 329;
    const fridgeCleaningCost5 = fridgeCleaning5 * 329;
    const fridgeCleaningCost6 = fridgeCleaning6 * 329;
    const fridgeCleaningCost7 = fridgeCleaning7 * 329;
    const fridgeCleaningCost8 = fridgeCleaning8 * 329;

    const windowCleaningCost = windowCleaning ? 176 : 0;

    const total = storstadningPrice;


    setTotal(total);
  }, [storstadningPrice, windowCleaning]);
  const hanldesqm = (event) => {
    const newSqm = event.target.value;
    setSqm(newSqm);
  };
  useEffect(() => {
    console.log(sqm); // Log updated sqm value
    const formula = ((2000 + 100 * sqm) * 0.5);
    setTotal(formula);
    console.log(total); // Log updated total value
  }, [sqm]);



  const handlechangeaddresss = (value) => {
    setFromaddress(value);
    console.log(value);
  };

  const handlechangeaddress = (value) => {
    setToaddress(value);
    console.log(value);
  };
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  // Event handler for time input change
  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };
  const handleAddress = (value) => {
    setStreetAddress(value);
    console.log(value);
  };
  const handleAddress1 = (value) => {
    setStreetAddress1(value);
    console.log(value);
  };

  const handleFloor = (value) => {
    setFloor(value);
    console.log(value);
  }; const handleFloor1 = (value) => {
    setFloor1(value);
    console.log(value);
  };
  const handleWalkingdistance = (value) => {
    setWalkingdistance(value);
    console.log(value);
  };
  const handleWalkingdistance1 = (value) => {
    setWalkingdistance1(value);
    console.log(value);
  };
  return (
    <div
      className="container mx-auto my-auto pt-10 mb-20"
      style={{ maxWidth: "70%" }}
    >
      {loading && <Loader />}
      <div
        style={{ display: "grid", gridTemplateColumns: "70% 30%", gap: "30px" }}
      >
        <div className="text-center px-2">
          <div className="flex justify-end hover:shake">
            <CrossIcon handleClickCrossIcon={() => navigate("/MovingService")} />
          </div>
          <div
            className=" mt-2 font-normal text-5xl"
            style={{ fontFamily: "Tiempos Headline,serif" }}
          >
            {t('contactPages.movingService')}
          </div>


          <div className="w-full mt-8  flex justify-center items-center mb-5">
            <p className=" border-black border w-20 text-center hover:w-[400PX] hover:border-gray-500  transition-all duration-300 transform origin-center scale-100"></p>
          </div>

          <div className="mt-3 rounded-lg p-4">
            <label
              htmlFor="message"
              className="text-2xl font-semibold flex justify-left mb-2 font-normal ms-1"
            >
              {t('contactPages.movingFrom')}
            </label>
            <label
              htmlFor="message"
              className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
            >
              {t('contactPages.streetAddress')}
            </label>
            <div className="flex items-center mb-3">
              <input
                type="text"
                value={toaddress}
                onChange={(e) => handlechangeaddress(e.target.value)}
                id="text"
                className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
              />
            </div>

            <label
              htmlFor="message"
              className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
            >
              {t('contactPages.typeOfResidence')}
            </label>
            <div className="flex items-center mb-3">

              <select id="residenceDropdown" className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                onChange={(e) => handleAddress(e.target.value)} >
                <option value="" disabled selected hidden>Select an option</option>

                <option value="Villa/Terraced house
">Villa/Terraced house
                </option>
                <option value="Apartment">Apartment</option>
                <option value="Storage unit">Storage unit</option>
              </select>
            </div>
            <label
              htmlFor="message"
              className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
            >
              {t('contactPages.guideLineForEstimating')}

            </label>
            <div className="flex items-center mb-3">

              <select id="residenceDropdown" className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                onChange={(e) => handleAddress(e.target.value)} >
                <option value="" disabled selected hidden>Select an option</option>

                <option value="Studio Apartment or Small 1-Bedroom Apartment (up to 40 sqm)
">Studio Apartment or Small 1-Bedroom Apartment (up to 40 sqm)
                </option>
                <option value="Medium 1-Bedroom or Small 2-Bedroom Apartment (40-60 sqm)">Medium 1-Bedroom or Small 2-Bedroom Apartment (40-60 sqm)</option>
                <option value="Large 2-Bedroom or Small 3-Bedroom Apartment (60-80 sqm)">Large 2-Bedroom or Small 3-Bedroom Apartment (60-80 sqm)</option>
                <option value="Medium 3-Bedroom or Small 4-Bedroom House (80-100 sqm)">Medium 3-Bedroom or Small 4-Bedroom House (80-100 sqm)</option>

                <option value="Large 4-Bedroom or Small 5-Bedroom House (100-120 sqm)">Large 4-Bedroom or Small 5-Bedroom House (100-120 sqm)</option>
                <option value="Extra-Large 5-Bedroom or Larger House (120+ sqm">Extra-Large 5-Bedroom or Larger House (120+ sqm)</option>

              </select>
            </div>
            <label
              htmlFor="message"
              className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
            >
              {t('contactPages.floor')}
            </label>
            <div className="flex items-center mb-3">

              <select id="residenceDropdown" className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                onChange={(e) => handleFloor(e.target.value)}>
                <option value="" disabled selected hidden>Select an option</option>

                <option value="First">First</option>
                <option value="Scecond">Second</option>
                <option value="Third">Third</option>
                <option value="Fourth">Fourth</option>

                <option value="Fifth">Fifth</option>

                <option value="Sixth">Sixth</option>

                <option value="Seventh">Seventh</option>
                <option value="Higher than Seven">Higher than Seven</option>


              </select>
            </div>

            <label
              htmlFor="message"
              className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
            >
              {t('contactPages.walkingDistance')}
            </label>

            <div className="flex items-center mb-3">

              <select id="residenceDropdown" className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                onChange={(e) => handleWalkingdistance(e.target.value)} >
                <option value="" disabled selected hidden>Select an option</option>

                <option value="2-10m">2-10m</option>
                <option value="10-20m">10-20m</option>
                <option value="20-40m">20-40m</option>
                <option value="50-100m">50-100m</option>

                <option value="100-200m">100-200m</option>

                <option value="More than 200m">More than 200m</option>



              </select>
            </div>
            {/*   <label
              htmlFor="message"
              className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
            >
              Sqm For moving Service
            </label>

            <div className="flex items-center mb-3">
              <input
                type="number"
                name=""
                id="date"
                className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
              />
            </div> */}

            {/*  <label
              htmlFor="message"
              className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
            >
              Date:
            </label>
            <div className="flex items-center mb-3">
              <input
                type="date"
                name=""
                id="date"
                className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
              />
            </div> */}





            <label
              htmlFor="message"
              className="text-2xl font-semibold flex justify-left mb-2 font-normal ms-1"
            >
              {t('contactPages.movingTo')}
            </label>
            <label
              htmlFor="message"
              className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
            >
              {t('contactPages.streetAddress')}
            </label>
            <div className="flex items-center mb-3">
              <input
                type="text"
                value={fromaddress}

                onChange={(e) => handlechangeaddresss(e.target.value)}
                id="text"
                className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
              />
            </div>

            <label
              htmlFor="message"
              className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
            >
              {t('contactPages.typeOfResidence')}
            </label>
            <div className="flex items-center mb-3">

              <select id="residenceDropdown" className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                onChange={(e) => handleAddress1(e.target.value)} >
                <option value="" disabled selected hidden>Select an option</option>

                <option value="Villa/Terraced house
">Villa/Terraced house
                </option>
                <option value="Apartment">Apartment</option>
                <option value="Storage unit">Storage unit</option>
              </select>
            </div>
            <label
              htmlFor="message"
              className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
            >
              {t('contactPages.floor')}
            </label>
            <div className="flex items-center mb-3">

              <select id="residenceDropdown" className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                onChange={(e) => handleFloor1(e.target.value)} >
                <option value="" disabled selected hidden>Select an option</option>

                <option value="First">First</option>
                <option value="Scecond">Second</option>
                <option value="Third">Third</option>
                <option value="Fourth">Fourth</option>

                <option value="Fifth">Fifth</option>

                <option value="Sixth">Sixth</option>

                <option value="Seventh">Seventh</option>
                <option value="Higher than Seven">Higher than Seven</option>


              </select>
            </div>

            <label
              htmlFor="message"
              className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
            >
              {t('contactPages.walkingDistance')}
            </label>

            <div className="flex items-center mb-3">

              <select id="residenceDropdown" className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                onChange={(e) => handleWalkingdistance1(e.target.value)}>
                <option value="" disabled selected hidden>Select an option</option>

                <option value="2-10m">2-10m</option>
                <option value="10-20m">10-20m</option>
                <option value="20-40m">20-40m</option>
                <option value="50-100m">50-100m</option>

                <option value="100-200m">100-200m</option>

                <option value="More than 200m">More than 200m</option>



              </select>
            </div>
            <label
              htmlFor="message"
              className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
            >
              {t('contactPages.sqmForMovingService')}
            </label>

            <div className="flex items-center mb-3">
              <input
                type="number"
                value={sqm}
                onChange={hanldesqm}
                id="number"
                className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
              />
            </div>

            <label
              htmlFor="message"
              className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
            >
              {t('contactPages.date')}:
            </label>
            <div className="flex items-center mb-3">
              <input
                type="date"
                id="date"
                value={selectedDate}
                onChange={handleDateChange}
                className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
              />
            </div>
            <label
              htmlFor="message"
              className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
            >
              {t('contactPages.time')}
            </label>
            <div className="flex items-center mb-3">
              <input
                type="time"
                id="time"
                value={selectedTime}
                onChange={handleTimeChange}
                className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
              />
            </div>

            {/* Add First Name Input */}
            <label htmlFor="firstName" className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1">
              {t('contactPages.firstName')}:
            </label>
            <input
              type="text"
              id="firstName"
              onChange={(e) => setFirstName(e.target.value)}

              name="firstName"
              className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
              placeholder="Enter your first name"
            />

            {/* Add Last Name Input */}
            <label htmlFor="lastName" className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1">
              {t('contactPages.lastName')}:
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              onChange={(e) => setLastName(e.target.value)}

              className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
              placeholder="Enter your last name"
            />

            {/* Add Email Input */}
            <label htmlFor="email" className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1">
              {t('contactPages.email')}:
            </label>
            <input
              type="email"
              id="email"
              onChange={(e) => setEmail(e.target.value)}

              name="email"
              className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
              placeholder="Enter your email"
            />
          </div>

          {/* <div className="p-4">
            <p className="text-xl font-semibold text-left ms-1 my-4">Do you have a self-cleaning oven?*</p>
            <div className="border rounded-md mb-2 p-5 bg-white border-[#d5d2c4]">
              <div className="flex items-center justify-between mb-2">
                <label
                  htmlFor="yes"
                  className="cursor-pointer flex items-center justify-between w-full"
                >
                  <p className="text-xl font-normal">Yes</p>
                  <div
                    className={`w-7 h-7 rounded-full border border-gray-300 flex items-center justify-center ${wantCleaning === "yes" ? "bg-black" : "bg-white"
                      }`}
                  >
                    {wantCleaning === "yes" && (
                      <svg
                        className="w-4 h-4 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        ></path>
                      </svg>
                    )}
                  </div>
                </label>
                <input
                  type="radio"
                  id="yes"
                  name="cleaning"
                  checked={wantCleaning === "yes"}
                  onChange={() => handleCleaningChange("yes")}
                  className="hidden"
                />
              </div>

              <p className="mb-2 border-[#d6d2c4] border"></p>

              <div className="flex">
                <label
                  className="text-xl cursor-pointer flex justify-between items-center mt-1 w-full my-auto font-normal"
                  htmlFor="no"
                >
                  <p className="text-left">No</p>
                  <div
                    className={`w-7 h-7 rounded-full border border-gray-300 flex items-center justify-center ${wantCleaning === "no" ? "bg-black" : "bg-white"
                      }`}
                  >
                    {wantCleaning === "no" && (
                      <svg
                        className="w-4 h-4 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        ></path>
                      </svg>
                    )}
                  </div>
                </label>
                <input
                  type="radio"
                  id="no"
                  name="cleaning"
                  value={"no"}
                  checked={wantCleaning === "no"}
                  onChange={() => handleCleaningChange("no")}
                  className="hidden"
                />
              </div>
            </div>
          </div> */}
          {/* <div className=" mb-20 w-full  "
            style={{
              backgroundColor: " "
            }}>

            <Accordion className="ms-[20px] me-[13px]" style={{ maxHeight: '800px', overflowY: 'auto' }}
              allowToggle>
              <AccordionItem>
                <h2>
                  <AccordionButton className="mb-2 border-2 border-gray-400 rounded-md p-2">
                    <Box as="span" flex='1' textAlign='left' fontSize="25px">
                      <div className='flex '>
                        <img src='img/hgenral.svg' alt='Icon' className='w-10 font-semibold pr-2 hover:scale-95 transition duration-300 ease-in-out' />
                        <h4 className='heading mt-2 mb-2'>
                          Living rooms ({totalItem})
                        </h4>
                      </div>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <div className="flex p-4">
                    <div className='flex'>
                      <img src='img/hgenral.svg' alt='Icon' className='w-10 font-semibold pr-2 hover:scale-95 transition duration-300 ease-in-out' />
                      <h4 className='heading'>
                        Sofa (4 seater)
                      </h4>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button className="negative-icon opacity-40 " onClick={() => setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)}>
                        -
                      </button>
                      <p className="font-semibold mx-4"> {ovenCleaning} </p>
                      <button className="plus-icon bg-black" onClick={() => setOvenCleaning(ovenCleaning + 1)}>
                        +
                      </button>
                    </div>
                  </div>

                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className='flex'>

                      <img src='img/hgenral.svg' alt='Icon' className='w-10 font-semibold pr-2 hover:scale-95 transition duration-300 ease-in-out' />

                      <h4 className='heading'>
                        Sofa (2 seater)
                      </h4>
                    </div>





                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning(fridgeCleaning > 0 ? fridgeCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4">{fridgeCleaning}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning(fridgeCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex p-4">
                    <div className='flex'>

                      <img src='img/hgenral.svg' alt='Icon' className='w-10 font-semibold pr-2 hover:scale-95 transition duration-300 ease-in-out' />

                      <h4 className='heading'>
                        Sofa (3 seater)
                      </h4>
                    </div>

                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning1(ovenCleaning1 > 0 ? ovenCleaning1 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning1} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning1(ovenCleaning1 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className='flex'>

                      <img src='img/hgenral.svg' alt='Icon' className='w-10 font-semibold pr-2 hover:scale-95 transition duration-300 ease-in-out' />

                      <h4 className='heading'>
                        Sofa (5 seater)
                      </h4>
                    </div>


                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning1(fridgeCleaning1 > 0 ? fridgeCleaning1 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4">{fridgeCleaning1}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning1(fridgeCleaning1 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex p-4">
                    <div className='flex'>

                      <img src='img/hgenral.svg' alt='Icon' className='w-10 font-semibold pr-2 hover:scale-95 transition duration-300 ease-in-out' />

                      <h4 className='heading'>
                        Coffee Table (Medium)
                      </h4>
                    </div>

                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning2(ovenCleaning2 > 0 ? ovenCleaning2 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning2} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning2(ovenCleaning2 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className='flex'>

                      <img src='img/hgenral.svg' alt='Icon' className='w-10 font-semibold pr-2 hover:scale-95 transition duration-300 ease-in-out' />

                      <h4 className='heading'>
                        Flat Screen TV (Medium)
                      </h4>
                    </div>

                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning2(fridgeCleaning2 > 0 ? fridgeCleaning2 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4">{fridgeCleaning2}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning2(fridgeCleaning2 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex p-4">
                    <div className='flex'>

                      <img src='img/hgenral.svg' alt='Icon' className='w-10 font-semibold pr-2 hover:scale-95 transition duration-300 ease-in-out' />

                      <h4 className='heading'>
                        BookShell/Bookcase (Large)
                      </h4>
                    </div>

                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning3(ovenCleaning3 > 0 ? ovenCleaning3 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning3} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning3(ovenCleaning3 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className='flex'>

                      <img src='img/hgenral.svg' alt='Icon' className='w-10 font-semibold pr-2 hover:scale-95 transition duration-300 ease-in-out' />

                      <h4 className='heading'>
                        TV (Board)
                      </h4>
                    </div>

                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning3(fridgeCleaning3 > 0 ? fridgeCleaning3 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4">{fridgeCleaning3}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning3(fridgeCleaning3 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Carpet (Large)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning4(ovenCleaning4 > 0 ? ovenCleaning4 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning4} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning4(ovenCleaning4 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className='flex'>

                      <img src='img/hgenral.svg' alt='Icon' className='w-10 font-semibold pr-2 hover:scale-95 transition duration-300 ease-in-out' />

                      <h4 className='heading'>
                        Desk (Standard)
                      </h4>
                    </div>

                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning4(fridgeCleaning4 > 0 ? fridgeCleaning4 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4">{fridgeCleaning4}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning4(fridgeCleaning4 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </AccordionPanel>
              </AccordionItem>



              <AccordionItem>
                <h2>
                  <AccordionButton className="mb-2 border-2 border-gray-400 rounded-md p-2">
                    {totalbed > 0 ? (
                      <Box as="span" flex='1' textAlign='left' fontSize="25px">
                        <div className='flex'>

                          <img src='img/hgenral.svg' alt='Icon' className='w-10 font-semibold pr-2 hover:scale-95 transition duration-300 ease-in-out' />

                          <h4 className='heading mt-2 mb-2'>
                            Bedroom ({totalbed})
                          </h4>
                        </div>

                      </Box>
                    ) : (
                      <Box as="span" flex='1' textAlign='left' fontSize="25px">
                        <div className='flex'>

                          <img src='img/hgenral.svg' alt='Icon' className='w-10 font-semibold pr-2 hover:scale-95 transition duration-300 ease-in-out' />

                          <h4 className='heading mt-2 mb-2'>                        Bedroom
                          </h4>
                        </div>
                      </Box>
                    )}
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <div className="flex p-4">
                    <div className='flex'>

                      <img src='img/hgenral.svg' alt='Icon' className='w-10 font-semibold pr-2 hover:scale-95 transition duration-300 ease-in-out' />

                      <h4 className='heading'>
                        Bedside Table
                      </h4>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning5(ovenCleaning5 > 0 ? ovenCleaning5 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning5} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning5(ovenCleaning5 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Single Bed</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning5(fridgeCleaning5 > 0 ? fridgeCleaning5 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4">{fridgeCleaning5}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning5(fridgeCleaning5 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Double Bed</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning6(ovenCleaning6 > 0 ? ovenCleaning6 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning6} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning6(ovenCleaning6 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">King Size Bed</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning6(fridgeCleaning6 > 0 ? fridgeCleaning6 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className="font-semibold mx-4">{fridgeCleaning6}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning6(fridgeCleaning6 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Wardrobe simple</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning7(ovenCleaning7 > 0 ? ovenCleaning7 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning7} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning7(ovenCleaning7 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Wardrobe 2 doors</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning7(fridgeCleaning7 > 0 ? fridgeCleaning7 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4">{fridgeCleaning}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning7(fridgeCleaning7 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Wardrobe 4 doors</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning8(ovenCleaning8 > 0 ? ovenCleaning8 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning8} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning8(ovenCleaning8 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Chest of Drawers(Medium)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning8(fridgeCleaning8 > 0 ? fridgeCleaning8 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4">{fridgeCleaning8}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning8(fridgeCleaning8 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>

                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton className="mb-2 border-2 border-gray-400 rounded-md p-2">
                    {totalItem1 > 0 ? (
                      <Box as="span" flex='1' textAlign='left' fontSize="25px">
                        <div className='flex'>

                          <img src='img/hgenral.svg' alt='Icon' className='w-10 font-semibold pr-2 hover:scale-95 transition duration-300 ease-in-out' />

                          <h4 className='heading mt-2 mb-2'>
                            Dinning room ({totalItem1})
                          </h4>
                        </div>

                      </Box>
                    ) : (
                      <Box as="span" flex='1' textAlign='left' fontSize="25px">
                        <div className='flex'>

                          <img src='img/hgenral.svg' alt='Icon' className='w-10 font-semibold pr-2 hover:scale-95 transition duration-300 ease-in-out' />

                          <h4 className='heading mt-2 mb-2'>Dinning room
                          </h4>
                        </div>
                      </Box>
                    )}
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className="font-normal">Mirror (Large)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning11(ovenCleaning11 > 0 ? ovenCleaning11 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning11} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning11(ovenCleaning11 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Cabinat (Medium)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning11(fridgeCleaning11 > 0 ? fridgeCleaning11 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4">{fridgeCleaning11}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning11(fridgeCleaning11 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Cabinet (Small)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning12(ovenCleaning12 > 0 ? ovenCleaning12 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning12} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning12(ovenCleaning12 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Chair</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning12(fridgeCleaning12 > 0 ? fridgeCleaning12 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4">{fridgeCleaning12}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning12(fridgeCleaning12 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Carpet (Large)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning13(ovenCleaning13 > 0 ? ovenCleaning13 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning13} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning13(ovenCleaning13 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Flat Screen TV (Medium)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning13(fridgeCleaning13 > 0 ? fridgeCleaning13 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4">{fridgeCleaning13}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning13(fridgeCleaning13 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className="font-normal">Painting/Picture (Large)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning14(ovenCleaning14 > 0 ? ovenCleaning14 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning14} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning14(ovenCleaning14 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">(Dining Table)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning14(fridgeCleaning14 > 0 ? fridgeCleaning14 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4">{fridgeCleaning14}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning14(fridgeCleaning14 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>

                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton className="mb-2 border-2 border-gray-400 rounded-md p-2">
                    {totalbed1 > 0 ? (
                      <Box as="span" flex='1' textAlign='left' fontSize="25px">
                        <div className='flex'>

                          <img src='img/hgenral.svg' alt='Icon' className='w-10 font-semibold pr-2 hover:scale-95 transition duration-300 ease-in-out' />

                          <h4 className='heading mt-2 mb-2'>
                            Kitchen ({totalbed1})
                          </h4>
                        </div>

                      </Box>
                    ) : (
                      <Box as="span" flex='1' textAlign='left' fontSize="25px">
                        <div className='flex'>

                          <img src='img/hgenral.svg' alt='Icon' className='w-10 font-semibold pr-2 hover:scale-95 transition duration-300 ease-in-out' />

                          <h4 className='heading mt-2 mb-2'>                        Kitchen
                          </h4>
                        </div>
                      </Box>
                    )}
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Upright Fridge/Freezer(Standard)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning15(ovenCleaning15 > 0 ? ovenCleaning15 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning15} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning15(ovenCleaning15 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className="t font-normal">Upright/Freezer(Large)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning15(fridgeCleaning15 > 0 ? fridgeCleaning15 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className="font-semibold mx-4">{fridgeCleaning15}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning15(fridgeCleaning15 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Washing Machine</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning16(ovenCleaning16 > 0 ? ovenCleaning16 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning16} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning16(ovenCleaning16 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Microwave</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning16(fridgeCleaning16 > 0 ? fridgeCleaning16 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4">{fridgeCleaning16}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning16(fridgeCleaning16 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Stove/Oven/Cooker</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning17(ovenCleaning17 > 0 ? ovenCleaning17 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning17} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning17(ovenCleaning17 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Dishwasher</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning17(fridgeCleaning17 > 0 ? fridgeCleaning17 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4">{fridgeCleaning17}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning17(fridgeCleaning17 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>

                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton className="mb-2 border-2 border-gray-400 rounded-md p-2">
                    {totalItem > 0 ? (
                      <Box as="span" flex='1' textAlign='left' fontSize="25px">
                        <div className='flex'>

                          <img src='img/hgenral.svg' alt='Icon' className='w-10 font-semibold pr-2 hover:scale-95 transition duration-300 ease-in-out' />

                          <h4 className='heading mt-2 mb-2'>
                            Other ({totalItem})
                          </h4>
                        </div>

                      </Box>
                    ) : (
                      <Box as="span" flex='1' textAlign='left' fontSize="25px">
                        <div className='flex'>

                          <img src='img/hgenral.svg' alt='Icon' className='w-10 font-semibold pr-2 hover:scale-95 transition duration-300 ease-in-out' />

                          <h4 className='heading mt-2 mb-2'>                        Other
                          </h4>
                        </div>
                      </Box>
                    )}
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Bike/Bicycle (Adult)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning(ovenCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className="t font-normal">Bike/Bicycle (Child)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning(fridgeCleaning > 0 ? fridgeCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className="font-semibold mx-4">{fridgeCleaning}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning(fridgeCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Motorbike</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning(ovenCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Table football</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning(fridgeCleaning > 0 ? fridgeCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4">{fridgeCleaning}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning(fridgeCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Garden/Patio Table</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning(ovenCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>


                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton className="mb-2 border-2 border-gray-400 rounded-md p-2">
                    {totalItem > 0 ? (
                      <Box as="span" flex='1' textAlign='left' fontSize="25px">
                        <div className='flex'>

                          <img src='img/hgenral.svg' alt='Icon' className='w-10 font-semibold pr-2 hover:scale-95 transition duration-300 ease-in-out' />

                          <h4 className='heading mt-2 mb-2'>
                            Boxes ({totalItem})
                          </h4>
                        </div>

                      </Box>
                    ) : (
                      <Box as="span" flex='1' textAlign='left' fontSize="25px">
                        <div className='flex'>

                          <img src='img/hgenral.svg' alt='Icon' className='w-10 font-semibold pr-2 hover:scale-95 transition duration-300 ease-in-out' />

                          <h4 className='heading mt-2 mb-2'> Boxes
                          </h4>
                        </div>
                      </Box>
                    )}
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Carton(S)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning(ovenCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className="t font-normal">Carton(M)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning(fridgeCleaning > 0 ? fridgeCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className="font-semibold mx-4">{fridgeCleaning}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning(fridgeCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Carton(L)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning(ovenCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Carton(XL)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning(fridgeCleaning > 0 ? fridgeCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4">{fridgeCleaning}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning(fridgeCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Suitcase</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning(ovenCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  

                </AccordionPanel>
              </AccordionItem>

            </Accordion>

          </div> */}

          <div className="mt-[-2px] rounded-lg p-2">
            <label
              htmlFor="message"
              className="text-xl font-semibold flex justify-left mb-1 font-normal ms-1"
            >
              {t('contactPages.specificMessage')}
            </label>
            <div className="flex items-center mb-3">
              <input
                type="text"
                name="message"
                onChange={(e) => setMessage(e.target.value)}
                id="message"

                className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
              />
            </div>
          </div>


          <div className="p-2">
            {/*    <h4 className="font-semibold text-left mb-3 text-xl">Access*</h4> */}

            {/*  <div className="border rounded-md mb-2 p-5 bg-white border-[#d5d2c4]">
              <div className="flex items-center justify-between mb-2">
                <label
                  htmlFor="open"
                  className="cursor-pointer flex items-center justify-between w-full"
                >
                  <p className="text-xl font-normal">
                    I will open the door for Hemfrid
                  </p>
                  <div
                    className={`w-7 h-7 rounded-full border border-gray-300 flex items-center justify-center ${handover === "open" ? "bg-black" : "bg-white"
                      }`}
                  >
                    {handover === "open" && (
                      <svg
                        className="w-4 h-4 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        ></path>
                      </svg>
                    )}
                  </div>
                </label>
                <input
                  type="radio"
                  id="open"
                  name="handover"
                  checked={handover === "open"}
                  onChange={() => handleHandoverChange("open")}
                  className="hidden"
                />
              </div>

              <p className="mb-2 border-[#d6d2c4] border"></p>

              <div className="flex my-auro">
                <label
                  className="text-xl cursor-pointer flex items-center jutify-between w-full font-normal"
                  htmlFor="leave"
                >
                  <p className="text-left">
                    I will leave the keys at the Hemfrid office 12 pm the work
                    day before, at the latest
                  </p>
                  <div
                    className={`w-8 h-7 rounded-full border border-gray-300 flex items-center justify-center ${handover === "leave" ? "bg-black" : "bg-white"
                      }`}
                  >
                    {handover === "leave" && (
                      <svg
                        className="w-4 h-4 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        ></path>
                      </svg>
                    )}
                  </div>
                </label>
                <input
                  type="radio"
                  name="handover"
                  value={"leave"}
                  checked={handover === "leave"}
                  onChange={() => handleHandoverChange("leave")}
                  className="hidden"
                  id="leave"
                />
              </div>

              <p className="mb-2 border-[#d6d2c4] border"></p>

              <div className="flex my-auto">
                <label
                  className="text-xl cursor-pointer flex items-center justify-between w-full font-normal"
                  htmlFor="customer"
                >
                  <p className="text-left">
                    I'm a customer and have already left my keys
                  </p>
                  <div
                    className={`w-7 h-7 rounded-full border border-gray-300 flex items-center justify-center ${handover === "customer" ? "bg-black" : "bg-white"
                      }`}
                  >
                    {handover === "customer" && (
                      <svg
                        className="w-4 h-4 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        ></path>
                      </svg>
                    )}
                  </div>
                </label>
                <input
                  type="radio"
                  name="handover"
                  value={"customer"}
                  checked={handover === "customer"}
                  onChange={() => handleHandoverChange("customer")}
                  className="hidden"
                  id="customer"
                />
              </div>
            </div>

            <p className="text-4 text-left italic my-2">
              <a href="#" className="font-semibold underline">
                Here
              </a>{" "}
              you will find the address and opening hours of your nearest
              Hemfrid office where you can hand over the key.
            </p> */}
            <div
              className="mt-10"
              style={{ display: "flex", justifyContent: "space-evenly" }}
            >
              <button
                style={{ padding: "15px 50px 15px 50px" }}
                className="bg-[#000] font-bold uppercase text-white p-5 rounded text-bold"
                onClick={handleSubmit}

              >
                {t('contactPages.next')}
              </button>
            </div>
          </div>
        </div>
        <div
          className="mt-20 pb-5"
          style={{
            backgroundColor: "#d1dce7",
            width: "300px",
            height: "490px",
          }}
        >
          <div className="flex-col mt-5 px-5">
            <p
              className="text-2xl text-normal text-[#003b5c] text-left"
              style={{ fontFamily: "Tiempos Headline,serif" }}
            >
              {t('contactPages.yourBooking')}
            </p>
          </div>
          <div className="flex mt-5 px-5">
            <p className="text-lg text-normal text-[#003b5c] text-left flex-1">
            {t('contactPages.movingFrom')}
            </p>
            <p className="text-gl font-bold text-[#003b5c]">{toaddress}</p>
          </div>
          <div className="w-full mt-3 flex justify-center px-5 items-center mb-5">
            <p className=" border-[#003b5c] border opacity-10 w-full text-center "></p>
          </div>
          <div className="flex  px-5">
            <p className="text-lg text-normal text-[#003b5c] text-left flex-1">
            {t('contactPages.date')}
            </p>
            <p className="text-gl font-bold text-[#003b5c]">{selectedDate}</p>
          </div>
          <div className="flex  px-5">
            <p className="text-lg text-normal text-[#003b5c] text-left flex-1">
            {t('contactPages.time')}
            </p>
            <p className="text-gl font-bold text-[#003b5c]">{selectedTime}</p>
          </div>

          <div className="flex mt-5 px-5">
            <p className="text-lg text-normal text-[#003b5c] text-left flex-1">
            {t('contactPages.movingTo')}
            </p>
            <p className="text-gl font-bold text-[#003b5c]">{fromaddress}</p>
          </div>
          <div className="w-full mt-3 flex justify-center px-5 items-center mb-5">
            <p className=" border-[#003b5c] border opacity-10 w-full text-center "></p>
          </div>
          <div className="flex  px-5">
            <p className="text-lg text-normal text-[#003b5c] text-left flex-1">
            {t('contactPages.date')}
            </p>
            <p className="text-gl font-bold text-[#003b5c]">{selectedDate}</p>
          </div>
          <div className="flex  px-5">
            <p className="text-lg text-normal text-[#003b5c] text-left flex-1">
            {t('contactPages.time')}
            </p>
            <p className="text-gl font-bold text-[#003b5c]">{selectedTime}</p>
          </div>

          {/* <div className="mt-5 px-5">
            <p className="text-xl font-semibold text-[#003b5c] text-left">
              Date and time
            </p>
            <p className="text-xl text-left font-semibold">-</p>
          </div> */}
          <div className="w-full mt-3 flex justify-center px-5 items-center mb-5">
            <p className=" border-[#003b5c] border opacity-10 w-full text-center "></p>
          </div>
          <div className="flex mt-5 px-5">
            <div className="flex-1">
              <p className="text-lg text-[#003b5c] text-left flex-1">Total</p>
              <p className="text-sm text-[#003b5c] text-left flex-1 ms-1">
              {t('contactPages.withRUTReduction')}
              </p>
            </div>
            <p className="text-xl font-semibold text-[#003b5c] my-auto">
              {total} SEK
            </p>
          </div>
          <div className="w-full mt-3 flex justify-center px-5 items-center mb-5">
            <p className=" border-[#003b5c] border opacity-10 w-full text-center "></p>
          </div>
          <div className="flex mt-5 mb-3 px-5">
            <p
              className="text-normal text-normal underline text-[#003b5c] text-left"
              style={{ display: "inline-block" }}
            >
              {t('contactPages.addDiscountCode')}
            </p>

            {/*             <div 
            className="w-full   font-normal text-start md:text-center lg:text-left">
 */}






          </div>
          {/* <div
            className="mt-20 pb-5"
            style={{
              width: "450px",
              height: "690px",
            }}>
            {Note.map(({ title, question, answer }, i) => (
              <div
                className="bg-white rounded-lg  w-full shadow-lg hover:shadow-2xl group gap-8"
                onMouseLeave={() => setActiveIndex()}
                onMouseEnter={() => handleSetIndex(i)}
              >
                <div className="flex justify-between items-center my-2  px-4 sm:px-8 h-14 cursor-pointer">
                  <h3 className={`lg:text-xl md:text-xl sm:text-xl text-xs pb-2 font-semibold mr-2 `}>{question}</h3>
                  <div className="text-3xl group-hover:rotate-180 transition duration-1500 ml-auto">

                    <BiSolidChevronDown className="w-8 h-8  border-2 rounded-full text-green-500 border-green-500  group-hover:border-yellow-400 group-hover:text-yellow-400 " />
                  </div>
                </div>
                <div
                  x-ref="tab"
                  className={`border-l-2 rounded-bl-md border-yellow-500 dark:border-none overflow-hidden duration-[1500ms] transition-all ${activeIndex === i ? "max-h-[700px]" : "max-h-0 "
                    }`}
                >
                  <p className="text-start px-4 sm:px-8 py-2  p-3 text-xl font-light text-gray-700">
                    {answer}
                  </p>
                </div>
              </div>

            ))}
          </div> */}

          <Faq1 />
          {/* <div className=" mb-20"
            style={{
              backgroundColor: "",
              width: "400px",
              height: "400px",
            }}>

            <Accordion style={{ maxHeight: '800px', overflowY: 'auto' }} allowToggle>

              <AccordionItem  >
                <h2>
                  <AccordionButton>
                    {totalItem > 0 ? (
                      <Box as="span" flex='1' textAlign='left' fontSize="25px">
                        Living rooms ({totalItem})
                      </Box>
                    ) : (
                      <Box as="span" flex='1' textAlign='left' fontSize="25px">
                        Living rooms
                      </Box>
                    )}
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Sofa (4 seater)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning(ovenCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Sofa (2 seater)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning(fridgeCleaning > 0 ? fridgeCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4">{fridgeCleaning}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning(fridgeCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Sofa (3 seater)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning1(ovenCleaning1 > 0 ? ovenCleaning1 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning1} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning1(ovenCleaning1 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Sofa (5 seater)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning1(fridgeCleaning1 > 0 ? fridgeCleaning1 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4">{fridgeCleaning1}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning1(fridgeCleaning1 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Coffee Table (Medium)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning2(ovenCleaning2 > 0 ? ovenCleaning2 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning2} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning2(ovenCleaning2 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Flat Screen TV (Medium)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning2(fridgeCleaning2 > 0 ? fridgeCleaning2 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4">{fridgeCleaning2}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning2(fridgeCleaning2 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">BookShell/Bookcase (Large)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning3(ovenCleaning3 > 0 ? ovenCleaning3 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning3} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning3(ovenCleaning3 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">TV (Board)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning3(fridgeCleaning3 > 0 ? fridgeCleaning3 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4">{fridgeCleaning3}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning3(fridgeCleaning3 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Carpet (Large)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning4(ovenCleaning4 > 0 ? ovenCleaning4 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning4} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning4(ovenCleaning4 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Desk (Standard)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning4(fridgeCleaning4 > 0 ? fridgeCleaning4 - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4">{fridgeCleaning4}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning4(fridgeCleaning4 + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </AccordionPanel>
              </AccordionItem>



              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex='1' textAlign='left' fontSize="25px">
                      Bedroom
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Bedside Table</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning(ovenCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Single Bed</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning(fridgeCleaning > 0 ? fridgeCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4">{fridgeCleaning}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning(fridgeCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Double Bed</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning(ovenCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">King Size Bed</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning(fridgeCleaning > 0 ? fridgeCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className="font-semibold mx-4">{fridgeCleaning}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning(fridgeCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Wardrobe simple</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning(ovenCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Wardrobe 2 doors</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning(fridgeCleaning > 0 ? fridgeCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4">{fridgeCleaning}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning(fridgeCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Wardrobe 4 doors</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning(ovenCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Chest of Drawers(Medium)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning(fridgeCleaning > 0 ? fridgeCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4">{fridgeCleaning}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning(fridgeCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>

                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex='1' textAlign='left' fontSize="25px">
                      Dinning room
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className="font-normal">Mirror (Large)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning(ovenCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Cabinat (Medium)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning(fridgeCleaning > 0 ? fridgeCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4">{fridgeCleaning}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning(fridgeCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Cabinet (Small)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning(ovenCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Chair</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning(fridgeCleaning > 0 ? fridgeCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4">{fridgeCleaning}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning(fridgeCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Carpet (Large)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning(ovenCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Flat Screen TV (Medium)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning(fridgeCleaning > 0 ? fridgeCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4">{fridgeCleaning}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning(fridgeCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className="font-normal">Painting/Picture (Large)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning(ovenCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">TV (Board)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning(fridgeCleaning > 0 ? fridgeCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4">{fridgeCleaning}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning(fridgeCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>

                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex='1' textAlign='left' fontSize="25px">
                      Kitchen
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Upright Fridge/Freezer(Standard)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning(ovenCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className="t font-normal">Upright/Freezer(Large)</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning(fridgeCleaning > 0 ? fridgeCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className="font-semibold mx-4">{fridgeCleaning}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning(fridgeCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Washing Machine</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning(ovenCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Microwave</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning(fridgeCleaning > 0 ? fridgeCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4">{fridgeCleaning}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning(fridgeCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Stove/Oven/Cooker</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4"> {ovenCleaning} </p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setOvenCleaning(ovenCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                  <div className="flex p-4">
                    <div className="flex">
                      <p className=" font-normal">Dishwasher</p>
                    </div>
                    <div className="flex items-center" style={{ marginLeft: "auto" }}>
                      <button
                        className="negative-icon opacity-40 "
                        onClick={() =>
                          setFridgeCleaning(fridgeCleaning > 0 ? fridgeCleaning - 1 : 0)
                        }
                      >
                        -
                      </button>
                      <p className=" font-semibold mx-4">{fridgeCleaning}</p>
                      <button
                        className="plus-icon bg-black"
                        onClick={() => setFridgeCleaning(fridgeCleaning + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>

                </AccordionPanel>
              </AccordionItem>

            </Accordion>

          </div> */}

        </div>



        {/*  <div className="mt-20"
          style={{
            backgroundColor: "#d1dce7",
            width: "600px",
            height: "fit-content",
          }}>

          <Accordion defaultIndex={[0]} allowMultiple>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  {totalItem > 0 ? (
                    <Box as="span" flex='1' textAlign='left' fontSize="25px">
                      Living rooms ({totalItem})
                    </Box>
                  ) : (
                    <Box as="span" flex='1' textAlign='left' fontSize="25px">
                      Living rooms
                    </Box>
                  )}
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">Sofa (4 seater)</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4"> {ovenCleaning} </p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setOvenCleaning(ovenCleaning + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">Sofa (2 seater)</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setFridgeCleaning(fridgeCleaning > 0 ? fridgeCleaning - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4">{fridgeCleaning}</p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setFridgeCleaning(fridgeCleaning + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">Sofa (3 seater)</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setOvenCleaning1(ovenCleaning1 > 0 ? ovenCleaning1 - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4"> {ovenCleaning1} </p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setOvenCleaning1(ovenCleaning1 + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">Sofa (5 seater)</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setFridgeCleaning1(fridgeCleaning1 > 0 ? fridgeCleaning1 - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4">{fridgeCleaning1}</p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setFridgeCleaning1(fridgeCleaning1 + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">Coffee Table (Medium)</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setOvenCleaning2(ovenCleaning2 > 0 ? ovenCleaning2 - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4"> {ovenCleaning2} </p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setOvenCleaning2(ovenCleaning2 + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">Flat Screen TV (Medium)</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setFridgeCleaning2(fridgeCleaning2 > 0 ? fridgeCleaning2 - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4">{fridgeCleaning2}</p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setFridgeCleaning2(fridgeCleaning2 + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">BookShell/Bookcase (Large)</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setOvenCleaning3(ovenCleaning3 > 0 ? ovenCleaning3 - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4"> {ovenCleaning3} </p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setOvenCleaning3(ovenCleaning3 + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">TV (Board)</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setFridgeCleaning3(fridgeCleaning3 > 0 ? fridgeCleaning3 - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4">{fridgeCleaning3}</p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setFridgeCleaning3(fridgeCleaning3 + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">Carpet (Large)</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setOvenCleaning4(ovenCleaning4 > 0 ? ovenCleaning4 - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4"> {ovenCleaning4} </p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setOvenCleaning4(ovenCleaning4 + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">Desk (Standard)</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setFridgeCleaning4(fridgeCleaning4 > 0 ? fridgeCleaning4 - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4">{fridgeCleaning4}</p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setFridgeCleaning4(fridgeCleaning4 + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
              </AccordionPanel>
            </AccordionItem>


          
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as="span" flex='1' textAlign='left' fontSize="25px">
                    Bedroom
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">Bedside Table</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4"> {ovenCleaning} </p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setOvenCleaning(ovenCleaning + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">Single Bed</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setFridgeCleaning(fridgeCleaning > 0 ? fridgeCleaning - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4">{fridgeCleaning}</p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setFridgeCleaning(fridgeCleaning + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">Double Bed</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4"> {ovenCleaning} </p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setOvenCleaning(ovenCleaning + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">King Size Bed</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setFridgeCleaning(fridgeCleaning > 0 ? fridgeCleaning - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4">{fridgeCleaning}</p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setFridgeCleaning(fridgeCleaning + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">Wardrobe simple</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4"> {ovenCleaning} </p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setOvenCleaning(ovenCleaning + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">Wardrobe 2 doors</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setFridgeCleaning(fridgeCleaning > 0 ? fridgeCleaning - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4">{fridgeCleaning}</p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setFridgeCleaning(fridgeCleaning + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">Wardrobe 4 doors</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4"> {ovenCleaning} </p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setOvenCleaning(ovenCleaning + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">Chest of Drawers(Medium)</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setFridgeCleaning(fridgeCleaning > 0 ? fridgeCleaning - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4">{fridgeCleaning}</p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setFridgeCleaning(fridgeCleaning + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>

              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as="span" flex='1' textAlign='left' fontSize="25px">
                    Dinning room
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">Mirror (Large)</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4"> {ovenCleaning} </p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setOvenCleaning(ovenCleaning + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">Cabinat (Medium)</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setFridgeCleaning(fridgeCleaning > 0 ? fridgeCleaning - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4">{fridgeCleaning}</p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setFridgeCleaning(fridgeCleaning + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">Cabinet (Small)</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4"> {ovenCleaning} </p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setOvenCleaning(ovenCleaning + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">Chair</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setFridgeCleaning(fridgeCleaning > 0 ? fridgeCleaning - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4">{fridgeCleaning}</p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setFridgeCleaning(fridgeCleaning + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">Carpet (Large)</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4"> {ovenCleaning} </p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setOvenCleaning(ovenCleaning + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">Flat Screen TV (Medium)</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setFridgeCleaning(fridgeCleaning > 0 ? fridgeCleaning - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4">{fridgeCleaning}</p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setFridgeCleaning(fridgeCleaning + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">Painting/Picture (Large)</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4"> {ovenCleaning} </p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setOvenCleaning(ovenCleaning + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">TV (Board)</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setFridgeCleaning(fridgeCleaning > 0 ? fridgeCleaning - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4">{fridgeCleaning}</p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setFridgeCleaning(fridgeCleaning + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>

              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as="span" flex='1' textAlign='left' fontSize="25px">
                    Kitchen
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">Upright Fridge/Freezer(Standard)</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4"> {ovenCleaning} </p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setOvenCleaning(ovenCleaning + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">Upright/Freezer(Large)</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setFridgeCleaning(fridgeCleaning > 0 ? fridgeCleaning - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4">{fridgeCleaning}</p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setFridgeCleaning(fridgeCleaning + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">Washing Machine</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4"> {ovenCleaning} </p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setOvenCleaning(ovenCleaning + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">Microwave</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setFridgeCleaning(fridgeCleaning > 0 ? fridgeCleaning - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4">{fridgeCleaning}</p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setFridgeCleaning(fridgeCleaning + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">Stove/Oven/Cooker</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4"> {ovenCleaning} </p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setOvenCleaning(ovenCleaning + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
                <div className="flex p-4">
                  <div className="flex">
                    <p className="text-2xl font-normal">Dishwasher</p>
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <button
                      className="negative-icon opacity-40 "
                      onClick={() =>
                        setFridgeCleaning(fridgeCleaning > 0 ? fridgeCleaning - 1 : 0)
                      }
                    >
                      -
                    </button>
                    <p className="text-2xl font-semibold mx-4">{fridgeCleaning}</p>
                    <button
                      className="plus-icon bg-black"
                      onClick={() => setFridgeCleaning(fridgeCleaning + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>

              </AccordionPanel>
            </AccordionItem>

          </Accordion>

        </div> */}

      </div>
    </div >
  );
};

export default MovingOutService;










