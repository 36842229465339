import { useRef, useState } from "react";
import { ZIPCODES, ZIPCODES1 } from "../../../Constants/Constant";
import { useNavigate, useLocation } from "react-router";
import { Button, Select } from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";
import SelectArrow from "../../../Gernal_Components/SelectArrow";
import MovingHome from "../../../../Assets/images/New Images/26.png";

import { useTranslation } from 'react-i18next';

const MHero = () => {

  const { t } = useTranslation();

  const [text, setText] = useState("");
  const navigate = useNavigate();
  const [selectedValue, setSelectValue] = useState("1");
  const [sqmValue, setSqmValue] = useState(0);
  const [postCodevalue, setPostCodeValue] = useState("");

  const [city, setCity] = useState("");
  const [code, setCode] = useState("");
  const [show, setShow] = useState(false);

  const [city1, setCity1] = useState("");
  const [code1, setCode1] = useState("");
  const [show1, setShow1] = useState(false);
  const [moreOptions, setMoreOptions] = useState(false);
  const zipcodeRef = useRef();
  const zipcodeRef1 = useRef();

  const handleRef = () => {
    zipcodeRef.current.focus();
  };
  const handleRef1 = () => {
    zipcodeRef1.current.focus();
  };
  const handleInputChange = (e) => {
    const filteredCity = ZIPCODES.filter(
      (item) => item?.zipcode == e.target.value
    );
    if (filteredCity.length > 0) {
      setCode(e.target.value);
      setCity(filteredCity[0]?.city);
      console.log(filteredCity)
      setShow(true);
    } else {
      setCode(e.target.value);
      setShow(false);
    }
  };
  const location = useLocation();
  const handleInputChange1 = (e) => {
    const filteredCity1 = ZIPCODES1.filter(
      (item) => item?.zipcode == e.target.value
    );
    if (filteredCity1.length > 0) {
      setCode1(e.target.value);
      setCity1(filteredCity1[0]?.city);
      setShow1(true);
      console.log(filteredCity1)
    } else {
      setCode1(e.target.value);
      setShow1(false);
    }
  };

  /*   const isButtonDisabled = !city || !code1 || !code || (code1 && code && code1 === code);*/
  /*   const isButtonDisabled = !(city && code && code1);
   */
  /*  const isButtonDisabled = (!city || !city1) || (!code || !code1);
   */
  const isButtonDisabled = (!city || !city1) || (!code || !code1) || code.length > 5 || code.length <= 4 || code1.length > 5 || code1.length <= 4;

  /*   const isButtonDisabled = !(city && code1 && code && !code1 === !code);
   
  /* const isButtonDisabled = !city || !code1 || !code;
 */



  /*   const isButtonDisabled = !city || (code1 && code && code1 == code);
   */
  const handleChangeSelect = (event) => {
    setSelectValue(event.target.value);

  };

  const handleChangesqmValue = (event) => {
    setSqmValue(event.target.value);
  };


  const handelClickContinue = () => {
    if (selectedValue == 1) {
      const filteredCity1 = ZIPCODES1.filter(
        (item) => item?.zipcode == code1
      );
      // MHero component
      navigate("/moving_out_service2", {
        state: {
          data: {
            city,
            city1,
          },
          filteredCity1: city, // Pass the filteredCity here
          filteredCity1: filteredCity1.length > 0 ? filteredCity1[0]?.city : null,
        },
      });

      console.log("ss", filteredCity1);

    }
    if (selectedValue == 2) {
      const filteredCity1 = ZIPCODES1.filter(
        (item) => item?.zipcode == code1
      );

      navigate("/moving_out_service1", {
        state: {
          data: {
            city,
            city1,
          },
          filteredCity: city, // Pass the filteredCity here
          // Also pass filteredCity1 if needed
          filteredCity1: filteredCity1.length > 0 ? filteredCity1[0]?.city : null,
        },
      });

      console.log("ss", filteredCity1);
    }
    if (selectedValue == 3) {
      const filteredCity1 = ZIPCODES1.filter(
        (item) => item?.zipcode == code1
      );

      navigate("/moving_out_cleaning", {
        state: {
          data: {
            city,
            city1,
          },
          filteredCity: city, // Pass the filteredCity here
          // Also pass filteredCity1 if needed
          filteredCity1: filteredCity1.length > 0 ? filteredCity1[0]?.city : null,
        },
      });

      console.log("ss", filteredCity1);
    }
  };

  /*  const handelClickContinue = () => {
     if (selectedValue == 1) {
       const filteredCity1 = ZIPCODES1.filter(
         (item) => item?.zipcode == code1
       );
       // MHero component
       navigate("/moving_out_service2", {
         state: {
           data: {
             city,
             city1,
           },
           filteredCity1: city, // Pass the filteredCity here
           filteredCity1: filteredCity1.length > 0 ? filteredCity1[0]?.city : null,
         },
       });
 
       console.log("ss", filteredCity1);
 
     } else {
       // Define filteredCity1 here before using it
       const filteredCity1 = ZIPCODES1.filter(
         (item) => item?.zipcode == code1
       );
 
       navigate("/moving_out_service1", {
         state: {
           data: {
             city,
             city1,
           },
           filteredCity: city, // Pass the filteredCity here
           // Also pass filteredCity1 if needed
           filteredCity1: filteredCity1.length > 0 ? filteredCity1[0]?.city : null,
         },
       });
 
       console.log("ss", filteredCity1);
     }
   }; */


  const handleNavigate = () => {
    setMoreOptions(true);

  };


  /* 
    const navigate = useNavigate();
    const [selectedValue, setSelectValue] = useState("1");
    const [sqmValue, setSqmValue] = useState(0);
    const [postCodevalue, setPostCodeValue] = useState(null);
  
    const [city, setCity] = useState("");
    const [code, setCode] = useState("");
    const [show, setShow] = useState(false);
    const [moreOptions, setMoreOptions] = useState(false);
    const zipcodeRef = useRef();
    const handleRef = () => {
      zipcodeRef.current.focus();
    };
    const handleInputChange = (e) => {
      const filteredCity = ZIPCODES.filter(
        (item) => item?.zipcode == e.target.value
      );
      if (filteredCity.length > 0) {
        setCode(e.target.value);
        setCity(filteredCity[0]?.city);
        setShow(true);
      } else {
        setCode(e.target.value);
        setShow(false);
      }
    };
    
    const isButtonDisabled = !city || !code || code.length > 5 || code.length <= 4;
  
    const handleChangeSelect = (event) => {
      setSelectValue(event.target.value);
    };
  
    const handleChangesqmValue = (event) => {
      setSqmValue(event.target.value);
    };
  
    const handelClickContinue = () => {
      navigate("/moving_out_service", {
        state: {
          data: {
            city,
          },
        },
      });
    };
    const handleNavigate = () => {
      setMoreOptions(true);
      
    }; */
  return (
    <div
      className="bg-cover"
      style={{
        backgroundImage: `url(${MovingHome})`,
      }}
    >
      <div className=" mr-auto pt-48 md:px-28  text-white text-5xl font-medium font-['Young Serif'] ">
        {moreOptions ? (
          <>
            <div className="bg-[#f5f4f0] rounded-xl  py-6  h-auto max-w-lg ">
              <div className="text-[#1e1e1e] text-4xl px-6 text-center Tiempos-font">
                {t('generalComponents.hero.howCanWe')}
              </div>
              <div className="flex mt-3 justify-center">
                <span className="w-16 bg-black h-[1px]"></span>
              </div>
              <div className="w-auto px-16 mx-auto mt-6">
                <p className="text-[#1e1e1e] text-lg font-normal text-center">
                  {t('generalComponents.hero.heading2Part1')} <strong>{city}</strong>,<strong className="text-[green] font-bold text-xl"> FSA</strong> {t('generalComponents.hero.heading2Part2')}
                </p>
              </div>
              <div className="px-6  mt-5">
                <Select
                  className="flex justify-center items-center text-[#1e1e1e] "
                  onChange={handleChangeSelect}
                  icon={<SelectArrow />}
                  bg={"white"}
                  size={"lg"}
                >

                  <option value="1" defaultValue>
                    {t('generalComponents.navBarSub.linkMoveService&MoveoutCleaning')}
                  </option>
                  <option value="2">{t('generalComponents.navBarSub.linkMovingServices')}</option>
                  <option value="3">{t('generalComponents.navBarSub.linkMovingOutCleaning')}</option>

                </Select>
              </div>

              <hr />
              <div className="px-6 mt-4">
                <Button
                  size={"lg"}
                  width="100%"
                  colorScheme="teal"
                  onClick={handelClickContinue}
                >
                  {t('generalComponents.hero.continueButton')}
                </Button>

              </div>
            </div>


          </>


        ) : (
          <>
            <p className="text-start md:ml-0 ml-[10%] text-[yellow] font-bold">{t('generalComponents.hero.headingPart1')}</p>

            <p class="  text-start mt-1 md:ml-0 ml-[10%] text-[green] font-bold ">{t('generalComponents.hero.headingPart2')}</p>
            <div className="flex md:w-[400px] md:ml-0 ml-[10%] w-4/5 text-lg font-bold hover:border-none hover:text-green-500 transform hover:scale-90 transition-transform delay-200">
              <input
                className="flex-1 bg-white focus:border-none focus:outline-none text-black text-lg p-3 rounded-l-lg mt-10 placeholder-black font-bold cursor-pointer bg-opacity-80 transform transition-transform delay-200 hover:placeholder-gray-500 md:w-2/3"
                type="number"
                placeholder={`${t('generalComponents.hero.movingFrom')}`}
                value={code}
                ref={zipcodeRef}
                onChange={handleInputChange}
              />


              <div
                onClick={handleRef}
                className="flex-1 flex items-center justify-between bg-white focus:border-none focus:outline-none text-black text-lg p-3 rounded-r-lg mt-10 placeholder-black font-bold cursor-pointer bg-opacity-80 transform transition-transform delay-200 hover:placeholder-gray-500 md:w-1/3"
              >

                {show && city && (
                  <>
                    <p className="text-left">{city} </p>
                    <p>
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        class="fcNb"
                        style={{ fill: "green" }}
                      >
                        <g>
                          <path d="m12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24zm0 22.286a10.286 10.286 0 1 1 0-20.572 10.286 10.286 0 0 1 0 20.572z"></path>
                          <path d="m17.4 7.114-6.257 6.24-2.828-2.81a.848.848 0 1 0 -1.2 1.2l3.428 3.428a.824.824 0 0 0 1.2 0l6.857-6.858a.849.849 0 0 0 -1.2-1.2z"></path>
                        </g>
                      </svg>
                    </p>
                  </>
                )}
              </div>
            </div>
            <div
              className="w-2/5 border-0"
              style={{ marginTop: "-10px" }}
            >
              {!show && (code.length >= 1 || code.length > 5) && (
                <p className="text-red-500 text-xs text-center mt-2 flex items-center justify-center">
                  {t('generalComponents.dropDown.error1')}
                </p>
              )}

              {!show && code.length === 5 && (
                <p className="text-red-500 text-xs text-center mt-2">
                  {t('generalComponents.dropDown.error2')}
                </p>
              )}
            </div>
            <div className="flex md:w-[400px] md:ml-0 ml-[10%] w-4/5 text-lg font-bold hover:border-none hover:text-green-500 transform hover:scale-90 transition-transform delay-200">
              {Number(selectedValue) === 1 ? (
                <input
                  className="flex-1 bg-white focus:border-none focus:outline-none  text-black  text-lg p-3 rounded-l-lg mt-4 placeholder-black font-bold cursor-pointer bg-opacity-80 transform transition-transform delay-200 hover:placeholder-gray-500 md:w-2/3"
                  type="number"
                  placeholder={`${t('generalComponents.hero.movingTo')}`}
                  value={code1}
                  onChange={handleInputChange1}
                  ref={zipcodeRef1}


/*                   onChange={(e) => setPostCodeValue(e.target.value)}
 */                />

              ) : (
                <>
                  <Input
                    /*                     value={`${sqmValue}`}
                     */

                    value={code1}
                    type="number"
                    placeholder="MOVING FROM"
                    className="text-[#1e1e1e] relative text-center bottom-1  outline-none focus-visible:border-none"
                    onChange={handleInputChange1}
                    ref={zipcodeRef1}

                  />

                </>
              )}

              <div
                onClick={handleRef1}
                className="flex-1 flex items-center justify-between bg-white focus:border-none focus:outline-none text-black text-lg p-3 rounded-r-lg mt-4 placeholder-black font-bold cursor-pointer bg-opacity-80 transform transition-transform delay-200 hover:placeholder-gray-500 md:w-1/3"
              >

                {show1 && city1 && (
                  <>
                    <p className="text-left">{city1} </p>

                    <p>
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        class="fcNb"
                        style={{ fill: "green" }}
                      >
                        <g>
                          <path d="m12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24zm0 22.286a10.286 10.286 0 1 1 0-20.572 10.286 10.286 0 0 1 0 20.572z"></path>
                          <path d="m17.4 7.114-6.257 6.24-2.828-2.81a.848.848 0 1 0 -1.2 1.2l3.428 3.428a.824.824 0 0 0 1.2 0l6.857-6.858a.849.849 0 0 0 -1.2-1.2z"></path>
                        </g>
                      </svg>
                    </p>
                  </>
                )}
              </div>
            </div>
            <div
              className="w-2/5 border-0"
              style={{ marginTop: "-10px" }}
            >
              {!show1 && (code1.length >= 1 || code1.length > 5) && (
                <p className="text-red-500 text-xs text-center mt-2 flex items-center justify-center">
                  {t('generalComponents.dropDown.error1')}
                </p>
              )}

              {!show1 && code1.length === 5 && (
                <p className="text-red-500 text-xs text-center mt-2">
                  {t('generalComponents.dropDown.error2')}
                </p>
              )}
            </div>
            <button
              onClick={handleNavigate}
              disabled={isButtonDisabled}
              className={`flex md:w-[400px] md:ml-0 ml-[10%] w-4/5 bg-opacity-80 hover:shadow-lg hover:shadow-black bg-[#1e1e1e] text-lg font-bold mt-4 p-5 rounded-md ${isButtonDisabled || (!show && code.length === 5) || (!show1 && code1.length === 5)
                ? "cursor-not-allowed opacity-50"
                : "hover:text-green-500 transform hover:scale-90 transition-transform delay-200"
                }`}>
              <p className="ml-24 text-['#fff']">{t('generalComponents.hero.continueButton')} </p>
            </button>

            <div className="text-xl mt-10 flex items-start font-normal">
              <div className="mt-5">
                <div class="flex items-center">
                </div>

                {/*                 <MovingOut_Service2 filteredCity1={city} />
 */}              </div>

            </div>

          </>

        )}

      </div>

    </div>
  );
  /* return (
    <div
      className="bg-cover"
      style={{
        backgroundImage: `url(${MovingHome})`,
      }}
    >
      <div className=" mr-auto pt-48 md:px-28  text-white text-5xl font-medium font-['Young Serif'] ">
        {moreOptions ? (
          <>
            <div className="bg-[#f5f4f0] rounded-xl  py-6  h-auto max-w-lg ">
              <div className="text-[#1e1e1e] text-4xl px-6 text-center Tiempos-font">
                How can we simplify your move?
              </div>
              <div className="flex mt-3 justify-center">
                <span className="w-16 bg-black h-[1px]"></span>
              </div>
              <div className="w-auto px-16 mx-auto mt-6">
                <p className="text-[#1e1e1e] text-lg font-normal text-center">
                  Where you live in <strong>{city}</strong>,<strong>FLYTT,STAD & ATERVINNING</strong> offers the
                  following services
                </p>
              </div>
              <div className="px-6  mt-5">
                <Select
                  className="flex justify-center items-center text-[#1e1e1e] "
                  onChange={handleChangeSelect}
                  icon={<SelectArrow />}
                  bg={"white"}
                  size={"lg"}
                >
                  <option value="1" selected>
                    Moving service & Move out cleaning
                  </option>
                  <option value="2">MoveOut Cleaning</option>
                </Select>
              </div>
              <div className="mt-3 px-6 ">
                <p className="text-[#1e1e1e] text-lg">
                  {selectedValue === "1"
                    ? "Moving to"
                    : "How big is your home?"}
                </p>
              </div>
              <div className="px-6 mb-3 relative">
                {Number(selectedValue) === 1 ? (
                  <Input
                    bg={"white"}
                    placeholder="Postcode"
                    value={postCodevalue}
                    onChange={(e) => setPostCodeValue(e.target.value)}
                    className="text-[#1e1e1e] text-center bottom-1 outline-none focus-visible:border-none "
                  />
                ) : (
                  <>
                    <Input
                      bg={"white"}
                      value={`${sqmValue}`}
                      type="number"
                      placeholder="Postcode"
                      className="text-[#1e1e1e] relative text-center bottom-1 outline-none focus-visible:border-none"
                      onChange={handleChangesqmValue}
                    />
                    <span className="absolute text-[#1e1e1e] text-base  right-48 top-5 z-10">
                      sqm
                    </span>
                  </>
                )}
              </div>
              <hr />
              <div className="px-6 mt-4">
                <Button
                  size={"lg"}
                  width="100%"
                  colorScheme="teal"
                  onClick={handelClickContinue}
                >
                  CONTINUE
                </Button>
              </div>
            </div>

          </>
        ) : (
          <>
            <p className="text-start  text-gray-800">Get help with</p>
            <p className="text-start mt-1 text-gray-900">Moving Service</p>

            <div className="flex w-[400px] md:w-[400px]  text-lg font-bold hover:border-none hover:text-green-500 transform hover:scale-90 transition-transform delay-200">
              <input
                className="flex-1 bg-white focus:border-none focus:outline-none text-black text-right text-lg p-3 rounded-l-lg mt-10 placeholder-black font-bold cursor-pointer bg-opacity-80 transform transition-transform delay-200 hover:placeholder-gray-500"
                type="number"
                placeholder="ENTER ZIPCODE"
                value={code}
                ref={zipcodeRef}
                onChange={handleInputChange}
              />

              <div
                onClick={handleRef}
                className="flex-1 flex items-center justify-between bg-white focus:border-none focus:outline-none text-black text-lg p-3 rounded-r-lg mt-10 placeholder-black font-bold cursor-pointer bg-opacity-80 transform transition-transform delay-200 hover:placeholder-gray-500"
              >
                {show && city && (
                  <>
                    <p className="text-left">{city} </p>
                    <p>
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        class="fcNb"
                        style={{ fill: "green" }}
                      >
                        <g>
                          <path d="m12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24zm0 22.286a10.286 10.286 0 1 1 0-20.572 10.286 10.286 0 0 1 0 20.572z"></path>
                          <path d="m17.4 7.114-6.257 6.24-2.828-2.81a.848.848 0 1 0 -1.2 1.2l3.428 3.428a.824.824 0 0 0 1.2 0l6.857-6.858a.849.849 0 0 0 -1.2-1.2z"></path>
                        </g>
                      </svg>
                    </p>
                  </>
                )}
              </div>
            </div>
            <div
              className="w-2/5 border-0"
              style={{ marginTop: "-10px" }}
            >
              {!show && (code.length >= 1 || code.length > 5) && (
                <p className="text-red-500 text-xs text-center mt-2 flex items-center justify-center">
                  This is not a valid zip code.
                </p>
              )}

              {!show && code.length === 5 && (
                <p className="text-red-500 text-xs text-center mt-2">
                  Unfortunately, none of our services are available in the
                  area.
                </p>
              )}
            </div>
            <button
              onClick={handleNavigate}
              disabled={isButtonDisabled}
              className={`flex w-[400px] md:w-[400px] bg-opacity-80 hover:shadow-lg hover:shadow-black bg-[#1e1e1e] text-lg font-bold mt-4 p-5 rounded-md ${isButtonDisabled || (!show && code.length === 5)
                ? "cursor-not-allowed opacity-50"
                : "hover:text-green-500 transform hover:scale-90 transition-transform delay-200"
                }`}>
              <p className=" ml-24 text-['#fff']">CONTINUE </p>
            </button>
            <div className="text-xl mt-10 flex items-start font-normal">
              <div className="mt-5">
                <div class="flex items-center">

                </div>


              </div>
            </div>
          </>
        )}
      </div>
    </div>
  ); */
};

export default MHero;
