import React, { useState, useEffect } from "react";
/* import "../../../Assets/css/window_cleaning.css"; */
import "../../../../Assets/css/window_cleaning.css"
/* import CrossIcon from "../../Gernal_Components/CrossIcon";

 *//* import CrossIcon from "../../../Gernal_Components/Cross *
/* import CrossIcon from "../../Gernal_Components/CrossIcon"; */
import CrossIcon from "../../../Gernal_Components/CrossIcon";

import { useLocation, useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import emailjs from "emailjs-com";

import { useTranslation } from 'react-i18next';

const Generalhandyman1 = ({ filteredCity1 }) => {
    
  const { t } = useTranslation();

    console.log("first", filteredCity1)

    const navigate = useNavigate()
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [city, setCity] = useState("");
    const [doorCode, setDoorCode] = useState("");
    const [street, setStreet] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [agreeToOffers, setAgreeToOffers] = useState(false);

    const [confirmEmail, setConfirmEmail] = useState("");

    const [kind, setKind] = useState("")
    const [campaignCode, setCampaignCode] = useState("");

    const isValidEmail = (email) => {
        // Email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const location = useLocation();
    const data = location?.state?.data;
    console.log("data received", data);
    useEffect(() => {
        setCity(data ? data[0]?.city : "");
    }, [data]);
    const handleSubmit = async () => {
        if (
            !firstName ||
            !lastName ||

            !street ||
            !city ||
            !doorCode ||
            !phoneNumber ||
            !email ||
            !confirmEmail ||
            !campaignCode ||
            !kind ||
            !agreeToOffers
        ) {
            toast.error("Please fill in all required fields.");
            return;
        }
        if (!isValidEmail(email)) {
            toast.error("Please enter a valid email address.");
            return;
        }

        if (email !== confirmEmail) {
            toast.error("Email and Confirm Email must match.");
            return;
        }
        try {
            const messageBody = `
                New General Handyman Request:
            
                Name: ${firstName} ${lastName}
                First Name: ${firstName}
                Last Name: ${lastName}
                Street: ${street}
                City: ${city}
                Door Code: ${doorCode}
                Phone Number: ${phoneNumber}
                Email: ${email}
                What kind of help do you need? : ${kind}
                
            `;
            const templateParams = {
                to_name: `${firstName} ${lastName}`,
                from_name: "Code Zone",
                to_email: email,
                subject: "New General Handyman Request",
                message: messageBody,
            };
            const response = await emailjs.send(
                "service_m774pph",
                "template_qsec7n7",
                templateParams,
                "rc1ba_PvLbgEIUjbt"
            );
            console.log("Email sent successfully:", response);
            if (response) {
                toast.success(`${firstName} ${lastName} Your Request Sent Successfully`);
                setAgreeToOffers(false);
                setDoorCode("");
                setCity("");
                setEmail("");
                setConfirmEmail("");
                setDoorCode("");
                setFirstName("");
                setLastName("");
                setPhoneNumber("");
                setStreet("");
                setKind("");
                setCampaignCode("");
                document.getElementById("cat").checked = false;
                document.getElementById("dog").checked = false;
                document.getElementById("other").checked = false;
            }
        } catch (error) {
            console.error("Error sending email:", error);
/*             toast.error("Something Went wrong please try again");
 */        }
    };


    return (
        <>
            <ToastContainer position="top-right" autoClose={5000} />
            <div
                className="container mx-auto my-auto pt-10 mb-20"
                style={{ maxWidth: "70%" }}
            >
                <div
                    style={{ display: "grid", gridTemplateColumns: "80% 30%", gap: "30px" }}
                >
                    <div className="text-center px-2">
                        <div className="flex justify-end hover:shake">
                            <CrossIcon handleClickCrossIcon={() => navigate("/Generalhandyman")} />
                        </div>
                        <div
                            className=" mt-2 text-justify-center font-normal text-5xl"
                            style={{ fontFamily: "Tiempos Headline,serif" }}
                        >
                            {t('contactPages.generalHandyman')}
                        </div>


                        <div className="w-full mt-8 ml-10  flex justify-center items-center mb-5">
                            <p className=" border-black border w-20 text-center hover:w-[400PX] hover:border-gray-500  transition-all duration-300 transform origin-center scale-100"></p>
                        </div>

                        <div className="mt-3 rounded-lg p-4">

                            <label
                                htmlFor="message"
                                className="text-xl font-semibold flex justify-left mb-2 ms-1"
                            >
                                {t('contactPages.firstName')}*
                            </label>
                            <div className="flex items-center mb-3">
                                <input
                                    type="text"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}

                                    id="text"
                                    className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                                />
                            </div>


                            <label
                                htmlFor="message"
                                className="text-xl font-semibold flex justify-left mb-2  ms-1"
                            >
                                {t('contactPages.lastName')}*

                            </label>
                            <div className="flex items-center mb-3">
                                <input
                                    type="text"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    id="text"
                                    className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                                />
                            </div>


                            <label
                                htmlFor="message"
                                className="text-xl font-semibold flex justify-left mb-2  ms-1"
                            >
                                {t('contactPages.street')}*
                            </label>

                            <div className="flex items-center mb-3">
                                <input
                                    type="text"
                                    value={street}
                                    onChange={(e) => setStreet(e.target.value)}
                                    id="text"
                                    className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                                />
                            </div>
                            <label htmlFor="date" className="text-xl font-semibold flex justify-left mb-2  ms-1">
                            {t('contactPages.city')}*

                            </label>
                            <div className="flex items-center mb-3">
                                <input
                                    type="text"
                                    id="city"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                                />
                            </div>
                            <label htmlFor="time" className="text-xl font-semibold flex justify-left mb-2  ms-1">
                                {t('contactPages.doorCode')}
                            </label>
                            <div className="flex items-center mb-3">
                                <input
                                    type="text"
                                    id="doorCode"
                                    value={doorCode}
                                    onChange={(e) => setDoorCode(e.target.value)}
                                    className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                                />
                            </div>
                            <label
                                htmlFor="message"
                                className="text-xl font-semibold flex justify-left mb-2  ms-1"
                            >
                                {t('contactPages.phoneNumber')}*

                            </label>

                            <div className="flex items-center mb-3">
                                <input
                                    type="number"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    id="number"
                                    className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                                />
                            </div>

                            <label htmlFor="email" className="text-xl font-semibold flex justify-left mb-2  ms-1">
                                {t('contactPages.email')}*

                            </label>
                            <input
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}

                                type="email"
                                id="email"
                                name="email"
                                className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                                placeholder="Enter your email"
                            />
                            <label htmlFor="email" className="text-xl font-semibold flex justify-left mb-2  ms-1">
                                {t('contactPages.confirmEmail')}*:
                            </label>
                            <input
                                value={confirmEmail}
                                onChange={(e) => setConfirmEmail(e.target.value)}

                                type="email"
                                id="email"
                                name="email"
                                className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                                placeholder="Enter your email"
                            />
                        </div>




                        <div className="mt-[-2px] rounded-lg p-2">
                            <label
                                htmlFor="message"
                                className="text-xl font-semibold flex justify-left mb-1  ms-1"
                            >
                                {t('contactPages.whatKindOfHelpDoYouNeed')}
                            </label>
                            <div className="flex items-center mb-3">
                                <input
                                    type="text"
                                    name="kind"
                                    onChange={(e) => setKind(e.target.value)}
                                    id="kind"
                                    className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                                />
                            </div>
                        </div>
                        <div className="mt-[-2px] rounded-lg p-2">
                            <label
                                htmlFor="message"
                                className="text-xl font-semibold flex justify-left mb-1  ms-1"
                            >
                                {t('contactPages.ifYouHaveACampaignCode')}

                            </label>
                            <div className="flex items-center mb-3">
                                <input
                                    value={campaignCode}
                                    onChange={(e) => setCampaignCode(e.target.value)}
                                    type="text"
                                    id="campaignCode"
                                    name="campaignCode"
                                    className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                                />
                            </div>

                        </div>

                        <p className="text-2xl text-left mb-4 font-semibold">{t('contactPages.approval')}</p>

                        <div className="mb-4 pr-10 my-auto flex">
                            <input
                                type="checkbox"
                                id="agreeToOffers"
                                name="agreeToOffers"
                                checked={agreeToOffers}
                                onChange={() => setAgreeToOffers(!agreeToOffers)}
                                className="mr-2 h-8 w-8 flex text-start mt-4"
                            />
                            <label
                                htmlFor="agreeToOffers"
                                className="text-xl flex text-start ml-2 mb-4"
                            >
                                {t('contactPages.iAgreeToRecieve')}
                            </label>
                        </div>
                        <div className="flex justify-center">
                            {/* "Contact Me" button */}
                            <button
                                className="bg-black hover:bg-black text-white font-bold py-4 px-14 rounded relative group overflow-hidden"
                                onClick={handleSubmit}
                            >
                                <span className="absolute w-64 h-0 transition-all duration-[700ms] origin-center rotate-45 -translate-x-16 bg-[#9e478e] top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease flex justify-center text-center"></span>
                                <span className="relative text-white transition duration-[700ms] group-hover:text-white ease">
                                {t('contactPages.contactMe')}
                                </span>
                            </button>
                        </div>
                    </div>



                </div>
            </div >
        </>
    );
};

export default Generalhandyman1;










