import { useState } from "react";
import { BiSolidChevronDown } from "react-icons/bi";
import Image1 from '../../../../Assets/images/New Images/Moving_1.jpeg'
import { FaTimes } from "react-icons/fa"
import { Button } from "@chakra-ui/react"

import { useTranslation } from 'react-i18next';

const Todo = () => {
    
  const { t } = useTranslation();

    const [modalOpen, setModalOpen] = useState(false);

    const [activeIndex, setActiveIndex] = useState();
    const handleSetIndex = (index) => {
        setActiveIndex(index);
    };
    const todos = [
        {
            title: `${t('moveOutCleaning.todo.service.heading1')}`,
            question: "1",
            answer: t('moveOutCleaning.todo.service.description1', { returnObjects: true })
        },
        {
            title: `${t('moveOutCleaning.todo.service.heading2')}`,
            question: "2",
            answer: t('moveOutCleaning.todo.service.description2', { returnObjects: true })
        },
        {
            title: `${t('moveOutCleaning.todo.service.heading3')}`,
            question: "3",
            answer: t('moveOutCleaning.todo.service.description3', { returnObjects: true })
        },
        {
            title: `${t('moveOutCleaning.todo.service.heading4')}`,
            question: "4",
            answer: t('moveOutCleaning.todo.service.description4', { returnObjects: true })
        },
        {
            title: `${t('moveOutCleaning.todo.service.heading5')}`,
            question: "5",
            answer: t('moveOutCleaning.todo.service.description5', { returnObjects: true })
        },
        {
            title: `${t('moveOutCleaning.todo.service.heading6')}`,
            question: "6",
            answer: t('moveOutCleaning.todo.service.description6', { returnObjects: true })
        }
    ];
    
    return (

        <>
            {/* first content */}
            <div className="bg-[#f5f4f0]">

                <div className="flex flex-col-reverse lg:flex-row items-center justify-start relative mb-2">

                    <div className="w-5/6 md:w-1/2 mt-2 px-6 text-black font-medium font-['Young Serif'] lg:ml-28 xl:ml-16 2xl:ml-36 mb-10 md:mb-0">

                        <p className="text-center md:text-start text-3xl  ">{t('moveOutCleaning.todo.heading')} </p>
                        {/*                         <p className="text-start text-3xl mt-1">service</p>
 */}
                        <div className="w-full   font-normal text-start md:text-center lg:text-left">

                            {todos.map(({ title, question, answer }, i) => (
                                <div
                                    className="bg-white rounded-lg  w-full p-1 my-2 shadow-lg hover:shadow-2xl group gap-8"
                                    onMouseLeave={() => setActiveIndex()}
                                    onMouseEnter={() => handleSetIndex(i)}
                                >
                                    <div className="flex justify-between items-center my-2  px-4 sm:px-8 h-8 cursor-pointer">
                                    <h3 className={`lg:text-xl md:text-xl sm:text-xl pb-2 font-semibold mr-2 ${getTitleColor(i)}`}>{question}</h3>

                                        <h3 className={`lg:text-xl md:text-xl sm:text-xl pb-2 font-semibold mr-2 ${getTitleColor(i)}`}>{title}</h3>
                                        <div className="text-3xl group-hover:rotate-180 transition duration-1500 ml-auto">

                                            <BiSolidChevronDown className="w-7 h-7  border-2 rounded-full text-green-500 border-green-500  group-hover:border-yellow-400 group-hover:text-yellow-400 " />
                                        </div>
                                    </div>
                                    <div
  x-ref="tab"
  className={`border-l-2 rounded-bl-md border-yellow-500 dark:border-none overflow-hidden duration-[1500ms] transition-all ${activeIndex === i ? "max-h-[500px]" : "max-h-0 "
    }`}
>
  {/* {title && (
    <h3 className='text-start text-lg pb-1 font-semibold text-blue-500'>
      {title}
    </h3>
  )} */}
  <ul className='list-disc marker:text-blue-500 text-start pl-5 space-y-3 text-gray-700'>
    {answer.map((ans, j) => (
      <li key={j} className="text-sm">
        {ans}
      </li>
    ))}
  </ul>
</div>


                                </div>

                            ))}


                        </div>



                        {/*                         <p className="text-start w-3/4  text-lg">Remember that secondary areas such as storerooms, garages and balconies are not included in our moving service, but you can of course schedule them!</p>
 */}                    </div>

                    <div className='w-[650px] h-[400px] overflow-hidden'>
                        <div className="w-[650px] h-[400px] flex justify-center items-end background-cover bg-no-repeat bg-cover overflow-hidden transition-transform transform hover:scale-105 duration-300 image-div relative" style={{backgroundImage:`url(${Image1})`}}>
                            <Button style={{ background: "black" }} onClick={() => setModalOpen(true)} className="h-[30px] rounded-lg px-4 pt-2 overflow-hidden relative bottom-[-50px] group cursor-pointer border-2 font-medium text-[16px] border-white text-black hover:scale-105 duration-[900ms] hover:border-[#003942] hover:text-[white] preview-button">
                                <span className="absolute w-72 h-0 transition-all duration-[900ms] origin-center rotate-45 -translate-x-20 bg-white top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                                <span className="relative text-xl text-white transition duration-[900ms] group-hover:text-black ease mb-2">{t('generalComponents.preview')}</span>
                            </Button>
                        </div>
                    </div>


                </div>

                {modalOpen && (
                    <div style={{zIndex: 9999}} className="z-5000 fixed top-[30px] left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
                        <div className="fadeIn bg-white p-4 rounded-lg relative z-5001">
                            <div onClick={() => setModalOpen(false)} className='absolute top-0 right-0 m-2 p-2 cursor-pointer rounded-full border-2 border-orange-600 text-orange-600 bg-white text-center'>
                            <FaTimes />
                            </div>
                            <img src={Image1} alt="Full Size" className="h-[500px] rounded-lg"/>
                            <button onClick={() => setModalOpen(false)}>{t('generalComponents.close')}</button>
                        </div>
                    </div>
                )}

            </div>




        </>

    );
    function getTitleColor(index) {
        const colors = [
            "text-red-500",
            "text-blue-500",
            "text-green-500",
            "text-yellow-500",
            "text-pink-500",
            "text-purple-500",
            "text-indigo-500",
            "text-gray-500",
            // Additional colors
            "text-orange-500",
            "text-teal-500",
            "text-cyan-500",
            "text-amber-500",
            "text-lime-500",
            "text-gray-500",
            "text-green-500",
        ];
        return colors[index % colors.length];
    }

}
export default Todo;