import React from 'react';
import { useLocation, useNavigate } from 'react-router';

import { useTranslation } from 'react-i18next';

const Booking = () => {
    
  const { t } = useTranslation();

    const location = useLocation();
    const data = location?.state?.data;
    const navigate = useNavigate()
    const handleHomeVisit = () => {
        navigate('/calender', {
            state: {
                data: data
            }
        });
    }
    const handleCallNavigate = () => {
        navigate('/book_by_call', {
            state: {
                data: data
            }
        });
    }
    return (
        <>
            <div className="container mx-auto my-auto pt-10 mb-20" style={{ maxWidth: "70%" }}>
                <div style={{ display: 'grid', gridTemplateColumns: '70% 30%', gap: '30px' }}>

                    <div className=''>

                        <div className="text-center">
                            <div className="flex justify-center" style={{ maxWidth: '70%', margin: '0 auto' }}>
                                <span
                                    className="cross_icon_box hover:shake"
                                    onClick={() => navigate("/Home_clean")}
                                    style={{ marginLeft: '10%', marginRight: 'auto' }}
                                >
                                    <svg
                                        height="15"
                                        viewBox="0 0 9 15"
                                        width="15"
                                        className="csbbM95O hiH3WVS irER7"
                                    >
                                        <path
                                            d="m.958.995 6.51 6.51-6 6"
                                            fill="none"
                                            strokeWidth="2"
                                        ></path>
                                    </svg>
                                </span>

                                <span
                                    className="cross_icon_box hover:shake"
                                    onClick={() => navigate("/Home_clean")}
                                    style={{ marginLeft: 'auto', marginRight: '10%' }}
                                >
                                    <svg viewBox="0 0 60 60" className="csbbM95O hiH3WVS">
                                        <g
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-miterlimit="10"
                                            strokeWidth="8"
                                        >
                                            <path d="m5 5 50 50"></path>
                                            <path d="m5 55 50-50"></path>
                                        </g>
                                    </svg>
                                </span>
                            </div>
                        </div>


                        <div className=" font-normal text-5xl">{t('contactPages.howWouldYouLikeTo')}</div>
                        <div className="pt-5 font-normal text-5xl">{t('contactPages.bookYourHomeCleaning')}</div>
                        <div className="w-full my-8  flex justify-center items-center">

                            <p className=" border-black border w-20 text-center hover:w-[400PX] hover:border-gray-500  transition-all duration-300 transform origin-center scale-100" ></p>

                        </div>
                        <p className='text-center' style={{ color: '#1e1e1e', fontSize: '20px' }}>{t('contactPages.bookingParagraph')}</p>
                        <div className='mt-10' style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <button onClick={handleCallNavigate} style={{ background: 'white', width: '300px', display: 'flex', flexDirection: 'row', gap: '30px', justifyContent: 'space-between' }} className="bg-inherit text-black p-5 hover:bg-white border rounded border-inherit text-xl text-bold">
                                {t('contactPages.callMe')}
                                <p className="text-center text-4xl my-auto"><svg height="20" viewBox="0 0 9 15" width="20" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}   ><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                            </button>
                            <button onClick={handleHomeVisit} style={{ background: 'white', width: '300px', display: 'flex', flexDirection: 'row', gap: '30px', justifyContent: 'space-between' }} className="bg-inherit text-black p-5 my-auto hover:bg-white border rounded border-inherit text-xl text-bold">
                                <p>{t('contactPages.bookHomeVisit')}</p>
                                <p className="text-right text-4xl my-auto" ><svg height="20" viewBox="0 0 9 15" width="20" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}   ><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                            </button>
                        </div>
                    </div>
                    <div className='mt-20' style={{ backgroundColor: '#d1dce7', width: '300px', height: '250px' }}>
                        <div className='d-flex flex-col mt-5'>
                            <p style={{ fontSize: '22px', color: '#003b5c', fontFamily: 'Tiempos Headline,serif' }}>{t('contactPages.welcomeToASimplerLife')}</p>
                            <p className='text-base text-left px-5 mt-3' style={{ color: '#003b5c' }}>{t('contactPages.welcomeToASimplerLifeParagraph')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Booking;
