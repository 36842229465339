import React, { useEffect } from 'react';
import useTranslatePage from './../../Gernal_Components/TranslatePage';
import Loader from './../../Gernal_Components/Loader';

import Faq from "./sections/Faq";
import MHero from "./sections/Hero";
import Letus from "./sections/Letus";
import Movingtip from "./sections/Movingcleantips";
import Todo from "./sections/Todo";
import Include from "./sections/include";
import MWork from "./sections/work";

const MHome=()=>{

    const [translatePage, loading] = useTranslatePage();

    useEffect(() => {
        const lang = localStorage.getItem('language');
        if (lang) {
        translatePage(lang);
        }
    }, []);

return (
    <div>
        {loading && <Loader />}
        <MHero />
        <Include />
        <Todo />
        <MWork />
        <Faq />
        {/* <Movingtip /> */}
        <Letus />
    </div>
)


}

export default MHome;