import { useState } from "react";
import { BiSolidChevronDown } from "react-icons/bi";
import { FaTimes } from "react-icons/fa"
import { Button } from "@chakra-ui/react"

import Image1 from '../../../../Assets/images/contact.jpeg'

import { useTranslation } from 'react-i18next';

const Todo = () => {

    const { t } = useTranslation();

    const [modalOpen, setModalOpen] = useState(false);

    const [activeIndex, setActiveIndex] = useState();
    const handleSetIndex = (index) => {
        setActiveIndex(index);
    };
    const todos = [
        /* {
           title: " 1.",
 
           question: "Create a Moving Timeline:",
           answer:
               "Plan your move well in advance and create a timeline with key milestones to ensure a smooth transition."
       },
       {
           title: " 2.",
           question: "Declutter and Downsize:",
           answer:
               "Go through your belongings and declutter. Donate, sell, or discard items you no longer need to reduce the volume of your move."
       }, */
        {
            title: " 1.",
            question: "General Preparation:",
            answer:
                "Inform utility providers (electricity, water, gas, internet, etc.) of your move-out date to arrange for disconnection or transfer of services."
        },
        {
            title: " 2.",
            question: " Change of Address:",
            answer:
                "Update your address with the postal service, banks, government offices, and any subscriptions or memberships you have."
        },
        {
            title: " 3.",
            question: " Forward Mail:",
            answer:
                "Set up mail forwarding with the postal service to ensure you receive any mail at your new address"
        },
        /*  {
             title: " 6.",
             question: " Packing Supplies:",
             answer:
                 "Gather packing materials such as boxes, tape, bubble wrap, and labels. Start packing non-essential items well in advance."
         }, */
        {
            title: " 4.",
            question: " Label Boxes Clearly:",
            answer:
                "Clearly label each box with its contents and the room it belongs to. This makes unpacking much more efficient."
        },
        /*  {
             title: " 8.",
             question: " Pack Essentials Separately:   ",
             answer:
                 "Pack a separate box with essential items for the first day at your new home, including toiletries, clothes, and important documents."
         },  */
        {
            title: " 5.",
            question: " Notify Important Contacts:            ",
            answer:
                "Inform friends, family, and important contacts about your move, providing them with your new address and contact details.                "
        },
        {
            title: " 6.",
            question: " Book Movers or Rent a Truck:            ",
            answer:
                "If you're using a moving service, book them well in advance. If you're moving yourself, arrange for a rental truck or van."
        },
        {
            title: " 7.",

            question: " Reserve Parking:            ",
            answer:
                "If parking space is limited at your current or new residence, reserve parking for the moving day to facilitate loading and unloading.                "
        },
        /*  {
             title: " 12.",
 
             question: " Plan for Pets:            ",
             answer:
                 "Make arrangements for your pets on moving day, whether it's finding a pet sitter or ensuring they are comfortable during the move."
         }, */

        {
            title: " 8.",

            question: " Take Inventory:            ",
            answer:
                "Keep a detailed inventory of your belongings. This will be helpful for both packing and unpacking, as well as for insurance purposes.                "
        },
        {
            title: " 9.",

            question: " Clean Before Leaving:            ",
            answer:
                "Clean your current residence thoroughly before leaving. This includes floors, walls, appliances, and any other areas that need attention.                "
        },
        /*         {
                    title: " 15.",
        
                    question: " Final Check:            ",
                    answer:
                        "Conduct a final walkthrough of your home to ensure nothing is left behind. Check closets, cabinets, and all rooms.                "
                } */
    ];
    return (

        <>
            {/* first content */}
            <div className="bg-[#f5f4f0] my-10 md:my-0">

                <div className="flex flex-col-reverse lg:flex-row items-center justify-start relative mb-2">

                    <div className="w-full md:w-1/2  px-6 text-black font-medium font-['Young Serif'] lg:ml-28 xl:ml-16 2xl:ml-36">

                        <p className="text-start text-4xl font-bold mt-10 md:mt-0"><span className="text-orange-500">{t('aboutFSA.todo.headingPart1')}</span> {t('aboutFSA.todo.headingPart2')}
                        </p>
                        <div className="w-full  mt-5 text-lg font-normal text-start md:text-center lg:text-left">

                            <p>{t('aboutFSA.todo.paragraph')}</p>
                            {/* {todos.map(({ title, question, answer }, i) => (
                                <div
                                    className="bg-white rounded-lg  w-full shadow-lg hover:shadow-2xl group gap-8"
                                    onMouseLeave={() => setActiveIndex()}
                                    onMouseEnter={() => handleSetIndex(i)}
                                >
                                    <div className="flex justify-between items-center my-2  px-4 sm:px-8 h-8 cursor-pointer">
                                        <h3 className={`lg:text-xl md:text-xl sm:text-xl text-xs pb-2 font-semibold mr-2 ${getTitleColor(i)}`}>{title}</h3>
                                        <h3 className={`lg:text-xl md:text-xl sm:text-xl text-xs pb-2 font-semibold mr-2 ${getTitleColor(i)}`}>{question}</h3>
                                        <div className="text-3xl group-hover:rotate-180 transition duration-1500 ml-auto">

                                            <BiSolidChevronDown className="w-8 h-8  border-2 rounded-full text-green-500 border-green-500  group-hover:border-yellow-400 group-hover:text-yellow-400 " />
                                        </div>
                                    </div>
                                    <div
                                        x-ref="tab"
                                        className={`border-l-2 rounded-bl-md border-yellow-500 dark:border-none overflow-hidden duration-[1500ms] transition-all ${activeIndex === i ? "max-h-[500px]" : "max-h-0 "
                                            }`}
                                    >
                                        <p className="text-start px-4 sm:px-8 py-2  p-3 text-xl font-light text-gray-700">
                                            {answer}
                                        </p>
                                    </div>
                                </div>

                            ))} */}


                        </div>


                    </div>

                    <div className='w-[800px] h-[300px] md:h-[550px] overflow-hidden'>
                        <div className="w-[800px] h-[300px] md:h-[550px] flex justify-center items-end background-cover bg-no-repeat bg-cover overflow-hidden transition-transform transform hover:scale-105 duration-300 image-div relative" style={{backgroundImage:`url(${Image1})`}}>
                            <Button style={{ background: "black" }} onClick={() => setModalOpen(true)} className="h-[30px] rounded-lg px-4 pt-2 overflow-hidden relative bottom-[-50px] group cursor-pointer border-2 font-medium text-[16px] border-white text-black hover:scale-105 duration-[900ms] hover:border-[#003942] hover:text-[white] preview-button">
                                <span className="absolute w-72 h-0 transition-all duration-[900ms] origin-center rotate-45 -translate-x-20 bg-white top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                                <span className="relative text-xl text-white transition duration-[900ms] group-hover:text-black ease mb-2">{t('generalComponents.preview')}</span>
                            </Button>
                        </div>
                    </div>

                </div>

                {modalOpen && (
                    <div style={{zIndex: 9999}} className="z-5000 fixed top-[30px] left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
                        <div className="fadeIn bg-white p-4 rounded-lg relative z-5001">
                            <div onClick={() => setModalOpen(false)} className='absolute top-0 right-0 m-2 p-2 cursor-pointer rounded-full border-2 border-orange-600 text-orange-600 bg-white text-center'>
                            <FaTimes />
                            </div>
                            <img src={Image1} alt="Full Size" className="h-[500px] rounded-lg"/>
                            <button onClick={() => setModalOpen(false)}>{t('generalComponents.close')}</button>
                        </div>
                    </div>
                )}

            </div>




        </>

    );
    function getTitleColor(index) {
        const colors = [
            "text-red-500",
            "text-blue-500",
            "text-green-500",
            "text-yellow-500",
            "text-pink-500",
            "text-purple-500",
            "text-indigo-500",
            "text-gray-500",
            // Additional colors
            "text-orange-500",
            "text-teal-500",
            "text-cyan-500",
            "text-amber-500",
            "text-lime-500",
            "text-gray-500",
            "text-green-500",
        ];
        return colors[index % colors.length];
    }

}
export default Todo;