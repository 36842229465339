import React, { useState, useEffect } from "react";
import { BiSolidChevronDown } from "react-icons/bi"

import { useTranslation } from 'react-i18next';

const Note = () => {
    
    const { t } = useTranslation();

    const [activeIndex, setActiveIndex] = useState();
    const handleSetIndex = (index) => {
        setActiveIndex(index);
    };
    const faqs = [
        {
            question: `${t('movingService.faqTruck.questions.question1')}`,
            answer: t('movingService.faqTruck.questions.answer1', { returnObjects: true }),
        },
        {
            question: `${t('movingService.faqTruck.questions.question2')}`,
            answer: t('movingService.faqTruck.questions.answer2', { returnObjects: true }),
        },
        {
            question: `${t('movingService.faqTruck.questions.question3')}`,
            answer: t('movingService.faqTruck.questions.answer3', { returnObjects: true }),
        },
        {
            question: `${t('movingService.faqTruck.questions.question4')}`,
            answer: t('movingService.faqTruck.questions.answer4', { returnObjects: true }),
        },
        {
            question: `${t('movingService.faqTruck.questions.question5')}`,
            answer: t('movingService.faqTruck.questions.answer5', { returnObjects: true }),
        },
        {
            question: `${t('movingService.faqTruck.questions.question6')}`,
            answer: t('movingService.faqTruck.questions.answer6', { returnObjects: true }),
        }
    ];
    return (
        <div
            className="mt-20 pb-5"
            style={{
                width: "450px",
                height: "690px",
            }}>
            <h2 className="font-bold text-xl mb-3">
            {t('movingService.faqTruck.heading.headingPart1')}
                <span className="text-red-600"> {t('movingService.faqTruck.heading.headingPart2')} </span>{" "}
            </h2>
            {faqs.map(({ title, question, answer }, i) => (
                <div
                    className="bg-white rounded-lg  w-full shadow-lg hover:shadow-2xl group gap-8"
                    onMouseLeave={() => setActiveIndex()}
                    onMouseEnter={() => handleSetIndex(i)}
                >
                    <div className="flex justify-between items-center my-2  px-4 sm:px-8 h-14 cursor-pointer">
                        <h3 className={`lg:text-xl md:text-xl sm:text-xl text-xs pb-2 font-semibold mr-2 mt-3 `}>{question}</h3>
                        <div className="text-3xl group-hover:rotate-180 transition duration-1500 ml-auto">

                            <BiSolidChevronDown className="w-8 h-8  border-2 rounded-full text-green-500 border-green-500  group-hover:border-yellow-400 group-hover:text-yellow-400 " />
                        </div>
                    </div>
                    <div
                        x-ref="tab"
                        className={`border-l-2 rounded-bl-md border-yellow-500 dark:border-none overflow-hidden duration-[1500ms] transition-all ${activeIndex === i ? "max-h-[700px]" : "max-h-0 "
                            }`}
                    >
                        <p className="text-start px-4 sm:px-8 py-2  p-3 text-xl font-light text-gray-700">
                            {answer}
                        </p>
                    </div>
                </div>

            ))}
        </div>
    )
}

export default Note